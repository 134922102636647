// Librerías
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
// Componentes
import PaginationBackOffice from "../pagination";
import MainContainer from "../Utils/MainContainer";
import ExpandedComponent from "../Utils/ExpanderComponent";
import LoadingGift from "../../../assets/components-icons/LoadingGift";
import { useMemo } from "react";

const columns = [
  {
    name: "ID",
    selector: (row) => row["id"],
    sortable: true,
  },
  {
    name: "Titulo",
    selector: (row) => row["title"],
    sortable: true,
  },
  {
    name: "Dirección",
    selector: (row) => row["adress"],
    sortable: true,
  },
  {
    name: "Propietario",
    sortable: true,
    cell: (row) => `${row.user.firstName} ${row.user.lastName}`,
  },
  {
    name: "Localidad",
    selector: (row) => row["location"]["location"],
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row["statusPoints"],
    sortable: true,
  },
  {
    name: "Acciones",
    cell: (row) => (
      <>
        <Link to={`housing/${row.id}`} className="btn">
          <p className="btn__text">Ver sus datos</p>
        </Link>
      </>
    ),
  },
];

const Housing = (props) => {
  const [properties, setProperties] = useState([]);
  const [nextPage, setNextPage] = useState({
    page: 1,
    perPage: 10,
  });
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState({
    loading: true,
    csv: false,
  });

  useEffect(() => {
    filterHousing(filter);
  }, [nextPage]);

  const inputValue = useMemo(() => {
    return filter;
  }, [filter]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setNextPage({ ...nextPage, page: 1 });
      filterHousing(filter);
    }
  };

  const filterHousing = (event) => {
    setLoading({ loading: true });
    const url = `${BASE_URL}/backoffice/housing-paginate?page=${
      nextPage.page
    }&perPage=${nextPage.perPage}${filter ? `&filter=${filter} ` : ""}`;
    AxiosAuth.get(url)
      .then((response) => {
        setProperties(response.data.data);
        setLoading({ loading: false });
      })
      .catch((e) => {
        setLoading({ loading: false });
      });
  };
  return (
    <MainContainer title="Listado de Propiedades Publicadas">
      <div>
        <div className="ActionBar">
          <input
            className="ActionBar__input"
            type="search"
            placeholder="Propiedad"
            value={inputValue}
            onChange={(e) => setFilter(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
        {!loading.loading ? (
          <>
            <DataTable
              columns={columns}
              data={properties}
              expandableRowDisabled={(row) => row.disabled}
              expandableRowsComponent={<ExpandedComponent />}
            />
            <PaginationBackOffice
              setNextPage={setNextPage}
              nextPage={nextPage}
            />
          </>
        ) : (
          <LoadingGift size={50} />
        )}
      </div>
    </MainContainer>
  );
};

export default Housing;
