// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from '../../../store/user/actions';
import FormData from 'form-data'
import AxiosAuth from '../../../services/requestApi'  

// Recursos
import '../../../assets/styles/Users/Utils/dni_input.sass';
import {BASE_URL} from '../../../Helpers'

class DniInput extends Component {
  // Useful links:
  // https://medium.com/@650egor/react-30-day-challenge-day-2-image-upload-preview-2d534f8eaaa
  // https://stackoverflow.com/questions/37457128/react-open-file-browser-on-click-a-div

  constructor(props){
    super(props)

    const name = localStorage.getItem("name")
    this.state = {
      file: this.props.image,
      change: false,
      placeholder: {display: 'block'},
      photo: {display: 'none'},
      name: name ? 'name' : '',
      userAvatarDefault: ''
    }
    
    
    this.input = React.createRef()
    this.openDialog = this.openDialog.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image) {
      if(this.props.image){
        this.toggleDisplay()
        this.setState({file:this.props.image})
      }
      
    }
  }

  toggleDisplay(){
    if(!this.state.change){
      this.setState({
        change: true,
        placeholder: {display: 'none'},
        photo: {display: 'block'}
      })
    }
  }

  sendPhto(file){

    let data = new FormData();
    data.append('dni', file);
    data.append('side',this.props.side)
    
    AxiosAuth.defaults.headers.post['Content-Type'] = `multipart/form-data;`

    const url = BASE_URL +  `/user/dni`;
    
    AxiosAuth.post(url, data)
      .then((response) => {
      }).catch((error) => {
        console.log(error)
      });

  }

  handleChange(event) {
    const file = event.target.files[0];
    const localImageUrl = URL.createObjectURL(file)
    this.sendPhto(file)
    this.setState({
      file: localImageUrl
    })
    this.toggleDisplay()
  }

  openDialog = () => {
      this.input.current.click()
  }

  render(){
    return(
      <React.Fragment>
        <div className="DniInput">
          <div className="DniInput__placeholder" style={this.state.placeholder}>
            <svg className="DniInput__placeholder__svg" width="70" height="65" viewBox="0 0 70 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2763 5.856C23.649 2.09513 29.2261 0.018059 35 0C46.7687 0 56.5381 8.72727 57.6012 19.9811C64.5663 20.9629 70 26.7796 70 33.9185C70 41.7556 63.4463 48 55.5056 48H43.75C43.1698 48 42.6134 47.7701 42.2032 47.361C41.793 46.9518 41.5625 46.3968 41.5625 45.8182C41.5625 45.2395 41.793 44.6846 42.2032 44.2754C42.6134 43.8662 43.1698 43.6364 43.75 43.6364H55.51C61.1581 43.6364 65.625 39.2204 65.625 33.9185C65.625 28.6124 61.1625 24.1964 55.5056 24.1964H53.3181V22.0145C53.3225 12.3273 45.185 4.36364 35 4.36364C30.2745 4.38246 25.7112 6.0848 22.1331 9.16364C18.8212 12.0087 17.0887 15.4385 17.0887 18.1309V20.0858L15.1419 20.2996C9.03 20.9673 4.375 25.9724 4.375 31.9331C4.375 38.3345 9.75625 43.6364 16.5419 43.6364H26.25C26.8302 43.6364 27.3866 43.8662 27.7968 44.2754C28.207 44.6846 28.4375 45.2395 28.4375 45.8182C28.4375 46.3968 28.207 46.9518 27.7968 47.361C27.3866 47.7701 26.8302 48 26.25 48H16.5419C7.4725 48 0 40.8698 0 31.9331C0 24.24 5.53875 17.8691 12.8712 16.2545C13.4969 12.4887 15.925 8.736 19.2763 5.856Z" fill="#ACACAC"/>
                <path d="M33.4831 17.6409C33.6821 17.4378 33.9186 17.2766 34.1789 17.1666C34.4392 17.0566 34.7182 17 35 17C35.2818 17 35.5608 17.0566 35.8211 17.1666C36.0814 17.2766 36.3179 17.4378 36.5169 17.6409L49.3717 30.7308C49.774 31.1404 50 31.696 50 32.2754C50 32.8547 49.774 33.4103 49.3717 33.82C48.9694 34.2296 48.4238 34.4598 47.8548 34.4598C47.2859 34.4598 46.7403 34.2296 46.338 33.82L37.1425 24.452V62.8184C37.1425 63.397 36.9167 63.9519 36.515 64.361C36.1132 64.7701 35.5682 65 35 65C34.4318 65 33.8868 64.7701 33.485 64.361C33.0833 63.9519 32.8575 63.397 32.8575 62.8184V24.452L23.662 33.82C23.2597 34.2296 22.7141 34.4598 22.1452 34.4598C21.5762 34.4598 21.0306 34.2296 20.6283 33.82C20.226 33.4103 20 32.8547 20 32.2754C20 31.696 20.226 31.1404 20.6283 30.7308L33.4831 17.6409Z" fill="#ACACAC"/>
            </svg>
          </div>
          <div className="DniInput__imgPreview" style={this.state.photo} >
            <img 
                className="DniInput__imgPreview__img" 
                src={this.state.file} 
                onError={(e)=>{e.target.onerror = null; e.target.src=this.state.userAvatarDefault}}
                alt="Avatar"/>
          </div>
          <input ref={ this.input } className="DniInput__photo" accept=".png,.jpg,.jpeg" type="file" name="user_avatar" onChange={this.handleChange}/>
          <button className="DniInput__btn" onClick={ this.openDialog } >Subir Foto</button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {  
  return reducers.userReducer
};

export default connect(mapStateToProps, userActions)(DniInput);
