// Librerías
import React from "react";
// Componentes
import MainContainer from "../Utils/MainContainer";
import ButtonPrimary from "../../ui/button-primary";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DashboardComponent = (props) => {
  const router = useHistory();
  return (
    <MainContainer title="Dashboard">
      <div>
        <ButtonPrimary
          className={"px-3"}
          width={"auto"}
          title={"Blanqueo de contraseña"}
          onClick={() => router.push("/back-office/whiten-password")}
        />
        <br />
      </div>
      <div>
        <ButtonPrimary
          className={"px-3"}
          width={"auto"}
          title={"Ver mensajes de un usuario"}
          onClick={() => router.push("/back-office/view-messages")}
        />
        <br />
      </div>
      <div>
        <ButtonPrimary
          className={"px-3"}
          width={"auto"}
          title={"Agregar alojamiento Promo"}
          onClick={() => router.push("/back-office/hot-sale")}
        />
        <br />
      </div>
    </MainContainer>
  );
};

export default DashboardComponent;
