// Librerías
import React from 'react';
import { connect, useDispatch } from 'react-redux';
// Componentes
import { closeModal } from '../../store/modal/actions'
// Recursos
import x from '../../assets/static/Modals/x.png';
import '../../assets/styles/Modals/_confirm_modal.sass';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";


const ConfirmModal = ({confirmAction, title, text, confirmButtonText, cancelAction}) => {
    
    const dispatch = useDispatch()
    const handleClick = () => {
        confirmAction();
        dispatch(closeModal())
    }

    const handleClickCancel = () =>{
        dispatch(closeModal())
        if(cancelAction){
            cancelAction()
        }
    }
    
    return(
        <div className="ConfirmModal">
            <header className="ConfirmModal__header">{title} <img onClick={() => dispatch(closeModal())} alt="x" src={x} className="x"/></header>
            <section className="ConfirmModal__section">
                <div className="ConfirmModal__section__iconContainer">
                    <i className="ConfirmModal__section__icon" ><FontAwesomeIcon icon={faExclamationCircle} /></i>
                </div>
                <br/>
                {text}
            </section>
            <section className="ConfirmModal__buttons">
                <button className="button_orange" onClick={handleClick}>{confirmButtonText}</button>
                <button className="button_gray" onClick={handleClickCancel}>Cancelar</button>
            </section>
        </div>
    );
}

export default connect(null,{closeModal})(ConfirmModal);
