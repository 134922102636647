// Librerías
import React from 'react';
// Recursos
import '../../../assets/styles/AloharForm/Utils/_checkbox.sass';

const Checkbox = ({id, name, title, value, checked, customClickEvent}) => {
  return(
    <React.Fragment>
      <div className="Checkbox">
        <div className="Checkbox__CheckboxContainer">
          <input id={ id } value={ value } onChange={customClickEvent} checked={checked}  className="Checkbox__CheckboxContainer__checkbox" type="checkbox" name={ name } />
          <label htmlFor={ id } className="Checkbox__CheckboxContainer__title">
            <span className="Checkbox__CheckboxContainer__square"></span>
            { title }
          </label>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Checkbox;