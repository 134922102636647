// Librerías
import React from 'react';
// Componentes
import UserComponents from '../../../components/BackOffice/Users/Users';

const Users = () => {
  return(
    <UserComponents />
  );
}

export default Users;