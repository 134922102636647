import React from "react";
import CrossIcon from "../../../assets/components-icons/CrossIcon";
const PupOp = ({ children, close, onClose,success }) => {
  return (
    <div
      className={close ? "globalModal" : "displayNone"}
      style={{ background: "rgba(000,000,000,0.7)" }}
    >
      <div className={`relative w-11/12 h-auto max-w-screen-sm ${success ? "bg-success" : "bg-white"} sm:h-5/6 max-h-96 rounded-3xl`}>
        <span onClick={() => onClose(false)} className="absolute cursor-pointer top-4 right-4">
          <CrossIcon width={20} color={"#000"} />
        </span>
        {children}
      </div>
    </div>
  );
};

export default PupOp;
