import React from "react";
import arrow from "../../../assets/static/Icons/arrowShort.png";
import ImageLazyLoad from "../../ui/image-lazyLoad";
import "./home.sass";
import { Link } from "react-router-dom/cjs/react-router-dom";
const SectionCard = ({
  title,
  alt,
  secondTitle,
  children,
  backgroundImg,
  backgroundColor,
  link,
  invert,
  styleBackground,
}) => {
  const formattedLink = link || "";

  return (
    <section className="relative hidden sm:block py-[26%]">
      <div
        className={`overflow-hidden bg-black sm:rounded-150 rounded-50 h-full w-full grid grid-cols-2 absolute top-0 left-0 `}
      >
        <div
          className={`${
            invert && "order-2"
          } w-full h-full flex flex-col items-center justify-center relative`}
        >
          <span
            className={`${backgroundImg} w-full h-full font-castaBold bg-no-repeat absolute left-0 top-0`}
            style={styleBackground}
          ></span>
          <ImageLazyLoad
            alt={alt}
            src={title}
            className={` left-1/2  top-1/2 -translate-x-1/2  absolute
                      -translate-y-1/2  w-[70%]  `}
          />
        </div>
        <div
          className={`flex flex-col w-full items-center justify-center  ${backgroundColor} ${
            invert && "order-1"
          }`}
        >
          <div className="flex flex-col  gap-4 w-[70%] ">
            <h2 className="text-white text-[4.9vw] font-castaBold leading-[85%]">
              {secondTitle}
            </h2>
            <Link
              to={formattedLink}
              rel="noopener noreferrer"
              className="text-[#fff!important] text-[1.6vw] font-robotoBold relative w-9/12"
            >
              {children}
              <img
                alt="arrow"
                src={arrow}
                className="absolute right-0 top-[50%] w-[4.5vw]"
                style={{
                  transform: "translate(0, -50%)",
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionCard;
