import React from "react";
import InputGlobal from "../../../../../components/ui/input-global";
import "./formEditReservation.sass";
const FormEditReservation = ({ form, handleChange }) => {
  return (
    <div className="FormEditReservation">
      <InputGlobal
        type={"text"}
        prefix={"Ingrese el Nombre"}
        name={"playerName"}
        value={form.playerName}
        onChange={handleChange}
      />
      <InputGlobal
        type={"text"}
        prefix={"Ingrese cuit"}
        name={"payerCuit"}
        value={form.payerCuit}
        onChange={handleChange}
      />
      <InputGlobal
        type={"text"}
        prefix={"Ingrese monto a transferir"}
        name={"payAmount"}
        value={form.payAmount}
        onChange={handleChange}
      />
      <select
        onChange={handleChange}
        value={form.stateAdmin}
        name="stateAdmin"
        className="FormEditReservation__select"
        required
        defaultValue="null"
      >
        <option>Cambiar estado </option>
        <option value="2">Aceptar</option>
        <option value="null">Pendiente</option>
      </select>
      {/* <InputGlobal
          type={"text"}
          prefix={"cambiar estado "}
          name={"stateAdmin"}
          value={form.stateAdmin}
          onChange={handleChange}
        /> */}
    </div>
  );
};

export default FormEditReservation;
