import React from 'react';
import {connect,useDispatch, useSelector, shallowEqual} from 'react-redux';
import { cleanRedirectUser, redirectUser } from '../../store/user/actions'
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    const loginState = useSelector(state => state.userReducer, shallowEqual)
    const currentLocation = window.location.pathname;
    const dispatch = useDispatch()

    loginState.login ? dispatch(cleanRedirectUser()) : dispatch(redirectUser(currentLocation))

    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to / page
        <Route {...rest} render={props => (
            loginState.login ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default connect()(PrivateRoute);
