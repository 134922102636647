/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
// Librerías
import React, { useState } from "react";
import { BASE_URL } from "../../Helpers";
import AuthService from "../../services/auth";
import axios from "axios";
// Componentes
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
// Recursos
import facebook_login from "../../assets/static/Modals/facebook_login.png";
import google_login from "../../assets/static/Modals/google_login.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import "../../assets/styles/Users/_register.sass";

const eye = <FontAwesomeIcon icon={faEye} />;

const RegisterContainer = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [errorInput, setErrorInput] = useState("");
  const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSendedEmail, setShowSendedEmail] = useState(false);

  const handleGoogleSingUp = () => {
    window.location = `${BASE_URL}/auth/google/`;
  };

  const handleFacebookSingUp = () => {
    window.location = `${BASE_URL}/auth/facebook/`;
  };

  const isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    d = new Date(dateString);
    return d.toISOString().slice(0, 10) === dateString;
  };

  const ifValidate = (input) => {
    if (input === "name" || input === undefined) {
      if (name.length < 4) {
        return { v: false, e: "El nombre debe ser más largo", i: "name" };
      }
    }

    if (input === "lastName" || input === undefined) {
      if (lastName.length < 2) {
        return { v: false, e: "El apellido debe ser más largo", i: "lastName" };
      }
    }

    if (input === "email" || input === undefined) {
      if (email.length < 5) {
        return { v: false, e: "El email debe ser válido", i: "email" };
      }
      let re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.length > 5) {
        if (!re.test(email)) {
          return { v: false, e: "El email es inválido", i: "email" };
        }
      }
    }

    if (input === "birthday" || input === undefined) {
      if (!isValidDate(birthday)) {
        return {
          v: false,
          e: "La fecha de nacimiento no es válida",
          i: "birthday",
        };
      }
    }
    if (input === "password" || input === undefined) {
      /* 1 mayuscula, 1 minuscula, 1 digito, 8 digitos*/
      let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      if (!re.test(password)) {
        return {
          v: false,
          e: "La contraseña debe tener al menos 8 cáracteres, una mayuscula y un número",
          i: "password",
        };
      }
    }

    if (input === "password2" || input === undefined) {
      if (password !== password2) {
        return { v: false, e: "Las contraseña no coinciden", i: "password" };
      }
    }

    return { v: true, e: "", i: "" };
  };

  const handleReSendEmail = () => {
    axios
      .post(`${BASE_URL}/auth/send_confirm_email`, {
        email: email,
      })
      .then(function (response) {
        setShowSendedEmail(false);
        setShowSendEmail(false);
        router.push("/gracias-registro-completo");
        setSuccess("El correo fue reenviado.");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ifSuccess = (input) => {
    if (input === "password" || input === undefined) {
      let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      if (re.test(password)) {
        return {
          v: true,
          e: "Su contraseña está correcta y es segura",
          i: "password",
        };
      }
    }
    return { v: false, e: "", i: "" };
  };

  const handleValidate = (input) => {
    setError("");
    window.localStorage.setItem("registerEmail", email);
    const _ifValidate = ifValidate(input);
    const _ifSuccess = ifSuccess(input);

    setValidate(_ifValidate.v);
    if (!_ifValidate.v) {
      setErrorInput(_ifValidate.i);
      setError(_ifValidate.e);
    }
    if (_ifSuccess.v) {
      setSuccess(_ifSuccess.e);
    }
  };
  const router = useHistory();
  const handleSubmit = (evt) => {
    evt.preventDefault();

    const _ifValidate = ifValidate();
    setValidate(_ifValidate.v);
    if (_ifValidate.v) {
      AuthService.register(name, lastName, email, password, birthday)
        .then(function (response) {
          router.push("/gracias-registro-completo");
          setShowRegisterSuccess(true);
          setSuccess("La cuenta fue creada correctamente.");
        })
        .catch(function (error) {
          console.log(error.response);
          if (error.response.status === 409) {
            setError("Ya existe una cuenta registrada con ese correo.");
            setErrorInput("email");
          } else {
            setError("Error en la comunicación con el servidor.");
          }
        });
    } else {
      setErrorInput(_ifValidate.i);
      setError(_ifValidate.e);
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="Register">
        <form className="Register__form" onSubmit={handleSubmit}>
          <div className="Register__form__header">
            <h2 className="Register__form__header__title">Regístrate</h2>
          </div>
          <div className="Register__form__body">
            {error ? <ErrorAlert message={error} /> : ""}
            {success ? <ErrorAlert type={"success"} message={success} /> : ""}
            {showSendEmail ? (
              <button
                className="Register__form__body__btn"
                onClick={handleReSendEmail}
              >
                Reenviar mail de confirmación
              </button>
            ) : (
              ""
            )}

            <div className="Register__form__body__inputcontainer">
              <label
                htmlFor="name"
                className="Register__form__body__inputcontainer__label"
              >
                Nombre
              </label>
              <input
                id="name"
                type="text"
                placeholder="Escribe tu nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => handleValidate("name")}
                className={
                  errorInput === "name"
                    ? "Register__form__body__inputcontainer__input error"
                    : "Register__form__body__inputcontainer__input"
                }
              />
            </div>
            <div className="Register__form__body__inputcontainer">
              <label
                htmlFor="lastname"
                className="Register__form__body__inputcontainer__label"
              >
                Apellido
              </label>
              <input
                id="lastname"
                type="text"
                placeholder="Escribe tu apellido"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                onBlur={() => handleValidate("lastName")}
                className={
                  errorInput === "lastName"
                    ? "Register__form__body__inputcontainer__input error"
                    : "Register__form__body__inputcontainer__input"
                }
              />
            </div>
            <div className="Register__form__body__inputcontainer">
              <label
                htmlFor="email"
                className="Register__form__body__inputcontainer__label"
              >
                EMAIL
              </label>
              <input
                id="email"
                type="email"
                placeholder="Escribe tu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => handleValidate("email")}
                className={
                  errorInput === "email"
                    ? "Register__form__body__inputcontainer__input error"
                    : "Register__form__body__inputcontainer__input"
                }
              />
            </div>
            <div className="Register__form__body__inputcontainer">
              <label
                htmlFor="birthday"
                className="Register__form__body__inputcontainer__label"
              >
                Fecha de Nacimiento
              </label>
              <input
                id="birthday"
                type="date"
                placeholder="Ingresa tu fecha de Nacimiento"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                onBlur={() => handleValidate("birthday")}
                className={
                  errorInput === "birthday"
                    ? "Register__form__body__inputcontainer__input error"
                    : "Register__form__body__inputcontainer__input"
                }
              />
            </div>
            <div className="Register__form__body__inputcontainer">
              <label
                htmlFor="password"
                className="Register__form__body__inputcontainer__label"
              >
                CONTRASEÑA
              </label>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Escribe tu contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => handleValidate("password")}
                className={
                  errorInput === "password"
                    ? "Register__form__body__inputcontainer__input error"
                    : "Register__form__body__inputcontainer__input"
                }
              />
              <i
                style={
                  showPassword
                    ? {
                        color: "#FF4E00",
                        position: "absolute",
                        top: "50%",
                        right: "29px",
                      }
                    : { position: "absolute", top: "50%", right: "29px" }
                }
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {eye}
              </i>
            </div>
            <div className="Register__form__body__inputcontainer">
              <label
                htmlFor="password2"
                className="Register__form__body__inputcontainer__label"
              >
                CONTRASEÑA
              </label>
              <input
                id="password2"
                type={showPassword ? "text" : "password"}
                placeholder="Escribe tu contraseña"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                onBlur={() => handleValidate("password2")}
                className={
                  errorInput === "password2"
                    ? "Register__form__body__inputcontainer__input error"
                    : "Register__form__body__inputcontainer__input"
                }
              />
            </div>
            <p className="Register__form__body__terms">
              Al seleccionar Registrarme a continuación, acepto Terminos de
              servicio, los términos de pago del servicio y la Política de
              Privacidad y la política contra la descriminación de Alohar.
            </p>
            <button type="submit" className="Register__form__body__btn">
              Regístrarme
            </button>
            <div className="Register__form__body__separator">
              <hr />
              <div className="Register__form__body__separator__text">
                o regístrate con tus redes sociales
              </div>
              <hr />
            </div>
            <div className="Register__form__body__social_container">
              <img
                alt="google login"
                onClick={() => handleGoogleSingUp()}
                src={google_login}
                className="social_login"
              />
              <img
                alt="facebook login"
                onClick={() => handleFacebookSingUp()}
                src={facebook_login}
                className="social_login"
              />
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default RegisterContainer;
