import React from "react";
import img1 from "./../../assets/static/Editorial/img1.jpg";
import body from "./../../assets/static/Editorial/body.jpg";
import IMG_9428 from "./../../assets/static/Editorial/IMG_9428.jpg";
import Navbar from "../../components/Navbar/Navbar";
import alohar from "./../../assets/static/logotipo.png";
import img1mob from "./../../assets/static/Editorial/img1mob.jpg";
import img2mob from "./../../assets/static/Editorial/img2mob.jpg";
import img3mob from "./../../assets/static/Editorial/img3mob.jpg";
import img5mob from "./../../assets/static/Editorial/img5mob.jpg";
import img6mob from "./../../assets/static/Editorial/img6mob.jpg";
import img7mob from "./../../assets/static/Editorial/img7mob.jpg";
import img4mob from "./../../assets/static/Editorial/img4mob.jpg";
import img9mob from "./../../assets/static/Editorial/img9mob.jpg";
import img10mob from "./../../assets/static/Editorial/img10mob.jpg";
import img11mob from "./../../assets/static/Editorial/img11mob.jpg";
import img12mob from "./../../assets/static/Editorial/img12mob.jpg";
import img13mob from "./../../assets/static/Editorial/img13mob.jpg";
import BLACK_LOGO_ALOHAR from "./../../assets/static/Navbar/BLACK_LOGO_ALOHAR.svg";
import "./editorialDesktop.sass";
const EditorialDesktop = () => {
  return (
    <div className="editorialDesktop">
      <div className="editorialDesktop__nav">
        <Navbar
          editorial={true}
          isLanding={true}
          logo="white"
          showMobile={true}
        />
      </div>
      <div className="editorialDesktop__container">
        <h1 className="editorialDesktop__container__titleDesktop">
          Geo - <br />
          locralización
        </h1>
        <section className="editorialDesktop__container__colums">
          <p>
            LOCRO, LUQRU, RUQRU tiene su origen <br /> en la cultura Quichua de
            la región
            <br /> andina, quienes utilizan maíz y porotos <br /> como
            ingredientes principales, propios <br /> de tierras frías y áridas,
          </p>
          <p>
            porque sus calorías son de mucha <br /> ayuda para habitar esas
            regiones. Plato <br /> típico patrio, tal vez porque estuvo en{" "}
            <br />
            las buenas y en las malas desde el prin- <br />
            cipio de nuestra identidad.
          </p>
        </section>
        <div className="editorialDesktop__container__figure">
          <img
            className="editorialDesktop__container__figure__img"
            src={img1}
            alt="Foto"
          />
          <img
            className="editorialDesktop__container__figure__logo"
            src={alohar}
            alt="Logo"
          />
        </div>
        <section className="editorialDesktop__container__colums">
          <h3>
            Quien se considere <br /> Gaucho deberia <br />
            reconocer cada pago <br />
            por su Locro.
          </h3>

          <p>
            Sus ingredientes son un recorrido por
            <br /> nuestra soberanía, a lo largo de su <br />
            vida y su extensión. El maíz, la carne <br />
            de vaca, el chorizo, la calabaza y los <br />
            porotos, son cultivados en todas las <br />
            llanuras argentinas.
          </p>
        </section>
        <div className="editorialDesktop__container__columsImg">
          <img
            className="editorialDesktop__container__columsImg__body"
            src={img13mob}
            alt="Foto"
          />
        </div>
        <h3 className="editorialDesktop__container__subtitle">
          El locro se cocina a fuego lento <br />
          durante horas para reunir gente alrededor.
        </h3>
        <section className="editorialDesktop__container__columsMargin">
          <h3>
            Viajar en un feriado patrio <br />y cocinar un locro <br />
            con ingredientes locales, <br />
            en cualquier destino del
            <br /> país, es una excelente <br />
            manera de compartir la <br />
            cultura de la región.
          </h3>
          <p>
            El locro es nuestro plato soberano,
            <br /> y cada región tiene su propia versión <br />
            con ingredientes locales que reflejan <br />
            la diversidad y riqueza del país. <br />
            Cocinar un locro en equipo, en un
            <br /> lugar nuevo, puede ser una experiencia <br />
            divertida y enriquecedora. También, una
            <br /> manera de conocer los mercados
            <br /> locales, aprender sobre la historia –<br />y otras historias–
            <br />y disfrutar de una
            <br /> manera auténtica y memorable
          </p>
        </section>

        <div className="editorialDesktop__container__columsImg">
          <img
            className="editorialDesktop__container__columsImg__body"
            src={IMG_9428}
            alt="Foto"
          />
        </div>
        <h3 className="editorialDesktop__container__subtitle">
          Geolocralización. Cada lugar tiene su propio toque <br /> y secreto
          para hacer del locro... un locro de autor.
        </h3>
        <br />
        <h2 className="editorialDesktop__container__seconTitleDesktop">
          Fondo con Locro <br />
          Alohate
        </h2>
        <div className="editorialDesktop__container__columsImg">
          <img
            className="editorialDesktop__container__columsImg__imgScale"
            src={img1mob}
            alt="Foto"
          />
        </div>

        <section className="editorialDesktop__container__columsMarginMax">
          <p>
            El dia anterior fuimos a la feria y pudi-
            <br />
            mos elegir los ingredientes. Indispens-
            <br />
            able el zapallo plomo, zapallo cabutia, <br />
            que hace la diferencia tanto en la textu-
            <br />
            ra y en el sabor, los choclos en su punto
            <br /> justo, morrones rojos, verdes y amaril-
            <br />
            los, verdeo, cebollas, batatas, zapallo <br />
            anco, zapallo común y de ahi al mercado
          </p>

          <p>
            por la carne: Pechito de cerdo, <br />
            falda, patitas de cerdo, chorizos, chorizo colo-
            <br />
            rado, panceta ahumada y salada y en la <br />
            despensa los porotos y el
            <br /> maíz blanco. Los dejamos remojando <br />y preparamos el
            patio y montamos <br />
            una cocina de campaña para el día
            <br /> siguiente.
            <br />
          </p>
        </section>
        <div className="editorialDesktop__container__imgGrid">
          <img src={img2mob} alt="Foto" />
          <img src={img6mob} alt="Foto" />
          <img src={img3mob} alt="Foto" />
          <img src={img5mob} alt="Foto" />
        </div>

        <section className="editorialDesktop__container__columsMarginMax">
          <p>
            Remojamos las legumbres 8 a 10 <br />
            horas, y estuvo bien, en este caso era un
            <br /> locro grande para 40 personas y fueron
            <br /> muchas horas de cocción, para un locro <br />
            chico tal vez un poco más de remojo. <br />
            Usamos zapallo plomo, que es el gi-
            <br />
            gante gris que se desploma con la coc-
            <br />
            ción y hace que sea cremoso, Zapallo <br />
            cabutia o japonés que le aporta un
          </p>
          <p>
            dulzor diferente, batata, zanahoria, ce-
            <br />
            bolla y morrón. <br />
            <br />
            Hicimos los cortes de acuerdo
            <br /> al tiempo de cocción de cada hortaliza: <br />
            los zapallos en pedazos más grandes,
            <br /> las batatas medianas, y las zanahorias
            <br /> un poco más chicas.
          </p>
        </section>
        <div className="editorialDesktop__container__imgGrid">
          <img src={img4mob} alt="Foto" />
          <img src={img7mob} alt="Foto" />
          <img src={img9mob} alt="Foto" />
          <img src={img10mob} alt="Foto" />
        </div>
        <section className="editorialDesktop__container__colums">
          <p>
            Sofreímos la panceta, tanto la salada <br /> como la ahumada, las
            retiramos y con <br />
            la grasa que quedó en la olla cocinamos <br />
            carnes con hueso como falda y extremi-
            <br />
            dades del cerdo para integrar el sabor.
            <br /> Lo mismo con las carnes: hay que tener en
          </p>
          <p>
            cuenta el bocado en una cazuela... Y<br /> hacerla fácil de entrada.
            Si bien en la
            <br /> cocción hay que esperar el punto en que
            <br />
            la carne se deshace con el tenedor, los <br />
            trozos deben ser confortables para
            <br /> disfrutar de una cazuela en mano
          </p>
        </section>
        <div className="editorialDesktop__container__columsImg">
          <img
            className="editorialDesktop__container__columsImg__bodyTop"
            src={img11mob}
            alt="Foto"
          />
        </div>

        <section className="editorialDesktop__container__columsMarginMax">
          <p>
            Cuchara de madera en mano, distribui-
            <br />
            mos los ingredientes dentro de la olla y<br /> agregamos las
            hortalizas, cebolla, pi-
            <br />
            miento, zanahoria, batata primero hasta
            <br /> rehogarlas. El resto, hasta que soltaron
            <br /> su propia agua y fuimos agregando un
            <br /> caldo tibio que hicimos con los cortes y<br /> cáscaras
            remanentes para darle más <br /> sabor.
          </p>
          <p>
            Por último las legumbres remojadas, <br />
            previamente enjuagadas. <br />
            Agregamos las pancetas y los chorizos <br />
            pre cocidos para que no pierdan su
            <br /> presencia y se deshagan en esa cocción
            <br /> tan lenta. <br />
            Aderezo: una salsa picante para quien <br />
            lo prefiera. Lo hacemos con verdeo,
            <br /> pimiento rojo, ají picante, aceite, sal y<br /> pimienta.
          </p>
        </section>
        <div className="editorialDesktop__container__figure">
          <img
            className="editorialDesktop__container__figure__img"
            src={img12mob}
            alt="Foto"
          />
        </div>
        <section className="editorialDesktop__container__columsMargin">
          <h3>
            En este caso usamos
            <br />
            Zapallos Cabutia
            <br />
            como cazuelas,
            <br />
            previamente los marcamos
            <br />
            en un hervor
            <br />
            y los llevamos al punto
            <br />
            *dente* al fuego.
          </h3>
          <p>
            Es importante tener en cuenta que <br />
            fermenta en un corto período <br />
            después de su elaboración, así que va <br />
            a la mesa. O lo mejor para su conservación <br />
            es dividirlo en diferentes recipientes <br />
            para acelerar su enfriamiento y <br />
            luego conservar en heladera.
          </p>
        </section>
        <div className="editorialDesktop__container__figure">
          <img
            className="editorialDesktop__container__figure__img"
            src={img13mob}
            alt="Foto"
          />
        </div>
        <img
          className="editorialDesktop__container__logoFooter"
          src={BLACK_LOGO_ALOHAR}
          alt="Foto"
        />
      </div>
    </div>
  );
};

export default EditorialDesktop;
