import React, { Suspense, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { useState } from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";

const SwiperBanks = () => {
  SwiperCore.use([Autoplay]);
  const [banks, setBanks] = useState([]);
  const urlPromotions = useMemo(
    () => `${BASE_URL}/location/promotions`,
    [BASE_URL]
  );

  useEffect(() => {
    AxiosAuth.get(urlPromotions)
      .then((response) => {
        const filterBanks = response.data.filter((e) => {
          return isMobile ? e.kind === 4 : e.kind === 5;
        });
        setBanks(filterBanks);
      })
      .catch((e) => {});
  }, [urlPromotions]);
  return (
    <>
      <Suspense fallback={<></>}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          // py-[40%] sm:py-0
          className="relative w-full h-full "
        >
          {banks.length > 0 &&
            banks.map((item) => (
              <SwiperSlide key={item.id}>
                <div
                  style={{
                    backgroundImage: `url(${BASE_URL}/location/promotion/${item.image})`,
                  }}
                  className={`z-0 bg-${item.image}  bg-center bg-contain sm:bg-cover sm:py-[26%]  h-screen sm:h-full w-full bg-no-repeat   flex items-center justify-center`}
                ></div>
              </SwiperSlide>
            ))}
        </Swiper>
      </Suspense>
    </>
  );
};

export default SwiperBanks;
