import React, { useEffect } from "react";
import EditorialDesktop from "./EditorialDesktop";
import EditorialMobile from "./EditorialMobile";

import { animateScroll as scroll } from "react-scroll";
import { throttle } from "lodash";
import smoothscroll from "smoothscroll-polyfill";
const Editorial = () => {
  // useEffect(() => {
  //   const handleScroll = throttle((event) => {
  //     event.preventDefault();
  //     let delta = 0;

  //     if (event.deltaY) {
  //       delta = event.deltaY;
  //     } else if (event.touches) {
  //       const currentY = event.touches[0].clientY;
  //       delta = startY - currentY;
  //       startY = currentY;
  //     }

  //     const scrollOffset = 200;

  //     if (delta > 0) {
  //       scroll.scrollMore(scrollOffset, {
  //         duration: 500,
  //         smooth: true,
  //       });
  //     } else {
  //       scroll.scrollMore(-scrollOffset, {
  //         duration: 500,
  //         smooth: true,
  //       });
  //     }
  //   }, 500);

  //   let startY = 0;

  //   const handleTouchStart = (event) => {
  //     startY = event.touches[0].clientY;
  //   };

  //   window.addEventListener("wheel", handleScroll);
  //   window.addEventListener("touchmove", handleScroll, { passive: false });
  //   window.addEventListener("touchstart", handleTouchStart);

  //   return () => {
  //     window.removeEventListener("wheel", handleScroll);
  //     window.removeEventListener("touchmove", handleScroll);
  //     window.removeEventListener("touchstart", handleTouchStart);
  //   };
  // }, []);
  useEffect(() => {
    smoothscroll.polyfill();

    let startY = 0;
    let touching = false;

    const handleScroll = (event) => {
      event.preventDefault();
      let delta = 0;

      if (event.deltaY) {
        delta = event.deltaY;
      } else if (touching && event.touches.length === 1) {
        const currentY = event.touches[0].clientY;
        delta = startY - currentY;
        startY = currentY;
      }

      const scrollOffset = 500;

      if (delta > 0) {
        window.scrollBy({ top: scrollOffset, behavior: 'smooth' });
      } else {
        window.scrollBy({ top: -scrollOffset, behavior: 'smooth' });
      }
    };

    const handleTouchStart = (event) => {
      if (event.touches.length === 1) {
        startY = event.touches[0].clientY;
        touching = true;
      }
    };

    const handleTouchEnd = () => {
      touching = false;
    };

    window.addEventListener('wheel', handleScroll, { passive: false });
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('touchcancel', handleTouchEnd);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, []);


  return (
    <>
      <EditorialDesktop />
      <EditorialMobile />
    </>
  );
};

export default Editorial;
