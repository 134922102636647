// Librerías
import React from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import AuthService from "../../services/auth";
import { logoutUser } from "../../store/user/actions";
// Recursos
import "../../assets/styles/Utils/__sidemenu.sass";
import logOut from "../../assets/static/Icons/logOut.svg";

const SideMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = (evt) => {
    localStorage.removeItem("housingPublicProcessData");
    AuthService.logout();
    dispatch(logoutUser());
    history.push("/");
  };

  return (
    <div className="SideMenuBefore">
      <div className="SideMenu">
        <NavLink
          className="SideMenu__link"
          activeClassName="activeFavorites "
          exact
          to="/profile"
        >
          <div className="borderTop"></div>
          <NavLink
            className="SideMenu__link__icon"
            activeClassName="iconActiveFavorites"
            exact
            to="/profile"
          >
            <svg
              className="SideMenu__link__icon__svg iconActive__svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g
                className="SideMenu__link__icon__svg"
                stroke-linejoin="round"
                stroke-linecap="round"
                stroke-width="2"
                fill="none"
                stroke="currentColor"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle
                  className="SideMenu__link__icon__svg iconActive__svg"
                  cx="12"
                  cy="7"
                  r="4"
                ></circle>
              </g>
            </svg>
            <p>Mis datos</p>
          </NavLink>
          <div className="borderBottom"></div>
        </NavLink>
        <NavLink
          className="SideMenu__link"
          activeClassName="activeHousing"
          exact
          to="/profile/housing"
        >
          <div className="borderTop"></div>
          <NavLink
            className="SideMenu__link__icon"
            activeClassName="iconActive"
            exact
            to="/profile/housing"
          >
            <svg
              className="SideMenu__link__icon__svg iconActive__svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 10.5L14 2.33331L24.5 10.5V23.3333C24.5 23.9522 24.2542 24.5456 23.8166 24.9832C23.379 25.4208 22.7855 25.6666 22.1667 25.6666H5.83333C5.21449 25.6666 4.621 25.4208 4.18342 24.9832C3.74583 24.5456 3.5 23.9522 3.5 23.3333V10.5Z"
                stroke="#fff"
                stroke-width="2.79728"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5 25.6667V14H17.5V25.6667"
                stroke="#fff"
                stroke-width="2.79728"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Alohamientos</p>
          </NavLink>
          <div className="borderBottom"></div>
        </NavLink>
        <NavLink
          className="SideMenu__link"
          activeClassName="activeFavorites "
          exact
          to="/messengers"
        >
          <div className="borderTop"></div>
          <NavLink
            className="SideMenu__link__icon"
            activeClassName="iconActiveFavorites"
            exact
            to="/messengers"
          >
            <svg
              className="SideMenu__link__icon__svg iconActive__svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="iconActive__svg"
                d="M24.5 13.4167C24.504 14.9565 24.1442 16.4756 23.45 17.85C22.6268 19.4971 21.3614 20.8824 19.7954 21.8509C18.2293 22.8193 16.4246 23.3327 14.5833 23.3334C13.0435 23.3374 11.5245 22.9776 10.15 22.2834L3.5 24.5L5.71667 17.85C5.02242 16.4756 4.66265 14.9565 4.66667 13.4167C4.66738 11.5754 5.18071 9.77069 6.14917 8.20468C7.11763 6.63866 8.50296 5.3732 10.15 4.55003C11.5245 3.85579 13.0435 3.49602 14.5833 3.50003H15.1667C17.5984 3.63419 19.8952 4.66059 21.6173 6.3827C23.3394 8.10482 24.3658 10.4016 24.5 12.8334V13.4167Z"
                stroke="#fff"
                stroke-width="2.63216"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Mensajes</p>
          </NavLink>
          <div className="borderBottom"></div>
        </NavLink>
        <NavLink
          className="SideMenu__link"
          activeClassName="activeFavorites"
          exact
          to="/profile/favorites"
        >
          <div className="borderTop"></div>
          <NavLink
            className="SideMenu__link__icon"
            activeClassName="iconActiveFavorites"
            exact
            to="/profile/favorites"
          >
            <svg
              className="SideMenu__link__icon__svg iconActive__svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.3131 5.37827C23.7173 4.7821 23.0098 4.30919 22.2311 3.98653C21.4524 3.66387 20.6177 3.4978 19.7748 3.4978C18.9319 3.4978 18.0973 3.66387 17.3186 3.98653C16.5399 4.30919 15.8324 4.7821 15.2365 5.37827L13.9998 6.61493L12.7631 5.37827C11.5595 4.17463 9.92702 3.49843 8.22481 3.49843C6.52261 3.49843 4.89012 4.17463 3.68648 5.37827C2.48284 6.58191 1.80664 8.21439 1.80664 9.9166C1.80664 11.6188 2.48284 13.2513 3.68648 14.4549L4.92315 15.6916L13.9998 24.7683L23.0765 15.6916L24.3131 14.4549C24.9093 13.8591 25.3822 13.1515 25.7049 12.3728C26.0275 11.5941 26.1936 10.7595 26.1936 9.9166C26.1936 9.0737 26.0275 8.23905 25.7049 7.46035C25.3822 6.68165 24.9093 5.97415 24.3131 5.37827V5.37827Z"
                stroke="white"
                stroke-width="2.71071"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Favoritos</p>
          </NavLink>
          <div className="borderBottom"></div>
        </NavLink>
        <NavLink
          className="SideMenu__link"
          activeClassName="activeHelp"
          exact
          to="/ayuda"
        >
          <div className="borderTop"></div>
          <NavLink
            className="SideMenu__link__icon"
            activeClassName="iconHelpActive"
            exact
            to="/ayuda"
          >
            <svg
              className="SideMenu__link__icon__svg iconActive__svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.0007 25.6666C20.444 25.6666 25.6673 20.4432 25.6673 13.9999C25.6673 7.5566 20.444 2.33325 14.0007 2.33325C7.55733 2.33325 2.33398 7.5566 2.33398 13.9999C2.33398 20.4432 7.55733 25.6666 14.0007 25.6666Z"
                stroke="white"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6055 10.4999C10.8798 9.72021 11.4211 9.06272 12.1337 8.64392C12.8464 8.22512 13.6842 8.07203 14.4988 8.21176C15.3135 8.3515 16.0524 8.77504 16.5847 9.40738C17.117 10.0397 17.4084 10.84 17.4071 11.6666C17.4071 13.9999 13.9071 15.1666 13.9071 15.1666"
                stroke="white"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 19.8333H14.0109"
                stroke="white"
                stroke-width="2.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>Ayuda</p>
          </NavLink>
          <div className="borderBottom"></div>
        </NavLink>
      </div>
      <Link className="SideMenu__link__exit" onClick={() => handleLogout()}>
        <img
          alt="SignOut"
          className="SideMenu__link__icon__iconHelp iconHelp"
          src={logOut}
        />
        <p>Cerrar sesión</p>
      </Link>
    </div>
  );
};

export default connect(null, { logoutUser })(SideMenu);
