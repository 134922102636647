// Librerías
import React from 'react';
// Componentes
import Previaje from '../../../components/BackOffice/Housing/Previaje';

const HousingPreviaje = () => {
  return(
    <Previaje />
  );
}

export default HousingPreviaje;