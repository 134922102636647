// Librerías
import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { closeModal, openRecoverModal } from '../../store/modal/actions'
// Recursos
import x from '../../assets/static/Modals/x.svg';
import clock from '../..//assets/static/Icons/clock.svg';
import credit from '../..//assets/static/Icons/credit-card.svg';
import key from '../..//assets/static/Icons/key.svg';
import coffee from '../..//assets/static/Icons/coffee.svg';
import camera from '../..//assets/static/Icons/camera.svg';
import clipboard from '../..//assets/static/Icons/clipboard.svg';
import calendar from '../..//assets/static/Icons/calendar.svg';
import book from '../..//assets/static/Icons/book-open.svg';
import map from '../..//assets/static/Icons/map-pin.svg';
import '../../assets/styles/Modals/_edit_modal.sass';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const completeIcon = <i style={{color:"#b3b3b3"}} ><FontAwesomeIcon icon={faCheckCircle} /></i>;
const uncompleteIcon = <i style={{color:"#FF4E00"}}><FontAwesomeIcon icon={faTimesCircle} /></i>;
// const clock =  <img src={clock} />
const steps ={ 
  type:"Tipo de alohamiento",
  quantities:"Cantidad de Huéspedes",
  location:"Ubicación",
  services:"Servicios",
  sanitary:"Higiene y Seguridad",
  media:"Fotos",
  rules:"Normas de la casa",
  reservation:"Horarios de Checkin y Checkout",
  title:"Título y Descripción",
  price:"Precio y descuentos",
  payment:"Datos bancarios",
  tos:"Términos y condiciones",
  calendar:"Calendario",
};

const EditModal = ({ housing }) => {
  const {id,title, stepStatus} = housing
  const dispatch = useDispatch()
  const ListHousing = (id) => {
    return (<div className="EditModal__body__container">
       {/* {Object.keys(steps).map((step,index)=>(
        <div className="EditModal__body__container__part">
          {stepStatus[step] ? completeIcon : uncompleteIcon }
          <a href={`/edit/${index+3}/${id}`}>{steps[step]} </a>
        </div>
      ))} */}
      {/* {stepStatus[step] ? <img src={clock}/> : uncompleteIcon } */}
      {/* //edit/3/2579 */}
        <div className="EditModal__body__container__part">
          <a className="EditModal__body__container__part__enlace" href={`/edit/11/${id}`}><img src={clipboard}/>Título y Descripción </a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/3/${id}`}>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.5 10.5L14 2.33331L24.5 10.5V23.3333C24.5 23.9522 24.2542 24.5456 23.8166 24.9832C23.379 25.4208 22.7855 25.6666 22.1667 25.6666H5.83333C5.21449 25.6666 4.621 25.4208 4.18342 24.9832C3.74583 24.5456 3.5 23.9522 3.5 23.3333V10.5Z" stroke="#2B2B2B" stroke-width="2.79728" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5 25.6667V14H17.5V25.6667" stroke="#2B2B2B" stroke-width="2.79728" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Tipo de alohamiento 
          </a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/4/${id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g stroke-linejoin="round" stroke-linecap="round" stroke-width="2" fill="none" stroke="currentColor">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path  d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path  d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </g>
            </svg>
            Cantidad de Huéspedes
          </a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/5/${id}`}><img src={map}/>Ubicación </a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/6/${id}`}><img src={coffee}/>Servicios </a>
        </div>
        <div className="EditModal__body__container__part">
          <a className="EditModal__body__container__part__enlace" href={`/edit/7/${id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g stroke-linejoin="round" stroke-linecap="round" stroke-width="2" fill="none" stroke="currentColor">
                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
              </g>
            </svg>
            Higiene y Seguridad
          </a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/8/${id}`}><img src={camera}/> Fotos</a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/9/${id}`}><img src={key}/> Normas de la casa</a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/15/${id}`}><img src={calendar}/>Calendario </a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/10/${id}`}><img src={clock}/>Checkin y Checkout </a>
        </div>
        <div className="EditModal__body__container__part">
          <a className="EditModal__body__container__part__enlace" href={`/edit/12/${id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <g stroke-linejoin="round" stroke-linecap="round" stroke-width="2" fill="none" stroke="currentColor">
                <path d="M12 1v22"></path>
                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
              </g>
            </svg>
            Precio y descuentos 
          </a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/13/${id}`}><img src={credit}/> Datos bancarios</a>
          <a className="EditModal__body__container__part__enlace" href={`/edit/14/${id}`}><img src={book}/> Términos y condiciones</a>
        </div>
          
    </div>)
  }
  return(
    <div className="EditModal">
      <header className="EditModal__header">
        <div className="EditModal__header__titles">
          <h3 className="EditModal__header__titles__edit">
            Editar Publicación
          </h3>
          <h2 className="EditModal__header__titles__title">
            {title}
          </h2>
        </div>
          <img className="EditModal__header__cross" onClick={() => dispatch(closeModal())} alt="x" src={x} />
      </header>
      
      <div className="EditModal__body">
        { ListHousing(id) }
      </div>
    </div>
  );
}
export default connect(null,{closeModal})(EditModal);
