// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
// Recursos
import { BASE_URL } from "../../../Helpers";
import "../../../assets/styles/AloharForm/Utils/_buttons.sass";
import AxiosAuth from "../../../services/requestApi";

class Buttons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: this.props.step === 1 ? true : this.props.validate,
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.hadnleValidate = this.hadnleValidate.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate });
    }
  }

  async sendData(url, bodyParameters) {
    const send = await AxiosAuth.post(url, bodyParameters)
      .then((data) => {
        if (data.status === 201) {
          if (data.data.id) this.props.housingIdSetup(data.data);
        }
        return true;
      })
      .catch((e) => {
        return false;
      });
    return send;
  }

  hadnleValidate() {
    this.props.countValidate();
    if (this.state.validate) this.handleNextStep();
  }

  async handleNextStep() {
    this.props.validateStep(false);
    const currentStep = this.props.step;
    if (this.props.steps[currentStep].endopoit) {
      const bodyParameters = this.props.steps[currentStep].bodyParameters;
      const url = BASE_URL + this.props.steps[currentStep].endopoit;
      bodyParameters.id = this.props.housingId;

      const send = await this.sendData(url, bodyParameters);

      if (send) {
        this.props.stepSuccess(currentStep, true);
        /* We have connection, try to send old steps*/
        Object.values(this.props.steps).filter((obj, key) => {
          if (obj.status === false) {
            const oldEndPoint = BASE_URL + obj.endopoit;
            if (this.sendData(oldEndPoint, obj.bodyParameters)) {
              this.props.stepSuccess(key, true);
            }
          }
        }, this);
      } else {
        this.props.stepSuccess(currentStep, false);
      }
      this.props.nextStep();
    } else {
      this.props.nextStep();
    }

    if (currentStep === 14) this.props.nextStep();

    /* Set scroll to top*/
    window.scrollTo(0, 0);

    // if(currentStep<16){
    //   /* Save data on localStorage */
    //   localStorage.setItem("housingPublicProcessData", JSON.stringify({
    //     step: this.props.step + 1,
    //     validate: this.state.validate,
    //     steps: this.props.steps,
    //     housingId: this.props.housingId
    //   }));
    // }else{
    //   /* Finish process, clean storage*/
    //   localStorage.removeItem("housingPublicProcessData")

    // }
  }

  handlePreviousStep = () => {
    /* Set scroll to top*/
    window.scrollTo(0, 0);
    this.props.previousStep();
  };

  render() {
    return (
      <React.Fragment>
        <div className="Buttons">
          {this.props.step === 1 ? (
            ""
          ) : (
            <button
              className="Buttons__btn-secondary"
              onClick={this.handlePreviousStep}
            >
              Atrás
            </button>
          )}
          <button
            className="Buttons__btn-primary"
            onClick={this.hadnleValidate}
          >
            Siguiente
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return { ...reducers.aloharFormReducer, ...reducers.userReducer };
};

export default connect(mapStateToProps, aloharFormActions)(Buttons);
