// Librerías
import React from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector, shallowEqual } from "react-redux";
import { openRegisterModal } from "../../../store/modal/actions";
// Recursos
import "../../../assets/styles/RentLanding/Content/_easyHousing.sass";
import ButtonPrimary from "../../ui/button-primary";

const EasyHousing = () => {
  const user = useSelector((state) => state.userReducer, shallowEqual);
  const router = useHistory();

  return (
    <React.Fragment>
      <div className="EasyHousing">
        <div className="EasyHousing__headline">
          <div className="EasyHousing__headline__container">
            <div className="EasyHousing__headline__article">
              <p className="EasyHousing__headline__article__title">
                ¿Querés ser parte de la {" "}
                <strong>
                  Comunidad
                  <br />
                  de Alohadores
                </strong>
                ?
                <br />
                Es muy fácil
              </p>
              <ButtonPrimary
                title={"Alohá"}
                onClick={
                  user.login
                    ? router.push("/alohar")
                    : () => router.push("/register")
                }
              />
            </div>
          </div>
          <div className="EasyHousing__headline__quote">
            <div className="EasyHousing__headline__quote__container">
              <p className="EasyHousing__headline__quote__container__text">
                - Alohá gente
                <br />
                en tu casa
                <br />y pesos
                <br />
                en tu cuenta!
              </p>
              <p className="EasyHousing__headline__quote__container__names">
                Estefanía, Alohadora
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { openRegisterModal })(EasyHousing);
