// Librerías
import React from 'react';
// Componentes
import ConfirmedReservationsList from '../../../components/BackOffice/Reservations/ConfirmedReservations';

const ConfirmedReservations = () => {
  return(
    <ConfirmedReservationsList />
  );
}

export default ConfirmedReservations;