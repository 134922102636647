import React from "react";
import "./button-white.sass";
const ButtonWhite = ({ title, onClick, width, height, disabled }) => {
  return (
    <button
      disabled={disabled ? true : false}
      style={{
        width: width ? width : "150px",
        height: height ? height : "55px",
      }}
      onClick={onClick}
      className="buttonWhite"
    >
      {title}
    </button>
  );
};

export default ButtonWhite;
