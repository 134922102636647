// Librerías
import React from 'react';
import { connect, useDispatch } from 'react-redux';
// Componentes
import { closeModal } from '../../store/modal/actions'
// Recursos
import x from '../../assets/static/Modals/x.png';
import whatsapp from '../../assets/static/whatsapp.png';
import '../../assets/styles/Modals/_confirm_modal.sass';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const NotificationModal = ({buttonText, title, text, icon = faExclamationCircle, showWhatsApp = false}) => {

    const dispatch = useDispatch()

    return(
        <div className="ConfirmModal">
            <header className="ConfirmModal__header">{title} <img onClick={() => dispatch(closeModal())} alt="x" src={x} className="x"/></header>
            <section className="ConfirmModal__section">
                <div className="ConfirmModal__section__iconContainer">
                    <i className="ConfirmModal__section__icon" ><FontAwesomeIcon icon={icon} /></i>
                </div>
                <br/>
                {text}
                {showWhatsApp ?
                    <div>
                    <p>Podes ponerte en contacto con nosotros directamente por Whatsapp: </p>
                    <a className="ConfirmModal__section__whatsapp" href="https://api.whatsapp.com/send?phone=5492233018749" target="_blank" rel="noopener noreferrer">
                        <img alt="whatsapp" src={whatsapp}/>
                    </a>
                    </div>
                :''}
            </section>
            <section className="ConfirmModal__buttons">
                <button className="button_orange" onClick={() => dispatch(closeModal())} >{buttonText}</button>
            </section>
        </div>
    );
}

export default connect(null,{closeModal})(NotificationModal);
