// Librerías
import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';
import ReactGA from 'react-ga';
import {isMobile} from 'react-device-detect';
// Componentes
import Navbar from '../../components/Navbar/Navbar';
import MessengerComponent from '../../components/MessengersPage'

ReactGA.initialize('G-WM6E6JJLL6');

const MessengersPage = () => {
  return(
    <ScreenClassProvider>
      {/* { isMobile ? ''
        : <Navbar />
      } */}
      <MessengerComponent/>
    </ScreenClassProvider>
  );
}

export default MessengersPage;