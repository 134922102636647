// Librerías
import React from 'react'
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../Helpers';
// Sass
import '../../assets/styles/Utils/_valuationcontainer.sass';

const setAvatar = (avatar, firstName, lastName) => {
  if(avatar === null || avatar === undefined) return `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${firstName}%20${lastName}`;
  if(avatar?.includes("https")) return avatar;
  return `${BASE_URL}/user/avatar/${avatar.replace("public", "")}`;
}

const ValuationContainer = (props) => {
  const valuation = props.valuation;
  const avatar = setAvatar(valuation.from.avatar, valuation.from.firstName, valuation.from.lastName);

  return (
    <div className="ValuationContainer">
      <div className="ValuationContainer__top">
        <div className="ValuationContainer__top__img">
          <Link to={`/users/show/${valuation.from.id}`}>
            <img src={ avatar } alt={`Avatar de ${valuation.from.firstName}`} />
          </Link>
        </div>
        <div className="ValuationContainer__top__c">
          <p className="ValuationContainer__top__c__name">
            { `${valuation.from.firstName} ${valuation.from.lastName}` }
          </p>
          <p className="ValuationContainer__top__c__date">
            { valuation.reservation.checkout }
          </p>
        </div>
      </div>
      <p className="ValuationContainer__text">
        { valuation.publicMessage }
      </p>
    </div>
  )
}

export default ValuationContainer;