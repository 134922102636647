// Librerías
import React from 'react';
// Recursos
import '../../assets/styles/Utils/toggleButtons.sass';

const ToggleButtons = ({ id, name, title, value, checked,customClickEvent}) => {
  return(
    <React.Fragment>
      <div className="toggleButtons">
        <label className="switch" htmlFor={ id }>
          <input type="checkbox" checked={checked} value={value} id={ id } name={ name } onChange={customClickEvent} />
          <span className="slider"></span>
        </label>
        <p className="title">{ title }</p>
      </div>
    </React.Fragment>
  );
}

export default ToggleButtons;