import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import { BASE_URL } from "../../Helpers";
import {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
  openContactAlohadorModal,
  openReportModal,
} from "../../store/modal/actions";
import MessengerModal from "../Modals/MessengerModal";
import ModalWrapper from "../Modals/ModalWrapper";
import ReportModal from "../Modals/ReportModal";

const ButtonContactHost = ({ title, housingId }) => {
  const userState = useSelector((state) => state.userReducer, shallowEqual);
  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const [firstName, setFirstName] = useState("");
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();
  const handleActionModal = (modal) => {
    if (!userState.login) {
      dispatch(openLoginModal());
    } else {
      if (modal === "contact") dispatch(openContactAlohadorModal());
      if (modal === "report") dispatch(openReportModal());
    }
  };
  const selectModal = (modalType) => {
    switch (modalType) {
      case "CONTACT_ALOHADOR":
        return (
          <ModalWrapper key="contacto_modal">
            <MessengerModal
              firstName={firstName}
              housingId={housingId}
              userId={userId}
            />
          </ModalWrapper>
        );
      case "REPORT":
        return (
          <ModalWrapper key="report_modal">
            <ReportModal housingId={housingId} />
          </ModalWrapper>
        );
      default:
        return "";
    }
  };
  useEffect(() => {
    const apiKey = process.env.REACT_APP_ALOHAR_KEY;
    const url = `${BASE_URL}/housing/${housingId}?apiKey=${apiKey}`;
    axios
      .get(url)
      .then(function (response) {
        setFirstName(response.data.user.firstName);
        setUserId(response.data.user.id);
      })
      .catch(function (error) {});
  }, [housingId]);
  return (
    <>
      {modalState.isOpen && selectModal(modalState.type)}
      <button
        className="Checkout__form__button"
        onClick={() => {
          handleActionModal("contact");
        }}
      >
        <strong>Consejo: Dale click a </strong> 
        "Contractar al Alohador"
        <strong> y asegúrate que todo esté preparado para tu reserva.</strong>
      </button>
    </>
  );
};

export default connect(null, {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
  openReportModal,
})(ButtonContactHost);
