// Librerías
import React from 'react';
// Recursos
import '../../assets/styles/Utils/_radio.sass';

const CustomRadio = (props) => {

    return (    
        <div className={`Radio${props.checked === props.value?" active":""}`}>
            <input id={ props.id } className="Radio__radio" type="radio" checked={ props.checked === props.value } name={ props.name } value={props.value} onChange={ props.customClickEvent }/>
            <label htmlFor={ props.id } className="Radio__container__label">
                <span className="Radio__container__circle"></span>
                <div className="Radio__container__label__text">
                    <p className="Radio__container__title">{ props.title }</p>
                </div>
            </label>
        </div>
    );
}

export default CustomRadio;