// Librerías
import React from 'react';

const PropertyProgressBar = ({ progress }) => {
  const progressBar = progress * 20;
  return(
    <div className="PropertyReviews__progressBars__container__progressBar">
      <div className="PropertyReviews__progressBars__container__progressBar__progress"  style={{ width: `${progressBar}%`}} >
      </div>
    </div>
  );
}

export default PropertyProgressBar;