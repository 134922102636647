import React, { useState, useRef, useEffect } from "react";

import L from "leaflet";
import pinUbication from "../../../assets/static/Icons/map-pin.svg";
const UbicationMap = ({
  latitude,
  longitude,
  setLatitude,
  setLongitude,
  pantToCity,
}) => {
  const [zoom, setZoom] = useState(13);

  useEffect(() => {
    const map = L.map("map", {
      center: [latitude, longitude],
      zoom: zoom,
    });

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(
      map
    );
    var customIcon = new L.Icon({
      iconUrl: pinUbication,
      iconSize: [50, 50],
      iconAnchor: [25, 50],
    });

    const marker = L.marker([latitude, longitude], {
      // draggable: true,
      icon: customIcon,
    }).addTo(map);

    map.on("click", (e) => {
      const { lat, lng } = e.latlng;
      setLatitude(lat);
      setLongitude(lng);

      marker.setLatLng([lat, lng]);
    });

    // Clean up the map when the component unmounts
    return () => {
      map.remove();
    };
  }, [latitude, longitude, zoom]);
  return <div style={{ height: "100%", width: "100%" }} id="map" />;
};

export default UbicationMap;
