import React, { useEffect, useState } from "react";
import InputGlobal from "../../../../../components/ui/input-global";
import AxiosAuth from "../../../../../services/requestApi";
import { BASE_URL } from "../../../../../Helpers";
import "./formAddReservation.sass";
import ErrorAlert from "../../../../../components/Alerts/ErrorAlert";

const FormAddReservation = ({ form, handleChange, setCouponId }) => {
  const [error, setError] = useState({ couponUnkown: false });
  const [couponDetails, setCouponDetails] = useState({
    display_text: null,
    code: null,
  });
  useEffect(() => {
    const url = `${BASE_URL}/coupons/${form.coupon}`;
    AxiosAuth.get(url)
      .then((response) => {
        setCouponId(response.data.id);
        setError({ couponUnkown: false });
        setCouponDetails({
          display_text: response.data.display_text,
          code: response.data.code,
        });
        if (form.coupon !== undefined && response.data.message)
          setError({ couponUnkown: response.data.message });
      })
      .catch((error) => {
        setCouponId(0);
      });
  }, [form.coupon,form.userId]);

  return (
    <div className="FormAddReservation">
      <InputGlobal
        type={"number"}
        prefix={"Ingrese id de usuario"}
        name={"userId"}
        value={form.userId}
        onChange={handleChange}
      />
      <InputGlobal
        type={"number"}
        prefix={"Ingrese id del alojamiento"}
        value={form.housingId}
        name={"housingId"}
        onChange={handleChange}
      />
      <select
        onChange={handleChange}
        value={form.reservationType}
        name="reservationType"
        className="MainContainer__form__select"
        required
        defaultValue="transfer"
      >
        <option disabled>Selecciona un tipo de reserva</option>
        <option value="transfer">Transferencia</option>
        <option value="link">Link</option>
      </select>
      <select
        onChange={handleChange}
        value={form.transferDiscount}
        name="transferDiscount"
        className="MainContainer__form__select"
        required
        defaultValue="2.5"
        disabled={form.reservationType !== "transfer"}
      >
        <option disabled>Descuento por transferencia</option>
        <option value="2.5">2.5%</option>
        <option value="5">5%</option>
        <option value="7">7%</option>
        <option value="7.5">7.5%</option>
      </select>

      <InputGlobal
        type={"text"}
        prefix={"Cupon de descuento"}
        value={form.coupon}
        name={"coupon"}
        onChange={handleChange}
      />

      {error.couponUnkown && <ErrorAlert message={error.couponUnkown} />}
    </div>
  );
};

export default FormAddReservation;
