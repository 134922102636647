import React from "react";

const InputSelect = ({
  name,
  style,
  label,
  setValue,
  children,
  disabled,
  value,
  onBlur,
}) => {
  return (
    <div
      style={style}
      className="relative flex flex-col justify-between border-solid border-b-primary border-b-1 h-[30px] "
    >
      <label
        className="absolute top-[-24px] left-0 text-primary font-robotoBold"
        htmlFor={name}
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        onBlur={(e) => setValue(e)}
        onChange={(e) => setValue(e)}
        value={value}
        className="text-base"
        disabled={disabled}
      >
        {children}
      </select>
    </div>
  );
};

export default InputSelect;
