// Libraries
import React from "react";
import { useModal } from "./useModal";
import Modal from "./Modal";
import vector from "../../assets/static/Icons/Vector.png";
import ellipse from "../../assets/static/Icons/ellipse.png";
import ListFilter from "../SearchPage/Utils/ListFilter";
import NeighborhoodFilter from "../SearchPage/Utils/NeighborhoodsFilter";
import RoundToggle from "../Utils/RoundToggle";
import PriceFilter from "../SearchPage/Utils/PriceFilter";
import ActiveFilters from "../SearchPage/Utils/ActiveFilters";
import FilterTypeSpace from "../SearchPage/Utils/FilterTypeSpace";
const Modals = ({
  allOptions,
  clickEventCustom,
  clickEventCustomTypeSpace,
  clickEventPrice,
  housingType,
  housingTypeHandle,
  spaceType,
  spaceTypeHandle,
  flexibility,
  flexibilityHandle,
  inmediateBooking,
  inmediateBookingHandle,
  filterInmediatBooking,
  handleInmediatBooking,
  petFilter,
  neighborhoods,
  neighborhoodsHandle,
  handlePetFilter,
  allGlampings,
  handleAllGlampings,
  handleHotSale,
  filterHotSale,
  reset,
}) => {
  const [isOpenModal, openModal, closeModal] = useModal(false);
  return (
    <div>
      <button className="btnIcon" onClick={openModal}>
        <img alt="Cross" className="btnIcon__Icon" src={vector} />
        {flexibility ||
        filterInmediatBooking ||
        petFilter ||
        allOptions.some((e) => e.checked) ||
        spaceType.some((e) => e.checked) ? (
          <img alt="Cross" className="btnIcon__Icon__filter" src={ellipse} />
        ) : (
          " "
        )}
      </button>
      <Modal isOpen={isOpenModal} closeModal={closeModal}>
        <ActiveFilters
          housingType={housingType}
          housingTypeHandle={housingTypeHandle}
          spaceType={spaceType}
          spaceTypeHandle={spaceTypeHandle}
          flexibility={flexibility}
          flexibilityHandle={flexibilityHandle}
          inmediateBooking={inmediateBooking}
          inmediateBookingHandle={inmediateBookingHandle}
          neighborhoods={neighborhoods}
          neighborhoodsHandle={neighborhoodsHandle}
          pets={petFilter}
          handlePetFilter={handlePetFilter}
          reset={reset}
        />
        <div className="filtersContainer">
          <ListFilter
            title="Tipo de alojamiento"
            allOptions={allOptions}
            clickEventCustom={clickEventCustom}
          />
          <FilterTypeSpace
            spaceType={spaceType}
            clickEventCustomTypeSpace={clickEventCustomTypeSpace}
            title="Tipo de espacio"
          />
          {neighborhoods.length > 0 && (
            <>
              <NeighborhoodFilter
                title="Barrios"
                allOptions={neighborhoods}
                clickEventCustom={neighborhoodsHandle}
              />
            </>
          )}
          <PriceFilter clickEventPrice={clickEventPrice} />
          <RoundToggle
            id={"flexbility"}
            name={"flexbility"}
            value={"flexbility"}
            checked={flexibility}
            title={"Flexibilidad para cancelar"}
            customClickEvent={flexibilityHandle}
          />
          <RoundToggle
            id={"inmeediatBooking"}
            name={"inmeediatBooking"}
            value={"inmeediatBooking"}
            checked={filterInmediatBooking}
            title={"Reserva inmediata"}
            customClickEvent={handleInmediatBooking}
          />

          {/* <RoundToggle
            id={"hotSale"}
            name={"hotSale"}
            value={"hotSale"}
            checked={filterHotSale}
            title={"Hot sale"}
            customClickEvent={handleHotSale}
          /> */}
          <RoundToggle
            id={"pets"}
            name={"pets"}
            value={"pets"}
            checked={petFilter}
            title={"Mascotas"}
            customClickEvent={handlePetFilter}
          />
          <RoundToggle
            id={"allGlampings"}
            name={"allGlampings"}
            value={"allGlampings"}
            checked={allGlampings}
            title={"Todos los glampings"}
            customClickEvent={handleAllGlampings}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Modals;
