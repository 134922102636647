import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { loginUser } from '../store/user/actions';
import queryString from 'query-string';

const SocialLoginPage = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const query = queryString.parse(window.location.search)
  const {token, refresh_token, firstName, lastName, avatar} = query

  if(token && refresh_token && firstName && lastName && avatar){
    localStorage.setItem("token", token)
    localStorage.setItem("refresh_token", refresh_token)
    localStorage.setItem("userData", JSON.stringify({
      token:token,
      refresh_token:refresh_token,
      avatar:avatar,
      firstName:firstName,
      lastName:lastName,
      login: true
    }));

    dispatch(loginUser({token,refresh_token,firstName,lastName,avatar}))
  }
  
  history.push("/");
  
  return(<></>);
}

export default connect()(SocialLoginPage);

