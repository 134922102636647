import React from "react";
import InputGlobal from "../ui/input-global";
import { useForm } from "../../hooks/useForm";
import AxiosAuth from "../../services/requestApi";
import ButtonPrimary from "../ui/button-primary";
import { BASE_URL } from "../../Helpers";
import { useEffect } from "react";
import { useState } from "react";
import ReloadIcon from "../../assets/components-icons/ReloadIcon";
import { TrashIcon } from "../../assets/components-icons/TrashIcon";
import ArrowLeft from "../../assets/components-icons/ArrowLeft";
import CrossIcon from "../../assets/components-icons/CrossIcon";
import { validateFormCalendar } from "./validateFormCalendar";
import ErrorMessage from "../Alerts/ErrorAlert";

const initialForm = {
  link: "",
  description: "",
};
const CalendarSincronize = ({ housingId }) => {
  const { errors, form, handleChange, resetForm } = useForm(
    initialForm,
    validateFormCalendar
  );
  const [linkIcalExport, setLinkIcalExport] = useState("");
  const [calendarImports, setCalendarImports] = useState({});
  const [viewCalendars, setViewCalendars] = useState({
    calendarImports: false,
    addCalendar: false,
  });
  const [errorLink, setErrorLink] = useState();
  const [notifyUrlCopy, setNotifyUrlCopy] = useState(false);
  const getCalendarsImports = async () => {
    const urlCalendarImports = `${BASE_URL}/housing/get-calendars-imports/${housingId}`;

    try {
      const response = await AxiosAuth.get(urlCalendarImports);
      const calendars = response.data;
      setCalendarImports(calendars);

      if (calendars) {
        calendars.forEach((link) => {
          updateImportCalendar(link);
        });
      }
      resetForm();

      return true; // Resuelve la promesa con true en caso de éxito
    } catch (error) {
      return false; // Resuelve la promesa con false en caso de error
    }
  };
  const updateImportCalendar = (link) => {
    const url = `${BASE_URL}/housing/update-calendar`;
    const calendar = {
      calendar: {
        link: link.link,
        description: link.description,
        housingId: link.housingId,
        user: link.id,
      },
    };
    AxiosAuth.post(url, calendar)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    const url = `${BASE_URL}/housing/create-link-ical/${housingId}`;
    AxiosAuth.post(url)
      .then((response) => {
        setLinkIcalExport(response.data);
      })
      .catch((error) => {});
    getCalendarsImports();
    resetForm();
  }, [housingId]);

  const submitImportCalendar = (link) => {
    const url = `${BASE_URL}/housing/import-calendar`;
    const body = {
      calendar: {
        housingId: housingId,
        link: link ? link : form.link,
        description: form.description,
      },
    };
    AxiosAuth.post(url, body)
      .then((response) => {
        window.location.reload();
        getCalendarsImports();
      })
      .catch((error) => {
        setErrorLink(error.response.data.message);
        setTimeout(() => {
          setErrorLink(false);
        }, 3000);
      });
  };
  const linkCopy = () => {
    const linkToCopy = linkIcalExport;

    // Crear un elemento de texto temporal para copiar al portapapeles
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    setNotifyUrlCopy(true);

    setTimeout(() => {
      setNotifyUrlCopy(false);
    }, 2000);
  };
  const deleteCalendar = (id) => {
    const url = `${BASE_URL}/housing/delete-calendars-imports/${id}/${housingId}`;
    AxiosAuth.delete(url)
      .then((response) => {
        postDeleteCalendar();
        // getCalendarsImports();
      })
      .catch((error) => {});
    resetForm();
  };

  const postDeleteCalendar = () => {
    const pre = getCalendarsImports();
    console.log(pre);
    if (pre) {
      window.location.reload();
    }
  };

  return (
    <div className="relative flex flex-col w-full gap-4 my-5">
      <h2 className="text-lg">Exportar calendario de Alohar</h2>
      <p className="m-0">Copia y pega el enlace en otros servicios que usas.</p>
      <div className="relative flex items-center justify-between w-full gap-4 px-3 py-2 border-2 rounded shadow-md border-primary">
        <textarea
          rows={1}
          className="w-full overflow-hidden bg-transparent resize-none whitespace-nowrap overflow-ellipsis"
          disabled
          value={linkIcalExport}
        ></textarea>
        <p className="absolute text-gray-600 top-2 left-3">
          URL del calendario
        </p>
        <ButtonPrimary className={"p-2 "} title={"Copiar"} onClick={linkCopy} />
        {notifyUrlCopy && (
          <div
            className={`absolute bottom-0 right-0 px-2 py-1 bg-white rounded shadow-sm animate-fade-out-2000 `}
          >
            url copiada
          </div>
        )}
      </div>
      <div
        onClick={() => setViewCalendars({ calendarImports: true })}
        className="relative flex items-center justify-between w-full gap-4 px-3 py-2 border-2 rounded shadow-md border-primary"
      >
        <p className="flex items-center justify-between w-full cursor-pointer">
          Importar calendarios <ArrowLeft className={" rotate-180"} size={15} />
        </p>
      </div>
      {viewCalendars.calendarImports && (
        <div className="absolute top-0 left-0 z-10 flex flex-col w-full gap-4 pt-6 pb-4 pl-2 pr-6 bg-white rounded shadow-md cursor-pointer">
          <p
            onClick={() => setViewCalendars(false)}
            className="absolute top-2 right-2 "
          >
            <CrossIcon width={15} />
          </p>

          {calendarImports.length > 0 &&
            calendarImports.map((e) => (
              <div
                key={e.id}
                className="flex flex-col items-start justify-start gap-2 py-1 border-2 rounded shadow-md border-primary "
              >
                <p className="px-2 py-1 text-base ">{e.description}</p>
                <button
                  onClick={() => submitImportCalendar(e.link)}
                  className="flex w-full gap-4 px-2 py-1 text-base rounded cursor-pointer hover:bg-primary hover:text-black "
                >
                  <ReloadIcon size={15} /> actualizar calendario
                </button>
                <button
                  className="flex w-full gap-4 px-2 py-1 text-base rounded cursor-pointer hover:bg-primary hover:text-black "
                  onClick={() => deleteCalendar(e.id)}
                >
                  <TrashIcon size={15} /> Eliminar calendario
                </button>
              </div>
            ))}
          <div className="relative flex items-center justify-between w-full gap-6 px-2 py-4 border-2 rounded shadow-md border-primary">
            <div className="flex flex-col w-full gap-4">
              <InputGlobal
                onChange={handleChange}
                value={form.link}
                prefix={"Dirección URL del calendario"}
                type={"text"}
                name={"link"}
              />
              {errors.link && <ErrorMessage message={errors.link} />}
              <InputGlobal
                onChange={handleChange}
                type={"text"}
                value={form.description}
                name={"description"}
                prefix={"Asigna un nombre a tu calendario"}
              />
              {errors.description && (
                <ErrorMessage message={errors.description} />
              )}
            </div>
          </div>
          {errorLink && (
            <ErrorMessage
              message={
                errorLink.length > 0
                  ? errorLink
                  : "Error en link de importacion, pruebe nuevamente"
              }
            />
          )}
          <ButtonPrimary
            className={"mx-auto	"}
            title={"Agrega un calendario"}
            onClick={() => submitImportCalendar()}
          />
        </div>
      )}
      {viewCalendars.addCalendar && <div> </div>}
    </div>
  );
};

export default CalendarSincronize;
