// Librerías
import React from 'react';
// Componentes
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import BaproLegal from '../components/LandingPage/BaproLegal';

const BaproContainer = () => {
  return(
    <div>
        <Navbar addSearch={false} hideAlohadorButtons={true} hideSearchButtons={true}/>
        <BaproLegal />
        <Footer />
    </div>
  );
}

export default BaproContainer;