import React from "react";
import europAssitenceMobile from "../../../assets/static/Promos/Slide_3_Banner_EUROP-ASS2.webp";
import europAssitence from "../../../assets/static/landing-host/people.webp";
import ImageLazyLoad from "../../ui/image-lazyLoad";

const BannerEuropAssitence = () => {
  return (
    <>
      <ImageLazyLoad
        src={europAssitenceMobile}
        classNameContent={"w-full h-full"}
        alt="DownLoad app"
        className={`z-0 bg-europMobile bg-center sm:hidden  bg-cover  sm:py-[26%]  h-screen sm:h-full w-full bg-no-repeat   flex items-center justify-center`}
      />
      <ImageLazyLoad
        src={europAssitence}
        className="hidden object-cover sm:block"
        alt="EuropAssistence"
      />
    </>
  );
};

export default BannerEuropAssitence;
