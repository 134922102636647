import React, {useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import ErrorAlert from '../Alerts/ErrorAlert';
import { closeModal } from '../../store/modal/actions';
import AuthService from '../../services/auth';
import x from '../../assets/static/Modals/x.png';

const RecoverModal = ({closeModal}) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [errorInput, setErrorInput] = useState('');
    const [hideEmail, setHideEmail] = useState(false);
    const dispatch = useDispatch();
    const handleModalContainerClick = e => e.stopPropagation();
    const ifValidate = (input) => {
        if(input === "email" || input=== undefined){
            if(email.length < 5){
                return {v:false, e:"El email debe ser válido",i:'email'}
            }
            // eslint-disable-next-line no-useless-escape
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(email.length > 5){
                if(!re.test(email)){
                    return {v:false, e:"El email es inválido", i:'email'}
                }
            }
        }
        return {v:true, e:"", i:''}
    }

    const handleValidate = (input) =>{
        const _ifValidate = ifValidate(input);
        if(!_ifValidate.v){
            setErrorInput(_ifValidate.i)
            setError(_ifValidate.e)
        }
    }

    const handleSubmit = (evt) => {

        evt.preventDefault();

        const _ifValidate = ifValidate();
        if(_ifValidate.v){
            AuthService.recover(email)
            .then(function (response) {
                setError("Enviamos un correo a la cuenta de email con un link de recuperación. Revisá SPAM.")
                setHideEmail(true)
            })
            .catch(function (error) {
                console.log(error.response)
                if(error.response.status === 404){
                    setError("El E-mail no se encuentra registrado.")
                }else{
                    setError(error.response)
                }
            });
        }else{
            setErrorInput(_ifValidate.i)
            setError(_ifValidate.e)
        }
    }

    return(
        <div className="recover_modal" onClick={closeModal}>
            <header >
                ¿Olvidaste tu contraseña? 
                <img style={{height:"30px",width:"30px", top:"3px",right:"10px"}}  src={x} alt="Close Modal" className="x" onClick={() => dispatch(closeModal()) }/> 
            </header>
            <section onClick={handleModalContainerClick}>
                <form onSubmit={handleSubmit}>
                <p className="mensaje">Te enviaremos un código a tu e-mail para que puedas resetear tu contraseña</p>
                {
                    (error)
                    ? <ErrorAlert message={error} />
                    : ''
                }
                {
                    hideEmail ? ''
                                :
                        <div>
                            <div className="input_continer">
                                <div className={errorInput === 'email' ? "input_wrapper error recover_wrapper": "input_wrapper recover_wrapper"}>
                                        <label>E-MAIL</label>
                                        <input 
                                            type="email" 
                                            placeholder="Escribe tu e-mail"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            onBlur={()=>handleValidate('email')}
                                        />
                                </div>
                            </div>

                            <button type="submit" className="button_orange">Enviar código</button>
                        </div>
                }
                
                
                </form>
            </section>
        </div>
    );
}

export default connect(null,{closeModal})(RecoverModal);
