// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
import AxiosAuth from '../../../services/requestApi'
// Recursos
import {BASE_URL} from '../../../Helpers'
import '../../../assets/styles/AloharForm/Utils/_gallery.sass';
import Spinner from '../../../assets/static/spinner.gif';
import FirstPhotoRadio from './FirstPhotoRadio';

class Gallery extends Component{
  constructor(props){
    super(props)
    this.state = {
      files: this.props.files,
      id: this.props.housingId,
      limit: 3,
    }
    this.timeout =  0;
    this.fileInput = React.createRef();
    this.openDialog = this.openDialog.bind(this);
    this.handleAddPhoto = this.handleAddPhoto.bind(this);
    this.handleDelFile = this.handleDelFile.bind(this);
    this.listImages = this.listImages.bind(this);
    this.handleCoverFile = this.handleCoverFile.bind(this)
  }

  async sendFile(file,cover){
    const url = BASE_URL + "/housing/public/media"
    
    let data = new FormData();
    data.append('media', file);
    data.append("id", this.state.id);
    data.append("cover", cover);
    AxiosAuth.defaults.headers.post['Content-Type'] = `multipart/form-data;`
    const response = await AxiosAuth.post(url, data)
      .then((data)=>{
        return data.data
      })
      .catch((e)=>{
        console.log(e)
        return false
      });

    return response

  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.files !== prevState.files){
      this.setState({files:prevProps.files})
    }
  }

  uploadFile(){
      const files = this.state.files
      files.map(async (file,index)=>{
        if(file.URL.includes('blob')){
          const sendedFile = await this.sendFile(file,false);
          if(sendedFile){
            const urlImage = BASE_URL +"/housing/media/"+ sendedFile.fileName
            let files = this.state.files
            files[index].URL = urlImage;
          }
          
          const pendingUpload = this.state.files.filter((f)=>{
            if(f.URL.includes("blob")) return f
          })
          
          this.props.validateStep(pendingUpload.length === 0 && this.state.files.length >= 5)

          this.setState({files:files},()=>{
            this.props.photosStep(this.state);
          })
        }
      })
  }

  async sendDescripcion(name,description){
    const url = BASE_URL + "/housing/public/media/description"

    const data = {
      id: this.state.id,
      fileName:name,
      description: description
    }

    await AxiosAuth.post( url, data)
      .then((data)=>{})
      .catch((e)=>{console.log(e)});
  }

  componentDidMount() {
    this.uploadFile();
  }

  handleAddPhoto(event){
    let fileInput = event.target.files;
    let array = [...fileInput];
    const idBase = this.state.files.length
    // RULE: File ext
    array = array.filter( (obj) => {
      if(obj.type === 'image/jpeg' || obj.type === 'image/jpg' || obj.type === 'image/png') {
        return true;
      } else {
        console.log('Hubo un error con los formatos');
        this.props.handleTypeError('Hubo un error');
        return false;
      }
    });
    array.forEach((file, key) => {
      // We set the max size to 4MB
      let size = (file.size / 1024 / 1024).toFixed(2);
      if(size <= this.state.limit){
        file.id = idBase + key + 1;
        file.URL = URL.createObjectURL(file);
        this.handleAddItem(file);
      } else {
        console.log('Hubo un error con el tamaño de una foto');
        this.props.handleSizeError('Hubo un error');
      }
    });

    this.uploadFile();
  }

  openDialog = () => {
    this.fileInput.current.click()
  }

  handleAddItem(file){
    this.props.onFileUpload(file);
  }

  handleDelFile(item){
    // Pass this to parent
    this.props.delFile(item);
  }

  

  handleCoverFile(item){
    // console.log("handleCoverFile",item.target.value)
    const file = item.target.value.split("/")
    const fileName = file[file.length-1]
    this.props.setCoverImage(fileName)
    const newState = this.state.files.map((f)=>{
      f.cover = (f.URL === item.target.value)
      return f
    })
    this.setState({files:newState})
  }

  handleDescription(event, item){
    let description = event.target.value;
    item.description = description;
    this.props.description(item);
    if(!item.URL.includes('blob')){
      const url = item.URL.split("/")
      const name = url[url.length-1]
      /* Wait 300ms when finish typing */
      if(this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.sendDescripcion(name,description)
      }, 300);
      
    }
  }

  listImages = () => {
    let files;



    if(!Array.isArray(this.state.files) && this.state.files){
      let files_array = Object.keys(this.state.files).map( (key) => {
        return this.state.files[key]
      });
      this.setState({files:files_array})
      files = files_array.filter((f)=>(f !== null));
    }else{
      files = this.state.files.filter((f)=>(f !== null));
    }
    /* Por alguna razon se duplican elementos, vamos a verificar y sacarlos */
    const urls = []
    files = files.filter((f)=>{
      if(!urls.includes(f.URL)){
        urls.push(f.URL)
        return true
      }
      return false;
    })
    return files.map((item,index) => {
        return (
            <div className="Gallery__container__galleryItem" key={index}>
              <div className="Gallery__container__galleryItem__clipper">
                <img
                  className="Gallery__container__galleryItem__clipper__img"
                  src={ item.URL }
                  alt="fondo"
                />
                 
                <button className="Gallery__container__galleryItem__delBtn" onClick={ () => this.handleDelFile(item) }>
                  <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.64 21.66">
                    <path stroke="#000" d="M.67,3.34H16c0,.41,0,.81,0,1.21a.93.93,0,0,1-.28.8,1.12,1.12,0,0,0-.31.86c0,4.49,0,9,0,13.46a1.86,1.86,0,0,1-2,2c-2,0-4.08,0-6.11,0H3.25a2.1,2.1,0,0,1-1.43-.46,1.7,1.7,0,0,1-.57-1.37V9.11c0-1,0-1.92,0-2.88a1.26,1.26,0,0,0-.39-1,.65.65,0,0,1-.2-.51C.68,4.28.67,3.82.67,3.34ZM4.39,7V17.82H5.71V7ZM9,17.82V7H7.68V17.82Zm2.32-5.41v2.1c0,1,0,2.06,0,3.09,0,.19.07.25.26.25s.52,0,.79,0,.33,0,.33-.32V7.15c0-.13-.05-.18-.18-.17h-.89c-.3,0-.3,0-.3.3Z" />
                    <path stroke="#fff" d="M0,2.94V2.06c0-.18.12-.15.22-.15H3.41c.22,0,.45,0,.67,0a.29.29,0,0,0,.2-.13A2.45,2.45,0,0,1,6.76,0c1.16,0,2.31,0,3.47,0a2.36,2.36,0,0,1,2.11,1.68.23.23,0,0,0,.28.19h4v1Zm10.94-1a.45.45,0,0,0,0-.08,1.2,1.2,0,0,0-1.13-.55H7.49c-.31,0-.62,0-.93,0a1,1,0,0,0-.86.61Z" />
                  </svg>
                </button>
                { item.URL.includes('blob')
                    ? <div className="Gallery__container__galleryItem__clipper__spinner" ><img src={ Spinner } alt="spinner" /></div>
                    : ''
                }
              </div>
              <div className="Gallery__container__galleryItem__upperBar">
                <FirstPhotoRadio
                  id={index}
                  name={"portada"}
                  title={'Mi Portada'}
                  value={item.URL}
                  checked={item.cover}
                  customClickEvent={this.handleCoverFile}
                />
              
              </div>
              
              {/* {( index === 0) 
                ? <p className="Gallery__container__galleryItem__firstOne">Portada</p>
                : '' 
              } */}
            
              {/* <div className="Gallery__container__galleryItem__inputContainer">
                <input
                  className="Gallery__container__galleryItem__inputContainer__input"
                  type="text"
                  key={item ? "key_"+item.id : "key_"+index} 
                  name={item ? item.id : index}
                  value={(item.description) ? item.description : null }
                  placeholder="Agregue una descripción..."
                  onBlur={ (e) => this.handleDescription(e, item) }
                  onChange= { (e) => this.handleDescription(e, item) }
                  disabled={ item.URL.includes('blob') }
                />
              </div> */}
            </div>
              
        );
      }
    );

  }

  render(){
  
      
    return(
      <React.Fragment>
          <div className="Gallery">
            <div className="Gallery__big__container">
              <div className="Gallery__big__container__column">
                <h3 className="Gallery__big__container__column__title">
                  ¡CONSEJOS SALVADORES!
                </h3>
                <ul className="Gallery__big__container__column__list">
                  <li className="Gallery__big__container__column__list__description">
                    Ordena tu espacio
                    <p className="Gallery__big__container__column__list__description__text">Se verá cálido, amplio y acogedor para sus futuros huéspedes.
                    </p>
                  </li>
                  <li className="Gallery__big__container__column__list__description">
                    Mucha luz
                    <p className="Gallery__big__container__column__list__description__text">Saca tus fotos durante el día y abre las cortinas para mayor nitidez.</p>
                  </li>
                  <li className="Gallery__big__container__column__list__description">
                    Fotos variadas
                    <p className="Gallery__big__container__column__list__description__text">Muestra todos los ambientes: el living, la cocina, el baño, el exterior... no olvides nada.</p>
                  </li>
                  <li className="Gallery__big__container__column__list__description">
                    Cámara horizontal
                    <p className="Gallery__big__container__column__list__description__text">Ayuda a tus huéspedes a entender cómo es tu espacio, con fotos amplias y claras.</p>
                  </li>
                  <li className="Gallery__big__container__column__list__description">
                    Calidad media/alta
                    <p className="Gallery__big__container__column__list__description__text">Toma fotos que sean de al menos 1024 x 683 px, en caso de duda usa la foto mas grande.</p>
                  </li>
                </ul>
              </div>
              <div className="Gallery__container">
                {
                  this.listImages()
                }
                <div className="Gallery__addPhoto">
                  <button
                    className="Gallery__addPhoto__btn"
                    onClick={ this.openDialog }
                  >
                    <svg className="Gallery__addPhoto__btn__svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z" stroke="#FF4E00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z" stroke="#FF4E00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Agregar nueva foto
                  </button>
                  <input ref={ this.fileInput } type="file" accept=".png,.jpg,.jpeg" onChange={this.handleAddPhoto} multiple/>
                  {/* <input ref={ this.fileInput } type="file" accept=".png,.jpg,.jpeg" onClick={e => (e.target.value = null)} onChange={this.handleAddPhoto}/> */}
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return {...reducers.userReducer, ...reducers.aloharFormReducer}
};

export default connect(mapStateToProps, aloharFormActions)(Gallery);