import React, { useEffect, useState } from "react";
import Navbar from "../../../components/BackOffice/Navbar/Navbar";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import ButtonPrimary from "../../../components/ui/button-primary";
import InputGlobal from "../../../components/ui/input-global";
import { useForm } from "../../../hooks/useForm";
import CalendarPick from "../../../components/host-mode/host-mode-components/calendar-pick";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
import moment from "moment";
import InputSelect from "../../../components/ui/select-input";
import hotSale from "../../../../src/assets/static/hot_sale/alohar-hot-sale.png";

import levelPlatinium from "../../../../src/assets/static/housing-promotions/bandas-plata-borde.png";
import levelGold from "../../../../src/assets/static/housing-promotions/bandas-dorado-borde.png";
const BackOfficeHotSale = () => {
  const initialForm = {
    housingId: "",
  };
  const { errors, form, handleChange, handleBlur } = useForm(initialForm, null);
  const [loading, setLoading] = useState(true);
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const [valid_to_heighlight, setValid_to_heighlight] = useState();
  const [valid_from_heighlight, setValid_from_heighlight] = useState();
  const [success, setSuccess] = useState(null);
  const [housingInformation, setHousingInformation] = useState(null);
  const [messageMail, setMessageMail] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [status, setStatus] = useState(0);

  const [listPlans, setListPlans] = useState([]);
  const sendMail = () => {
    setMessageMail(false);

    const url = `${BASE_URL}/housing-promotions/send-mail-heighlight-active/${housingInformation.id}`;
    AxiosAuth.post(url)
      .then((response) => {
        setMessageMail(true);
      })
      .catch((error) => {
        console.log(error);
        setMessageMail(false);
      });
  };
  const getHousing = () => {
    setLoading(true);
    const url = `${BASE_URL}/backoffice/housing-promotion-id/${form.housingId}`;
    AxiosAuth.get(url)
      .then((response) => {
        console.log("housingInformation");
        console.log(housingInformation);

        setHousingInformation(response.data.response);
        setLoading(false);
        console.log("response");
        console.log(response.data.response);
      })
      .catch((error) => {
        setHousingInformation(null);
        setLoading(false);
        console.log(error);
      });
  };
  console.log("valid_to_heighlight");
  console.log(valid_to_heighlight);

  const loadNewHousingHotSale = () => {
    setSuccess(null);
    const body = {
      housingId: form.housingId,
      discount: form.discount,
      checkin: checkin,
      checkout: checkout,
      status: status,
      valid_to_heighlight:
        parseInt(selectedPlan) !== 0 ? valid_to_heighlight : null,
      valid_from_heighlight:
        parseInt(selectedPlan) !== 0 ? valid_from_heighlight : null,
      priority_id: selectedPlan ? parseInt(selectedPlan) : null,
    };
    const url = `${BASE_URL}/backoffice/new-housing-hot-sale`;
    AxiosAuth.post(url, body)
      .then((response) => {
        setSuccess("success");
        console.log(response);
        getHousing();
      })
      .catch((error) => {
        setSuccess("error");
        console.log(error);
      });
  };
  useEffect(() => {
    getHousing();
    setMessageMail(false);
  }, [form.housingId]);

  useEffect(() => {
    const url = `${BASE_URL}/backoffice/get-all-promotions`;
    AxiosAuth.get(url)
      .then((response) => {
        setListPlans(response.data.getAllPromotions);
      })
      .catch((error) => {
        setListPlans([]);
      });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="flex justify-center mx-auto">
        <div
          className={`${
            housingInformation ? "rounded-l-14" : "rounded-14"
          } flex justify-center  items-center flex-col mt-14 border border-1 border-solid border-black w-max  px-5 py-7`}
        >
          <div className="flex flex-col justify-between gap-12 ">
            <div className="w-80">
              <InputGlobal
                prefix={"ingresar housing id"}
                name={"housingId"}
                value={form.housingId}
                type={"number"}
                onChange={handleChange}
              />
            </div>
            <div className="flex w-80">
              <InputGlobal
                prefix={"ingresar porcentaje de descuento"}
                name={"discount"}
                value={form.discount}
                type={"number"}
                onChange={handleChange}
              />
              %
            </div>
            <div className="relative flex flex-col gap-6 w-80">
              <span className="flex items-center justify-between ">
                <InputSelect
                  style={{ width: "100%" }}
                  label={"selecionar plan"}
                  name={"plan"}
                  setValue={(e) => setSelectedPlan(e.target.value)}
                  // value={
                  //   housingInformation
                  //     ? housingInformation.priorityLevel.id
                  //     : null
                  // }
                >
                  {/* housingInformation.priorityLevel.id */}
                  <option value={0}>Ninguno</option>
                  {listPlans.map((plan, index) => (
                    <option key={index} value={plan.id}>
                      {plan.name}
                    </option>
                  ))}
                </InputSelect>
              </span>
            </div>
            <div>
              estado 0 muestra el icono de hot sale solo
              <br />
              estado 1 muestra solo el icono de destacado <br />
              estado 2 muestra ambos iconos <br />
            </div>
            <div className="relative flex flex-col gap-6 w-80">
              <span className="flex items-center justify-between ">
                <InputSelect
                  style={{ width: "100%" }}
                  label={"seleccionar estado"}
                  name={"status"}
                  setValue={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value={0}>Hot sale</option>
                  <option value={1}>Destacado</option>
                  <option value={2}>Ambos</option>
                </InputSelect>
              </span>
            </div>
            <div className="flex flex-col w-80">
              Fecha hot sale
              <CalendarPick
                orientation={"horizontal"}
                setCheckin={setCheckin}
                setCheckout={setCheckout}
              />
            </div>
            <div className="flex flex-col w-80">
              Fecha alojamientos promocionados
              <CalendarPick
                orientation={"horizontal"}
                setCheckin={setValid_from_heighlight}
                setCheckout={setValid_to_heighlight}
              />
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <ButtonPrimary
              width={"auto"}
              loading={loading === "success" || "error" || false ? false : true}
              title={"cargar/editar alojamiento promoción"}
              className={"mx-auto px-3"}
              onClick={() => loadNewHousingHotSale()}
            />
            {success === "error" && (
              <ErrorAlert message={"Error al crear descuento"} />
            )}
            {success === "success" && (
              <ErrorAlert type={"success"} message={"Destacado actualizado"} />
            )}
          </div>
        </div>
        {housingInformation && !loading && (
          <div
            className={
              " flex flex-col relative justify-between  mt-14 border border-1 border-solid border-black w-max p-5 py-7 rounded-r-14"
            }
          >
            {[1, 2].includes(housingInformation.status) &&
              housingInformation?.priorityLevel?.name === "platinum" && (
                <img
                  src={levelPlatinium}
                  alt="Hot sale"
                  className="absolute top-0 left-0 z-50 w-12"
                />
              )}
            {[1, 2].includes(housingInformation.status) &&
              housingInformation?.priorityLevel?.name === "gold" && (
                <img
                  src={levelGold}
                  alt="Hot sale"
                  className="absolute top-0 left-0 z-50 w-12"
                />
              )}
            {[0, 2].includes(housingInformation.status) && (
              <img
                src={hotSale}
                alt="Hot sale"
                className="absolute z-50 w-10 -top-14 right-2"
              />
            )}
            <div className="flex flex-col gap-12">
              <div className="flex flex-col w-80">
                <InputGlobal
                  disabled
                  prefix={"id del alojamiento"}
                  name={"housingId"}
                  value={form.housingId}
                  type={"string"}
                />
              </div>
              <div className="flex w-80">
                <InputGlobal
                  type={"string"}
                  prefix={"Porcentaje actual"}
                  disabled
                  value={housingInformation.discount}
                />
                %
              </div>
              <div className="flex w-80">
                <InputGlobal
                  type={"string"}
                  prefix={"Estado actual"}
                  disabled
                  value={housingInformation.status}
                />
              </div>
              <div className="flex flex-col w-80">
                <InputGlobal
                  prefix={"Plan actual"}
                  type={"string"}
                  value={housingInformation?.priorityLevel?.name}
                />
              </div>
              <div className="flex flex-col w-80">
                Fecha hot sale
                <CalendarPick
                  start={moment(housingInformation.valid_from)}
                  end={moment(housingInformation.valid_to)}
                  orientation={"horizontal"}
                  disabled
                />
              </div>
              <div className="flex flex-col w-80">
                Fecha alojamientos promocionados
                <CalendarPick
                  start={moment(housingInformation.valid_from_heighlight)}
                  end={moment(housingInformation.valid_to_heighlight)}
                  orientation={"horizontal"}
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-col items-center mt-4">
              <ButtonPrimary
                width={"auto"}
                // loading={loading === "success" || "error" || false ? false : true}
                title={`${messageMail ? "Mail enviado" : "Enviar mail"}`}
                className={"mx-auto px-3"}
                onClick={() => sendMail()}
              />
              <p className="mt-3 text-xs">*Mail destacado activo </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BackOfficeHotSale;
