import React, { Suspense } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import barilocheBackground from "../../../assets/static/landing-host/bariloche.webp";
import iguazuBackground from "../../../assets/static/landing-host/water.webp";
import BariloTitle from "../../../assets/static/Promos/barilo-title.webp";
import iguazuTitle from "../../../assets/static/Promos/iguazu-title.webp";
import moment from "moment";
import SectionCard from "../../AloharForm/components/SectionCard";
import HotSaleButton from "../../Buttons/HotSaleButton";
const PromotionsDesktop = () => {
  SwiperCore.use([Autoplay]);

  const checkin = moment(Date.now()).utc(true).format("DD/MM/YY");
  const checkout = moment().add(5, "days").format("DD/MM/YY");
  return (
    <Suspense fallback={<></>}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        preloadImages={1}
        lazy={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="relative w-full h-full swiperHome"
      >
        <SwiperSlide>
          <SectionCard
            link={`iguazu/search?city=69&checkin=${checkin}&checkout=${checkout}&childs=0&adults=1&cityName=iguazu`}
            secondTitle={
              <>
                Adentrate
                <br />
                en esta
                <br />
                catarata
                <br />
                de emociones
              </>
            }
            alt={"iguazu"}
            title={iguazuTitle}
            styleBackground={{
              backgroundSize: "110%",
              backgroundImage: `url('${iguazuBackground}')`,
            }}
            backgroundColor={"bg-primaryHome"}
          >
            Alohamientos
            <br />
            {`desde AR$ ${Intl.NumberFormat("de-AR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(parseFloat(20000).toFixed(2))}`}
            <br />x día
          </SectionCard>
        </SwiperSlide>

        <SwiperSlide>
          <SectionCard
            invert={true}
            link={`bariloche/search?city=56&checkin=${checkin}&checkout=${checkout}&childs=0&adults=1&cityName=bariloche`}
            title={BariloTitle}
            alt={"Bariloche"}
            backgroundColor={"bg-primaryHome"}
            styleBackground={{
              backgroundPositionY: "100%",
              backgroundPositionX: "55%",
              backgroundSize: "100%",
              backgroundImage: `url('${barilocheBackground}')`,
            }}
            secondTitle={
              <>
                Bariló es <br /> Bariló <br /> Qué importa <br /> la estación?
              </>
            }
          >
            Alohamientos
            <br />
            {`desde AR$ ${Intl.NumberFormat("de-AR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(parseFloat(45000).toFixed(2))}`}
            <br />x día
          </SectionCard>
        </SwiperSlide>
      </Swiper>

    </Suspense>
  );
};

export default PromotionsDesktop;
