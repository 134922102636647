// Librerías
import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AxiosAuth from '../../../services/requestApi';
import { BASE_URL } from '../../../Helpers';
// Componentes
import MainContainer from '../Utils/MainContainer';
import BackOfficeToggle from '../Utils/BackOfficeToggle';

const Update = ( props ) => {
    const { id } = useParams();

    const [email, setEmail] = useState('');
    const [emailConfirmed, setEmailConfirmed] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberConfirmed, setPhoneNumberConfirmed] = useState(0);
    const [dniConfirmed, setDniConfirmed] = useState(0);

    ;
    const history = useHistory(); 

    const getUser = (id) => {
      const url = `${BASE_URL}/backoffice/users/${id}`;
       ;
       AxiosAuth.get(url).then((response)=>{
        let user = response.data;
        setEmail(user.email);
        setEmailConfirmed(user.emailConfirmed);
        setPhoneNumber(user.phoneNumber);
        setPhoneNumberConfirmed(user.phoneNumberConfirmed);
        setDniConfirmed(user.dniConfirmed);
        console.log(user);
      }).catch((e)=>{console.log(e)});
    }


    useEffect(() => {
      getUser(id);
    },[]);

    const handleChange = (event)  => {
      const name = event.target.name;
      const value = event.target.value;

      if(name === "email") setEmail(value)
      if(name === "phoneNumber") setPhoneNumber(value)
    }
    
    const handleChecked = (e) => {
      const name = e.target.name;
      const value = e.target.checked;
      
      if(name === "emailConfirmed") setEmailConfirmed(value)
      if(name === "phoneNumberConfirmed") setPhoneNumberConfirmed(value)
      if(name === "dniConfirmed") setDniConfirmed(value)
    }

    const sendData = () =>{
      const url = `/backoffice/users/${id}`;
      const data = {
        email,
        phoneNumber,
        emailConfirmed,
        phoneNumberConfirmed,
        dniConfirmed
      }

      AxiosAuth.put(url,data)
        .then((response) => {
          history.push(`../${id}`);
        }).catch((error) => {
          console.log(error)
        });
    }

    const handleSubmit = () =>{
      sendData();
    }

    return (
        <MainContainer title="Editar Usuario">
            <>
                <div className="MainContainer__form">
                    <div className="MainContainer__form__group">
                      <label htmlFor="email" className="MainContainer__form__label">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="MainContainer__form__input"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={email}
                        required
                      />
                    </div>
                    <div className="MainContainer__form__groupCheckbox">
                      <BackOfficeToggle 
                        title={"Email Verificado"}
                        name="emailConfirmed" 
                        value={ true }
                        checked={ (emailConfirmed === true) }
                        customClickEvent={ handleChecked }
                      />
                    </div>
                    <div className="MainContainer__form__group">
                      <label htmlFor="phoneNumber" className="MainContainer__form__label">Número de teléfono / celular</label>
                      <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        className="MainContainer__form__input"
                        placeholder="Número de teléfono / celular"
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={phoneNumber}
                        required
                      />
                    </div>
                    <div className="MainContainer__form__groupCheckbox">
                      <BackOfficeToggle 
                        title={"Teléfono / Celular Confirmado"}
                        name="phoneNumberConfirmed" 
                        value={ true }
                        checked={ (phoneNumberConfirmed === true) }
                        customClickEvent={ handleChecked }
                      />
                    </div>
                    <div className="MainContainer__form__groupCheckbox">
                      <BackOfficeToggle 
                        title={"DNI Verificado"}
                        name="dniConfirmed" 
                        value={ true }
                        checked={ (dniConfirmed === true) }
                        customClickEvent={ handleChecked }
                      />
                    </div>
                    <div className="UserEditComponent__btns">
                        <button className="MainContainer__form__btn__outline" onClick={ () => history.push(`../${id}`) }>
                            <p className="MainContainer__form__btn__outline__text">Volver</p>
                        </button>
                        <button className="MainContainer__form__btn" onClick={ handleSubmit }>Guardar</button>
                    </div>
                
                </div>
            </>
        </MainContainer>
    )
}

export default Update;
