// Librerías
import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import { BASE_URL } from '../../../Helpers'
import AxiosAuth from '../../../services/requestApi';
// Componentes
import MainContainer from '../Utils/MainContainer';



const NewLinks = () => {
  const { id } = useParams();
  const history = useHistory();
  const [guest, setGuest] = useState('');
  const [host, setHost] = useState('');

  useEffect(() => {
    const url = `${BASE_URL}/jwt/${id}`;
    AxiosAuth.get(url).then((response)=>{
      const links = response.data
      setHost(links.host);
      setGuest(links.guest);
    }).catch((e)=>{console.log(e)});
}, [])

  return (
    <MainContainer title={`Nuevos Links de la reserva: #${id}`}>
      <section className="UserData" style={{ display: 'flex', flexFlow: 'column'}}>
          <div className="UserData__actions">
            <button className="UserData__actions__btn" onClick={ () => history.push("../users") }>
              <p className="UserData__actions__btn__text">Volver</p>
            </button>
          </div>
          <div className="UserData__section" style={{width: '100%', boxSizing: 'border-box'}}>
            <h2 className="UserData__section__title">
              <p className="UserData__section__title__text" style={{margin: 0}}>Links:</p>
            </h2>
              <p className="UserData__dataGroup__title" style={{justifyContent: 'flex-start'}}>Link Alohador (Propietario): </p>
              <br/>
              <p className="UserData__dataGroup__content" style={{width: '100%', fontSize: '12px', justifyContent: 'flex-start'}}>{ host }</p>
              <br/>
              <p className="UserData__dataGroup__title" style={{justifyContent: 'flex-start'}}>Link Huésped: </p>
              <br/>
              <p className="UserData__dataGroup__content" style={{width: '100%', fontSize: '12px', justifyContent: 'flex-start'}}>{ guest }</p>
              <br/>
          </div>
      </section>
    </MainContainer>
  )
}

export default NewLinks
