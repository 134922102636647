import React from "react";
import Navbar from "../../Navbar/Navbar";
import InputGlobal from "../../ui/input-global";
import { useState } from "react";
import InputPrice from "./InputPrice";
import ButtonPrimary from "../../ui/button-primary";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import CalendarPick from "../../host-mode/host-mode-components/calendar-pick";
import { useForm } from "../../../hooks/useForm";
import ErrorAlert from "../../Alerts/ErrorAlert";
import { seasonValidateForm } from "./validate-season-form";
import { TrashIcon } from "../../../assets/components-icons/TrashIcon";
import axios from "axios";
import LoadingGift from "../../../assets/components-icons/LoadingGift";
import CheckIcon from "../../../assets/components-icons/CheckIcon";
import PricingModalUpdate from "./pricing-modal-update";

const initialForm = {
  percentage: "",
  description: "",
};

const PricingApp = () => {
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const { housingId } = useParams();
  const [basePrice, setBasePrice] = useState(0);
  const [monthlyPercentage, setMonthlyPercentage] = useState(0);
  const [seasonList, setSeasonList] = useState([]);
  const history = useHistory();
  const [disabledInput, setDisabledInput] = useState({
    disabledInput: true,
  });
  const [seasonUpdate, setSeasonUpdate] = useState({
    percentage: "",
    description: "",
  });
  const [openModal, setOpenModal] = useState({
    delete: false,
    update: false,
  });
  const [loading, setLoading] = useState({ seasons: true, percentage: true });
  const [success, setSuccess] = useState({
    itemId: "",
    monthlyIncrease: false,
  });
  const [errorMessage, setErrorMessage] = useState({ percentage: "" });
  const [errorUpdateSeason, setErrorUpdateSeason] = useState({
    itemId: "",
    message: "",
  });
  const [errorDates, setErrorDates] = useState(false);
  const { errors, form, handleChange, resetForm, handleBlur } = useForm(
    initialForm,
    seasonValidateForm
  );

  const updatePercentage = () => {
    if (monthlyPercentage < 0) {
      setErrorMessage({ percentage: "Debe ser un numero valido" });
      setTimeout(() => {
        setErrorMessage({ ...errorMessage, percentage: "" });
      }, 1500);
    } else {
      const url = `${BASE_URL}/housing/monthly-increase`;
      const body = {
        monthlyIncrease: {
          housingId: housingId,
          percentage: monthlyPercentage,
        },
      };

      AxiosAuth.post(url, body)
        .then((response) => {
          setBasePrice(response.data[1]);
          setMonthlyPercentage(response.data[0]);
          setSuccess({ ...success, monthlyIncrease: true });
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    setLoading({ ...loading, percentage: true });
    const url = `${BASE_URL}/housing/monthly-increase?housingId=${housingId}`;
    AxiosAuth.get(url)
      .then((response) => {
        setMonthlyPercentage(response.data[0]);
        setBasePrice(response.data[1]);
      })
      .catch((error) => {
        setMonthlyPercentage(0);
        setBasePrice(0);
      });
    setLoading({ ...loading, percentage: false });
    getSeasonCalendar();
  }, []);

  const getSeasonCalendar = () => {
    setLoading({ ...loading, seasons: true });
    const url = `${BASE_URL}/housing/get-season?housingId=${housingId}`;
    AxiosAuth.get(url)
      .then((response) => {
        setSeasonList(response.data.reverse());
        setLoading({ ...loading, seasons: false });
      })
      .catch((error) => {
        setLoading({ ...loading, seasons: false });
      });
  };

  const deleteSeason = (id) => {
    const url = `${BASE_URL}/housing/delete-season?seasonId=${id}`;
    AxiosAuth.delete(url)
      .then((response) => {
        setOpenModal({ ...openModal, delete: false });
        getSeasonCalendar();
      })
      .catch((error) => {});
  };
  const createSeason = () => {
    if (!checkin || !checkout) {
      setErrorDates("Debe ingresar fechas para continuar");

      setTimeout(() => {
        setErrorDates(false);
      }, 1500);
    } else {
      const url = `${BASE_URL}/housing/create-season`;
      const body = {
        createSeason: {
          checkin: checkin,
          checkout: checkout,
          housingId: housingId,
          percentage: form.percentage,
          description: form.description,
        },
      };
      AxiosAuth.post(url, body)
        .then((response) => {
          form.description = "";
          form.percentage = "";
          resetForm();
          getSeasonCalendar();
        })
        .catch((error) => {});
    }
  };

  const updateSeason = (item) => {
    setDisabledInput(false);
    const url = `${BASE_URL}/housing/update-season`;
    const body = {
      season: {
        id: item.id,
        checkin: checkin,
        checkout: checkout,
        percentage: seasonUpdate.percentage
          ? seasonUpdate.percentage
          : item.percentage,
        description: seasonUpdate.description
          ? seasonUpdate.description
          : item.description,
      },
    };
    AxiosAuth.post(url, body)
      .then((response) => {
        resetForm();
        getSeasonCalendar();
        setOpenModal({ ...openModal, update: true });
      })
      .catch((error) => {
        setErrorUpdateSeason({
          ...errorUpdateSeason,
          itemId: item.id,
          message: "Debe ser un porcentaje valido",
        });
        setTimeout(() => {
          setErrorUpdateSeason({
            itemId: "",
            message: "",
          });
        }, 1500);
      });
  };
  const updatePricing = () => {
    // este endpoint lo dejo porque dispara el cron de los precios automaticos, por si se quiere probar
    const url = `${BASE_URL}/housing/pricing-cron-manual`;
    axios
      .get(url)
      .then((response) => {})
      .catch((error) => {});
  };
  return (
    <>
      {/* <Navbar /> */}
      <div
        className="relative flex flex-col w-11/12 gap-2 pb-24 m-auto mt-5 sm:flex-row "
        style={{ marginTop: 150 }}
      >
        <PricingModalUpdate
          buttonTitle={"volver"}
          secondButton={"Eliminar"}
          title={"¿Desea eliminar la temporada ?"}
          closeModal={openModal.delete}
          setCloseModal={() => {
            setOpenModal({ ...openModal, delete: false });
            history.goBack();
          }}
          onClick={() => {
            deleteSeason(openModal.delete);
          }}
        />

        <PricingModalUpdate
          success={true}
          buttonTitle={"Volver"}
          title={"Se actualizó la temporada exitosamente "}
          closeModal={openModal.update}
          setCloseModal={() => {
            setOpenModal({ ...openModal, update: false });
            history.goBack();
          }}
          onClick={() => {
            {
              deleteSeason(openModal.delete);
            }
          }}
        />
        <PricingModalUpdate
          success={true}
          buttonTitle={"Volver"}
          title={"Precio actualizado"}
          closeModal={success.monthlyIncrease}
          setCloseModal={() => {
            setSuccess({ ...success, monthlyIncrease: false });
            history.goBack();
          }}
          onClick={() => {
            deleteSeason(openModal.delete);
          }}
        />

        <div className="flex flex-col col-span-1 gap-4 ">
          <h2 className="text-xl text-center font-rotoboBold sm:text-start ">
            Elegi un aumento mensual para el precio base de tu alojamiento
          </h2>
          <br />
          <div className="flex flex-col items-center justify-between mx-auto mb-8 w-80 sm:mx-0">
            <div className="flex flex-col justify-between gap-4 my-4">
              <>
                <InputPrice
                  name={"percentage"}
                  title={"Porcentaje mensual"}
                  prefix={"%"}
                  min={0}
                  value={monthlyPercentage}
                  onChange={(e) => {
                    setMonthlyPercentage(e.target.value);
                  }}
                />
                {errorMessage.percentage.length > 0 && (
                  <ErrorAlert message={errorMessage.percentage} />
                )}
                <div className="flex justify-between gap-4">
                  <InputPrice
                    name={"priceActualy"}
                    title={"Precio base actual"}
                    prefix={"$"}
                    value={basePrice}
                    disabled={true}
                  />
                  <InputPrice
                    name={"priceActualy"}
                    title={"Precio el proximo mes"}
                    prefix={"$"}
                    value={(basePrice * monthlyPercentage) / 100 + basePrice}
                    disabled={true}
                  />
                </div>
              </>
            </div>
            <ButtonPrimary
              title={"Guardar"}
              onClick={() => updatePercentage()}
            />
          </div>
          <h1 className="w-11/12 text-lg">
            Para programar la actualización de precios automática solo tenés que
            elegir un porcentaje de aumento mensual. Éste se aplicará al precio
            base todos los meses y al mostrar los precios para fechas
            posteriores al mes en curso.
          </h1>
          <p className="w-full mx-auto text-lg text-start">
            <strong> Nota:</strong> Tené en cuenta que al valor agregado le
            descontaremos la comisión alohar.
          </p>
        </div>
        <div className="flex flex-col items-end gap-14 sm:gap-12 ">
          <h2 className="w-11/12 mx-auto mt-8 text-xl text-center font-rotoboBold sm:mx-auto sm:my-0 ">
            Elegi un porcentaje de precio para tus temporadas
          </h2>

          <p className="w-full mx-auto text-lg text-start">
            Podés elegir un porcentaje diferencial para las temporadas altas,
            pudiendo elegir la fecha de inicio y de fin de la temporada y el
            porcentaje que se le aplicará
            <br />
            <br />
            <strong>Nota:</strong> se puede programar más de una temporada alta.
            Si tenés dudas, siempre te podés contactar con nuestro servicio de
            atención al cliente y te guiaremos en el paso a paso.
          </p>

          {loading.seasons === true ? (
            <LoadingGift size={150} />
          ) : (
            <>
              <div className="grid items-center w-full grid-cols-1 gap-8 p-4 rounded shadow-md sm:min-w-max sm:gap-4 sm:grid-cols-5 ">
                <div className="sm:col-span-2">
                  <CalendarPick
                    disabledDays={seasonList}
                    setCheckin={setCheckin}
                    setCheckout={setCheckout}
                  />
                </div>
                <InputGlobal
                  prefix={"Porcentaje"}
                  className={"w-full sm:w-28 sm:col-span-1"}
                  type={"number"}
                  value={form.percentage}
                  name={"percentage"}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />
                <InputGlobal
                  prefix={"Descripción"}
                  className={"w-full sm:w-28 sm:col-span-1"}
                  type={"text"}
                  value={form.description}
                  name={"description"}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                />

                <div className="relative sm:col-span-1">
                  <ButtonPrimary
                    className={"sm:col-span-1"}
                    disabled={errors.description && true}
                    width={25}
                    height={25}
                    title={"+"}
                    onClick={() => createSeason()}
                  />
                  <div className="absolute right-0 w-80 top-11">
                    {errors.percentage && (
                      <ErrorAlert message={errors.percentage} />
                    )}
                    {errors.description && (
                      <ErrorAlert message={errors.description} />
                    )}
                    {errorDates && <ErrorAlert message={errorDates} />}
                  </div>
                </div>
              </div>
              {seasonList.length > 0 &&
                seasonList.map((list) => (
                  <div
                    key={list.id}
                    className="grid items-center w-full grid-cols-1 gap-8 p-4 rounded shadow-md ms:gap-4 sm:grid-cols-5 sm:w-max"
                  >
                    <div className="sm:col-span-2">
                      <CalendarPick
                        disabled={disabledInput.disabledInput !== list.id}
                        end={moment(list.checkout)}
                        start={moment(list.checkin)}
                        setCheckin={setCheckin}
                        setCheckout={setCheckout}
                      />
                    </div>
                    <InputGlobal
                      disabled={disabledInput.disabledInput !== list.id}
                      prefix={"Porcentaje"}
                      className={"w-full sm:w-28 sm:col-span-1"}
                      type={"text"}
                      value={list.percentage}
                      name={"percentage"}
                      onChange={(event) =>
                        setSeasonUpdate({
                          ...seasonUpdate,
                          percentage: event.target.value,
                        })
                      }
                      handleBlur={handleBlur}
                    />
                    <InputGlobal
                      disabled={disabledInput.disabledInput !== list.id}
                      prefix={"Descripción"}
                      className={"w-full sm:w-28 sm:col-span-1"}
                      type={"text"}
                      value={list.description}
                      name={"description"}
                      onChange={(event) =>
                        setSeasonUpdate({
                          ...seasonUpdate,
                          description: event.target.value,
                        })
                      }
                      handleBlur={handleBlur}
                    />
                    <div className="relative grid justify-between w-full grid-cols-2 sm:col-span-1">
                      <ButtonPrimary
                        fontSize={".8rem"}
                        width={60}
                        height={30}
                        title={
                          disabledInput.disabledInput !== list.id ? (
                            "Editar"
                          ) : (
                            <CheckIcon size={15} color={"#fff"} />
                          )
                        }
                        onClick={
                          disabledInput.disabledInput !== list.id
                            ? () =>
                                setDisabledInput({
                                  ...disabledInput,
                                  disabledInput: list.id,
                                })
                            : () => updateSeason(list)
                        }
                        className={" mr-auto text-xs"}
                      />
                      <ButtonPrimary
                        className={"ml-auto"}
                        width={60}
                        height={30}
                        title={<TrashIcon size={15} color={"#fff"} />}
                        onClick={() =>
                          setOpenModal({ ...openModal, delete: list.id })
                        }
                      />

                      {errorUpdateSeason.itemId === list.id && (
                        <div className="absolute right-0 w-48 top-11">
                          <ErrorAlert message={errorUpdateSeason.message} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default PricingApp;
