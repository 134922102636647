// Librerías
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { isMobile } from "react-device-detect";

// Componentes
import Header from "../components/LandingPage/Header";
import Seccion1 from "../components/LandingPage/Seccion_1";
import Seccion2 from "../components/LandingPage/Seccion_2";
import Footer from "../components/Footer/Footer";
import ReactGA from "react-ga";
import SectionBanksMobile from "../components/LandingPage/SectionBanksMobile";
import SectionBanks from "../components/LandingPage/SectionBanks";
/* Modal */
import { connect, useSelector, shallowEqual } from "react-redux";
import {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
} from "../store/modal/actions";
import ModalWrapper from "../components/Modals/ModalWrapper";
import LoginModal from "../components/Modals/LoginModal";
import RegisterModal from "../components/Modals/RegisterModal";
import RecoverModal from "../components/Modals/RecoverModal";
import axios from "axios";
import { BASE_URL } from "../Helpers";
import WeDontWannaGetSued from "../components/LandingPage/WeDontWannaGetSued";
import Offline from "../components/LandingPage/Offline";
import PreFooter from "../components/LandingPage/PreFooter";
import { BannerHertz } from "../components/Utils/bannerHertz";

ReactGA.initialize("G-RFYCNS3LB1");

const LandingPage = () => {
  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const [getPromotions, setGetPromotions] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [offline, setOffline] = useState(false);
  const query = queryString.parse(window.location.search);
  const { partner } = query;
  localStorage.removeItem("infoReservation");
  localStorage.removeItem("typeReservation");
  const sendAndSaveMarketingLog = (partner) => {
    localStorage.setItem("partner", JSON.stringify(partner));
    const url = BASE_URL + "/housing/marketing/";
    const bodyParameters = {
      action: "lead",
      partner: partner,
      reservation: null,
      housing: null,
    };
    axios.post(url, bodyParameters).catch((error) => {
      console.log(error.response);
    });
  };

  function selectModal(modalType) {
    switch (modalType) {
      case "LOGIN":
        return (
          <ModalWrapper key="login_modal">
            <LoginModal />
          </ModalWrapper>
        );
      case "REGISTER":
        return (
          <ModalWrapper key="register_modal">
            <RegisterModal />
          </ModalWrapper>
        );
      case "RECOVER":
        return (
          <ModalWrapper key="recover_modal">
            <RecoverModal />
          </ModalWrapper>
        );
      default:
        return "";
    }
  }

  useEffect(() => {
    localStorage.removeItem("partner");
    if (!getPromotions) {
      const url = `${BASE_URL}/location/promotions`;
      axios
        .get(url)
        .then((response) => {
          setPromotions(response.data);
        })
        .catch((e) => {
          setOffline(true);
        });
      setGetPromotions(true);
    }
    if (partner) {
      sendAndSaveMarketingLog(partner);
    }
  }, []);

  return (
    <React.Fragment>
      {!offline ? (
        <>
          <Header />
          <BannerHertz />
          {isMobile ? <SectionBanksMobile /> : <SectionBanks />}
          <Seccion1 promotions={promotions} />
          <Seccion2 />
          <PreFooter />
          <WeDontWannaGetSued />
          <Footer />
        </>
      ) : (
        <div class="header">
          <Offline />
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(null, {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
})(LandingPage);
