// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
// Componentes
import MaskedInput from "react-maskedinput";
import Buttons from "../Utils/Buttons";
import ErrorMessage from "../../Alerts/ErrorAlert";
import ProgressBar from "../Utils/ProgressBar";
import ButtonEdit from "../Utils/ButtonEdit";
import AxiosAuth from "../../../services/requestApi";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_payment.sass";
import { BASE_URL, loadUserData } from "../../../Helpers";

/**
 * Formulario:
 * CBU: cbu
 * Nombre del banco: bank
 * Alias: alias
 *
 * Titular: owner
 * CUIT/CUIL: cuit_cuil
 */

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cbu: this.props.bodyParameters.cbu || "",
      alias: this.props.bodyParameters.alias || "",
      bank: this.props.bodyParameters.bank || "",
      headline: this.props.bodyParameters.headline || "",
      cuit: this.props.bodyParameters.cuit || "",
      errors: {},
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.handleValidate({ showError: false });

    const url = BASE_URL + "/payment/bankAccount";

    // await AxiosAuth.get(url)
    //   .then((response) => {
    //     if (response.data) {
    //       const { cbu, alias, bank, headline, cuit } = response.data;

    //       this.setState(
    //         {
    //           cbu: cbu,
    //           alias: alias,
    //           bank: bank,
    //           headline: headline,
    //           cuit: cuit,
    //         },
    //         () => {
    //           this.props.paymentStep(this.state);
    //           this.handleValidate({ showError: true });
    //         }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     this.handleValidate();
    //   });

    const role = JSON.parse(window.localStorage.getItem("role"));
    // if (role.role === "admin") {
    if (role.role === "admin") {
      console.log("hhola");
      const pathname = window.location.pathname;
      const pathnameParts = pathname.split("/");
      const lastPart = pathnameParts[pathnameParts.length - 1];
      if (!isNaN(lastPart)) {
        const housingId = parseInt(lastPart, 10);
        const body = {
          cbu: this.state.cbu,
          alias: this.state.alias,
          bank: this.state.bank,
          headline: this.state.headline,
          housingId: housingId,
          cuit: this.state.cuit,
          errors: this.state.errors,
          modeEdit: this.state.modeEdit,
          count_validate: this.state.count_validate,
        };

        this.props.paymentStep(body);
      }
    } else {
      this.props.paymentStep(this.state);
    }

    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.count_validate !== prevProps.count_validate) {
      this.handleValidate({ showError: true });
    }
  }

  handleValidate = ({ inputName, showError }) => {
    let errors = {};
    const messengers = {
      cbu: "El CBU debe tener 22 dígitos",
      bank: "El nombre del banco debe ser más largo",
      headline: "El titular de la cuenta debe ser de al menos 5 caracteres",
      cuit: "El CUIT debe tener al menos 8 dítigos",
    };
    const validate =
      this.state.cbu.length === 22 &&
      this.state.bank.length > 3 &&
      this.state.headline.length > 3 &&
      this.state.cuit.length > 5;
    if (inputName) {
      if (inputName === "cbu" && this.state.cbu.length !== 22)
        errors["cbu"] = messengers["cbu"];
      if (inputName === "bank" && this.state.bank.length < 3)
        errors["bank"] = messengers["bank"];
      if (inputName === "headline" && this.state.headline.length < 3)
        errors["headline"] = messengers["headline"];
      if (inputName === "cuit" && this.state.cuit.length < 8)
        errors["cuit"] = messengers["cuit"];
    } else {
      if (this.state.cbu.length !== 22) errors["cbu"] = messengers["cbu"];
      if (this.state.bank.length < 3) errors["bank"] = messengers["bank"];
      if (this.state.headline.length < 3)
        errors["headline"] = messengers["headline"];
      if (this.state.cuit.length < 8) errors["cuit"] = messengers["cuit"];
    }

    if (showError) this.setState({ errors: errors });
    this.props.validateStep(validate);
  };

  handleBlur = (event) => {
    const nam = event.target.name;
    const val = event.target.value;
    this.setState({ [nam]: val }, () => {
      this.props.paymentStep(this.state);
      this.handleValidate({ inputName: nam, showError: true });
    });
  };

  handleChange = (event) => {
    const nam = event.target.name;
    const val = event.target.value;
    let errors = {};

    if (nam === "cbu" && val.length > 22) {
      errors["cbu"] = "Máxima longitud alcanazada";
      this.setState({ errors: errors });
      return;
    }

    this.setState({ [nam]: val }, () => {
      this.props.paymentStep(this.state);
      this.handleValidate({ inputName: nam, showError: true });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="paymentResponsive">
          <div className="Payment" autocomplete="off">
            {this.state.modeEdit ? (
              " "
            ) : (
              <div className="Payment__progressBar">
                <ProgressBar
                  title={"DATOS BANCARIOS"}
                  stepAt={"Paso 3: Toques finales"}
                />{" "}
              </div>
            )}

            <div className="Payment__container">
              <h2 className="Payment__container__title">Recibí tu pago</h2>
              <p className="Payment__container__description">
                Completa los datos para recibir los pagos de tus huéspedes.
              </p>
              <div className="Payment__container__inputContainer">
                <label
                  htmlFor="cbu"
                  className="Payment__container__inputContainer__label"
                >
                  CBU
                </label>
                <input
                  autocomplete="off"
                  type="number"
                  name="cbu"
                  id="cbu"
                  className="Payment__container__inputContainer__input"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={this.state.cbu}
                />
                {/* <span className="Payment__container__inputContainer__counter">{this.state.cbu.length} de 22</span> */}
                {/* {
                  this.state.errors["cbu"]
                  ? <ErrorMessage message={this.state.errors["cbu"]} />
                  : ''
                } */}
              </div>
              <div className="Payment__container__inputContainer__error">
                {this.state.errors["cbu"] ? (
                  <ErrorMessage message={this.state.errors["cbu"]} />
                ) : (
                  ""
                )}
              </div>
              <div className="Payment__container__inputContainer">
                <label
                  htmlFor="bank"
                  className="Payment__container__inputContainer__label"
                >
                  Banco
                </label>
                <input
                  autocomplete="off"
                  type="text"
                  name="bank"
                  id="bank"
                  className="Payment__container__inputContainer__input"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={this.state.bank}
                />
                <div className="Payment__container__inputContainer__error">
                  {this.state.errors["bank"] ? (
                    <ErrorMessage message={this.state.errors["bank"]} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="Payment__container__inputContainer">
                <label
                  htmlFor="alias"
                  className="Payment__container__inputContainer__label"
                >
                  Alias
                </label>
                <input
                  autocomplete="off"
                  type="text"
                  name="alias"
                  id="alias"
                  className="Payment__container__inputContainer__input"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={this.state.alias}
                />
                <div className="Payment__container__inputContainer__error">
                  {this.state.errors["alias"] ? (
                    <ErrorMessage message={this.state.errors["alias"]} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="Payment__container__inputContainer">
                <label
                  htmlFor="owner"
                  className="Payment__container__inputContainer__label"
                >
                  Titular
                </label>
                <input
                  autocomplete="off"
                  type="text"
                  name="headline"
                  id="owner"
                  className="Payment__container__inputContainer__input"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={this.state.headline}
                />
                <div className="Payment__container__inputContainer__error">
                  {this.state.errors["headline"] ? (
                    <ErrorMessage message={this.state.errors["headline"]} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="Payment__container__inputContainer">
                <label
                  htmlFor="cuit_cuil"
                  className="Payment__container__inputContainer__label"
                >
                  CUIT / CUIL
                </label>
                <MaskedInput
                  type="text"
                  name="cuit"
                  mask="11-11111111-1"
                  id="cuit_cuil"
                  className="Payment__container__inputContainer__input"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={this.state.cuit}
                />
                <div className="Payment__container__inputContainer__error">
                  {this.state.errors["cuit"] ? (
                    <ErrorMessage message={this.state.errors["cuit"]} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {this.state.modeEdit ? <ButtonEdit /> : <Buttons />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps, aloharFormActions)(Payment);
