import React from "react";
import { Slideshow, Slide, TextoSlide } from "../LandingPage/useCarrousel";
import bank from "../../assets/static/Bancos/bank1.png";
import bank1 from "../../assets/static/Bancos/bank2.png";
import bank3 from "../../assets/static/Bancos/NaranjaX.png";
import bank5 from "../../assets/static/Bancos/bank5.png";
import bankProvince from "../../assets/static/Bancos/logoBancoProvincia.png";
import "../../assets/styles/LandingPage/sectionBanksMobile.sass";
const SectionBanksMobile = ({title}) => {
  return (
    <main className="sectionBankMobile">
      {
!title &&
      <h2 className="sectionBankMobile__title">Pagá en cuotas</h2>
      }
      <Slideshow
        controles={true}
        autoplay={true}
        velocidad="1000"
        intervalo="5000"
      >
        <Slide>
          <div className="sectionBank__container__section__logo ">
            <h2 className="sectionBankMobile__transferLogo ">$</h2>
          </div>
          <TextoSlide>
            <p className="sectionBankMobile__text">
              <strong>Transferencia bancaria</strong>
              <br />
              5% de descuento
            </p>
          </TextoSlide>
        </Slide>
        <Slide>
          <div className="sectionBank__container__section__logo ">
            <img className="sectionBankMobile__img1" src={bank} alt="" />
          </div>
          <TextoSlide colorFondo="navy">
            <p className="sectionBankMobile__text">
              <strong>Banco Nación</strong>
              <br />
              9 cuotas sin interés
              <br />
              hasta 30/09/2023
            </p>
          </TextoSlide>
        </Slide>
        <Slide>
          <div className="sectionBank__container__section__logo ">
            <img
              className="sectionBankMobile__img1"
              src={bankProvince}
              alt=""
            />
          </div>
          <TextoSlide colorFondo="navy">
            <p className="sectionBankMobile__text">
              <strong>Banco Provincia</strong>
              <br />
              6 cuotas sin interés
              <br />
              hasta 31/12/2023
            </p>
          </TextoSlide>
        </Slide>
        <Slide>
          <div className="sectionBank__container__section__logo ">
            <img className="sectionBankMobile__img1" src={bank5} alt="" />
          </div>
          <TextoSlide colorFondo="navy">
            <p className="sectionBankMobile__text">
              <strong>Promoción Yoy</strong>
              <br />
              30% de reintegro*
              <br />
              Abonando desde la app Yoy <br />
              con tarjeta de débito <br />
              Y 6 cuotas sin interés + reintegro* <br /> abonando con Mástercard
              *<br /> Reintegro con tope de $8000
              <br />
              hasta 31/12/2023
            </p>
          </TextoSlide>
        </Slide>
        <Slide>
          <div className="sectionBank__container__section__logo ">
            <img className="sectionBankMobile__img" src={bank1} alt="" />
          </div>
          <TextoSlide colorFondo="navy">
            <p className="sectionBankMobile__text">
              <strong>Plan Ahora</strong>
              <br />
              3,6,12 Con interés <br />
              hasta 31/12/2023
            </p>
          </TextoSlide>
        </Slide>
        <Slide>
          <div className="sectionBank__container__section__logo ">
            <img className="sectionBankMobile__img" src={bank3} alt="" />
          </div>
          <TextoSlide>
            <p className="sectionBankMobile__text">
              {/* <strong>Plan Z Con interés</strong> <br />
                <strong> 5 y 10 cuotas Con interés</strong> */}
              <strong>Plan Z Con interés</strong> <br />5 y 10 cuotas Con
              interés
            </p>
          </TextoSlide>
        </Slide>
      </Slideshow>
    </main>
  );
};
export default SectionBanksMobile;
