import React, { useRef, useEffect, useCallback } from "react";
import styled from "styled-components";

const Slideshow = ({
  children,
  controles = false,
  autoplay = false,
  velocidad = "500",
  interval = "5000",
}) => {
  const slideshow = useRef(null);
  const intervaloSlideshow = useRef(null);

  const siguiente = useCallback(() => {
    // Comprobamos que el slideshow tenga elementos
    if (slideshow.current.children.length > 0) {
      // Obtenemos el primer elemento del slideshow.
      const primerElemento = slideshow.current.children[0];

      // Establecemos la transicion para el slideshow.
      slideshow.current.style.transition = `${velocidad}ms ease-out all`;

      const tamañoSlide = slideshow.current.children[0].offsetWidth;

      // Movemos el slideshow
      slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

      const transicion = () => {
        // Reiniciamos la posicion del Slideshow.
        slideshow.current.style.transition = "none";
        slideshow.current.style.transform = `translateX(0)`;

        // Tomamos el primer elemento y lo mandamos al final.
        slideshow.current.appendChild(primerElemento);

        slideshow.current.removeEventListener("transitionend", transicion);
      };

      // Eventlistener para cuando termina la animacion.
      slideshow.current.addEventListener("transitionend", transicion);
    }
  }, [velocidad]);

  const anterior = () => {
    if (slideshow.current.children.length > 0) {
      // Obtenemos el ultimo elemento del slideshow.
      const index = slideshow.current.children.length - 1;
      const ultimoElemento = slideshow.current.children[index];
      slideshow.current.insertBefore(
        ultimoElemento,
        slideshow.current.firstChild
      );

      slideshow.current.style.transition = "none";
      const tamañoSlide = slideshow.current.children[0].offsetWidth;
      slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

      setTimeout(() => {
        slideshow.current.style.transition = `${velocidad}ms ease-out all`;
        slideshow.current.style.transform = `translateX(0)`;
      }, 30);
    }
  };

  useEffect(() => {
    if (autoplay) {
      intervaloSlideshow.current = setInterval(() => {
        siguiente();
      }, interval);

      // Eliminamos los intervalos
      slideshow.current.addEventListener("mouseenter", () => {
        clearInterval(intervaloSlideshow.current);
      });

      // Volvemos a poner el intervalo cuando saquen el cursor del slideshow
      slideshow.current.addEventListener("mouseleave", () => {
        intervaloSlideshow.current = setInterval(() => {
          siguiente();
        }, interval);
      });
    }
  }, [autoplay, interval, siguiente]);

  return (
    <Container>
      <ContainerSlideShow ref={slideshow}>{children}</ContainerSlideShow>
      {controles && (
        <Controls>
          <Button onClick={anterior}>
            <Arrow>{"<"}</Arrow>
          </Button>
          <Button derecho onClick={siguiente}>
            <Arrow>{">"}</Arrow>
          </Button>
        </Controls>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const ContainerSlideShow = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
const Arrow = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff4e00;
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid #ff4e00;
  border-radius: 100%;
  height: 35px;
  width: 35px;
`;

const Slide = styled.div`
  min-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 0;
  align-items: center;
  transition: 0.3s ease all;
  z-index: 10;
  /* max-height: 500px; */
  position: relative;
`;

const TextoSlide = styled.div`
  background: ${(props) =>
    props.colorFondo ? props.colorFondo : "rgba(0,0,0,.3)"};
  color: ${(props) => (props.colorTexto ? props.colorTexto : "#000")};
  width: 100%;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  @media screen and (max-width: 700px) {
    position: relative;
    background: none;
  }
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Button = styled.button`
  pointer-events: all;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  width: 50px;
  height: 100%;
  text-align: center;
  position: absolute;
  transition: 0.3s ease all;
  /* &:hover {
		background: rgba(0,0,0,.2);
		path {
			fill: #fff;
		}
	} */
  path {
    filter: ${(props) =>
      props.derecho
        ? "drop-shadow(-2px 0px 0px #fff)"
        : "drop-shadow(2px 0px 0px #fff)"};
  }
  ${(props) => (props.derecho ? "right: 0" : "left: 0")}
`;

export { Slideshow, Slide, TextoSlide };
