// Librerías
import React from 'react';
// Recursos
import '../../assets/styles/LandingPage/_flag.sass'

const Flag = () => {
    return(
        <div className="FlagArgentina__container">
            <div className="FlagArgentina">
                <div className="FlagArgentina__whitestrip"></div>
            </div>
        </div>
    );
}

export default Flag;