export const setCity = (data) => (dispatch) => {
    dispatch({
        type: "SET_CITY",
        city: data.city,
        cityName: data.cityName,
        neighborhood: data.neighborhood
    })
}
export const setCityHome = (data) => (dispatch) => {
    dispatch({
        type: "SET_CITY_HOME",
        cityHome: data.cityHome,
    })
}

export const setTriggerSearch = () => (dispatch) => {
    dispatch({
        type: "TRIGGER_SEARCH"
    })
}

export const setSearch = (data) => (dispatch) => {
    dispatch({
        type: "SET_SEARCH",
        city: data.city,
        checkin: data.checkin,
        checkout: data.checkout,
        adults: data.adults,
        childs: data.childs
    })
}

export const setUrl = (data) => (dispatch) => {
    dispatch({
        type: "SET_URL",
        url: data.url,
    })
}

export const setResult = (data) => (dispatch) => {
    dispatch({
        type: "SET_RESULT",
        result: data.result,
    })
}

export const setCheckin = (date) =>{
    return {
        type: "SET_CHECKIN",
        checkin: date
    }
}

export const setCheckout = (date) =>{
    return {
        type: "SET_CHECKOUT",
        checkout: date
    }
}

export const setAdult = (n) =>{
    return {
        type: "SET_ADULT",
        adults: n
    }
}

export const setChild = (n) =>{
    return {
        type: "SET_CHILD",
        childs: n
    }
}