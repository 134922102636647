import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const NavFooter = ({ children }) => {
  return (
    <div className="flex flex-col justify-between flex-1 h-min ">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default NavFooter;
