// Librerías
import React from "react";
import { useParams } from "react-router-dom";
// Componentes
import IntermediateStep from "../components/Intermediate/IntermediateStep";

const IntermediateContainer = () => {
  const { id } = useParams();

  return <IntermediateStep id={id} />;
};

export default IntermediateContainer;
