// Librerías
import React from 'react';
// Componentes
import Card from '../../PlaceCard/CardV2';
// Recursos
import '../../../assets/styles/PropertyPage/Utils/_properties.sass';
import img1 from '../../../assets/static/PlaceCards/place1.png';
import img2 from '../../../assets/static/PlaceCards/place2.png';
import img3 from '../../../assets/static/PlaceCards/place3.png';

const properties = [
  {
    id: 1,
    url: "/",
    img: img1,
    price: 900,
    title: "Lindo monambiente cerca del mar",
    guests: 2,
    rooms: 2,
    beds: 2,
    bathrooms: 2,
  },
  {
    id: 2,
    url: "/",
    img: img2,
    price: 1300,
    title: "Departamento al Mar en Playa Varese con cochera",
    guests: 2,
    rooms: 2,
    beds: 2,
    bathrooms: 2,
  },
  {
    id: 3,
    url: "/",
    img: img3,
    price: 2300,
    title: "Departamento nuevo con vista al mar",
    guests: 2,
    rooms: 2,
    beds: 2,
    bathrooms: 2,
  },
];

const listProperties = properties.map((item) => {
  return(
  <Card
    key={item.id}
    url={item.url}
    img={item.img}
    price={item.price}
    title={item.title}
    guests={item.guests}
    rooms={item.rooms}
    beds={item.beds}
    bathrooms={item.bathrooms}
  />);
});

const PropertyProperties = ({housing}) => {
  return(
    <React.Fragment>
      <div className="PropertyProperties">
        <div className="PropertyProperties__title">
          Más propiedades de {housing.location.location}
        </div>
        <div className="PropertyProperties__container">
          { listProperties }
        </div>
      </div>
    </React.Fragment>
  );
}

export default PropertyProperties;