import React, { Component } from "react";
import ReactDOM from "react-dom";
import store from "../../store";
import { Provider } from "react-redux";
import "../../assets/styles/Modals/_modal.sass";

class ModalWrapper extends Component {
  // Helpers
  // https://www.youtube.com/watch?v=WGjv-p9jYf0
  // https://medium.com/backticks-tildes/creating-a-modal-component-the-redux-way-cf9f4c5497dd

  componentDidMount() {
    this.modalTarget = document.createElement("div");
    this.modalTarget.className = "modalLogin overlay";
    document.body.appendChild(this.modalTarget);
    /* Overlay */
    this.overlay = document.createElement("div");
    this.overlay.className = "modalOverlay";
    document.body.appendChild(this.overlay);

    this._render();
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.modalTarget);
    document.body.removeChild(this.modalTarget);

    document.body.removeChild(this.overlay);
  }

  _render() {
    ReactDOM.render(
      <Provider store={store}>{this.props.children}</Provider>,
      this.modalTarget
    );
  }

  render() {
    return <noscript />;
  }
}

export default ModalWrapper;
