// Librerías
import React from 'react';
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
// Recursos
import arrowLeft from '../../src/assets/static/Landing/arrowLeft.png';
import '../assets/styles/Error404/_error.sass';

const Error404 = (props) => {

  const handleBack = () => {
    props.history.goBack()
  }

  return(
    <div className="Error404">
      <div className="Error404__container">
        <div className="Error404__container__text">
          <p className="Error404__container__text__subtitle">404</p>
          <h1 className="Error404__container__text__title">La página no fue encontrada, podés volver a intentar</h1>
          <h1 className="Error404__container__text__title">o pensar que sos vos disfrutando esa vista por un ratito.</h1>
          <button className="Error404__container__text__btn" onClick={() => handleBack()}> <img alt='Volver' src={arrowLeft}/>
            Volver a intentar
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Error404);