import React from "react";
import "./valuation-comments.sass";
import UserAvatar from "../../../ui/userAvatar";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
const ValuationComments = ({ response,userId }) => {
  const commetsGuestToHost = response.data.guest_to_host.filter(
    (e) => e.publicMessage.length > 0
  );
  const commetHostToGuest = response.data.host_to_guest.filter(
    (e) => e.publicMessage.length > 0
  );
  return (
    <div className="valuationComments">
      <UserAvatar userData={response} />
      {commetsGuestToHost.length > 0 && (
        <Link
          className="valuationComments__link"
          to={`/users/show/${userId}`}
        >
          <div className="valuationComments__content">
            <UserAvatar size={50} userData={commetsGuestToHost[0].from} />
            <p> {commetsGuestToHost[0].from.firstName}</p>
          </div>
          <p className="valuationComments__text">
            {commetsGuestToHost[0].publicMessage}
          </p>
        </Link>
      )}
      {commetHostToGuest.length > 0 && (
        <Link
          className="valuationComments__link"
          to={`/users/show/${userId}`}
        >
          <div className="valuationComments__content">
            <UserAvatar size={50} userData={commetHostToGuest[0].from} />
            <p> {commetHostToGuest[0].from.firstName}</p>
          </div>
          <p className="valuationComments__text">
            {commetHostToGuest[0].publicMessage}
          </p>
        </Link>
      )}
    </div>
  );
};

export default ValuationComments;
