export const validateFormCalendar = (form) => {
  let errors = {};
  if (form.link.length < 5) {
    errors.cbu = "Debes ingresar un link valido";
  }
  if (form.description.length < 5) {
    errors.description = "Debes guardar el calendario con un nombre ";
  }

  return errors;
};
