import React from "react";
import hotSale from "../../assets/static/hot_sale/boton-home-hot-sale-.png";
import "../../assets/styles/Buttons/btn.sass";
import { Link } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
const HotSaleButton = () => {
  const checkin =  moment().add(3, "days").format("DD/MM/YY")
  const checkout = moment().add(11, "days").format("DD/MM/YY");
  return (
    <Link
      to={`Promos/search?city=501&checkin=${checkin}&checkout=${checkout}&childs=0&adults=1&cityName=Promos`}
      className={"fixed left-[20px] bottom-[3%] w-[50px] h-[50px] sm:h-[9vw] sm:w-[9vw] z-50"}
    >
      <img src={hotSale} alt="Hot sale" className="w-full h-full " />
    </Link>
  );
};
export default HotSaleButton;
