import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import ArrowSendIcon from "../../../../assets/components-icons/ArrowSendIcon";
import { BASE_URL } from "../../../../Helpers";
import { useForm } from "../../../../hooks/useForm";
import AxiosAuth from "../../../../services/requestApi";
import "./box-send-message.sass";

const initialForm = {
  message: "",
};

const BoxSendMessage = ({
  toId,
  housingId,
  refClickUser,
  setChatList,
  setScollToDown,
}) => {
  const router = useHistory();
  const { form, handleChange } = useForm(initialForm);
  const myUser = JSON.parse(window.localStorage.getItem("userData"));

  const sendMessenger = async () => {
    const url = BASE_URL + "/user/messages";
    const bodyParameters = {
      message: form.message,
      to: {
        id: toId,
      },
      hosting: {
        id: housingId.id,
      },
    };

    try {
      const response = await AxiosAuth.post(url, bodyParameters);
      const newMessenger = {
        from: {
          avatar: myUser.avatar,
          firstName: myUser.firstName,
        },
        issued: new Date(),
        message: form.message,
      };
      console.log(response);
      
      setScollToDown(true);
      setChatList((chatList) => [...chatList, newMessenger]);
    } catch (error) {
      console.log(error);
    }

    handleChange({ target: { name: "message", value: "" } });
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter" && event.key !== "Backspace") {
      // No es necesario mantener el valor en el estado inputValue
      // setInputValue(event.target.value);
    } else if (event.key === "Backspace") {
      event.preventDefault();
      handleBackspace(event);
    } else if (event.target.selectionStart !== event.target.selectionEnd) {
      event.preventDefault();
      const currentPosition = event.target.selectionStart;
      const newValue =
        form.message.substring(0, currentPosition) +
        String.fromCharCode(event.which) +
        form.message.substring(event.target.selectionEnd);
      handleChange({ target: { name: "message", value: newValue } });
      event.target.setSelectionRange(currentPosition + 1, currentPosition + 1);
    }
  };

  const handleBackspace = (event) => {
    const currentPosition = event.target.selectionStart;
    if (currentPosition > 0) {
      const newValue =
        form.message.substring(0, currentPosition - 1) +
        form.message.substring(currentPosition);

      handleChange({ target: { name: "message", value: newValue } });

      if (refClickUser.current) {
        setTimeout(() => {
          refClickUser.current.setSelectionRange(
            currentPosition - 1,
            currentPosition - 1
          );
        }, 0);
      }
    }
  };

  useEffect(() => {
    refClickUser.current.focus();
  }, []);

  return (
    <div
      className={
        router.location.pathname.includes("host-mode")
          ? "boxSendMessageHost"
          : "boxSendMessage"
      }
    >
      <textarea
        name="message"
        className="boxSendMessage__input"
        type="text"
        placeholder="Escribe tu respuesta..."
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={form.message}
        id="messenger"
        rows="1"
        ref={refClickUser}
      ></textarea>
      <button
        disabled={form.message.trim() === ""}
        className="boxSendMessage__button"
        onClick={sendMessenger}
      >
        <ArrowSendIcon size={"100%"} color={"#FF4E00"} />
      </button>
    </div>
  );
};

export default BoxSendMessage;
