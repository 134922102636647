// Librerías
import React from "react";
// Componentes
import Card from "./BigCard";
// Recursos
import "../../assets/styles/LandingPage/_seccion_1.sass";
import { BASE_URL } from "../../Helpers";
import moment from "moment";

const Seccion_1 = ({ promotions }) => {
  const checkin = moment(Date.now()).utc(true).format("DD/MM/YY");
  const checkout = moment().add(5, "days").format("DD/MM/YY");
  const listedPromotions = promotions
    .sort((a, b) => {
      return a.order > b.order ? 1 : -1;
    })
    .filter((item) => item.kind === 1)
    .slice(0, 4);

  const listPromos = listedPromotions.map((item, index) => {
    return (
      <div
        key={item.id}
        className={`${
          index === 3
            ? "Section1__container Section1__container__big"
            : "Section1__container"
        }`}
      >
        <Card
          titulo={item.titulo}
          text={item.description}
          img={`${BASE_URL}/location/promotion/${item.image}`}
          url={`search?city=${item.location.id}&checkin=${checkin}&checkout=${checkout}&childs=0&adults=1&cityName=${item.description}`}
          price={item.price}
          place={item.location.location}
          marginTop={index < 4}
          last={index === 3}
        />
      </div>
    );
  });

  return <div className="Section1">{listPromos}</div>;
};

export default Seccion_1;
