// Librerías
import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
// Componentes
import SelectCity from "./SelectCity.js";
import SelectDate from "./SelectDate.js";
// Recursos
import "../../assets/styles/MainSearch/__maininputsearch.sass";
import search from "../../assets/static/Landing/icon/search.svg";
import * as serachActions from "../../store/search/actions";
import "../../../src/assets/styles/Buttons/btn.sass";
import { isMobile } from "react-device-detect";
import backgroundSearch from "../../assets/static/landing-host/background-search.webp";
import ImageLazyLoad from "../ui/image-lazyLoad/index.js";
import AdultsAndChild from "./AdultsAndChild.js";

const MainInputSearchNew = (props) => {
  const { addSearch } = props;
  const { login } = props.userReducer;
  const navbar = addSearch ? "navbar" : "";
  const [city, setCity] = useState(
    props.searchReducer.cityName ? props.searchReducer.cityName : false
  );

  const [adults, setAdults] = useState(1);
  const [childs, setChilds] = useState(0);
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const history = useHistory();
  const [urlInit, setUrlInit] = useState(false);
  const [errorSearch, setErrorSearch] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [cityName, setCityName] = useState(props.searchReducer.cityName);

  const query = queryString.parse(window.location.search);

  useEffect(() => {
    if (!urlInit) {
      if (query["city"]) {
        setCity(parseInt(query.city));
        props.setCity({ city: parseInt(query.city), cityName: query.cityName });
        props.setAdult(
          !isNaN(parseInt(query.adults)) ? parseInt(query.adults) : 1
        );
        props.setChild(
          !isNaN(parseInt(query.childs)) ? parseInt(query.childs) : 0
        );
        props.setCheckin(query.checkin ? query.checkin : "");
        props.setCheckout(query.checkout ? query.checkout : "");
        setUrlInit(true);
      }
    } else {
      setCity(props.searchReducer.city);
      setAdults(props.searchReducer.adults);
      setChilds(props.searchReducer.childs);
      setCheckin(props.searchReducer.checkin);
      setCheckout(props.searchReducer.checkout);
    }
  }, [props]);

  const goSearch = () => {
    const { city, checkin, checkout, childs, adults } = props.searchReducer;
    const cityNameSearch = cityName.replace(/\s+/g, "-");
    if (city && checkin && checkout && city && cityName) {
      const url = `/${cityNameSearch}/search?city=${city}&checkin=${checkin}&checkout=${checkout}&childs=${childs}&adults=${adults}&cityName=${cityName}&locationNeighborhood=${props.searchReducer.neighborhood}`;
      history.push(url);
      props.setTriggerSearch();
    }

    if (city && cityName && (checkin === "" || checkout === "")) {
      const url = `/${cityNameSearch}/search?city=${city}&childs=${childs}&adults=${adults}&cityName=${cityName}`;
      history.push(url);
      props.setTriggerSearch();
    }
  };

  const handleSearch = (e) => {
    goSearch();
  };
  const preSearch = () => {
    if (startDate && endDate) {
      handleSearch();
      setErrorSearch(false);
    } else {
      setErrorSearch(true);
    }
  };

  useEffect(() => {
    if (
      props.searchReducer.checkin &&
      props.searchReducer.checkout &&
      props.searchReducer.cityName
    ) {
      setCheckin(props.searchReducer.checkin);
      setCheckout(props.searchReducer.checkout);
      setCityName(props.searchReducer.cityName);
    } else {
      setCheckin("");
      setCheckout("");
      setCityName("");
    }
  }, [
    props.searchReducer.checkin,
    props.searchReducer.checkout,
    props.searchReducer.cityName,
  ]);

  useEffect(() => {
    if (checkin && checkout) {
      setCheckin(checkin);
      setCheckout(checkout);
    } else {
      setCheckin("");
      setCheckout("");
    }
  }, [checkin, checkout]);
  useEffect(() => {
    if (props.searchReducer.adults || props.searchReducer.childs) {
      setAdults(props.searchReducer.adults);
      setChilds(props.searchReducer.childs);
    } else {
      setAdults(1);
      setChilds(props.searchReducer.childs);
    }
  }, [props.searchReducer.adults, props.searchReducer.childs]);
  useEffect(() => {
    if (checkin && checkout) {
      preSearch();
    }
  }, [props.searchReducer.city]);

  return (
    <Suspense fallback={<></>}>
      <div
        className={`relative ${
          addSearch && "sm:p-[0!important] max-w-[800px!important]"
        } pt-8vw MainInputSearch ${navbar}  sm:min-w-[600px]  h-full mx-auto w-11/12 sm:w-[69.2vw] min-w-0 sm:max-w-full sm:max-h-96 sm:h-[8.2vw] sm:rounded-150 sm:pl-[4%] sm:flex sm:justify-between sm:items-center p-0 flex flex-col sm:flex-row`}
      >
        {isMobile && (
          <ImageLazyLoad
            src={backgroundSearch}
            alt="Background"
            classNameContent={"w-full sm:hidden"}
            className="w-full   bg-no-repeat   bg-cover bg-backgroundSearch rounded-14 sm:h-auto h-[40vw]"
          />
        )}
        <div
          className={`${
            addSearch && "sm:w-auto navSearch"
          } sm:w-[25%] border-b-2 sm:flex sm:flex-col sm:justify-center border-black MainInputSearch__cities p-[0] sm:border-none  sm:max-h-full sm:h-full m-0`}
        >
          <div
            className={`flex flex-col items-center sm:justify-between p-0 sm:ml-[15%]  MainInputSearch__container sm:items-start sm:h-[65%] ${
              addSearch && "navSearch"
            }`}
          >
            {isMobile ? (
              <h2
                className="h-[21vw] text-start flex justify-start items-end pb-3 w-full RobotoBold text-[6vw]"
                onClick={() => props.setCity(false)}
              >
                <strong> {props.searchReducer.cityName}</strong>
              </h2>
            ) : (
              <>
                <label
                  onClick={() => props.setCity(false)}
                  className={`hidden p-0 font-extrabold MainInputSearch__container__label sm:grid ${
                    addSearch && "sm:text-[.8rem]"
                  }`}
                >
                  ¿Cual es tu próximo destino ?
                </label>
                <SelectCity
                  cityName={props.searchReducer.cityName}
                  newStyle={true}
                  selectedCity={city}
                  placeholder={
                    props.searchReducer.cityName
                      ? props.searchReducer.cityName
                      : "Dónde vamos?"
                  }
                />
              </>
            )}
          </div>
        </div>
        <div
          // sm:w-[17%]
          className={`${
            addSearch && "sm:w-auto"
          } MainInputSearch__people  sm:w-max p-0 ml-4 sm:max-h-full h-[23vw] sm:h-full sm:items-center`}
        >
          {/* sm:h-[40px] */}
          <div className={`${addSearch && "justify-end"} sm:h-[65%] sm:flex flex-row gap-4 sm:gap-12    sm:w-full MainInputSearch__container  items-center `}>
            {/* justify-between */}
            {/* items-center */}
            {/* sm:items-start */}
            {/*  */}
            <AdultsAndChild
              title={"Adultos"}
              setValue={props.setAdult}
              value={adults}
              addSearch={addSearch}
            />
            <AdultsAndChild
              title={"Menores"}
              setValue={props.setChild}
              value={childs}
              addSearch={addSearch}
            />
          </div>
        </div>
        <div className="MainInputSearch__dates sm:h-[100%] p-0   max-sm:max-w-[100%] sm:max-w-[270px] sm:max-w-auto sm:w-max sm:max-h-full  items-center">
          <div
            className={`w-full MainInputSearch__container  sm:w-72 sm:h-[65%] justify-between ${
              addSearch && "navSearch"
            }`}
          >
            <label
              className={`${
                addSearch && "text-[.8rem]"
              } hidden p-0 mx-auto font-extrabold text-center sm:grid MainInputSearch__container__label`}
            >
              ¿Cuándo?
            </label>
            {errorSearch && (
              <span className="MainInputSearch__container__alert"></span>
            )}
            <span className="flex h-[23vw] justify-center w-full mx-auto border-t-2 border-b-2 sm:h-[60%] border-t-black border-b-black sm:border-none mt-[2px] sm:mt-0">
              <SelectDate
                from={"Desde"}
                to={"Hasta"}
                format={isMobile && "DD | MM | YY "}
                newStyle={true}
                checkin={checkin}
                checkout={checkout}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setErrorSearch={setErrorSearch}
              />
            </span>
          </div>
        </div>
        <button
          aria-label="search"
          disabled={errorSearch ? true : false}
          className="flex self-start justify-center ml-[50%] items-center sm:hidden sm:h-auto h-[23vw] w-[50%] rounded-14 MobileMainInputSearch"
          onClick={() => preSearch()}
        >
          <img className="w-[40%]" src={search} alt="Buscar" />
        </button>
        <div className="items-center justify-center hidden max-w-xl sm:flex max-h-24 MainInputSearch__btncontainer">
          <button
            aria-label="pre search"
            disabled={errorSearch ? true : false}
            className="w-[6.2vw] h-[6.2vw] -translate-x-4 MainInputSearch__searchButton"
            onClick={() => preSearch()}
          >
            <img
              src={search}
              alt="Buscar"
              className="MainInputSearch__searchButton__img"
            />
          </button>
        </div>
      </div>

      {login ? (
        ""
      ) : (
        <div className="MobileMainInputSearchButtonsContainer"></div>
      )}
    </Suspense>
  );
};

const mapStateToProps = (reducers) => {
  return {
    searchReducer: reducers.searchReducer,
    userReducer: reducers.userReducer,
  };
};

export default connect(mapStateToProps, serachActions)(MainInputSearchNew);
