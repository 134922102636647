import React from "react";
import NavFooter from "../../containers/nav-footer";
import { termsAndConditions } from "./termsAndConditions";
import { termsAndPrivacy } from "./termsAndPrivacy";
import TitleAndList from "./TitleAndList";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import ButtonPrimary from "../ui/button-primary";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TermsConditions = () => {
  const termCondition = Object.values(termsAndConditions);
  const termPrivacy = Object.values(termsAndPrivacy);
  const buttonHeader = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const router = useHistory();

  const pushButtonRef = () => {
    if (buttonHeader.current) {
      buttonHeader.current.scrollIntoView({
        behavior: "smooth", // Opcional: agrega un desplazamiento suave
        block: "start", // Opcional: posición del elemento relativo a la ventana (start, center, end)
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // entries es una lista de objetos de intersección
        entries.forEach((entry) => {
          // isIntersecting es true cuando el elemento es visible en el viewport
          setIsVisible(entry.isIntersecting);
        });
      },
      {
        // Opciones del Intersection Observer
        root: null, // null para usar el viewport como root
        rootMargin: "0px", // margen del root, ej. '10px 0px 0px 0px'
        threshold: 0.1, // porcentaje de intersección para considerar visible (0.1 significa 10%)
      }
    );

    if (buttonHeader.current) {
      observer.observe(buttonHeader.current);
    }

    // Limpiar el observer cuando el componente se desmonta
    return () => {
      if (buttonHeader.current) {
        observer.unobserve(buttonHeader.current);
      }
    };
  }, []);
  return (
    <NavFooter>
      <div className="relative w-full">
        <div ref={buttonHeader}></div>
        <TitleAndList
          array={
            router.location.pathname.includes("conditions")
              ? termCondition
              : termPrivacy
          }
        />
        {!isVisible && (
          <div
            className={`${
              !isVisible
                ? "component-animationFadeIn sticky transition-all duration-500 right-10 bottom-10   pr-10 pb-10 flex justify-end  "
                : "component-animationFadeOut sticky transition-all duration-500 right-10 bottom-10  pr-10 pb-10 flex justify-end "
            }`}
          >
            <ButtonPrimary
              title={"Volver al principio"}
              onClick={pushButtonRef}
            />
          </div>
        )}
      </div>
    </NavFooter>
  );
};

export default TermsConditions;
