// Librerías
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { BASE_URL } from "../../Helpers";
// Componentes
import { closeModal } from "../../store/modal/actions";
// Recursos
import x from "../../assets/static/Modals/x.svg";
import "../../assets/styles/Modals/_messenger_modal.sass";
import AxiosAuth from "../../services/requestApi";
import loadingGif from "../../assets/static/loading.gif";

const MessengerModal = ({
  actionModal,
  housingId,
  userId,
  firstName,
  checkin,
  checkout,
  messageSistem,
}) => {
  const [messenger, setMessenger] = useState("");
  const [sending, setSending] = useState(true);
  const [error, setError] = useState(false);
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (housingId && userId && messenger.length > 0) {
      setSending(true);
      sendMessenger();
    }
  };

  useEffect(() => {
    sendMessenger();
  }, []);

  const sendMessenger = async () => {
    setSending(false);
    setLoading(true);
    const url = BASE_URL + "/user/messages";
    const bodyParameters = {
      messageSistem:
        messageSistem &&
        `¡Hola ${firstName}!, Estoy interesado/a en alojarme en tu propiedad desde el ${checkin} hasta el  ${checkout}. ¿Está disponible para esas fechas? `,
      message: !messageSistem && messenger,
      to: {
        id: userId,
      },
      hosting: {
        id: housingId,
      },
    };

    const send = await AxiosAuth.post(url, bodyParameters)
      .then((data) => {
        setSending(false);
        setMessenger("");
        setSended(true);
        setLoading(false);
        window.location.href = "/messengers";
      })
      .catch((e) => {
        setLoading(false);
        setSending(false);
        setError(true);
        console.log(e);
      });

    return send;
  };

  return (
    <div className="MessengerModal">
      <header className="MessengerModal__header">
        <p className="MessengerModal__header__title">
          Comunicate con {firstName}
        </p>
        <img
          className="MessengerModal__header__cross"
          onClick={() => dispatch(closeModal())}
          alt="x"
          src={x}
        />
      </header>
      <section className="MessengerModal__section">
        <form className="MessengerModal__section__form" onSubmit={handleSubmit}>
          {loading && (
            <img
              style={{ position: "static" }}
              className="Checkout__loading"
              alt="loading"
              src={loadingGif}
            />
          )}
          {sended && (
            <>
              <p className="MessengerModal__section__form__title">
                ¡Mensaje enviado!
              </p>
              <br />
              <p className="MessengerModal__section__form__text">
                ¡{firstName} recibió tu consulta!
              </p>
              <p className="MessengerModal__section__form__text">
                Podrás ver su respuesta en la sección de mensajería
              </p>
              <br />
              <p className="MessengerModal__section__form__text">
                <strong>Atención:</strong> cuando te responda {firstName},
                recibirás un email, si utilizas GMAIL puede que su mensaje te
                llegue a la casilla de "Promotion".
              </p>

              <button
                onClick={() => dispatch(closeModal())}
                className="MessengerModal__section__form__btn"
              >
                Cerrar
              </button>
            </>
          )}
          {sending && (
            <>
              <p className="MessengerModal__section__form__title">
                {firstName} suele
                <br />
                responder en unas horas
              </p>
              <br />
              <p className="MessengerModal__section__form__text">
                ¿Tenés alguna pregunta para {firstName}?
              </p>
              <br />
              <textarea
                className="MessengerModal__section__form__textarea"
                value={messenger}
                onChange={(e) => setMessenger(e.target.value)}
              />
              <button
                type="submit"
                className="MessengerModal__section__form__btn"
              >
                Enviar
              </button>
            </>
          )}
          {error && (
            <div
              style={{ height: "200px", display: "flex", alignItems: "center" }}
            >
              <p className="MessengerModal__header__title">
                Ups! hubo un error al enviar tu mensaje. Intentá cerrando sesión
                e iniciando nuevamente, o probá nuevamente en unos minutos.
              </p>
            </div>
          )}
        </form>
      </section>
    </div>
  );
};

export default connect(null, { closeModal })(MessengerModal);
