import React from "react";

const ReloadIcon = ({ size, color }) => {
  return (
    <svg width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill={color ? color : "#000"}
        d="M17 11h6.98A9.98 9.98 0 0 0 6 17a10.01 10.01 0 0 0 19.8 2h4.04A14 14 0 0 1 16 31C8.28 31 2 24.72 2 17S8.28 3 16 3a13.94 13.94 0 0 1 10 4.22V2h4v10a3 3 0 0 1-3 3H17v-4z"
      ></path>
    </svg>
  );
};

export default ReloadIcon;
