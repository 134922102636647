import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import HandleLikeIcon from "../../../assets/components-icons/HandleLike";
import LoadingGift from "../../../assets/components-icons/LoadingGift";
import { BASE_URL } from "../../../Helpers";
import { useForm } from "../../../hooks/useForm";
import AxiosAuth from "../../../services/requestApi";
import ErrorMessage from "../../Alerts/ErrorAlert";
import FinishedModal from "../../Modals/FinishedModal";
import GlobalModal from "../../Modals/global-modal";
import ButtonPrimary from "../../ui/button-primary";
import InputGlobal from "../../ui/input-global";
import ModalGlobal from "../../ui/modal-global";
import "./bank-account.sass";

const initialForm = {
  cbu: "",
  alias: "",
  bank: "",
  cuit: "",
  headline: "",
};
const validateForm = (form) => {
  let errors = {};
  if (form.cbu.length !== 22) {
    errors.cbu = "El CBU debe tener 22 dígitos";
  }
  if (form.bank.length < 3) {
    errors.bank = "El nombre del banco debe ser más largo";
  }
  if (form.headline.length < 3) {
    errors.headline =
      "El titular de la cuenta debe ser de al menos 5 caracteres";
  }
  if (form.cuit.length < 5) {
    errors.cuit = "El CUIT debe tener al menos 8 dítigos";
  }

  return errors;
};
const BankAccount = ({ buttonBack }) => {
  const { errors, form, handleChange, handleBlur } = useForm(
    initialForm,
    validateForm
  );
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    const url = BASE_URL + "/payment/bankAccount";

    AxiosAuth.get(url)
      .then((response) => {
        if (response.data) {
          form.cbu = response.data.cbu;
          form.alias = response.data.alias;
          form.bank = response.data.bank;
          form.cuit = response.data.cuit;
          form.headline = response.data.headline;
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleValidate = async () => {
    setLoadingButton(true);
    const url = `${BASE_URL}/payment`;
    const body = {
      cbu: form.cbu,
      alias: form.alias,
      bank: form.bank,
      headline: form.headline,
      cuit: form.cuit,
    };

    await AxiosAuth.post(url, body)
      .then((response) => {
        setLoadingButton(false);
        setOpenModal(true);
      })
      .catch((error) => {
        setLoadingButton(false);
        setOpenModal(true);
        console.log(error);
      });
  };

  return (
    <>
      {loading ? (
        <LoadingGift size={250} />
      ) : (
        <div className="bankAccount">
          <ModalGlobal
            title={"asdasd"}
            closeModal={openModal}
            setCloseModal={() => setOpenModal(!openModal)}
          >
            <div className="bankAccount__modal">
              <HandleLikeIcon size={100} color={"#ff4e00"} />
              <h2 className="bankAccount__modal__title">
                Sus datos han sido guardados correctamente
              </h2>
              <ButtonPrimary
                title={"Volver"}
                onClick={() => setOpenModal(!openModal)}
              />
            </div>
          </ModalGlobal>

          <h1 className="bankAccount__title">Datos bancarios</h1>

          <InputGlobal
            maxLength={22}
            value={form.cbu}
            prefix={"CBU"}
            title={"CBU"}
            name={"cbu"}
            placeholder={"Cbu"}
            type={"text"}
            onChange={handleChange}
            handleBlur={handleBlur}
          />
          {errors.cbu && <ErrorMessage message={errors.cbu} />}

          <InputGlobal
            handleBlur={handleBlur}
            value={form.bank}
            prefix={"banco"}
            title={"banco"}
            name={"bank"}
            placeholder={"Banco"}
            type={"text"}
            onChange={handleChange}
          />
          {errors.bank && <ErrorMessage message={errors.bank} />}
          <InputGlobal
            handleBlur={handleBlur}
            value={form.alias}
            title={"Alias"}
            prefix={"Alias"}
            name={"alias"}
            placeholder={"Alias"}
            type={"text"}
            onChange={handleChange}
          />
          {errors.alias && <ErrorMessage message={errors.alias} />}

          <InputGlobal
            handleBlur={handleBlur}
            value={form.headline}
            title={"Titular"}
            name={"headline"}
            placeholder={"Titular"}
            type={"text"}
            onChange={handleChange}
            prefix={"Titular"}
          />
          {errors.headline && <ErrorMessage message={errors.headline} />}

          <InputGlobal
            handleBlur={handleBlur}
            value={form.cuit}
            prefix={"CUIT / CUIL"}
            title={"CUIT / CUIL"}
            onChange={handleChange}
            name={"cuit"}
            placeholder={"CUIT / CUIL"}
            cuit={true}
            min={0}
            type={"number"}
          />
          {errors.cuit && <ErrorMessage message={errors.cuit} />}
          <div className="bankAccount__buttons">
            <ButtonPrimary
              secondaryColor={true}
              title={"Volver"}
              onClick={buttonBack}
            />
            <ButtonPrimary
              loading={loadingButton}
              disabled={
                errors.cbu || errors.bank || errors.cuit || errors.headline
                  ? true
                  : false
              }
              title={"Guardar"}
              onClick={() => handleValidate()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BankAccount;
