import React from "react";
import img1 from "./../../assets/static/Editorial/img1.jpg";
import aloharBlack from "./../../assets/static/Navbar/BLACK_LOGO_ALOHAR.svg";
import img1mob from "./../../assets/static/Editorial/img1mob.jpg";
import img2mob from "./../../assets/static/Editorial/img2mob.jpg";
import img3mob from "./../../assets/static/Editorial/img3mob.jpg";
import img5mob from "./../../assets/static/Editorial/img5mob.jpg";
import img6mob from "./../../assets/static/Editorial/img6mob.jpg";
import img7mob from "./../../assets/static/Editorial/img7mob.jpg";
import img4mob from "./../../assets/static/Editorial/img4mob.jpg";
import img9mob from "./../../assets/static/Editorial/img9mob.jpg";
import img10mob from "./../../assets/static/Editorial/img10mob.jpg";
import img11mob from "./../../assets/static/Editorial/img11mob.jpg";
import img12mob from "./../../assets/static/Editorial/img12mob.jpg";
import img13mob from "./../../assets/static/Editorial/img13mob.jpg";
import Navbar from "../../components/Navbar/Navbar";
import "./editorialMobile.sass";
const EditorialMobile = () => {
  return (
    <div className="editorialMobile">
      <Navbar />
      <div className="editorialMobile__container">
        <div className="editorialMobile__container__titles">
          <h1 className="editorialMobile__container__titles__title">
            Geo - <br />
            locraliza - <br />
            ción
          </h1>
          <p className="editorialMobile__container__titles__text">
            LOCRO, LUQRU, RUQRU tiene su origen en
            <br />
            la cultura Quichua de la región andina,
            <br />
            quienes utilizan maíz y porotos como in- <br />
            gredientes principales, propios de tierras <br />
            frías y áridas, porque sus calorías son de <br />
            mucha ayuda para habitar esas regiones.
            <br />
            <span className="editorialMobile__container__titles__text__space">
              Plato típico patrio, tal vez porque estuvo en
              <br />
              las buenas y en las malas desde el principio
              <br />
              de nuestra identidad.
            </span>
          </p>
          <br />
          <h3 className="editorialMobile__container__titles__h3">
            Quien se considere
            <br />
            Gaucho deberia reconocer
            <br />
            cada pago por su Locro.
          </h3>
        </div>
        <div className="editorialMobile__container__images">
          <img
            className="editorialMobile__container__images__imgSmall"
            src={img1}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__text">
            Sus ingredientes son un recorrido por
            <br />
            nuestra soberanía, a lo largo de su vida
            <br />
            y su extensión. El maíz, la carne de vaca,
            <br />
            el chorizo, la calabaza y los porotos,
            <br />
            son cultivados en todas las llanuras
            <br />
            argentinas.
          </p>
          <br />
          <h3 className="editorialMobile__container__titles__h3">
            El locro se cocina
            <br />
            a fuego lento durante horas
            <br />
            para reunir gente alrededor
            <br />
            y lograr una textura suave
            <br />y un sabor ahumado.
          </h3>
        </div>
        <div className="editorialMobile__container__images">
          <img
            className="editorialMobile__container__images__imgBig"
            src={img13mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <h3 className="editorialMobile__container__titles__h3">
            Viajar en un feriado patrio <br />
            y cocinar un locro <br />
            con ingredientes locales, <br />
            en cualquier destino del país, <br />
            es una excelente manera de <br />
            compartir la cultura de la <br />
            región.
          </h3>
          <p className="editorialMobile__container__titles__text">
            El locro es nuestro plato soberano, y<br />
            cada región tiene su propia versión con
            <br />
            ingredientes locales que reflejan la
            <br />
            diversidad y riqueza del país.
            <br />
            <span className="editorialMobile__container__titles__text__space">
              Cocinar un locro en equipo, en un
              <br /> lugar nuevo, puede ser una experiencia <br />
              divertida y enriquecedora. También, una
              <br /> manera de conocer los mercados
              <br /> locales, aprender sobre la historia –<br />y otras
              historias–
              <br />y disfrutar de una
              <br /> manera auténtica y memorable
            </span>
          </p>
          <br />
          <h3 className="editorialMobile__container__titles__h3">
            Geolocralización.
            <br />
            Cada lugar tiene su propio
            <br />
            toque y secreto
            <br />
            para hacer del locro...
            <br />
            un locro de autor.
          </h3>
          <h2 className="editorialMobile__container__titles__secondTitle">
            'Fondo con
            <br />
            &nbsp;Locro'
            <br />
            &nbsp;Alohate
          </h2>
        </div>
        <div className="editorialMobile__container__imagesScale">
          <img
            className="editorialMobile__container__imagesScale__img"
            src={img1mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__textMax">
            El dia anterior fuimos a la feria y pudimos
            <br />
            elegir los ingredientes. Indispensable el
            <br />
            zapallo plomo, zapallo cabutia, que hace la
            <br />
            diferencia tanto en la textura y en el sabor,
            <br />
            los choclos en su punto justo, morrones
            <br />
            rojos, verdes y amarillos, verdeo, cebollas,
            <br />
            batatas, zapallo anco, zapallo común y<br />
            de ahí al mercado por la carne: Pechito de
            <br />
            cerdo, falda, patitas de cerdo, chorizos,
            <br />
            chorizo colorado, panceta ahumada y<br />
            salada y en la despensa los porotos y el
            <br />
            maíz blanco. Los dejamos remojando <br />y preparamos el patio y
            montamos <br />
            una cocina de campaña para el día
            <br /> siguiente.
            <br />
          </p>
        </div>
        <div className="editorialMobile__container__imagesDoble">
          <img
            className="editorialMobile__container__imagesDoble__imgFloatLeftBottom"
            src={img2mob}
            alt="Foto"
          />
          <img
            className="editorialMobile__container__imagesDoble__imgFloatLeftTop"
            src={img3mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__textMax">
            Remojamos las legumbres 8 a 10
            <br />
            horas, y estuvo bien, en este caso era un
            <br />
            locro grande para 40 personas y fueron
            <br />
            muchas horas de cocción, para un locro
            <br />
            chico tal vez un poco más de remojo.
            <br />
            Usamos zapallo plomo, que es el gigante
            <br />
            gris que se desploma con la cocción y<br />
            hace que sea cremoso, Zapallo cabutia o<br />
            japonés que le aporta un dulzor diferente,
            <br />
            batata, zanahoria, cebolla y morrón.
          </p>
        </div>
        <div className="editorialMobile__container__imagesDoble">
          <img
            className="editorialMobile__container__imagesDoble__imgFloatLeftTop"
            src={img5mob}
            alt="Foto"
          />
          <img
            className="editorialMobile__container__imagesDoble__imgFloatLeftBottom"
            src={img6mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__textMax">
            Hicimos los cortes de acuerdo
            <br /> al tiempo de cocción de cada hortaliza: <br />
            los zapallos en pedazos más grandes,
            <br /> las batatas medianas, y las zanahorias
            <br /> un poco más chicas.
          </p>
        </div>
        <div className="editorialMobile__container__imagesDoble">
          <img
            className="editorialMobile__container__imagesDoble__imgFloatLeftBottom"
            src={img4mob}
            alt="Foto"
          />
          <img
            className="editorialMobile__container__imagesDoble__imgFloatLeftTop"
            src={img7mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__textMax">
            Lo mismo con las carnes: hay que tener
            <br /> en cuenta el bocado en una cazuela... Y<br /> hacerla fácil
            de entrada. Si bien en la
            <br /> cocción hay que esperar el punto en que <br />
            la carne se deshace con el tenedor, los
            <br /> trozos deben ser confortables para
            <br /> disfrutar de una cazuela en mano.
          </p>
        </div>
        <div className="editorialMobile__container__images">
          <img
            className="editorialMobile__container__images__imgBig"
            src={img9mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__textMax">
            Sofreímos la panceta, tanto la salada
            <br />
            como la ahumada, las retiramos y con la
            <br />
            grasa que quedó en la olla cocinamos
            <br />
            carnes con hueso como falda y extremi- <br />
            dades del cerdo para integrar el sabor.
          </p>
        </div>
        <div className="editorialMobile__container__images">
          <img
            className="editorialMobile__container__images__imgBig"
            src={img10mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__textMax">
            Lo mismo las carnes, hay que tener en
            <br />
            cuenta el bocado en una cazuela y hace-
            <br /> rla fácil de entrada, si bien en la cocción
            <br />
            hay que esperar el punto en que la carne
            <br />
            se deshaga con el canto del tenedor, los
            <br />
            trozos deben ser confortables para una
            <br />
            cazuela en mano.
            <br />
            <span className="editorialMobile__container__titles__text__space">
              Sofreímos la panceta, tanto la salada
              <br />
              como la ahumada, las retiramos y con la
              <br />
              grasa que quedó en la olla cocinamos
              <br />
              carnes con hueso como falda y extremidades
              <br />
              del cerdo para integrar el sabor.
              <br />
            </span>
          </p>
        </div>
        <div className="editorialMobile__container__images">
          <img
            className="editorialMobile__container__images__imgBig"
            src={img11mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <p className="editorialMobile__container__titles__textMax">
            Cuchara de madera en mano, distribuimos
            <br />
            los ingredientes dentro de la olla y agre-
            <br />
            gamos las hortalizas, cebolla, pimiento,
            <br />
            zanahoria, batata primero hasta rehogar- <br />
            las. El resto, hasta que soltaron su propia
            <br />
            agua y fuimos agregando un caldo tibio
            <br />
            que hicimos con los cortes y cáscaras re- <br />
            manentes para darle más sabor.
            <br />
            <br />
            Por último las legumbres remojadas, pre-
            <br />
            viamente enjuagadas.
            <br />
            <br />
            Agregamos las pancetas y los chorizos
            <br />
            pre cocidos para que no pierdan su pre-
            <br />
            sencia y se deshagan en esa cocción tan
            <br />
            lenta.
            <br />
            <br />
            Aderezo: una salsa picante para quien <br />
            lo prefiera. Lo hacemos con verdeo,
            <br /> pimiento rojo, ají picante, aceite, sal y<br /> pimienta.
            <br />
          </p>
        </div>
        <div className="editorialMobile__container__images">
          <img
            className="editorialMobile__container__images__imgSmall"
            src={img12mob}
            alt="Foto"
          />
        </div>
        <div className="editorialMobile__container__titles">
          <h3 className="editorialMobile__container__titles__h3">
            En este caso usamos
            <br />
            Zapallos Cabutia
            <br />
            como cazuelas, previamente
            <br />
            los marcamos en un hervor
            <br />
            y los llevamos al punto
            <br />
            *dente* al fuego.
          </h3>
          <p className="editorialMobile__container__titles__text">
            Es importante tener en cuenta que fermenta
            <br />
            en un corto período después de su elaboración,
            <br />
            así que va a la mesa. O lo mejor para
            <br />
            su conservación es dividirlo en diferentes
            <br />
            recipientes para acelerar su enfriamiento y<br />
            luego conservar en heladera.
          </p>
        </div>
        <div className="editorialMobile__container__images">
          <img
            className="editorialMobile__container__images__imgSmallSecond"
            src={img13mob}
            alt="Foto"
          />
        </div>
        <img
          className="editorialMobile__container__logo"
          src={aloharBlack}
          alt="Logo"
        />
      </div>
    </div>
  );
};

export default EditorialMobile;
