import React, { useEffect, useState } from "react";
import TextCard from "../textCard";
import moment from "moment";
const InformationHousingCard = ({ housingInfo, form }) => {
  const checkin = moment(housingInfo.checkin);
  const checkout = moment(housingInfo.checkout);

  const duracion = moment.duration(checkout.diff(checkin));

  const dias = duracion.asDays();

  const [transferDiscount, setDiscountTransfer] = useState(
    (housingInfo.totalPrice * 5) / 100
  );

  const [totalPrices, setTotalPrices] = useState(0);

  useEffect(() => {
    setDiscountTransfer((housingInfo.totalPrice * form.transferDiscount) / 100);
    if (form.reservationType === "transfer") {
      setTotalPrices(
        housingInfo.totalPrice -
          (housingInfo.totalPrice * form.transferDiscount) / 100
      );
    } else {
      setTotalPrices(housingInfo.totalPrice);
    }
  }, [
    form.reservationType,
    form.paymentType,
    form.transferDiscount,
    transferDiscount,
    housingInfo.checkout,
    housingInfo.totalPrice,
    housingInfo.paymentType,
  ]);
  const truncar = (num, digitos) => {
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);
    return _num ? _num[1] : num;
  };

  return (
    <div>
      <TextCard title={"minimo de noches"} number={housingInfo.minNight} />
      <TextCard title={"Maximo de noches"} number={housingInfo.maxNight} />
      <TextCard
        title={`Precio por alohamiento`}
        number={dias ? housingInfo.BASE_PRICE / dias : 0}
      />
      <TextCard
        title={`Precio por ${dias ? dias : 0} noches`}
        number={housingInfo.BASE_PRICE}
      />
      <TextCard
        title={"Tarifa de limpieza"}
        number={housingInfo.CLEANING_PRICE}
      />
      <TextCard
        title={"Tarifa por servicio"}
        number={housingInfo.SERVICE_FEE}
      />
      {housingInfo.MONTHLY_DISCOUNT > 0 && (
        <TextCard
          title={"Descuento mensual"}
          number={housingInfo.MONTHLY_DISCOUNT}
        />
      )}
      {housingInfo.WEEKLY_DISCOUNT > 0 && (
        <TextCard
          title={"Descuento semanal"}
          number={housingInfo.WEEKLY_DISCOUNT}
        />
      )}
      {housingInfo.couponDiscount > 0 && (
        <TextCard
          title={"Descuento por cupon"}
          number={housingInfo.couponDiscount}
        />
      )}
      {housingInfo.paymentType !== "link" && (
        <TextCard
          title={"Descuento por Transferencia"}
          number={Math.round(transferDiscount)}
        />
      )}
      <TextCard title={"Precio total"} number={truncar(totalPrices, 2)} />
    </div>
  );
};

export default InformationHousingCard;
