// Librerías
import React from 'react';
import {
  GoogleMap,
  Marker
} from "@react-google-maps/api";
// Recursos
import markerIcon from '../../../assets/static/marker.svg';

const mapContainerStyle = {
  width: "100%",
  heigth: "100px"
};



const options = {
  disableDefaultUI: true,
}

const ConfirmedMap = ({ lat, lng }) => {

  const center = {
    lat: lat ? parseFloat(lat) : -37.996971,
    lng: lng ? parseFloat(lng): -57.549099,
  }

  return (
    <GoogleMap
      mapContainerStyle={ mapContainerStyle }
      zoom={ 16 }
      center={ center }
      options={ options }
    >
      <Marker
        position={{
          lat: parseFloat(lat),
          lng: parseFloat(lng)
        }}
        icon={{
          url: markerIcon,
          scaledSize: new window.google.maps.Size(120, 60),
        }}
      />
    </GoogleMap>
  );
}

export default ConfirmedMap;