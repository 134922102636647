// Librerías
import React from 'react';
// Componentes
import UserEdit from '../../../components/BackOffice/Users/Update';

const Edit = () => {
  return(
    <UserEdit />
  );
}

export default Edit;