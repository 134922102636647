import React from "react";
import close from "../../assets/static/Icons/close.svg";
import "../../assets/styles/Modals/hertzModal.sass";
export const HertzModal = ({ number, openModal, setOpenModal }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const copyCode = () => {
    navigator.clipboard.writeText(number);
    setTimeout(() => {
      window.open("https://www.hertz.com.ar/", "_blank");
    }, 500);
  };
  return (
    <div
      className={
        openModal
          ? "bg-dark-9 z-50 fixed flex w-full h-screen justify-center items-center top-0 left-0"
          : "modalNone"
      }
    >
      <div className={"hertzModal z-[99999999]"}>
        <div className="hertzModal__container">
          <div className="hertzModal__container__header">
            <h2 className="hertzModal__container__header__title">
              ¡Felicitaciones {userData?.firstName}!
            </h2>
            <p className="hertzModal__container__header__subtitle">
              Te regalamos un 25% de descuento para alquilar un vehículo. <br />
              Ingresando a www.hertz.com.ar y cargando este CÓDIGO vas a poder
              aprovechar el beneficio que te brinda Alohar.
            </p>
          </div>
          <img
            onClick={() => {
              setOpenModal(false);
            }}
            className="hertzModal__container__close"
            src={close}
            alt="cerrar"
          />
          <div className="hertzModal__container__body">
            <ul className="hertzModal__container__body__square">
              {number.map((e) => (
                <>
                  <li className="hertzModal__container__body__square__number">
                    {e[0]}
                  </li>
                </>
              ))}
            </ul>
            <ul className="hertzModal__container__body__square">
              {number.map((e) => (
                <>
                  <li className="hertzModal__container__body__square__number">
                    {e[1]}
                  </li>
                </>
              ))}
            </ul>
            <ul className="hertzModal__container__body__square">
              {number.map((e) => (
                <>
                  <li className="hertzModal__container__body__square__number">
                    {e[2]}
                  </li>
                </>
              ))}
            </ul>
            <ul className="hertzModal__container__body__square">
              {number.map((e) => (
                <>
                  <li className="hertzModal__container__body__square__number">
                    {e[3]}
                  </li>
                </>
              ))}
            </ul>
            <ul className="hertzModal__container__body__square">
              {number.map((e) => (
                <>
                  <li className="hertzModal__container__body__square__number">
                    {e[4]}
                  </li>
                </>
              ))}
            </ul>
            <ul className="hertzModal__container__body__square">
              {number.map((e) => (
                <>
                  <li className="hertzModal__container__body__square__number">
                    {e[5]}
                  </li>
                </>
              ))}
            </ul>
            <ul className="hertzModal__container__body__square">
              {number.map((e) => (
                <>
                  <li className="hertzModal__container__body__square__number">
                    {e[6]}
                  </li>
                </>
              ))}
            </ul>
          </div>
          <button
            aria-label="Copy code"
            onClick={() => copyCode()}
            className="hertzModal__container__body__button"
          >
            ¡Copiar codigo!
          </button>
        </div>
      </div>
    </div>
  );
};
