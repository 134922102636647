import React from "react";

export const TransferInfo = ({
  percentageTransfer,
  userData,
  price,
  locationIp,
}) => {
  return (
    <>
      <div className="Checkout__form__inputContainer">
        <h2 className="Checkout__form__inputContainer__title">
          {`¡${userData.firstName
            .charAt(0)
            .toUpperCase()}${userData.firstName.slice(
            1
          )}! te contamos que abonando mediante transferencia te podrás ahorrar: 
          $ ${percentageTransfer}`}
        </h2>
        <h3 className="Checkout__form__inputContainer__description">
          <strong>¡IMPORTANTE!</strong> Por favor recordá verificar
          disponibilidad con el alohador. La acreditación de la reserva puede
          demorar hasta 48hs hábiles, sin la acreditación del monto no podremos
          confirmar la reserva, tené en cuenta disponer de ese plazo antes del
          checkin.
        </h3>
        <h3 className="Checkout__form__inputContainer__description">
          ¡No te preocupes! desde el equipo de alohar daremos seguimiento a tu
          solicitud continuamente.
        </h3>

        <p className="Checkout__detail__list__item__desc">
          <strong>
            Una vez realizado esto, deberás enviar una transferencia a los
            siguientes datos:
          </strong>
        </p>
        <br />
        <br />
        <p className="Checkout__detail__list__item__desc">
          Importe a transferir:
          {` ${price}`}
        </p>
        <br />
        <p className="Checkout__detail__list__item__desc">
          CBU: 0720212620000000342340
        </p>
        <br />
        <p className="Checkout__detail__list__item__desc">
          Alias: alohar.agencia.ar
        </p>
        <br />
        <p className="Checkout__detail__list__item__desc">
          CUIT: 30-71686126-7
        </p>

        <br />
        <br />
        <p className="Checkout__detail__list__item__desc">
          <strong>
            Vas a poderaprovechar esta promoción siguiendo estos pasos:
          </strong>
          <br /> <br />- Una vez que confirmes tu reserva mediante el botón
          confirmar y transferir, estarás enviando un mensaje al equipo de
          Alohar, quienes darán seguimiento. <br />
          <br />
          -En cuanto transfieras, debés informar al Whatsapp de atención al
          cliente de ALOHAR con imagen del comprobante de transferencia.
          <br />
          <br /> - Una vez que la transferencia haya sido acreditada se dará por
          confirmada la reserva mediante el envío manual de un email y/o mensaje
          de whatsapp.
          <br />
          <br /> - En caso de haber transferido, y no conseguir disponibilidad
          en el alohamiento que elegiste, te ayudaremos a conseguir uno nuevo o
          te devolveremos el monto.
          <br />
          <br /> - La devolución puede demorar hasta 72hs hábiles en verse
          plasmada.
        </p>
      </div>
    </>
  );
};
