import React from "react";

const CheckIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-hidden="true"
      role="presentation"
      focusable="false"
      width={size ? size : "100%"}
      height={size ? size : "100%"}
    >
      <path
        stroke={color ? color : "#000"}
        fill={"none"}
        d="m4 16.5 8 8 16-16"
      ></path>
    </svg>
  );
};

export default CheckIcon;
