import React from "react";
import CardInfo from "./CardInfo";
import "../../assets/styles/LandingPage/_section_2.sass";
import icono1 from "../../assets/static/Landing/icon/home.svg";
import icono2 from "../../assets/static/Landing/icon/lock.svg";
import icono3 from "../../assets/static/Landing/icon/dollar.svg";

const cards_list = [
  {
    id: 1,
    img: icono1,
    titulo: "Alohar",
    texto:
      "<p>Alohar es una plataforma digital Argentina donde podes resolver la necesidad de tu hospedaje temporal en cualquier parte del país en pesos, fácil y seguro.</p><br/><p>Se genera una comunidad de oferta y demanda, a través de la cual los Alohadores ofrecen sus propiedades a los huéspedes.</p><br/><p> En la plataforma Alohar se realiza la operación de manera integral: elección del sitio, pago y evaluación de la experiencia.</p>",
  },
  {
    id: 2,
    img: icono2,
    titulo: "Seguro",
    texto:
      "<p>Nosotros resguardamos tu dinero hasta que estés en tu alohamiento contratado y el mismo cumpla con los servicios establecidos.</p><br/><p>Se transfiere el dinero al Alohador recién después de haber transcurrido las primeras 72 hs en su propiedad.</p><br/><p> En el caso que surja inconvenientes podes comunicarte con nuestro centro de atención, estamos para ayudarte.</p>",
  },
  {
    id: 3,
    img: icono3,
    titulo: "Pesos",
    texto:
      "<p>Somos Alohar, somos Argentinos... operamos en pesos.<p><br/><p>No operamos en dólares ni tampoco generamos tasas extras o adicionales (Ej: impuesto PAIS).</p><br/><p>Generamos opciones de pago con diferentes bancos y tarjetas para facilitar tus viajes. Pagas lo que figura en pantalla, de manera clara y detallada para que no haya diferencias entre lo contratado y lo pagado con tu tarjeta.</p>",
  },
];

const Seccion_2 = () => {
  const showCards = cards_list.map((item, index) => {
    return (
      <div className="Section2__container__cards" key={index}>
        <div className="Section2__container__cards__img">
          <img
            className="Section2__container__cards__img__icon"
            src={item.img}
            alt={`Ícono ${item.titulo}`}
          />
        </div>
        <h2 className="Section2__container__cards__title">{item.titulo}</h2>
        <p
          className="Section2__container__cards__text"
          dangerouslySetInnerHTML={{ __html: item.texto }}
        ></p>
      </div>
    );
  });

  return (
    <div className="Section2">
      <div className="Section2__container">{showCards}</div>
    </div>
  );
};

export default Seccion_2;
