import React from "react";
import "./textCard.sass";
const TextCard = ({ title, number }) => {
  return (
    <div className="TextCard">
      <p>{title}</p>
      <p>{number}</p>
    </div>
  );
};

export default TextCard;
