// Librerías
import React from 'react';
// Recursos
import '../../assets/styles/Utils/_toggle.sass';

const RoundToggle = ({ id, name, title, value, checked,customClickEvent}) => {
  return(
    <React.Fragment>
      <div className="RoundToggle">
        <p className="title">{ title }</p>
          <label className="switch" htmlFor={ id }>
            <input type="checkbox" checked={checked} value={value} id={ id } name={ name } onChange={customClickEvent} />
            <span className="slider"></span>
          </label>
      </div>
    </React.Fragment>
  );
}

export default RoundToggle;