import * as moment from "moment";

export const showDisabledDate = (
  disabledDates,
  checkin,
  checkout,
) => {
  const isDateInRange = (date, startDate, endDate) => {
    return date.isBetween(startDate, endDate, null, "[]");
  };

  const checkinDate = moment(checkin, "DD/MM/YY");
  const checkoutDate = moment(checkout, "DD/MM/YY");

  for (let i = 0; i < disabledDates.length; i++) {
    const startDate = moment(disabledDates[i].start, "YYYY-MM-DD");
    const endDate = moment(disabledDates[i].end, "YYYY-MM-DD");

    if (
      isDateInRange(checkinDate, startDate, endDate) ||
      isDateInRange(checkoutDate, startDate, endDate) ||
      checkinDate.isSame(startDate, "day") ||
      checkoutDate.isSame(endDate, "day")
    ) {
      return true;
    }
  }
  return false;
};
