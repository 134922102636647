// Liberías
import React from 'react'
// Recursos
import '../../assets/styles/LandingPage/_sued.sass';
import WhatsAppButton from '../Buttons/WhatsAppButton';
import '../../assets/styles/Buttons/btn.sass';

const WeDontWannaGetSued = () => {
    return (
      <>
        <div className="WeDontWannaGetSued">
          <div className="WeDontWannaGetSued__container">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdtsLaludr5OKqop018bHWKapy7eZuM2n4aZJFZNFpIspOEsA/viewform" target="_blank" rel="noopener noreferrer" className="WeDontWannaGetSued__container__textContainer__title">
                Podés cancelar tus reservas* realizadas de forma online dentro de un plazo máximo de 10 días desde la fecha que realizaste la reserva.
            </a>
            <div className="WppBtnFooter">
              <WhatsAppButton />
            </div>
          </div>
        </div>
      </>
    )
}

export default WeDontWannaGetSued
