import React from "react";
import styles from "./notification-message.module.sass";
const NotificationMessage = ({ title, userMenu }) => {
  return (
    <>
      {title > 0 && (
        <div
          className={
            userMenu
              ? styles.notificationMessageMenu
              : styles.notificationMessage
          }
        >
          {title}
        </div>
      )}
    </>
  );
};

export default NotificationMessage;
