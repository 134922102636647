// Librerías
import React from 'react';
// Componentes
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import Terms from '../components/LandingPage/Terms';

const TermsContainer = () => {
  return(
    <div>
        <Navbar addSearch={false} hideAlohadorButtons={true} hideSearchButtons={true}/>
        <Terms />
        <Footer />
    </div>
  );
}

export default TermsContainer;