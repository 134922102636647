import React from "react";
import "../../assets/styles/Modals/acceptReservationModal.sass";
import close from "../../assets/static/Icons/close.svg";

export const AcceptReservationModal = ({
  guest,
  isOpen,
  isClose,
  checkin,
  checkout,
  status,
  locator,
  dateLimit,
  nights,
  guests,
}) => {
  const refresh = () => {
    isClose(false);
    window.location.reload(true);
  };
  return (
    <div className={isOpen ? "reservationModal" : "modalNone"}>
      <div className="reservationModal__header">
        <h2 className="reservationModal__header__title">
          Felicitaciones acabas de aceptar la reserva de {guest}
        </h2>
        <img
          onClick={() => refresh()}
          className="reservationModal__header__close"
          src={close}
          alt="cerrar"
        />
      </div>
      <div className="reservationModal__body">
        <div className="Reservation__datacontainer__dates">
          Del {checkin} al
          {checkout}
        </div>
        <div className="Reservation__datacontainer__dates">
          {nights} Noches | {guests} PERSONAS
        </div>
        <div className="Reservation__datacontainer__title">Huespéd {guest}</div>
        <div className="Reservation__datacontainer__address">
          Estado: Confirmada
        </div>
        <div className="Reservation__datacontainer__address">
          Localizador: {locator}
        </div>
        <div className="Reservation__datacontainer__address">
          Fecha límite para confirmar: {dateLimit}
        </div>
      </div>
      <button
        className="reservationModal__button"
        onClick={() => refresh()}
      >
        Aceptar
      </button>
    </div>
  );
};
