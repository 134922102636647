const inistalState = {
    login:false,
    token:'',
    refresh_token:'',
    avatar:'',
    redirect:false,
    fcmToken:'',
    fcmTokenLoginSended:false,
    fcmTokenSended:false,
    firstName:'',
    lastName:''
}

export default (state = inistalState, action) => {
    
    switch (action.type) {
        case 'LOGIN_USER':
            
            return {
                ...state,
                login: true,
                token: action.token,
                refresh_token: action.refresh_token,
                firstName: action.firstName,
                lastName: action.lastName,
                avatar: action.avatar,
                email: action.email,
            }
        case 'LOGOUT_USER':
            return {
                ...state,
                login: false,
                token: '',
                refresh_token: '',
                firstName: '',
                lastName: '',
                avatar: '',
                email: ''
            }
        case 'AVATAR':
            return {
                ...state,
                avatar: action.avatar
            }
        case 'REDIRECT_USER':{
            return {
                ...state,
                redirect: action.redirect
            }
        }
        case 'CLEAN_REDIRECT_USER':{
            return {
                ...state,
                redirect: false
            }
        }
        case 'SET_FCM_TOKEN':{
            return {
                ...state,
                fcmToken: action.token
            }
        }
        case 'CLEAN_FCM_TOKEN':{
            return {
                ...state,
                fcmToken: ''
            }
        }
        case 'CLEAN_FCM_TOKEN_SENDED':{
            return {
                ...state,
                fcmTokenSended: false
            }
        }
        case 'SET_FCM_TOKEN_SENDED':{
            return {
                ...state,
                fcmTokenSended: true
            }
        }
        case 'SET_TOKEN':{
            return {
                ...state,
                token: action.token
            }
        }
        
        default:
            return state
    }
}
