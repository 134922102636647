import React from "react";
import "../../assets/styles/Utils/bannerTransfer.sass";

const BannerTransfer = () => {
  return (
    <div className="bannerTransfer">
      <h2 className="bannerTransfer__logo">$</h2>
      <h2 className="bannerTransfer__title">
        Aprovecha el 2.5% de descuento pagando con trasferencia bancaria
      </h2>
    </div>
  );
};

export default BannerTransfer;
