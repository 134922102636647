// Librerías
import React from 'react';
// Componentes
import LocationsList from '../../../components/BackOffice/Locations/Locations';

const Locations = () => {
  return(
    <LocationsList />
  );
}

export default Locations;