// Librerías
import React from "react";
// Components
import Footer from "../components/Footer/Footer";
import MPConfirmed from "../components/MPConfirmed/MPConfirmed";
import Navbar from "../components/Navbar/Navbar";

const MercadoPagoConfirmed = () => {
  return (
    <React.Fragment>
      <Navbar />
      <MPConfirmed />
      <Footer />
    </React.Fragment>
  );
};

export default MercadoPagoConfirmed;
