/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// Librerías
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { connect } from "react-redux";
import * as serachActions from "../../store/search/actions";
// Componentes
import Card from "./Utils/SearchCard";
import ListFilter from "./Utils/ListFilter";
import PriceFilter from "./Utils/PriceFilter";
import RoundToggle from "../Utils/RoundToggle";
// Assets
import loadingGif from "../../assets/static/loading.gif";
// Placeholder Data
import { isMobile } from "react-device-detect";

import { BASE_URL } from "../../Helpers";
import ActiveFilters from "./Utils/ActiveFilters";
import Modals from "../Modals/Modals";
import NeighborhoodFilter from "./Utils/NeighborhoodsFilter";
import LeafletMap from "./Utils/leaflet-map/LeafletMap";
import { useInView } from "react-intersection-observer";
import { useRef } from "react";
import ButtonPrimary from "../ui/button-primary";
import moment from "moment";
import "../../assets/styles/SearchPage/_search_page.sass";

const INITIAL_PAGE = 10;
const SearchPagePaginate = (props) => {
  const query = queryString.parse(window.location.search);
  const [properties, setProperties] = useState([]);
  const [propertiesId, setPropertiesId] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(INITIAL_PAGE);
  const [currentPage, setCurrentPage] = useState(
    query.pageNumber ? query.pageNumber : 1
  );
  // filtros
  const [filter, setFileter] = useState(false);
  const [filterFlexbility, setFilterFlexbility] = useState(false);
  const [petFilter, setPetFilter] = useState(false);
  const [filterHotSale, setFilterHotSale] = useState(false);

  const [reloadMap, setReloadMap] = useState(false);
  const [filterInmediatBooking, setFilterInmediatBooking] = useState(false);
  const [filterHousingType, setFilterHousingType] = useState([]);
  const [filterTypeHousingSpace, setFilterTypeHousingSpace] = useState([]);
  const [filterNeighborhoods, setFilterNeighborhoods] = useState([]);
  const [housingType, setHousingType] = useState(false);
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  // filtros
  const [cityName, setCityName] = useState(props.cityName);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [neighborhood, setNeighborhood] = useState([]);
  const [locationNeighborhood, setLocationNeighborhood] = useState(
    query.locationNeighborhood ? query.locationNeighborhood : false
  );
  const history = useHistory();
  const location = useLocation();
  const [sumPagination, setSumPagination] = useState(0);
  const [locationId, setLocationId] = useState(1);
  const [typeHousingProperty, setTypeHousingProperty] = useState(null);
  const [orderPrice, setOrderPrice] = useState("");
  const [typeSpace, setTypeSpace] = useState(false);
  const [disabledScoll, setDisabledScoll] = useState(false);
  const [housingList, setHousingList] = useState([]);
  const [allHousingList, setAllHousingList] = useState([]);
  const [loadingPaginate, setLoadingPaginate] = useState(false);
  const [viewMap, setViewMap] = useState(false);
  const [allGlampings, setAllGlampings] = useState(
    query.city === "2000" ? true : false
  );
  useEffect(() => {
    if (locationId === 2000) {
      setAllGlampings(true);
    } else {
      setAllGlampings(false);
    }
  }, [locationId]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [guests, setGuests] = useState(1);
  const getCityName = () => {
    const url = `${BASE_URL}/location/${query.city}`;
    axios
      .get(url)
      .then((response) => {
        const cn = response.data.location;
        setCityName(cn);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("cityName", cn);
        history.push({
          pathname: "/search",
          search: searchParams.toString(),
        });
      })
      .catch((e) => {});
  };

  const filterNeighborhoodsList = (data, filter) => {
    const returnValue = data
      .sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      })
      .map((neighborhood) => {
        return {
          ...neighborhood,
          checked:
            filter.findIndex(
              (fNeighborhood) => neighborhood.id === fNeighborhood.id
            ) !== -1,
        };
      });
    return returnValue;
  };

  const allNeighborhoods = useMemo(
    () => filterNeighborhoodsList(neighborhoods, filterNeighborhoods),
    [neighborhoods, filterNeighborhoods]
  );

  const allHousingSpaceFilters = [
    {
      name: "private",
      title: "Habitación Privada",
      value: "private",
      checked: filterTypeHousingSpace.indexOf("private") !== -1,
    },
    {
      name: "entire",
      title: "Alohamiento entero",
      value: "entire",
      checked: filterTypeHousingSpace.indexOf("entire") !== -1,
    },
    {
      name: "allSpace",
      title: "Todos los espacios",
      value: "allSpace",
      checked: filterTypeHousingSpace.indexOf("allSpace") !== -1,
    },
  ];

  const allHousingFilters = [
    {
      name: "apartment",
      title: "Departamento",
      value: "entire",
      checked: filterHousingType.indexOf("apartment") !== -1,
    },
    {
      name: "house",
      title: "Casa",
      value: "house",
      checked: filterHousingType.indexOf("house") !== -1,
    },
    {
      name: "cabin",
      title: "Cabaña",
      value: "cabin",
      checked: filterHousingType.indexOf("cabin") !== -1,
    },
    {
      name: "country_house",
      title: "Casa Quinta",
      value: "country_house",
      checked: filterHousingType.indexOf("country_house") !== -1,
    },
    {
      name: "anex_house",
      title: "Vivienda Anexa",
      value: "anex_house",
      checked: filterHousingType.indexOf("anex_house") !== -1,
    },
    {
      name: "apart_hotel",
      title: "Apart Hotel",
      value: "apart_hotel",
      checked: filterHousingType.indexOf("apart_hotel") !== -1,
    },
    {
      name: "boutique_hotel",
      title: "Hotel Boutique",
      value: "boutique_hotel",
      checked: filterHousingType.indexOf("boutique_hotel") !== -1,
    },
    {
      name: "glamping",
      title: "Glamping",
      value: "glamping",
      checked: filterHousingType.indexOf("glamping") !== -1,
    },
    {
      name: "allHousing",
      title: "Todos los alojamientos",
      value: "allHousing",
      checked: filterHousingType.indexOf("allHousing") !== -1,
    },
  ];

  const type_service = {
    basic: "Elementos básicos (Toallas, sábanas, jabón, papel higiénico, etc.)",
    wifi: "Wifi",
    tv: "TV",
    heating: "Calefacción",
    air_conditioning: "Aire acondicionado",
    griddle: "Plancha",
    hair_dryer: "Secador de pelo",
    electric_oven: "Horno eléctrico",
    washing_machine: "Lavarropas",
  };
  const randomPosition = (position) => {
    const max = 0.001;
    const min = 0.0001;
    var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
    const r = Math.floor(Math.random() * (max - min)) + min;
    return position + plusOrMinus * r;
  };
  // Send Search Request
  const search = (params) => {
    const apiKey = process.env.REACT_APP_ALOHAR_KEY;

    const pets = params.pets ? "&pets=pets" : "";
    // const price = params.orderPrice.length > 0 ? "&orderPrice=ASC" : "&orderPrice=DESC";

    const price =
      params.orderPrice === ""
        ? ""
        : params.orderPrice
        ? "&orderPrice=ASC"
        : "&orderPrice=DESC";

    const cancell = params.flexibilityCancel ? "&cancelation=flex" : "";

    const space =
      params.typeSpace === "private"
        ? "&typeHousingSpace=private"
        : params.typeSpace === "entire"
        ? "&typeHousingSpace=entire"
        : "";

    const TypeReservation = params.TypeReservation
      ? "&typeHousingReservation=immediate"
      : "";
    const skip = params.skip ? `&skip=0` : `&skip=${params.moreSkip}`;
    const neighborhoods =
      params.neighborhoods !== undefined && params.neighborhoods.length > 1
        ? `&Neighborhood=${params.neighborhoods}`
        : "";
    const limit = params.limit ? `&limit=${params.limit}` : `&limit=${10}`;

    const housingType = params.housingType
      ? "&typeHousingProperty=" + params.housingType
      : "";

    const allGlampings =
      params.locationId === 2000
        ? "&allGlampings=" + true
        : params.allGlampings
        ? "&allGlampings=" + params.allGlampings
        : "";
    const hotSale = params.filterHotSale
      ? `&hotSale=${params.filterHotSale}`
      : ``;
    const location =
      params.locationId > 0 ? `&locationId=${params.locationId}` : "";
    const locationNeighborhood = query.locationNeighborhood
      ? `&locationNeighborhood=${query.locationNeighborhood}`
      : "";

    const url = `${BASE_URL}/housing/searchPaginated/?apiKey=${apiKey}${
      params.guests && `&guests=${params.guests}`
    }${params.bookingStart && "&bookingStart=" + params.bookingStart}${
      params.bookingEnd && `&bookingEnd=${params.bookingEnd}`
    }${location}${locationNeighborhood}
    ${limit}${skip}${space}${cancell}${price}${pets}${TypeReservation}${housingType}${neighborhoods}${allGlampings}${hotSale}`;
    const noLimit = `skip=0&limit=${90000000000000}`;

    const urlNoLimit = `${BASE_URL}/housing/searchPaginated/?apiKey=${apiKey}${
      params.guests && `&guests=${params.guests}`
    }${params.bookingStart && "&bookingStart=" + params.bookingStart}${
      params.bookingEnd && `&bookingEnd=${params.bookingEnd}`
    }${location}${locationNeighborhood}
    ${noLimit}${space}${cancell}${price}${pets}${TypeReservation}${housingType}${neighborhoods}${allGlampings}${hotSale}`;
    getAllHousingList(urlNoLimit);

    axios
      .get(url)
      .then(function (response) {
        let newState = housingList.concat(response.data);
        // setHousingList(newState);
        if (response.data.length < 9) {
          setDisabledScoll(true);
        } else {
          setDisabledScoll(false);
        }
        if (params.skip) {
          setHousingList(response.data);
        } else {
          setHousingList(newState);
        }

        let propsId = propertiesId;
        const prop = response.data.map((p) => {
          if (propsId.includes(p.id)) {
            const oldP = properties.filter((pp) => pp.id === p.id);
            return oldP.length > 0 ? oldP[0] : p;
          } else {
            p.lat = randomPosition(parseFloat(p.lat));
            p.lng = randomPosition(parseFloat(p.lng));
            propsId.push(p.id);
            return p;
          }
        });
        setPropertiesId(propsId);
        setProperties(newState);
        setLoading(false);
        setLoadingPaginate(false);
      })

      .catch(function (error) {
        setProperties([]);
        props.setResult({
          result: [],
        });
        setLoading(false);
        setLoadingPaginate(false);
      });
  };
  const getNeighborhoods = (id) => {
    const url = `${BASE_URL}/location/neighborhoods/${id}`;
    axios.get(url).then((response) => {
      setNeighborhoods(response.data);
    });
  };

  const formatDate = (dateCalendar) => {
    const arrayDate = dateCalendar.split("/");
    return arrayDate.length === 3
      ? arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0]
      : false;
  };

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("pageNumber", pageNumber);
    history.push({
      pathname: "/search",
      search: searchParams.toString(),
    });
  };

  const handleFilter = (filterSelected) => {
    filter === filterSelected ? setFileter(false) : setFileter(filterSelected);
  };

  const listProperties = housingList.map((item) => {
    const cover = item.housingMedia.filter((i) => i.cover);
    const img =
      cover.length > 0
        ? BASE_URL + "/" + cover[0].media.replace("public/", "")
        : item.housingMedia.length > 0
        ? BASE_URL + "/" + item.housingMedia[0].media.replace("public/", "")
        : "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";
    //const img = item.housingMedia[0] ? BASE_URL +"/"+ item.housingMedia[0].media.replace("public/","") : '#'
    // const housingService = item.housingService.map(
    //   (s) => type_service[s.housingService] + " "
    // );
    return (
      <Card
        bookingStart={query.checkin}
        bookingEnd={query.checkout}
        key={item.id}
        id={item.id}
        housingMedia={item.housingMedia}
        img={img}
        amount={item.basePrice}
        title={item.title}
        guests={item.guests}
        bedrooms={item.rooms}
        beds={item.double_beds + item.simple_beds}
        bathrooms={item.bathrooms}
        type={item.type}

        // services={housingService}
        rate={item.rate}
        user={item.user}
        description={item.description}
        neighborhood={item.neighborhood}
        location={item.location}
        previaje={item.previaje}
        setLoading={setLoading}
        hotSale={item.housingPromotions.length > 0 && item.housingPromotions[0]}
        city={item.locationId}
      />
    );
  });
  const handleHousingType = (event) => {
    setAllGlampings(false);
    setLoading(true);
    setProperties([]);
    setHousingList([]);
    const name = event.target.name;
    if (!filterHousingType.includes(name)) {
      setFilterHousingType(name);
    } else {
      setFilterHousingType([]);
    }
    setHousingType(name);
    setSumPagination(0);
    setTypeHousingProperty(name);
    let params;
    params = {
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking,
      housingType: name === "allHousing" ? false : name,
      locationId: props.city,
      skip: true,
      flexibilityCancel: filterFlexbility,
      orderPrice: orderPrice,
      pets: petFilter,
      filterHotSale: filterHotSale,
      neighborhoods: neighborhood,
    };
    search(params);
  };

  const handleNeighborhoodFilter = (event) => {
    setLoading(true);
    const name = event.target.name;
    const id = parseInt(event.target.id);
    const status = event.target.checked;
    if (status) {
      if (!filterNeighborhoods.find((n) => n.name === name))
        setFilterNeighborhoods((filterNeighborhoods) => [
          ...filterNeighborhoods,
          { name, id },
        ]);
    } else {
      setFilterNeighborhoods(filterNeighborhoods.filter((p) => p.id !== id));
    }
    setNeighborhood(name);
    let params = {
      filterHotSale: filterHotSale,
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking,
      locationId: props.city,
      limit: 10,
      housingType: housingType === "allHousing" ? false : housingType,
      skip: true,
      flexibilityCancel: filterFlexbility,
      pets: petFilter,
      orderPrice: orderPrice,
      neighborhoods: name,
    };
    if (name) {
      search(params);
    }
  };

  const handleHousingTypeForFilter = (name, status) => {
    if (status) {
      if (!filterHousingType.includes(name)) {
        setFilterHousingType(name);
      }
    } else {
      setFilterHousingType([]);
    }
    let params;
    params = {
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      locationId: props.city,
      typeSpace: typeSpace,
      filterHotSale: filterHotSale,
      limit: 10,
      pets: petFilter,
      neighborhoods: neighborhood,
    };
    setLoading(true);
    search(params);
  };

  const handleHousingSpaceType = (event) => {
    setAllGlampings(false);
    setLoading(true);
    setProperties([]);
    setHousingList([]);
    const name = event.target.name;
    setTypeSpace(name);
    setSumPagination(0);
    if (!filterTypeHousingSpace.includes(name)) {
      setFilterTypeHousingSpace(name);
    } else {
      setFilterTypeHousingSpace([]);
    }
    let params = {
      filterHotSale: filterHotSale,
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: name,
      TypeReservation: filterInmediatBooking,
      locationId: props.city,
      limit: 10,
      housingType: housingType === "allHousing" ? false : housingType,
      skip: true,
      flexibilityCancel: filterFlexbility,
      pets: petFilter,
      orderPrice: orderPrice,
      neighborhoods: neighborhood,
    };
    if (name) {
      search(params);
    }
  };

  const handleHousingSpaceTypeForFilter = (name, status) => {
    setLoading(true);
    if (status) {
      if (!filterTypeHousingSpace.includes(name))
        setFilterTypeHousingSpace(name);
    } else {
      setFilterTypeHousingSpace([]);
    }
    let params;
    params = {
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      filterHotSale: filterHotSale,
      TypeReservation: filterInmediatBooking,
      housingType: housingType === "allHousing" ? false : housingType,
      locationId: props.city,
      limit: 10,
      typeSpace: typeHousingProperty,
      pets: petFilter,
      flexibilityCancel: filterFlexbility ? true : false,
      neighborhoods: neighborhood,
    };
    search(params);
  };

  const handleFlexbility = () => {
    setLoading(true);
    setProperties([]);
    setHousingList([]);
    setFilterFlexbility(!filterFlexbility);
    setSumPagination(0);
    let params = {
      filterHotSale: filterHotSale,
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking,
      housingType: housingType === "allHousing" ? false : housingType,
      locationId: props.city,
      limit: 10,
      skip: true,
      flexibilityCancel: filterFlexbility ? false : true,
      orderPrice: orderPrice,
      pets: petFilter,
      neighborhoods: neighborhood,
    };
    search(params);
  };

  const handleInmediatBooking = () => {
    setLoading(true);
    setFilterInmediatBooking(!filterInmediatBooking);
    setSumPagination(0);
    setHousingList([]);
    let params = {
      filterHotSale: filterHotSale,
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking ? false : true,
      locationId: props.city,
      housingType: housingType === "allHousing" ? false : housingType,
      flexibilityCancel: filterFlexbility,
      skip: true,
      pets: petFilter,
      neighborhoods: neighborhood,
      orderPrice: orderPrice,
    };

    search(params);
  };
  const handleHotSale = () => {
    setLoading(true);
    setFilterHotSale(!filterHotSale);
    setSumPagination(0);
    setHousingList([]);
    let params = {
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking,
      filterHotSale: filterHotSale ? false : true,
      locationId: props.city,
      housingType: housingType === "allHousing" ? false : housingType,
      flexibilityCancel: filterFlexbility,
      skip: true,
      pets: petFilter,
      neighborhoods: neighborhood,
      orderPrice: orderPrice,
    };

    search(params);
  };

  const handlePetFilter = () => {
    setLoading(true);
    setProperties([]);
    setHousingList([]);
    setPetFilter(!petFilter);
    setSumPagination(0);
    let params = {
      filterHotSale: filterHotSale,
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking,
      locationId: props.city,
      housingType: housingType === "allHousing" ? false : housingType,
      limit: 10,
      skip: true,
      flexibilityCancel: filterFlexbility,
      pets: petFilter ? false : true,
      orderPrice: orderPrice,
      neighborhoods: neighborhood,
    };

    search(params);
  };
  const handleAllGlampings = () => {
    setLoading(true);
    setProperties([]);
    setHousingList([]);
    setAllGlampings(!allGlampings);
    setSumPagination(0);
    let params = {
      filterHotSale: filterHotSale,
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking,
      locationId: props.city,
      housingType: housingType === "allHousing" ? false : housingType,
      limit: 10,
      skip: true,
      flexibilityCancel: filterFlexbility,
      pets: petFilter,
      allGlampings: allGlampings ? false : true,
      orderPrice: orderPrice,
      neighborhoods: neighborhood,
    };

    search(params);
  };
  const reset = () => {
    setLoading(true);
    setFilterTypeHousingSpace([]);
    setFilterHousingType([]);
    setFilterFlexbility(false);
    setFilterNeighborhoods([]);
    setPetFilter(false);
    setTypeSpace(false);
    setFilterInmediatBooking(false);
    setTypeHousingProperty(false);
    setHousingType(false);
    setFilterHotSale(false);
    setProperties([]);
    setHousingList([]);
    setPetFilter(false);
    const bookingStart = formatDate(props.checkin);
    const bookingEnd = formatDate(props.checkout);
    const params = {
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: bookingStart,
      bookingEnd: bookingEnd,
      filterHotSale: filterHotSale,
      locationId: props.city,
      limit: 10,
      skip: true,
      typeSpace: false,
      pets: false,
      neighborhoods: false,
      orderPrice: "",
    };

    search(params);
  };

  const handleSetFilterPrice = (filter) => {
    setLoading(true);
    setOrderPrice(!orderPrice);
    setProperties([]);
    setHousingList([]);
    handleFilter("price");
    const params = {
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      filterHotSale: filterHotSale,
      TypeReservation: filterInmediatBooking,
      locationId: props.city,
      housingType: housingType === "allHousing" ? false : housingType,
      flexibilityCancel: filterFlexbility,
      pets: petFilter,
      skip: true,
      limit: sumPagination,
      orderPrice: orderPrice ? false : true,
      neighborhoods: neighborhood,
    };

    search(params);
  };

  useEffect(() => {
    getNeighborhoods(props.city);
    const startDay = moment(Date.now()).utc(true).format("YY-MM-DD");
    const endDay = moment().add(5, "days").format("YY-MM-DD");
    setCheckin(query.checkin ? formatDate(query.checkin) : startDay);
    setCheckout(query.checkout ? formatDate(query.checkout) : endDay);
    setCityName(props.cityName);
    setLocationId(props.city);
    const params = {
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      locationId: props.city,
      limit: 10,
      skip: true,
      orderPrice: orderPrice,
      filterHotSale: query.city === "501" ? true : false,
    };

    if (props.city) {
      setReloadMap(true);
      setLoading(true);
      setHousingList([]);
      // getCityName()
      setTimeout(() => {
        setReloadMap(false);
      }, 2000);
    }

    if (query.city === "501") {
      setFilterHotSale(true);
    }
    if (guests && checkin && checkout) {
      search(params);
      setSumPagination(0);
    }
  }, [
    checkin,
    checkout,
    props.triggerSearch,
    props.city,
    props.neighborhood,
    props.cityName,
    cityName,
    query.locationNeighborhood,
  ]);
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!loading) {
        setIsVisible(entry.isIntersecting);
      }
    });
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [elementRef]);

  const loadMoreData = () => {
    setSumPagination(sumPagination + 10);
    let params = {
      filterHotSale: filterHotSale,
      guests: parseInt(props.adults) + parseInt(props.childs),
      bookingStart: checkin,
      bookingEnd: checkout,
      typeSpace: typeSpace,
      TypeReservation: filterInmediatBooking,
      housingType: housingType === "allHousing" ? false : housingType,
      locationId: props.city,
      flexibilityCancel: filterFlexbility,
      skip: false,
      pets: petFilter,
      moreSkip: sumPagination + 10,
      neighborhoods: neighborhood,
      orderPrice: orderPrice,
    };
    search(params);
  };

  useEffect(() => {
    if (inView) {
      if (!disabledScoll) {
        setLoadingPaginate(true);
        loadMoreData(sumPagination);
      }
    }
  }, [inView]);
  const getAllHousingList = (url) => {
    axios
      .get(url)
      .then((response) => {
        setAllHousingList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <div className="Search">
        <div className={"Search__buttonViewMap"}>
          <ButtonPrimary
            width={viewMap ? "200px" : "100px"}
            onClick={() => setViewMap(!viewMap)}
            title={viewMap ? "Ver alojamientos" : "Mapa"}
          />
        </div>
        <nav className="Search__navigation">
          <div className="Search__navigation__header">
            <h2 className="Search__navigation__header__title">
              {cityName === "undefined" ? "" : cityName}
            </h2>
            <Modals
              allGlampings={allGlampings}
              handleAllGlampings={handleAllGlampings}
              housingType={allHousingFilters}
              allOptions={allHousingFilters}
              clickEventCustom={handleHousingType}
              clickEventCustomTypeSpace={handleHousingSpaceType}
              clickEventPrice={handleSetFilterPrice}
              housingTypeHandle={handleHousingTypeForFilter}
              spaceType={allHousingSpaceFilters}
              spaceTypeHandle={handleHousingSpaceTypeForFilter}
              flexibility={filterFlexbility}
              flexibilityHandle={handleFlexbility}
              filterInmediatBooking={filterInmediatBooking}
              handleInmediatBooking={handleInmediatBooking}
              petFilter={petFilter}
              handlePetFilter={handlePetFilter}
              neighborhoods={allNeighborhoods}
              handleHotSale={handleHotSale}
              filterHotSale={filterHotSale}
              neighborhoodsHandle={handleNeighborhoodFilter}
              reset={reset}
            />
          </div>
          <div className="filtersDesktop">
            <div className="Search__navigation__filters">
              <ListFilter
                title="Tipo de alojamiento"
                allOptions={allHousingFilters}
                clickEventCustom={handleHousingType}
              />
              <ListFilter
                title="Tipo de espacio"
                allOptions={allHousingSpaceFilters}
                clickEventCustom={handleHousingSpaceType}
              />
              {locationId === 428 && (
                <>
                  <NeighborhoodFilter
                    title="Barrios"
                    allOptions={allNeighborhoods}
                    clickEventCustom={handleNeighborhoodFilter}
                  />
                </>
              )}
              <PriceFilter clickEventPrice={handleSetFilterPrice} />
              <RoundToggle
                id={"inmeediatBooking"}
                name={"inmeediatBooking"}
                value={"inmeediatBooking"}
                checked={filterInmediatBooking}
                title={"Reserva inmediata"}
                customClickEvent={handleInmediatBooking}
              />
              <RoundToggle
                id={"hotSale"}
                name={"hotSale"}
                value={"hotSale"}
                checked={filterHotSale}
                title={"Promociones"}
                customClickEvent={handleHotSale}
              />
              <RoundToggle
                id={"flexbility"}
                name={"flexbility"}
                value={"flexbility"}
                checked={filterFlexbility}
                title={"Flexibilidad para cancelar"}
                customClickEvent={handleFlexbility}
              />
              <RoundToggle
                id={"pets"}
                name={"pets"}
                value={"pets"}
                checked={petFilter}
                title={"Mascotas"}
                customClickEvent={handlePetFilter}
              />
              <RoundToggle
                id={"allGlampings"}
                name={"allGlampings"}
                value={"allGlampings"}
                checked={allGlampings}
                title={"Todos los Glampings del país"}
                customClickEvent={handleAllGlampings}
              />
            </div>
            <ActiveFilters
              allGlampings={allGlampings}
              handleAllGlampings={handleAllGlampings}
              housingType={allHousingFilters}
              housingTypeHandle={handleHousingTypeForFilter}
              spaceType={allHousingSpaceFilters}
              spaceTypeHandle={handleHousingSpaceTypeForFilter}
              flexibility={filterFlexbility}
              flexibilityHandle={handleFlexbility}
              inmediateBooking={filterInmediatBooking}
              inmediateBookingHandle={handleInmediatBooking}
              pets={petFilter}
              handlePetFilter={handlePetFilter}
              neighborhoods={allNeighborhoods}
              neighborhoodsHandle={handleNeighborhoodFilter}
              reset={reset}
            />
          </div>
        </nav>
        <div className="Search__container">
          <div className="Search__container__blankspace"></div>
          {viewMap && (
            <div className={"Search__mapMobile"}>
              {reloadMap ? "" : <LeafletMap properties={allHousingList} />}
            </div>
          )}

          <div
            className={viewMap ? "Search__mapNone" : "Search__container__list"}
          >
            {
              <>
                {loading ? (
                  <img
                    className="Search__container__list__loading"
                    alt="Cargando..."
                    src={loadingGif}
                  />
                ) : (
                  <>
                    {listProperties.length > 0 ? (
                      listProperties
                    ) : (
                      <> No tenemos resultados en tu búsqueda</>
                    )}
                    {loadingPaginate ? (
                      <img
                        className="Search__container__list__loading"
                        alt="Cargando..."
                        src={loadingGif}
                      />
                    ) : (
                      <>
                        <div ref={ref} style={{ marginTop: "-250px" }}></div>
                      </>
                    )}
                  </>
                )}
              </>
            }
          </div>
          {!isMobile && (
            <div className={"Search__map"}>
              {reloadMap ? "" : <LeafletMap properties={allHousingList} />}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.searchReducer;
};

export default connect(mapStateToProps, serachActions)(SearchPagePaginate);
