// Librerías
import React from 'react';
// Componentes
import Navbar from '../components/Navbar/Navbar';
import UserEdit from '../components/Users/UserEditComponent';
import Footer from '../components/Footer/Footer';

const UserProfilePage = () => {
    return(
        <React.Fragment>
            <Navbar isLogged />
            <UserEdit />
            <Footer/>
        </React.Fragment>
    );
}

export default UserProfilePage;
