// Librería
import React from 'react'
// Componentes
import PublicProfile from '../components/Profile/PublicProfile/PublicProfile';

const PublicProfilePage = () => {
  return (
    <PublicProfile />
  )
}

export default PublicProfilePage;
