import React, { useState } from "react";
import hertzLogo from "../../assets/static/Promos/hertzLogo.png";
import hertzCars from "../../assets/static/Promos/hertzCars.png";
import "../../assets/styles/Utils/bannerHertz.sass";
import { HertzModal } from "../Modals/hertzModal";

import { useSelector, shallowEqual } from "react-redux";
import { openLoginModal } from "../../store/modal/actions";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../Helpers";
import AxiosAuth from "../../services/requestApi";
import axios from "axios";

export const BannerHertz = () => {
  const [openModal, setOpenModal] = useState(false);
  const [number, setNumber] = useState([]);
  const userState = useSelector((state) => state.userReducer, shallowEqual);
  const dispatch = useDispatch();
  const code = () => {
    !userState.login ? dispatch(openLoginModal()) : codeDiscount();
  };
  
  const codeDiscount = async () => {
    const url = BASE_URL + "/hertz"
    try{
      const response = await AxiosAuth.get(url)
      setNumber([response.data])
    }catch (error) {
        console.log(error);
      }
    setOpenModal(true);
  };
  return (
    <div className="hertz">
      <HertzModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        number={number}
      />
      <img alt="logo hertz" className="hertz__logo" src={hertzLogo} />
      <div className="hertz__content">
        <p className="hertz__content__title">
          25% de descuento en el alquiler de tu vehículo.
          <button
            onClick={() => code()}
            className="hertz__content__link"
          >
            ¡Hacé click y obtene tu código!
          </button>
        </p>
      </div>
      <img className="hertz__icon" alt="icon" src={hertzCars} />
    </div>
  );
};
