import React from "react";
import CrossIcon from "../../../assets/components-icons/CrossIcon";
import HandleLikeIcon from "../../../assets/components-icons/HandleLike";
import HouseIcon from "../../../assets/components-icons/HouseIcon";
// import HouseAlertIcon from "../../../assets/components-icons/HouseAlertIcon";
import "./modal-global.sass";

const ModalGlobal = ({ children, closeModal, setCloseModal, error }) => {
  const handleModalContainerClick = (e) => e.stopPropagation();

  return (
    <div
      className={closeModal ? "ModalGlobalPrimary" : "displayNone"}
      onClick={() => setCloseModal(false)}
    >
      <div
        className="ModalGlobalPrimary__container"
        onClick={handleModalContainerClick}
      >
        <div
          className="ModalGlobalPrimary__container__close"
          onClick={setCloseModal}
        >
         
          <CrossIcon />
        </div>

        {children}
      </div>
    </div>
  );
};

export default ModalGlobal;
