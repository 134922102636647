// Librerías
import React  from 'react';
import { connect } from 'react-redux';
import { restart } from '../store/aloharForm/actions';
// Componentes
import AloharForm from '../components/AloharForm/AloharForm';
import Navbar from '../components/Navbar/Navbar';
// Recursos
import '../assets/styles/AloharForm/__aloharform.sass'


const Alohar = () => {
  return(
    <div className="AloharForm">
      <Navbar  addSearch={false} hideAlohadorButtons={true} hideSearchButtons={true}/>
      <AloharForm />
    </div>
  );
}

export default connect(null,{restart})(Alohar);