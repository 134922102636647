// Librerías
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
// Componentes
import SelectCity from "./SelectCity.js";
import SelectDate from "./SelectDate.js";
import SelectPersons from "./SelectPersons.js";
// Recursos
import "../../assets/styles/MainSearch/__maininputsearch.sass";
import search from "../../assets/static/Landing/icon/search.svg";
import * as serachActions from "../../store/search/actions";
import WhatsAppButton from "../Buttons/WhatsAppButton.js";
import "../../../src/assets/styles/Buttons/btn.sass";

const MainInputSearch = (props) => {
  const { addSearch } = props;
  const { login } = props.userReducer;

  const navbar = addSearch ? "navbar" : "";
  const [city, setCity] = useState(false);
  const [adults, setAdults] = useState(1);
  const [childs, setChilds] = useState(0);
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const history = useHistory();
  const [urlInit, setUrlInit] = useState(false);
  const [errorSearch, setErrorSearch] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [cityName, setCityName] = useState();
  const query = queryString.parse(window.location.search);
  useEffect(() => {
    // const {city,adults,childs,checkin, checkout, cityName} = props

    if (!urlInit) {
      if (query["city"]) {
        setCity(parseInt(query.city));
        props.setCity({ city: parseInt(query.city), cityName: query.cityName });
        props.setAdult(
          !isNaN(parseInt(query.adults)) ? parseInt(query.adults) : 1
        );
        props.setChild(
          !isNaN(parseInt(query.childs)) ? parseInt(query.childs) : 0
        );
        props.setCheckin(query.checkin ? query.checkin : "");
        props.setCheckout(query.checkout ? query.checkout : "");
        setUrlInit(true);
      }
    } else {
      setCity(props.searchReducer.city);
      setAdults(props.searchReducer.adults);
      setChilds(props.searchReducer.childs);
      setCheckin(props.searchReducer.checkin);
      setCheckout(props.searchReducer.checkout);
    }
  }, [props]);
  const goSearch = () => {
    const { city, checkin, checkout, childs, adults } = props.searchReducer;
    const cityNameSearch = cityName.replace(/\s+/g, "-");
    if (city && checkin && checkout && city && cityName) {
      const url = `/${cityNameSearch}/search?city=${city}&checkin=${checkin}&checkout=${checkout}&childs=${childs}&adults=${adults}&cityName=${cityName}`;
      history.push(url);
      props.setTriggerSearch();
    }

    if (city && cityName && (checkin === "" || checkout === "")) {
      const url = `/${cityNameSearch}/search?city=${city}&childs=${childs}&adults=${adults}&cityName=${cityName}`;
      history.push(url);
      props.setTriggerSearch();
    }
  };
  // `current` apunta al elemento de entrada de texto montado

  const handleSearch = (e) => {
    // e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    goSearch();
  };
  const preSearch = () => {
    if (startDate && endDate) {
      handleSearch();
      setErrorSearch(false);
    } else {
      setErrorSearch(true);
    }
  };

  useEffect(() => {
    if (
      props.searchReducer.checkin &&
      props.searchReducer.checkout &&
      props.searchReducer.cityName
    ) {
      setCheckin(props.searchReducer.checkin);
      setCheckout(props.searchReducer.checkout);
      setCityName(props.searchReducer.cityName);
    } else {
      setCheckin("");
      setCheckout("");
      setCityName("");
    }
  }, [
    props.searchReducer.checkin,
    props.searchReducer.checkout,
    props.searchReducer.cityName,
  ]);
  useEffect(() => {
    if (checkin && checkout) {
      setCheckin(checkin);
      setCheckout(checkout);
    } else {
      setCheckin("");
      setCheckout("");
    }
  }, [checkin, checkout]);
  return (
    <React.Fragment>
      {login ? (
        <div className="whatsappIconBelow">
          <WhatsAppButton />
        </div>
      ) : (
        <div className="whatsappIconTop wbcomWhatsappImageTop">
          <WhatsAppButton />
        </div>
      )}
      <div className={`MainInputSearch ${navbar}`}>
        <div className="MainInputSearch__cities">
          <div className="MainInputSearch__container">
            <label className="MainInputSearch__container__label">
              ¿Dónde vamos?
            </label>
            <SelectCity
              selectedCity={city}
              placeholder="Seleccioná tu nuevo destino"
            />
          </div>
        </div>
        <div className="MainInputSearch__people">
          <div className="MainInputSearch__container">
            <label className="MainInputSearch__container__label">
              ¿Cuántos somos?
            </label>
            <SelectPersons adults={adults} childs={childs} />
          </div>
        </div>
        <div className="MainInputSearch__dates">
          <div className="MainInputSearch__container">
            <label className="MainInputSearch__container__label">
              ¿Cuándo?
            </label>
            {errorSearch && (
              <span className="MainInputSearch__container__alert"></span>
            )}
            <SelectDate
              checkin={checkin}
              checkout={checkout}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setErrorSearch={setErrorSearch}
            />
          </div>
        </div>
        <button
          disabled={errorSearch ? true : false}
          className="MobileMainInputSearch"
          onClick={() => preSearch()}
        >
          <img src={search} alt="Buscar" className="" />
          <p>Buscar</p>
        </button>
        <div className="MainInputSearch__btncontainer">
          <button
            disabled={errorSearch ? true : false}
            className="MainInputSearch__searchButton"
            onClick={() => preSearch()}
          >
            {/* `/search?city=${city.id}&checkin=${checkin}&checkout=${checkout}&childs=${childs}&adults=${adults}` */}
            <img
              src={search}
              alt="Buscar"
              className="MainInputSearch__searchButton__img"
            />
          </button>
        </div>
      </div>
      {/* <div className="MobileMainInputSearch" onClick={handleSearch}>
        <button className="MobileMainInputSearch__button">Buscar</button>
      </div> */}
      {login ? (
        ""
      ) : (
        <div className="MobileMainInputSearchButtonsContainer">
          {/* <Link to="/quiero-ser-alohador" className="MobileMainInputSearchButtonsContainer__btn">Alohadores</Link> */}
          {/* <Link to="/login" className="MobileMainInputSearchButtonsContainer__btn">Ingresá</Link> */}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (reducers) => {
  return {
    searchReducer: reducers.searchReducer,
    userReducer: reducers.userReducer,
  };
};

export default connect(mapStateToProps, serachActions)(MainInputSearch);
