import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import Navbar from "../../../Navbar/Navbar";
import NavbarBacks from "../../../../components/BackOffice/Navbar/Navbar";
import UserAvatar from "../../../ui/userAvatar";
import SideMenu from "../../../Utils/SideMenu";
import BoxItem from "./../../components/box-item";
import ContentTitle from "./../../components/content-title";
import SendMessage from "./../../components/send-message";
import CrossIcon from "../../../../assets/components-icons/CrossIcon";
import SectionDetail from "../../components/section-detail";
import "./../../messengers-page.sass";
import ArrowLeft from "../../../../assets/components-icons/ArrowLeft";
import { loadUserData } from "../../../../Helpers";

const MessaggesDesktop = ({ users, userId }) => {
  const userdata = loadUserData();

  const router = useHistory();
  const [openDetails, setOpenDetails] = useState(false);
  const [chatMobile, setChatMobile] = useState(false);
  const [housingMobile, setHousingMobile] = useState(false);
  const [usersMobile, setUsersMobile] = useState(true);
  const [loading, setLoading] = useState(true);
  const [refMessages, setRefMessages] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [userHousing, setUserHousing] = useState([]);
  const [activeBox, setActiveBox] = useState(null);
  const getUser = (userInfo) => {
    setUserInfo(userInfo);
  };
  const viewDetails = () => {
    if (userHousing.id) {
      setOpenDetails(true);
      setChatMobile(false);
      setHousingMobile(true);
    }
  };
  const viewChat = () => {
    setOpenDetails(false);
    setChatMobile(true);
    setHousingMobile(false);
  };
  const buttonBack = () => {
    if (chatMobile) {
      setChatMobile(false);
      setUsersMobile(true);
    }
    if (housingMobile) {
      setChatMobile(true);
      setHousingMobile(false);
    }
  };
  const refClickUser = useRef(null);

  useEffect(() => {
    if (refClickUser.current) {
      refClickUser.current.focus();
    }
  }, [userHousing]);

  useEffect(() => {
    setOpenDetails(false);
  }, [userInfo]);
  console.log("userInfo");
  console.log(userdata);

  return (
    <div className="layoutMessages">
      {router.location.pathname.includes("back-office") ? (
        <NavbarBacks />
      ) : (
        <Navbar />
      )}

      <>
        <div
          className={`layoutMessages__containerSide ${
            router.location.pathname.includes("back-office") && "grid-cols-1"
          }`}
        >
          {!router.location.pathname.includes("back-office") && (
            <div className="layoutMessages__containerSide__sideBar">
              <SideMenu />
            </div>
          )}
          <div className="layoutMessages__container">
            <div
              className={`layoutMessages__container__section ${
                router.location.pathname.includes("back-office") && "max-w-full"
              }`}
            >
              <div className={"layoutMessages__container__section__header"}>
                <ContentTitle title={"Mensajes"} />
              </div>
              <div className={"layoutMessages__container__section__chats"}>
                {users.map((e) => (
                  <BoxItem
                    activeBox={activeBox}
                    setActiveBox={setActiveBox}
                    refMessages={refMessages}
                    getUser={getUser}
                    messageInfo={e.lastMessage}
                    refClickUser={refClickUser}
                    setChatMobile={setChatMobile}
                    setUsersMobile={setUsersMobile}
                  />
                ))}
              </div>
            </div>
            {userInfo.length === 0 ? (
              <h2 className="layoutMessages__container__empty">
                Elija una conversación
              </h2>
            ) : (
              <>
                <div
                  className={`layoutMessages__container__section ${
                    router.location.pathname.includes("back-office") &&
                    "max-w-full"
                  }`}
                >
                  <div
                    onClick={() => viewDetails()}
                    className={"layoutMessages__container__section__header"}
                  >
                    {userInfo && (
                      <UserAvatar
                        userData={
                          userInfo.from.id !== userdata.id
                            ? userInfo.from
                            : userInfo.to
                        }
                        size={40}
                      />
                    )}
                    <ContentTitle
                      title={
                        userInfo.from.id !== userdata.id
                          ? userInfo.from.firstName
                          : userInfo.to.firstName
                      }
                    />
                    <p className="flex items-center gap-1 text-xs ">
                      Ver Alojamiento{" "}
                      <ArrowLeft size={16} className={"rotate-180"} />
                    </p>
                  </div>
                  <SendMessage
                    userId={userId}
                    user={
                      userInfo.from.id !== userdata.id
                        ? userInfo.from
                        : userInfo.to
                    }
                    setUserHousing={setUserHousing}
                    refClickUser={refClickUser}
                    setRefMessages={setRefMessages}
                  />
                </div>
              </>
            )}
            <div
              className={
                openDetails
                  ? `layoutMessages__container__sectionDetail `
                  : `layoutMessages__container__section `
              }
            >
              <>
                <div className={"layoutMessages__container__section__header"}>
                  <div
                    className="layoutMessages__container__section__header__icon"
                    onClick={() => viewChat()}
                  >
                    <CrossIcon />
                  </div>
                  <ContentTitle title={"Detalles"} />
                </div>
                <SectionDetail userHousing={userHousing} userInfo={userInfo} />
              </>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default MessaggesDesktop;
