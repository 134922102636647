const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(token) {
    localStorage.setItem("token", token);
  }
  function _setRefreshToken(refresh_token) {
    localStorage.setItem("refresh_token", refresh_token);
  }
  function _getLanguage() {
    return JSON.parse(localStorage.getItem("language"));
  }
  function _setLanguage(languaje) {
    localStorage.setItem("language", JSON.stringify(languaje));
  }
  function _getAccessToken() {
    return localStorage.getItem("token");
  }
  function _getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  function _clearToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    setRefreshToken: _setRefreshToken,
    clearToken: _clearToken,
    getlanguage: _getLanguage,
    setlanguage: _setLanguage,
  };
})();
export default LocalStorageService;
export const myUser = JSON.parse(window.localStorage.getItem("userData"));
