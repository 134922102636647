import React from "react";
export const Button = ({ onClick, caracter, value, addSearch, title }) => {
  return (
    <button
      aria-label="rest adult"
      disabled={
        caracter === "-" && title === "Adultos"
          ? value <= 0
          : caracter === "-" && value <= 0
      }
      style={{
        fontSize: `${caracter === "-" ? "32px" : "24px"}`,
        lineHeight: "1",
      }}
      className={
        addSearch
          ? `  sm:text-[1em] RobotoBold`
          : `  sm:text-[2vw]  RobotoBold `
      }
      onClick={onClick}
    >
      {caracter}
    </button>
  );
};
const AdultsAndChild = ({ title, setValue, value, addSearch }) => {
  //   <label
  //   onClick={() => props.setCity(false)}
  //   className={`hidden p-0 font-extrabold MainInputSearch__container__label sm:grid ${
  //     addSearch && "sm:text-[.8rem]"
  //   }`}
  // >
  return (
    <div
      className={`flex    flex-row-reverse items-center  justify-center sm:justify-start
         sm:h-full gap-3  flex-end sm:flex-start sm:flex-col ${
           // sm:gap-[3px]
           // sm:justify-between
           addSearch ? "sm:gap:0 h-[32px]" : ""
         }`}
    >
      <strong
        className={` p-0 font-extrabold   text-[5vw]MainInputSearch__container__label sm:grid ${
          addSearch && "sm:text-[.8rem] flex flex-col"
        }
  `}
      >
        {title}
      </strong>
      <span
        className={`flex items-center h-[23vw]  gap-2 ${
          addSearch ? " h-[24px] mt-[-10px]" : ""
        }`}
      >
        <Button
          title={title}
          onClick={() => setValue(value - 1)}
          caracter={"-"}
          addSearch={addSearch}
          value={value}
        />

        <strong
          className={
            addSearch
              ? " text-[6vw] sm:text-[1rem] RobotoBold"
              : `
      
     text-[6vw] sm:text-[2vw]  RobotoBold `
          }
        >
          {value}
        </strong>
        <Button
          title={title}
          onClick={() => setValue(value + 1)}
          caracter={"+"}
          value={value}
          addSearch={addSearch}
        />
      </span>
    </div>
  );
};

export default AdultsAndChild;
