import React, { useState } from "react";
import useFetchAndLoad from "../../../hooks/useFetchAndLoad";
import AxiosAuth from "../../../services/requestApi";
import { loadAbort } from "../../../utilities/load-abort-axios";
import * as moment from "moment";

const MPButton = ({ state }) => {
  const { id, persons, checkin, checkout, totalPrice, couponId } = state;
  const [mpUrl, setMpUrl] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Hubo un error con Mercado Pago, intente más tarde..."
  );
  const body = {
    checkin: moment(checkin, "D/M/YY").format("YYYY-MM-DD"),
    checkout: moment(checkout, "D/M/YY").format("YYYY-MM-DD"),
    guests: parseInt(persons),
    totalPrice: parseInt(totalPrice),
    coupon: parseInt(couponId),
  };

  const { loading, callEndpoint } = useFetchAndLoad();
  const controller = loadAbort();

  const redirectToMP = () => {
    setTimeout(() => {
      window.location.href = mpUrl;
    }, 2000);
  };

  const initPayment = (body) => {
    return {
      call: AxiosAuth.post(`/mercadopago/${id}/init-payment`, body),
      controller,
    };
  };

  const handleClick = async () => {
    try {
      const response = await callEndpoint(initPayment(body));
      setSuccess(true);
      setMpUrl(response.data);
    } catch (error) {
      console.error(error.response.data.error);
      setErrorMessage(error.response.data.error);
      setError(true);
    }
  };

  if (error) {
    alert(errorMessage);
    return (
      <div className="Checkout__detail__mp">
        <button className="Checkout__detail__btn">Error</button>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="Checkout__detail__mp">
        <button className="Checkout__detail__btn">
          Cargando Mercado Pago...
        </button>
      </div>
    );
  }

  if (success) {
    return (
      <div className="Checkout__detail__mp">
        <b>Redirigiendo a Mercado Pago...</b>
        {redirectToMP()}
      </div>
    );
  }

  return (
    <div className="Checkout__detail__mp">
      <button className="Checkout__detail__btn" onClick={() => handleClick()}>
        Pagar vía Mercado Pago
      </button>
      * Mercadopago no aplica las promociones de Alohar
      <img
        src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/120X240.jpg"
        title="Mercado Pago - Medios de pago"
        alt="Mercado Pago - Medios de pago"
        width="120"
        height="240"
      />
    </div>
  );
};

export default MPButton;