// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../store/aloharForm/actions";

// Componentes
import Values from "./StepsOnlyApp/Values";
import UserStep from "./StepsOnlyApp/UserStep";
import Title from "./StepsOnlyApp/Title";
import Type from "./StepsOnlyApp/Type";
import Quantities from "./StepsOnlyApp/Quantities";
import Location from "./StepsOnlyApp/Location";
import Services from "./StepsOnlyApp/Services";
import Covid from "./StepsOnlyApp/Covid";
import Photos from "./StepsOnlyApp/Photos";
import Rules from "./StepsOnlyApp/Rules";
import Guests from "./StepsOnlyApp/Guests";
import Prices from "./StepsOnlyApp/Prices";
import Calendar from "./StepsOnlyApp/Calendar";
import Payment from "./StepsOnlyApp/Payment";
import Legal from "./StepsOnlyApp/Legal";
import Finished from "./StepsOnlyApp/Finished";

class AloharFormOnlyApp extends Component {
  showStep = () => {
    let step = this.props.step;
   
    let bodyParameters = {};

    // if (this.props.modeEdit === undefined) {
    //   localStorage.removeItem("housingPublicProcessData");
    // }
    if (this.props.steps[step]) {
      bodyParameters = this.props.steps[step].bodyParameters;
    }

    // if (step === 16) {
    //   /* Cuando finaliza el proceso de publicación la información queda en Redux
    //     por lo cual si se hace el restart al finalizar, debido al state, automaticamente se reinicia
    //     el proceso de publicación. Para evitar ese comportamiento dejo aca el restart del redux 
    //     y la redirección al paso 1.
    //     Que dios y la patria me juzguen.
    //   */
    //   step = 1;
    //   this.props.restart();
    // }
    console.log(step);
    switch (step) {
      case 1:
        return <Values bodyParameters={bodyParameters} />;
      case 2:
        return <UserStep bodyParameters={bodyParameters} />;
      case 3:
        return <Type bodyParameters={bodyParameters} />;
      case 4:
        return <Quantities bodyParameters={bodyParameters} />;
      case 5:
        return <Location bodyParameters={bodyParameters} />;
      case 6:
        return <Services bodyParameters={bodyParameters} />;
      case 7:
        return <Covid bodyParameters={bodyParameters} />;
      case 8:
        return <Photos bodyParameters={bodyParameters} />;
      case 9:
        return <Rules bodyParameters={bodyParameters} />;
      case 10:
        return <Guests bodyParameters={bodyParameters} />;
      case 11:
        return <Title bodyParameters={bodyParameters} />;
      case 12:
        return <Prices bodyParameters={bodyParameters} />;
      case 13:
        return <Payment bodyParameters={bodyParameters} />;
      case 14:
        return <Legal bodyParameters={bodyParameters} />;
      case 15:
        return <Calendar bodyParameters={bodyParameters} />;
      case 16:
        return <Finished bodyParameters={bodyParameters} />;
      default:
        return <Legal bodyParameters={bodyParameters} />;
    }
  };

  render() {
    return <React.Fragment>{this.showStep()}</React.Fragment>;
  }
}

const mapStateToProps = (reducers) => {
  return { ...reducers.aloharFormReducer, ...reducers.userReducer };
};

export default connect(mapStateToProps, aloharFormActions)(AloharFormOnlyApp);
