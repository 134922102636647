// Librerías
import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AxiosAuth from '../../../services/requestApi';
import { BASE_URL } from '../../../Helpers';
// Componentes
import MainContainer from '../Utils/MainContainer';

const Update = ( props ) => {
    const { id } = useParams();

    const [statusPoints, setStatusPoints] = useState(0);

    const history = useHistory();

    const handleChange = (event)  => {
      const name = event.target.name;
      const value = event.target.value;

      if(name === "points") setStatusPoints(value)
    }

    const sendData = () =>{
      const url = `/backoffice/housing/status-points/${id}`;
      const data = {
        points: parseInt(statusPoints)
      }

      AxiosAuth.post(url,data)
        .then((response) => {
          history.push(`../${id}`);
        }).catch((error) => {
          console.log(error)
        });
    }

    const handleSubmit = () =>{
      sendData();
    }

    return (
        <MainContainer title="Status Points">
          <>
            <div className="MainContainer__form">
              <div className="MainContainer__form__group">
                <label htmlFor="points" className="MainContainer__form__label">Puntos</label>
                <input
                  type="number"
                  name="points"
                  id="points"
                  className="MainContainer__form__input"
                  placeholder="Cantidad de puntos de status"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={statusPoints}
                  required
                />
              </div>
              <div className="UserEditComponent__btns">
                <button className="MainContainer__form__btn__outline" onClick={ () => history.push(`../${id}`) }>
                  <p className="MainContainer__form__btn__outline__text">Volver</p>
                </button>
                <button className="MainContainer__form__btn" onClick={ handleSubmit }>Guardar</button>
              </div>
            </div>
          </>
        </MainContainer>
    )
}

export default Update;
