import React from "react";
// recursos
import bank1 from "../../assets/static/Bancos/bank1.png";
import bank2 from "../../assets/static/Bancos/bank2.png";
import bank4 from "../../../src/assets/static/Bancos/NaranjaX.png";
import bank5 from "../../../src/assets/static/Bancos/Logotipo yoy.png";
import bankProvince from "../../../src/assets/static/Bancos/logoBancoProvincia.png";
import "../../assets/styles/LandingPage/sectionBanks.sass";

const SectionBanks = ({ title }) => {
  return (
    <>
      <div className="sectionBank">
        {!title && (
          <div className="sectionBank__header">
            <h3 className="sectionBank__header__title">Pagá en cuotas</h3>
          </div>
        )}
        <div className="sectionBank__container">
          <div class="sectionBank__container__section">
            <div className="sectionBank__container__section__logo ">
              <h2
                className="sectionBank__container__section__transferLogo "
                style={{ width: "100px", margin: "0 auto" }}
              >
                $
              </h2>
            </div>
            <div className="sectionBank__container__section__infoContainer">
              <p className="sectionBank__container__section__infoContainer__text">
                <strong>Transferencia bancaria</strong>
                <br />
                5% de descuento
              </p>
            </div>
          </div>
          <span className="sectionBank__container__separator"></span>
          <div class="sectionBank__container__section">
            <div className="sectionBank__container__section__logo ">
              <img
                style={{ width: "100px", margin: "0 auto" }}
                src={bank1}
                alt="Banco"
              />
            </div>
            <div className="sectionBank__container__section__infoContainer">
              <p className="sectionBank__container__section__infoContainer__text">
                <strong>Banco Nación</strong>
                <br />
                9 cuotas sin interés
                <br />
                hasta 31/12/2023
              </p>
            </div>
          </div>
          <span className="sectionBank__container__separator"></span>
          <div class="sectionBank__container__section">
            <div className="sectionBank__container__section__logo ">
              <img
                style={{ width: "100px", margin: "0 auto" }}
                src={bankProvince}
                alt="Banco"
              />
            </div>
            <div className="sectionBank__container__section__infoContainer">
              <p className="sectionBank__container__section__infoContainer__text">
                <strong>Banco Provincia</strong>
                <br />
                6 cuotas sin interés
                <br />
                hasta 31/12/2023
              </p>
            </div>
          </div>
          <span className="sectionBank__container__separator"></span>
          <div class="sectionBank__container__section">
            <div className="sectionBank__container__section__logo ">
              <img
                style={{ width: "100px" }}
                src={bank5}
                alt="Banco"
                className="sectionBank__container__section__logo__img"
              />
            </div>
            <div className="sectionBank__container__section__infoContainer">
              <p className="sectionBank__container__section__infoContainer__text">
                <strong>Promoción Yoy</strong>
                <br />
                30% de reintegro*
                <br />
                Abonando desde la app Yoy <br />
                con tarjeta de débito <br />
                Y 6 cuotas sin interés + reintegro* <br /> abonando con
                Mástercard * <br />
                Reintegro con tope de $8000
                <br />
                hasta 31/12/2023
              </p>
            </div>
          </div>
          <span className="sectionBank__container__separator"></span>
          <div class="sectionBank__container__section">
            <div className="sectionBank__container__section__logo ">
              <img
                src={bank2}
                alt="Banco"
                className="sectionBank__container__section__logo__img "
              />
            </div>
            <div className="sectionBank__container__section__infoContainer">
              <p className="sectionBank__container__section__infoContainer__text">
                <strong>Plan ahora</strong>
                <br />
                3,6,12 Con interés <br />
                hasta 31/12/2023
              </p>
            </div>
          </div>
          <span className="sectionBank__container__separator"></span>

          <div class="sectionBank__container__section">
            <div className="sectionBank__container__section__logo ">
              <img
                src={bank4}
                alt="Banco"
                className="sectionBank__container__section__logo__img "
              />
            </div>

            <div className="sectionBank__container__section__infoContainer">
              <p className="sectionBank__container__section__infoContainer__text">
                <strong>Plan Z Con interés</strong> <br />
                <strong> 5 y 10 cuotas Con interés</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionBanks;
