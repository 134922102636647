import React from "react";
import moment from "moment";
import { BASE_URL, getRole, loadUserData } from "../../../../Helpers";
import AxiosAuth from "../../../../services/requestApi";
import { useState } from "react";
import { useEffect } from "react";
import "./box-item.sass";
import UserAvatar from "../../../ui/userAvatar";

const BoxItem = ({
  messageInfo,
  getUser,
  setChatMobile,
  setUsersMobile,
  activeBox,
  setActiveBox,
}) => {
  const role = getRole();
  const [unread, setUnread] = useState(messageInfo.readed);
  const userdata = loadUserData();

  // const avatarReceptor = messageInfo.filter((user) => {
  //   return user.from.id !== userdata.id;
  // });
  // console.log("avatarReceptor");
  // console.log(avatarReceptor);

  const lastMessage = (messageId) => {
    setActiveBox(messageId);
    // le manda la informacion del usuario al componente send-message
    setUnread(true);
    markAsRead(messageId);
    getUser(messageInfo);
    setChatMobile(true);
    setUsersMobile(false);
  };

  const markAsRead = async (messageId) => {
    // marcar mensaje leido
    // const url = `${BASE_URL}/user/messages/${messageId}`;

    await AxiosAuth.put(
      `${BASE_URL}/user/messages/mark-all/${messageInfo.from.id}`
    )
      .then((response) => {})
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div
      key={messageInfo.from.id}
      className={activeBox === messageInfo.id ? "boxItemActive" : "boxItem"}
      onClick={() => lastMessage(messageInfo.id)}
    >
      {messageInfo && (
        <UserAvatar
          userData={
            messageInfo.from.id !== userdata.id
              ? messageInfo.from
              : messageInfo.to
          }
          size={50}
        />
      )}
      <div className="boxItem__content">
        <div className="boxItem__content__head">
          <p className="boxItem__content__head__name">
            {userdata.id === messageInfo.from.id
              ? messageInfo.to.firstName
              : messageInfo.from.firstName}

            {role.role === "admin" && (
              <p className="pr-2">{messageInfo.from.id}</p>
            )}
            <span className="boxItem__content__head__name__date">
              {moment(messageInfo.from.issued).utc(true).format("D MMM")}
              <span
                className={
                  userdata.id === messageInfo.from.id
                    ? "boxItem__content__head__name__date__readed"
                    : unread
                    ? "boxItem__content__head__name__date__readed"
                    : "boxItem__content__head__name__date__UnReaded"
                }
              ></span>
            </span>
          </p>
        </div>
        <p className="boxItem__content__message">{messageInfo.message}</p>
      </div>
    </div>
  );
};

export default BoxItem;
