import {loadUserData, loadHousingPublicProcessData} from '../Helpers'
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import modalReducer from './modal/reducer';
import searchReducer from './search/reducer';
import userReducer from './user/reducer';
import aloharFormReducer from './aloharForm/reducer';
import thunk from 'redux-thunk';

const initUserData = loadUserData()
const initHousingPublicProcessData = loadHousingPublicProcessData()

const initialData = {
    userReducer:initUserData,
    aloharFormReducer:initHousingPublicProcessData
}

const reducers = combineReducers({
    modalReducer, searchReducer, userReducer, aloharFormReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers, initialData,
    composeEnhancers(applyMiddleware(thunk))//, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store;
