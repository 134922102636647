// Librerías
import React from 'react';
// Componentes
import NewLinks from '../../../components/BackOffice/Reservations/NewLinks';

const NewLinksContainer = () => {
  return(
    <NewLinks />
  );
}

export default NewLinksContainer;