// Librerías
import React, { useState, useEffect } from 'react';
// Recursos
import '../../../assets/styles/AloharForm/Utils/_numbers.sass';

const Numbers = ({name, title, number, amount, customClickEvent}) => {
  const [value, num] = useState(number);
  const [firtMount, setFirtMount] = useState(false);
  const increment = (amount) ? amount : 1;

  useEffect(() => {
    let event = { target : {
      name:name,
      value:value
    }}
    /* Para evitar validación cuando se monta el componente */
    firtMount ?  customClickEvent(event) : setFirtMount(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value ]);

  const add = (value, increment) => {
    num(value + increment);
  }

  const subtract = (value, increment) => {
    if (value !== 0) num(value - increment);
  }

  return (
    <div className="Numbers">
      <input
        type="hidden"
        name={name}
        value={value}
        onChange={(e) => {console.log(e)}}
      />
      <p className="Numbers__title">
        {title}
      </p>
      <div className="Numbers__container">
        <button className="Numbers__btn" 
          onClick={()=> subtract(value, increment)}
          >-</button>
        <p className="Numbers__text">{value}</p>
        <button className="Numbers__btn" 
          onClick={ ()=> add(value, increment) }
          >+</button>
      </div>
    </div>
  );
}

export default Numbers;