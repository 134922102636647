// Librerías
import React from 'react';
import { ScreenClassProvider } from 'react-grid-system';
import ReactGA from 'react-ga';
// Componentes
import Headline from '../components/RentLanding/Headline';
import Content from '../components/RentLanding/Content';
import Footer from '../components/Footer/Footer';

ReactGA.initialize('G-J748BLTRLS');

const RentLanding = () => {
  return(
    <ScreenClassProvider>
      <Headline />
      <Content />
      <Footer />
    </ScreenClassProvider>
  );
}

export default RentLanding;