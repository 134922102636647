// Librerías
import React, { useState } from "react";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  closeModal,
  openRecoverModal,
  openRegisterModal,
} from "../../store/modal/actions";
import { cleanRedirectUser } from "../../store/user/actions";
import { BASE_URL } from "../../Helpers";
import axios from "axios";
import { loginUser } from "../../store/user/actions";
import AuthService from "../../services/auth";
// Componentes
// Recursos
import "../../assets/styles/Modals/_login_modal.sass";
import facebook_login from "../../assets/static/Modals/fb-icon.svg";
import google_login from "../../assets/static/Modals/g-icon.svg";
import cerrar from "../../assets/static/cerrar.svg";
import eye from "../../assets/static/Icons/eye.svg";
import alert from "../../assets/static/alert.svg";

const LoginModal = () => {
  const loginState = useSelector((state) => state.userReducer, shallowEqual);
  const [errorLogin, setErrorLogin] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState("");
  const [errorInput, setErrorInput] = useState("");
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showSendedEmail, setShowSendedEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const handleGoogleSingUp = () => {
    window.location = `${BASE_URL}/auth/google/`;
  };

  const handleFacebookSingUp = () => {
    window.location = `${BASE_URL}/auth/facebook/`;
  };

   const ifValidate = (input) => {
    if (input === "email" || input === undefined) {
      if (email.length < 5) {
        return { v: false, e: "El email debe ser válido", i: "email" };
      }
      let re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.length > 5) {
        if (!re.test(email)) {
          return { v: false, e: "El email es inválido", i: "email" };
        }
      }
    }
    return { v: true, e: "", i: "" };
  };

  const handleValidate = (input) => {
    const _ifValidate = ifValidate(input);
    setValidate(_ifValidate.v);
    if (!_ifValidate.v) {
      setErrorInput(_ifValidate.i);
      setError(_ifValidate.e);
    }
  };

  const handleReSendEmail = () => {
    axios
      .post(`${BASE_URL}/auth/send_confirm_email`, {
        email: email,
      })
      .then(function (response) {
        setShowSendedEmail(true);
        setShowSendEmail(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = (evt) => {
    localStorage.removeItem("housingPublicProcessData");
    setShowSendEmail(false);
    evt.preventDefault();

    const _ifValidate = ifValidate();
    setValidate(_ifValidate.v);
    if (_ifValidate.v) {
      AuthService.login(email, password)
        .then(function (response) {
          const { firstName, lastName, avatar, email } =
            response.data.data.user;
          const { refresh_token, token } = response.data.data.payload;

          dispatch(
            loginUser({
              token,
              refresh_token,
              firstName,
              lastName,
              avatar,
              email,
            })
          );
          dispatch(closeModal());
          if (loginState.redirect) {
            dispatch(cleanRedirectUser());
            window.location.href = `${loginState.redirect}`;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 401) {
            setError("Usuario y contraseña incorrectos.");
          }

          if (error.response.status === 403) {
            setError("Cuenta sin confirmar. Revise su bandeja de SPAM.");
            setShowSendEmail(true);
          }

          if (error.response.status !== 403 && error.response.status !== 401) {
            setError(error.response);
          }
        });
    } else {
      setErrorInput(_ifValidate.i);
      setError(_ifValidate.e);
    }
  };
  return (
    <div className="LoginModal">
      <section className="LoginModal__section">
        {error ? (
          <div className="LoginModal__section__errorContainer">
            <div className="LoginModal__section__errorContainer__error">
              <img
                alt="alert"
                src={alert}
                className="LoginModal__section__errorContainer__error__icon"
              />
              <p className="LoginModal__section__errorContainer__error__text">
                El email o contraseña ingresados son inválidos
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {showSendEmail ? (
          <div className="LoginModal__section__errorContainer__error">
            <div className="fake_link" onClick={handleReSendEmail}>
              Reenviar mail de confirmación
            </div>
          </div>
        ) : (
          ""
        )}

        <header className="LoginModal__header">
          <div className="LoginModal__header__title">¡Hola!</div>
        </header>
        <form className="LoginModal__form" onSubmit={handleSubmit}>
          <div className="LoginModal__form__inputContainer">
            <div
              className={
                errorInput === "email"
                  ? "LoginModal__form__inputContainer__inputWrapper error"
                  : "LoginModal__form__inputContainer__inputWrapper"
              }
            >
              <label className="LoginModal__form__inputContainer__inputWrapper__label">
                E-mail
              </label>
              <input
                className="LoginModal__form__inputContainer__inputWrapper__input"
                type="email"
                placeholder="Escribe tu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => handleValidate("email")}
              />
            </div>
            <div
              className={
                errorInput === "password"
                  ? "LoginModal__form__inputContainer__inputWrapper error"
                  : "LoginModal__form__inputContainer__inputWrapper"
              }
            >
              <label className="LoginModal__form__inputContainer__inputWrapper__label">
                Contraseña
              </label>
              <div className="LoginModal__form__inputContainer__inputWrapper__passwordWraper">
                <input
                  className="LoginModal__form__inputContainer__inputWrapper__input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Escribe tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() => handleValidate("password")}
                />
                <i
                  style={showPassword ? { color: "#FF4E00" } : {}}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <img alt="Ver contraseña" src={eye} />
                </i>
              </div>
            </div>
          </div>
          <div className="LoginModal__form__container__link__checkcontainer">
            <div className="LoginModal__form__container__link__checkcontainer__check">
              <input
                className="LoginModal__form__container__link__checkcontainer__check__checkIn"
                type="checkbox"
                name="remember "
                id="remember "
              />
              {/* <label className='LoginModal__form__container__link__checkcontainer__check__label' for="remember "> Recordarme</label> */}
            </div>
            <div
              className="LoginModal__form__container__link__checkcontainer__check"
              onClick={() => dispatch(openRecoverModal())}
            >
              <p className="LoginModal__form__container__link__checkcontainer__check__pass">
                ¿Olvidaste tu contraseña?
              </p>
            </div>
          </div>
          <div className="LoginModal__form__buttons">
            <button type="submit" className="LoginModal__form__buttons__btn">
              Ingresar
            </button>
          </div>
          <div className="LoginModal__form__separator">
            <hr />
            <p className="LoginModal__form__separator__text">O ingresá con</p>
            <hr />
          </div>
          <div className="LoginModal__form__socialContainer">
            <div
              className="LoginModal__form__socialContainer__btn google"
              onClick={() => handleGoogleSingUp()}
            >
              <img
                alt="Google Login"
                src={google_login}
                className="LoginModal__form__socialContainer__btn__icon "
              />
            </div>

            <div
              className="LoginModal__form__socialContainer__btn facebook"
              onClick={() => handleFacebookSingUp()}
            >
              <img
                alt="Facebook Login"
                className="LoginModal__form__socialContainer__btn__icon"
                src={facebook_login}
              />
            </div>
            {/* <div className="LoginModal__form__socialContainer__btn apple" >
                            <img alt="Apple Login" className="LoginModal__form__socialContainer__btn__icon" src={iconApple} />
                        </div> */}
          </div>
          <a href="/register" className="LoginModal__form__container links">
            <div className="LoginModal__form__container__link">
              <strong>¿Todavía no tenes una cuenta?</strong>
              <p>
                <strong>Registrate ahora</strong>
              </p>
            </div>
          </a>
        </form>
      </section>
      <section className="LoginBackground">
        <img
          alt="x"
          src={cerrar}
          className="LoginModal__header__cross"
          onClick={() => dispatch(closeModal())}
        />
      </section>
    </div>
  );
};

export default connect(null, { closeModal, openRegisterModal })(LoginModal);
