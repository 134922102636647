// CanonicalUrl.js
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const CanonicalUrl = () => {
  const router = useHistory();
  return (
    <Helmet>
      {window.location.pathname.includes("alojamiento-temporal") && (
        <link
          rel="canonical"
          href={`https://alohar.me${router.location.pathname.replace(
            "/alojamiento-temporal",
            ""
          )}`}
        />
      )}
    </Helmet>
  );
};

export default CanonicalUrl;
