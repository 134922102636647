import React from "react";
import "../../assets/styles/UserTravelsPage/_travel.sass";
import { BASE_URL } from "../../Helpers";
import { useHistory } from "react-router-dom";
import moment from "moment";
moment.locale("es");

const Travel = ({
  id,
  locator,
  status,
  housing,
  housingMedia = [],
  persons,
  checkin,
  checkout,
  address,
  title,
  handleOpenContactAlohadorModal,
  handleCancelationModal,
  cancellationType,
  cancellationPenalization,
  setTypeReservation,
}) => {
  const history = useHistory();
  const cover = housingMedia.filter((h) => h.cover);
  const img =
    cover.length > 0
      ? cover[0].media.replace("public/", "")
      : housingMedia.length > 0
      ? housingMedia[0].media.replace("public/", "")
      : "#";

  const _checkin = moment(checkin).utc(true);
  const _checkout = moment(checkout).utc(true);
  const today = moment().utc(true);

  const estados = {
    pending: "Pendiente",
    cancelled: "Cancelada",
    confirmed: "Confirmada",
  };

  return (
    <div className="Travel">
      <div className="Travel__imgcontainer">
        <img
          className="Travel__imgcontainer__img"
          alt="Viaje"
          src={BASE_URL + "/" + img}
        />
      </div>
      <div className="Travel__datacontainer">
        <div className="Travel__datacontainer__dates">
          {" "}
          {_checkin.format("ddd D, MMM")} - {_checkout.format("ddd D, MMM")} |{" "}
          {persons} {persons === 1 ? "PERSONA" : "PERSONAS"}
        </div>
        <div className="Travel__datacontainer__title">{title}</div>
        {status === "pending" ? (
          ""
        ) : (
          <div className="Travel__datacontainer__address">{address}</div>
        )}
        <div className="Travel__datacontainer__address">
          Localizador: {locator}
        </div>
        <div className="Travel__datacontainer__address">
          Estado: {estados[status]}
        </div>
        <div className="Travel__datacontainer__buttonList">
          <button
            className="Travel__datacontainer__buttonList__btn"
            onClick={() => {
              history.push(`/confirmed/${id}`);
            }}
          >
            Ver detalle
          </button>
          {status !== "cancelled" && _checkout > today ? (
            <button
              className="Reservation__datacontainer__buttonList__btn"
              onClick={() => {
                setTypeReservation();
                handleCancelationModal(
                  id,
                  cancellationType,
                  cancellationPenalization
                );
              }}
            >
              Cancelar Reserva
            </button>
          ) : (
            ""
          )}
          <button
            className="Travel__datacontainer__buttonList__btn"
            onClick={() => {
              handleOpenContactAlohadorModal(
                housing.user.firstName,
                housing.id,
                housing.user.id
              );
            }}
          >
            Contactar al Alohador
          </button>
        </div>
      </div>
    </div>
  );
};

export default Travel;
