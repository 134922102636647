// Librerías
import React from 'react';
// Recursos
import '../../../assets/styles/PropertyPage/Utils/_services.sass';
import img from '../../../assets/static/serviceplaceholder.svg';

const PropertyServices = ({ services, type_service, extraHousingRoom, extraHousingService }) => {

  return(
    <React.Fragment>
      <div className="PropertyServices">
        <h3 className="PropertyServices__title">Servicios</h3>
        <div className="PropertyServices__container">
          {
            services.map((item) => {
              return (
                <div className="PropertyServices__container__service" key={item}>
                  <img src={img} alt="IMG"/>
                  <p className="PropertyServices__container__service__text">
                    {type_service[item]}
                  </p>
                </div>
              );
            })
          }
        </div>
        <br/>
        <p>{extraHousingService !== "" ? <><b>Servicios extras: </b>{extraHousingService} </>: ""}</p>
        <p>{extraHousingRoom !== "" ? <><b>Espacios extras:</b> {extraHousingRoom} </>: ""}</p>
      </div>
    </React.Fragment>
  );
}

export default PropertyServices;