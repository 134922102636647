// Librerías
import React from 'react';
// Recursos
import '../../../assets/styles/AloharForm/Utils/_finished_card.sass';

const FinishedCard = ({price, title, guests, rooms, beds, bathrooms,img, blackText = false}) => {
  const imagen = {
    backgroundImage: `url(${img})`
  };
  const textStyle = (blackText) ? ' black' : '';
  return(
    <React.Fragment>
      <div className={`Card${textStyle}`}>
        {
          img ?<div className="Card__img" style={ imagen }>
            <div className="Card__img__price">
              ARS ${ price } LA NOCHE
            </div>
          </div> : ''
        }
        <h3 className="Card__title">{ title }</h3>
        <p className={`Card__description${textStyle}`}>{ guests } huéspedes • { rooms } dormitorio • { beds } cama • { bathrooms } baño</p>
      </div>
    </React.Fragment>
  );
}

export default FinishedCard;