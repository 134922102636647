// Librerías
import React from 'react';
import { connect, useDispatch } from 'react-redux';
// Componentes
import { closeModal } from '../../store/modal/actions'
// Recursos
import x from '../../assets/static/Modals/x.png';
import '../../assets/styles/Modals/_confirm_modal.sass';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const ErrorModal = ({handleCloseModal, title, text, icon = faExclamationCircle, confirmButtonText= "Aceptar"}) => {
    /* ESTE MODAL NO USA REDUX, para cerrarse llama al callback de handleCloseModal*/
    
    return(
        <div className="ConfirmModal">
            <header className="ConfirmModal__header">{title} <img onClick={handleCloseModal} alt="x" src={x} className="x"/></header>
            <section className="ConfirmModal__section">
                <div className="ConfirmModal__section__iconContainer">
                    <i className="ConfirmModal__section__icon" ><FontAwesomeIcon icon={faExclamationCircle} /></i>
                </div>
                <br/>

                <div dangerouslySetInnerHTML={{__html: text}} ></div>
                
                <section className="ConfirmModal__buttons">
                <button className="button_orange" onClick={handleCloseModal}>{confirmButtonText}</button>
                
            </section>
            </section>
        </div>
    );
}

export default ErrorModal;
