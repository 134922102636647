// Librerías
import React, { useEffect, useState } from "react";
import icon_point from "../../assets/static/Icons/location-pin.svg";
import { BASE_URL } from "../../Helpers";
import AxiosAuth from "../../services/requestApi";
import "../../assets/styles/ProfileCard/user_profile.sass";
import ChangePassword from "../Users/ChangePassword";
import BankAccount from "./bank-account";
const MainUserProfile = ({ password, showBankAccount, closeView }) => {
  window.scrollTo(0, 0);
  const url = `${BASE_URL}/user/myProfile`;
  const [userData, setUserData] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthday, setBirthday] = useState("");
  const [about, setAbout] = useState("Acerca de mi");
  const [locationStr, setLocationStr] = useState("Localidad sin definir");
  const [languageStr, setLanguageStr] = useState("Idiomas sin definir");
  const [join, setJoin] = useState("");
  const [name, setName] = useState("");

  const getUserData = () => {
    AxiosAuth.get(url)
      .then((response) => {
        const date = new Date(response.data.issued);
        setUserData(response.data);
        setJoin(date.getFullYear());
        const {
          phoneNumber,
          birthDay,
          locationStr,
          languageStr,
          about,
          firstName,
        } = response.data;
        if (locationStr) {
          if (locationStr.length > 5)
            setLocationStr(
              "Vive en " + response.data.locationStr + ", Argentina"
            );
        }

        if (languageStr) {
          if (languageStr.length > 5) setLanguageStr(response.data.languageStr);
        }

        if (about) {
          if (about.length > 5) setAbout(response.data.about);
        }
        if (birthDay) {
          if (birthDay.length > 5) setBirthday(response.data.birthDay);
        }
        if (phoneNumber) {
          if (phoneNumber.length > 5) setPhoneNumber(response.data.phoneNumber);
        }

        setName(firstName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const eraseUserSocialData = () => {
  //   let eraseUrl = `${BASE_URL}/user/erase-data`;
  //   AxiosAuth.patch(eraseUrl)
  //     .then()
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    if (!userData) {
      getUserData();
    }
  });

  return (
    <>
      <div className="userInfo">
        <div className="userInfo__container">
          {showBankAccount || password ? (
            ""
          ) : (
            <>
              <div className="userInfo__container__header">
                <p className="userInfo__container__header__init">
                  se unió en {join}
                </p>
                <p className="userInfo__container__header__title">{name}</p>
              </div>
              <div className="userInfo__container__info">
                <div className="userInfo__container__info__contentTextarea">
                  <p className="userInfo__container__info__contentTextarea__title">
                    Acerca de mi
                  </p>
                  {/* <input type='text' placeholder={about}/> */}
                  <p className="userInfo__container__info__contentTextarea__textarea">
                    {" "}
                    {about}{" "}
                  </p>
                </div>

                <div className="userInfo__container__info__content">
                  <p className="userInfo__container__info__content__title">
                    ubicacion
                  </p>
                  <img
                    className="userInfo__container__info__content__icon"
                    alt="Ubication"
                    src={icon_point}
                  />
                  <p className="userInfo__container__info__content__description">
                    {locationStr}
                  </p>
                </div>

                <div className="userInfo__container__info__content">
                  <p className="userInfo__container__info__content__title">
                    Idioma
                  </p>
                  {/* <img className='userInfo__container__info__content__icon' alt="Languages" src={icon_idiomas}/> */}
                  <p className="userInfo__container__info__content__description">
                    {languageStr}
                  </p>
                </div>

                <div className="userInfo__container__info__content">
                  <p className="userInfo__container__info__content__title">
                    Fecha de Nacimiento
                  </p>
                  <p className="userInfo__container__info__content__description">
                    {birthday}
                  </p>
                </div>

                <div className="userInfo__container__info__content">
                  <p className="userInfo__container__info__content__title">
                    telefono
                  </p>
                  <p className="userInfo__container__info__content__description">
                    {phoneNumber}
                  </p>
                </div>
              </div>
            </>
          )}

          {showBankAccount && <BankAccount buttonBack={() => closeView()} />}
          {password && <ChangePassword buttonBack={() => closeView()} />}
        </div>
      </div>
    </>
  );
};

export default MainUserProfile;

/* 
  onclick="if (confirm("Seguro que queres borrar NUTRIBON?")) { document.post_6092c3d958727858681756.submit(); } event.returnValue = false; return false;"

*/
