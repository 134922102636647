// Librerías
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
// Componentes
import { closeModal } from "../../store/modal/actions";
// Recursos
import close from "../../assets/static/Icons/close.svg";
import "../../assets/styles/Modals/_messenger_modal.sass";
import AxiosAuth from "../../services/requestApi";
import loadingGif from "../../assets/static/loading.gif";
import { BASE_URL } from "../../Helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
const cancellation_policy = [
  {
    id: "cancellation_policy_1",
    value: "flex",
    name: "cancellation_policy",
    title: "Flexible",
    description: [
      "Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si comunican su decisión de cancelar con una anticipación no menor a 24 horas antes del Check in*. Caso contrario no habra derecho alguno a reembolso. Una vez operado el ingreso  por parte del huesped y hallándose en uso de los servicios, si este efectuare por su decision abandono de los mismos, carecera de derecho alguno a reembolso por las noches no utilizadas.",
    ],
  },
  {
    id: "cancellation_policy_2",
    value: "moderate",
    name: "cancellation_policy",
    title: "Moderada",
    description: [
      "Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si cancelan al menos  7 días antes del Check in*.",
    ],
  },
  {
    id: "cancellation_policy_3",
    value: "stric",
    name: "cancellation_policy",
    title: "Estricta",
    description: [
      "Los huéspedes recibirán un reembolso del 50% (menos la tarifa de servicio del huésped) si cancelan al menos  30 días antes del Check in*",
    ],
  },
  {
    id: "cancellation_policy_4",
    value: "heavy",
    name: "cancellation_policy",
    title: "Muy Estricta",
    description: [
      "Si el huésped cancela la reserva, no se le reembolsará ningún importe abonado.",
    ],
  },
];

const CancelReservationModal = ({
  reservationId,
  hasPenalty = false,
  cancellationType,
  typeReservation,
  getAllreservation,
  reservations,
}) => {
  const router = useHistory();
  const [messenger, setMessenger] = useState("");
  const [sending, setSending] = useState(false);
  const [sended, setSended] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [penalty, setPenalty] = useState();
  const [statusReservation, setStatusReservation] = useState(false);
  const [errorCancelation, setErrorCancelation] = useState(false);
  useEffect(() => {
    if (penalty === undefined && hasPenalty) {
      cancelationQuote();
    }
  });

  const dispatch = useDispatch();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (messenger.length > 0) {
      setSending(true);
      sendCancelation();
    }
  };
  const cancelationQuote = async () => {
    setLoading(true);
    const url = `/reservations/cancelation/quote/${reservationId}`;
    await AxiosAuth.get(url)
      .then((response) => {
        setPenalty(response.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const descriptionCancelation = (cancellationType) => {
    const cancel = cancellation_policy.filter((c) => {
      return c.value === cancellationType;
    });

    if (cancel) return <div>{cancel[0].description[0]}</div>;
  };

  const sendCancelation = async () => {
    const url = `${BASE_URL}/v2/reservations/${reservationId}`;
    setError(false);
    let bodyParameter;
    if (window.location.href.includes("profile/travels")) {
      bodyParameter = { data: { reason: messenger, user: "guest" } };
    } else {
      bodyParameter = { data: { reason: messenger, user: "host" } };
    }
    const send = await AxiosAuth.delete(url, bodyParameter)
      .then((data) => {
        if (data.status === 200) {
          setMessenger("");
          setSended(true);
          dispatch(closeModal());
          getAllreservation();
        }
      })
      .catch((e) => {
        console.log(e);
        setError("Error cancelando la reserva.");
        // dispatch(closeModal());
        setErrorCancelation(true);
      })
      .then(() => {
        setSending(false);
      });

    return send;
  };
  return (
    <>
      <div className="MessengerModal ">
        <header className="MessengerModal__header">
          Cancelar reserva
          <img
            onClick={() => dispatch(closeModal())}
            alt="close"
            src={close}
            className="MessengerModal__header__cross"
          />
        </header>
        {errorCancelation ? (
          <section className="text-center MessengerModal__section">
            Para cancelar esta reserva, por favor comunicate con atención al
            cliente
            <a
              href="https://api.whatsapp.com/send?phone=5492233018749&text=¡Hola Alohar! Quisiera que me contacten."
              className="button_orange"
              rel="noopener noreferrer"
              target="_blank"
            >
              Quiero comunicarme con atención al cliente
            </a>
          </section>
        ) : (
          <section className="MessengerModal__section">
            <form
              className="MessengerModal__section__form"
              onSubmit={handleSubmit}
            >
              {error ? (
                <p>
                  <b>Error cancelando la reserva</b>
                  {error}
                </p>
              ) : (
                ""
              )}
              {sended ? (
                <h2>Cancelación realizada!</h2>
              ) : (
                <>
                  {hasPenalty ? (
                    loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <img
                          alt="Cargando"
                          style={{ margin: "auto", maxWidth: "50px" }}
                          src={loadingGif}
                        />
                      </div>
                    ) : (
                      <div>
                        <p>
                          La politica de cancelación de la reserva es{" "}
                          {cancellationType} y se te{" "}
                          <span
                            style={{ color: "#FF4E00", fontWeight: "bold" }}
                          >
                            cobrará una multa.
                          </span>
                        </p>
                        <p style={{ color: "#FF4E00", fontWeight: "bold" }}>
                          En caso de que el dueño del alojamiento le haya
                          solicitado que realice la cancelación tendrá que
                          comunicarse con nosotros para que procedamos.
                        </p>
                        {/* <p>La politica de cancelación de la reserva es {cancellationType} y tendrá un costo de ${penalty}</p>  */}
                        <p>{descriptionCancelation(cancellationType)}</p>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  <p>
                    Debe dejar un mensaje que explique la cancelación de la
                    reserva
                  </p>
                  <br />
                  <textarea
                    value={messenger}
                    onChange={(e) => setMessenger(e.target.value)}
                    className="MessengerModal__section__form__textarea"
                  />
                  {sending ? (
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <img
                        alt="Cargando"
                        style={{ margin: "auto", maxWidth: "50px" }}
                        src={loadingGif}
                      />
                    </div>
                  ) : (
                    <button
                      disabled={sending}
                      type="submit"
                      className="button_orange"
                    >
                      Cancelar reserva
                    </button>
                  )}
                </>
              )}
            </form>
          </section>
        )}
      </div>
    </>
  );
};

export default connect(null, { closeModal })(CancelReservationModal);
