import React from "react";
import StarIcon from "../../assets/components-icons/StarIcon";
import { useState } from "react";
import { useEffect } from "react";
import { caclValuations } from "./calcValuations";
import award from "../../assets/static/Icons/award.svg";
const Valuations = ({ userId, response, size }) => {
  const [valuationsLength, setValuationsLength] = useState(0);
  const [valuations, setValuations] = useState([]);
  useEffect(() => {
    // host_to_guest
    const valuationCleaningGuest = response.data.host_to_guest.map((e) =>
      e.valuations.filter((e) => e.topic.code === "CLEANING").map((e) => e.rate)
    );
    const valuationComunicationGuest = response.data.host_to_guest.map((e) =>
      e.valuations
        .filter((e) => e.topic.code === "COMMUNICATION")
        .map((e) => e.rate)
    );
    const valuationConductGuest = response.data.host_to_guest.map((e) =>
      e.valuations.filter((e) => e.topic.code === "CONDUCT").map((e) => e.rate)
    );
    const valuationCleaning = response.data.guest_to_host.map((e) =>
      e.valuations.filter((e) => e.topic.code === "CLEANING").map((e) => e.rate)
    );
    const valuationTrust = response.data.guest_to_host.map((e) =>
      e.valuations.filter((e) => e.topic.code === "TRUST").map((e) => e.rate)
    );
    const valuationComunication = response.data.guest_to_host.map((e) =>
      e.valuations
        .filter((e) => e.topic.code === "COMMUNICATION")
        .map((e) => e.rate)
    );
    const valuationLocation = response.data.guest_to_host.map((e) =>
      e.valuations.filter((e) => e.topic.code === "LOCATION").map((e) => e.rate)
    );
    const PriceQuality = response.data.guest_to_host.map((e) =>
      e.valuations
        .filter((e) => e.topic.code === "PRICEQUALITY")
        .map((e) => e.rate)
    );
    const checkin = response.data.guest_to_host.map((e) =>
      e.valuations.filter((e) => e.topic.code === "CHECKIN").map((e) => e.rate)
    );
    const sumValuationsGuest =
      caclValuations(valuationConductGuest) +
      caclValuations(valuationComunicationGuest) +
      caclValuations(valuationCleaningGuest);

    const sumValuations =
      caclValuations(valuationCleaning) +
      caclValuations(valuationTrust) +
      caclValuations(valuationComunication) +
      caclValuations(valuationLocation) +
      caclValuations(PriceQuality) +
      caclValuations(checkin);

    setValuations(sumValuationsGuest / 3 + sumValuations / 6);
    setValuationsLength(
      valuationConductGuest.length + valuationCleaning.length
    );
  }, [userId]);

  return (
    <div className="user__imgContainer__evaluation">
      <p
        style={{ fontSize: size ? size : "20px" }}
        className="user__imgContainer__evaluation__text"
      >
        <StarIcon size={25} />
        {valuationsLength
          ? `  ${parseFloat(valuations).toFixed(
              1
            )} (${valuationsLength} Evaluaciones)`
          : `0 Evaluaciones`}
      </p>
      <img
        className="user__imgContainer__evaluation__img"
        alt="icon evlauacion"
        src={award}
      />
    </div>
  );
};

export default Valuations;
