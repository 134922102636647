import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import esLocale from "moment/locale/es";
import moment from "moment";
import "react-dates/initialize";
import { setCheckin, setCheckout } from "../../store/search/actions";
import { DateRangePicker } from "react-dates";
import { isMobile } from "react-device-detect";
import "react-dates/lib/css/_datepicker.css";
import hotSale from "../../../src/assets/static/hot_sale/alohar-hot-sale.png";
import "../../assets/styles/MainSearch/daterange.sass";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { setCheckin: setCheckin, setCheckout: setCheckout },
    dispatch
  );
}

class SelectDate extends React.Component {
  constructor(props) {
    super(props);
    moment.locale("es", esLocale);
    const checkin =
      props.checkin === "" ? null : moment(props.checkin, "DD/MM/YY").utc(true);
    const checkout =
      props.checkout === ""
        ? null
        : moment(props.checkout, "DD/MM/YY").utc(true);
    this.state = {
      startDate: checkin ? checkin : null,
      endDate: checkout ? checkout : null,
      startDateId: "Llegada",
      endDateId: "Salida",
      orientation: "horizontal",
      months: 1,
      initDataFromURL: false,
      disableDates: props.disableDates,
      hotSaleDates: props.hotSaleDates,
      clearPrice: props.clearPrice,
      from: props.from,
      to: props.to,
      newStyle: props.newStyle,
      format: props.format,
      highlightedDates: [
        "2024-05-01",
        "2024-05-10",
        "2024-05-20",
        "2024-05-25",
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.startDate) {
      if (this.props.setErrorSearch) {
        this.props.setErrorSearch(true);
      }
    }
    if (this.state.endDate) {
      if (this.props.setErrorSearch) {
        this.props.setErrorSearch(true);
      }
    }
    if (this.state.startDate && this.state.endDate) {
      if (this.props.setErrorSearch) {
        this.props.setErrorSearch(false);
      }
    }
    if (this.props.setStartDate) {
      this.props.setStartDate(this.state.startDate);
    }
    if (this.props.setEndDate) {
      this.props.setEndDate(this.state.endDate);
    }
    if (this.props.checkinRef) {
    }
    if (this.props.disableDates !== prevProps.disableDates) {
      this.setState({
        disableDates: this.props.disableDates,
      });
    }
    if (this.props.hotSaleDates !== prevProps.hotSaleDates) {
      this.setState({
        hotSaleDates: this.props.hotSaleDates,
      });
    }

    if (
      this.state.startDate === null &&
      this.state.endDate === null &&
      !this.state.initDataFromURL
    ) {
      if (this.props.checkin !== "" && this.props.checkout !== "") {
        if (this.state.newStyle) {
          this.setState({
            startDate: moment(this.props.checkin, "DD-MM-YY").utc(true),
            endDate: moment(this.props.checkout, "DD-MM-YY").utc(true),
            initDataFromURL: true,
          });
        } else {
          this.setState({
            startDate: moment(this.props.checkin, "DD/MM/YY").utc(true),
            endDate: moment(this.props.checkout, "DD/MM/YY").utc(true),
            initDataFromURL: true,
          });
        }
      }
    }
  }

  disabledDates = (day) => {
    if (this.state.disableDates) {
      const ifBlock = this.state.disableDates.filter((d) => {
        const start = d.start;
        const end = d.end;
        return day.isBetween(start, end, "days", "[]");
      });
      return ifBlock.length !== 0;
    }

    return false;
  };

  iconHotSaleDates = (day) => {
    if (this.state.hotSaleDates) {
      const ifBlock = this.state.hotSaleDates.filter((d) => {
        const start = d.start;
        const end = d.end;
        return day.isBetween(start, end, "days", "[]");
      });
      return ifBlock;
      // return day.format("D");
    } else {
      return null;
    }
  };
  // iconHotSaleDates = (day) => {
  //   if (this.state.hotSaleDates) {
  //     const isHotSale = this.state.hotSaleDates.some((dateRange) => {
  //       const [startDate, endDate] = dateRange.split("-");
  //       const start = moment(startDate);
  //       const end = moment(endDate);
  //       return day.isBetween(start, end, "days", "[]");
  //     });
  //     return isHotSale;
  //   }
  //   return false;
  // };

  onDatesChange = ({ startDate, endDate }) => {
    if (startDate) {
      if (this.props.setErrorSearch) {
        this.props.setErrorSearch(true);
      }
    }
    if (endDate) {
      if (this.props.setErrorSearch) {
        this.props.setErrorSearch(true);
      }
    }
    if (startDate && endDate) {
      if (this.props.setErrorSearch) {
        this.props.setErrorSearch(false);
      }
    }
    if (this.props.setStartDate) {
      this.props.setStartDate(startDate);
    }
    if (this.props.setEndDate) {
      this.props.setEndDate(endDate);
    }
    this.setState({ startDate, endDate });

    const { setCheckout, setCheckin } = this.props;

    if (startDate) setCheckin(startDate.format("DD/MM/YY"));
    if (endDate) setCheckout(endDate.format("DD/MM/YY"));

    if (startDate === null && endDate === null) {
      this.setState({ startDate: "", endDate: "" });
      setCheckin("");
      setCheckout("");
      this.state.clearPrice();
    }
  };

  renderHighlightedDates = (day) => {
    const isHighlighted = this.state.highlightedDates.some((highlightedDate) =>
      highlightedDate.date.isSame(day, "day")
    );

    if (isHighlighted) {
      return <p>asd</p>; // Aquí renderiza tu icono en lugar de "your-icon-name"
    }

    return null;
  };

  render() {
    // TODO
    // Detectar cuando es mobile y pasar la propiedad de calendario vertical
    // http://airbnb.io/react-dates/?path=/story/daypicker--vertical
    return (
      // this.props.checkinRef;
      <div
        className={`${
          this.state.newStyle
            ? "input_wrapperNew w-full sm:h-max"
            : "input_wrapper"
        } `}
        id="select_date"
      >
        <DateRangePicker
          renderDayContents={(day) => {
            if (this.state.hotSaleDates) {
              if (this.iconHotSaleDates(day).length > 0) {
                return (
                  <div className="relative ">
                    {day.format("D")}
                    <img
                      src={hotSale}
                      alt="Hot sale"
                      className="absolute top-[-4px] right-[0] z-50 w-2"
                    />
                  </div>
                );
              }
            }
            return day.format("D");
          }}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId={this.state.startDateId} // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId={this.state.endDateId} // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) =>
            this.onDatesChange({ startDate, endDate })
          } // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          startDatePlaceholderText={
            this.state.from ? this.state.from : "Llegamos"
          }
          endDatePlaceholderText={this.state.to ? this.state.to : "Nos vamos"}
          orientation={this.state.orientation}
          numberOfMonths={this.state.months}
          noBorder
          hideKeyboardShortcutsPanel
          small
          showClearDates={true}
          displayFormat={this.state.format ? this.state.format : "DD MMM"}
          isDayBlocked={this.disabledDates}
        />
      </div>
    );
  }
}

SelectDate.defaultProps = {
  checkin: "",
  checkout: "",
};

const mapStateToProps = (reducers) => {
  return reducers.searchReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectDate);
