// Librerías
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { StepTitle } from "semantic-ui-react";
// Componentes
// Recursos
import "../../assets/styles/FAQ/_faq.sass"
import terms from '../../assets/static/legal.pdf';
import privacy from '../../assets/static/privacidad.pdf';
import Accordion from "../Utils/Accordion";
import SideMenu from "../Utils/SideMenu";

const FAQComponentMobile = () => {
    const [questionsType, setQuestionsType] = useState("");
    const [title, setTitle] = useState("");

    const mostCommonQuestions = [
        {
            id:1,
            questionsType: "covid",
            question: "Acerca del brote de coronavirus (COVID-19)",
            answer: "El objetivo de nuestros alohadores, es mantener la higiene y las normas establecidas de público conocimiento, manteniendo el distanciamiento social. Desde Alohar promulgamos el seguimiento del protocolo definido para el alquiler de espacios de alojamiento.<br /><br /><a href='http://www.turismomardelplata.gob.ar/pdf_covid/Alojamiento%20Extra%20Hotelero.pdf' target='_blank'>Ver Protocolo</a>",
        },
        {
            id:2,
            questionsType: "register",
            question: "¿Cómo me registro en Alohar?",
            answer: "En la esquina superior derecha de la página inicial de Alohar, deberás hacer click en el icono y seleccionar la opción de Regístrate. Podrás acceder a través de tu correo electrónico o cuenta de Facebook. Siempre deberás ingresar a través del mismo medio.",
        },
        {
            id:3,
            questionsType: "login",
            question: "¿Cómo completo el proceso de verificación para acceder a mi cuenta?",
            answer: "Al registrarte recibirás un e-mail para confirmar tu identidad. Una vez confirmada podrás iniciar sesión.",
        },
        {
            id:4,
            questionsType: "login",
            question: "¿Cómo inicio sesión?",
            answer: "En la esquina superior derecha de la página inicial de Alohar, deberás hacer click en el icono y seleccionar la opción de Iniciar Sesión. E ingresar con la opción seleccionada al registrarse. Con cuenta de Gmail, Facebook o correo electrónico y la contraseña.",
        },
        {
            id:5,
            questionsType: "password",
            question: "¿Qué debo hacer para cambiar mi contraseña?",
            answer: "Si olvidaste tu contraseña, simplemente debes ir a la opción “Iniciar sesión” en el menú que se encuentra en la página principal en el margen superior derecho. Una vez que se abre la ventana, para ingresar, debés hacer click en la opción “olvidé mi contraseña”. <br /><br />En caso de que quieras cambiar tu contraseña, podrás hacerlo desde el menú principal una vez que hayas iniciado sesión. En la opción Mi Perfil, encontrarás el link de “Cambio de contraseña”.",
        },
        {
            id:6,
            questionsType: "profile-configuration",
            question: "¿Cómo modifico mis ajustes de contacto?",
            answer: "Una vez iniciada la sesión, accedes al menú principal en el margen superior derecho. Seleccionas la opción Mi Perfil, luego “editar perfil” y allí podrás modificar todos tus datos personales y de contacto.",
        },
        {
            id:7,
            questionsType: "housing-configuration",
            question: "¿Cómo configuro mi alohamiento?",
            answer: "En el menú principal, ingreso en Mis Alohamientos.En mi aviso, selecciono la opción “Editar”. Y se abrirá una ventana en donde puede cambiar todas las configuraciones de su alohamiento: Desde este panel, podrás editar cada opción. ",
        },
        {
            id:8,
            questionsType: "bookings",
            question: "¿Cómo puedo realizar modificaciones en la reserva?",
            answer: `Las reservas confirmadas no podrán ser modificadas por la plataforma, comunícate con el
            grupo de atención al cliente de alohar.`,
        },
        {
            id:9,
            questionsType: "bookings",
            question: "¿Cómo sé que mi reserva es segura?",
            answer: "Alohar garantiza por pasarelas de pago que la reserva sea segura, utilizando estrictos protocolos de seguridad.",
        },
        {
            id:10,
            questionsType: "bookings",
            question: "¿Dónde puedo encontrar las indicaciones sobre la entrada al alohamiento y los demás datos sobre la llegada?",
            answer: "Nuestro Alohadores, suelen compartir la información de llegada por mensajes dentro de la plataforma.<br />Deberás estar en contacto con el alohador por medio de nuestra plataforma para acordar el horario de ingreso a la propiedad.",
        },
        {
            id:11,
            questionsType: "bookings",
            question: "No logro encontrar el establecimiento que he reservado online. ¿Qué debo hacer?",
            answer: "Deberás comunicarte con el propietario por medio de la plataforma, en caso de que esto no prospere, te contactas con hola@alohar.me",
        },
        {
            id:12,
            questionsType: "bookings",
            question: "¿Cómo puedo hacer un seguimiento de los pagos de las reservas?",
            answer: "Dentro de Mis Alohamientos, “reservas”.",
        },
        {
            id:14,
            questionsType: "bookings-comms",
            question: "¿Cómo me pongo en contacto con el propietario?",
            answer: "Hasta el ingreso a la propiedad el contacto con el propietario va a ser por medio de la plataforma.",
        },
        {
            id:15,
            questionsType: "bookings-comms",
            question: "¿Cómo puedo ponerme en contacto con mis huéspedes?",
            answer: "Te deberás contactar por medio del chat interno dentro de la plataforma.",
        },
        {
            id:16,
            questionsType: "bookings-comms",
            question: "¿Qué es mi índice de respuesta y por qué es importante?",
            answer: "El índice de respuesta le da mejor posicionamiento al propietario, y a medida que vaya generando mayores respuestas y mayor puntuación, se convierte en súper alohador.",
        },
        {
            id:17,
            questionsType: "bookings-comms",
            question: "¿Por qué es importante responder en 24 horas?",
            answer: "Porque mientras más rápido respondas mejor visualización de tu propiedad vas a tener.",
        },
        {
            id:18,
            questionsType: "bookings-comms",
            question: "¿Cómo gestiono las solicitudes de reserva y de información?",
            answer: "Dentro de la plataforma, recibirás un mail con la solicitud de un huésped para conocer tu propiedad, luego podrás interactuar con él por medio del chat dentro de la plataforma alohar.me",
        },
        {
            id:19,
            questionsType: "bookings-comms",
            question: "¿Por qué los huéspedes no pueden pagarme/ponerse en contacto conmigo directamente?",
            answer: "El objetivo de alohar es generar una comunidad de alohadores, en donde ambas partes puedan contratar un servicio fácil, seguro y en pesos.",
        },
        {
            id:20,
            questionsType: "bookings-comms",
            question: "¿Cómo respondo a los huéspedes?",
            answer: "En el chat dentro de la plataforma, previamente recibirás un mail con la inquietud de huésped.",
        },
        {
            id:21,
            questionsType: "bookings-comms",
            question: "¿Cómo escribo una opinión sobre un alquiler?",
            answer: "Una vez cumplida la estadía, recibirás un correo en donde puntuarás el alohador y la propiedad en donde estuviste alohada.",
        },
        {
            id:22,
            questionsType: "cancelled",
            question: "Han cancelado mi reserva. ¿Dónde voy a alojarme ahora?",
            answer: "Si el alohador cancela la reserva por algún motivo, tendrás que buscar un nuevo alohamiento en la plataforma.",
        },
        {
            id:23,
            questionsType: "cancelled",
            question: "¿Cómo puedo cancelar mi reserva? (para huéspedes)",
            answer: "Te deras contactar con el alohador y explicarle los motivos por los cuales se cancela la reserva, estarás sujeto a las distintos tipos de cancelacion eligio el propietario para publicar su anuncio.<br /><br /><b>Flexible</b><br />Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si comunican su decisión de cancelar con una anticipación no menor a 24 horas antes del Check in*. Caso contrario no habra derecho alguno a reembolso. Una vez operado el ingreso  por parte del huesped y hallándose en uso de los servicios, si este efectuare por su decision abandono de los mismos, carecera de derecho alguno a reembolso por las noches no utilizadas.<br /><b>Moderada</b><br />Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si cancelan al menos 7 días antes del Check in*.<br /><b>Estricta</b><br />Los huéspedes recibirán un reembolso del 50% (menos la tarifa de servicio del huésped) si cancelan al menos 30 días antes del Check in*<br /><b>Muy Estricta</b><br />Si el huésped cancela la reserva, no se le reembolsará ningún importe abonado.",
        },
        {
            id:24,
            questionsType: "cancelled",
            question: "¿Qué debería hacer si se produce una situación de emergencia que pueda afectar a mi reserva?",
            answer: "Deberías comunicarte con el alohador y plantearle la cuestión.",
        },
        {
            id:25,
            questionsType: "cancelled",
            question: "¿Cuándo recibiré la devolución?",
            answer: "La devolución dependerá de cada modalidad de pago y entidad bancaria quedando sujeta a los plazos de los mismos para su devolución. El huésped contará con el soporte del área de atención de alohar para un seguimiento de ser necesario.",
        },
        {
            id:26,
            questionsType: "cancelled",
            question: "¿Cómo puedo reclamar un reembolso?",
            answer: "En nuestro correo hola@alohar.me",
        },
        {
            id:27,
            questionsType: "taxes",
            question: "¿Tengo que pagar algún tipo de impuesto o IVA?",
            answer: "Alohar cumple el rol de intermediario entre partes y factura únicamente las comisiones por el servicio prestado, la facturación del saldo restante corresponde exclusivamente al Alohador.",
        },
        {
            id:28,
            questionsType: "taxes",
            question: "¿Qué comisiones debo pagar? (para propietarios)",
            answer: "6,5%* si sos alohador y 11,5%* si sos huésped. *Valores sin iva incluido",
        },
        {
            id:29,
            questionsType: "taxes",
            question: "¿En qué monedas puedo recibir los pagos de los huéspedes?",
            answer: "En pesos argentinos.",
        },
        {
            id:30,
            questionsType: "taxes",
            question: "¿Por qué no he recibido el pago de un huésped?",
            answer: "Los pagos a alohadores serán emitidos a los 5 días hábiles posteriores al check in del huésped. Para aquellas reservas generadas con al menos 20 días de antelación al ingreso, la fecha del envío de dinero puede ser mayor debido a la demora en el proceso de cobro de las tarjetas y bancos pudiendo llegar a ser superior a los 10 días hábiles posterior al ingreso del huésped. En cualquier caso, Alohar no se responsabiliza por demoras de gestión de entidades bancarias o de cobro.",
        },
        {
            id:31,
            questionsType: "taxes",
            question: "¿Debería recibir los pagos en mi cuenta bancaria?",
            answer: "Si, en la cuenta que ingresaron en “datos bancarios” al cargar la propiedad.",
        },
        {
            id:32,
            questionsType: "accountabilities",
            question: "¿Cómo puedo denunciar un problema ocurrido durante mi estancia?",
            answer: "Debes enviar un mail a hola@alohar.me",
        },
        {
            id:33,
            questionsType: "accountabilities",
            question: "¿Cómo desactivo mi perfil?",
            answer: "Para dar de baja, debes enviar un email a hola@alohar.me y solicitar la baja, el equipo de aloha te ayudará en tu pedido.",
        }, 
        {
            id:34,
            questionsType: "cancelled",
            question: "¿Cómo puedo pagar mi reserva?",
            answer: "El proceso de reserva es únicamente por la plataforma con tarjeta de crédito en 6,12 o 18 cuotas, también se puede abonar con tarjeta de débito y además se puede realizar una transferencia directa, esta última tendrá un beneficio de un 2% de descuento para el huésped. </bn> Se paga el total de la estadía una vez que la reserva está confirmada por el alohador.",
        },
        
    ];

    const questions = mostCommonQuestions.filter(mostCommonQuestions => mostCommonQuestions.questionsType === questionsType);

    return(
        <React.Fragment>
            <div className="FAQContainer">
                <div className='FAQContainer__sideBar'>
                    {/* <SideMenu /> */}
                </div>
            <div className="FAQ">
                <div className="FAQ__menu">
                    <p className={`FAQ__menu__question ${ questionsType === "covid" ? "active" : ""}`} onClick={() => {setQuestionsType("covid")}}>
                        COVID
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "register" ? "active" : ""}`} onClick={() => {setQuestionsType("register")}}>
                        Registro
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "login" ? "active" : ""}`} onClick={() => {setQuestionsType("login")}}>
                        Inicio y Verificación
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "password" ? "active" : ""}`} onClick={() => {setQuestionsType("password")}}>
                        Contraseña
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "profile-configuration" ? "active" : ""}`} onClick={() => {setQuestionsType("profile-configuration")}}>
                        Configuración del Perfil
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "housing-configuration" ? "active" : ""}`} onClick={() => {setQuestionsType("housing-configuration")}}>
                        Configuración del Alohamiento
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "bookings" ? "active" : ""}`} onClick={() => {setQuestionsType("bookings")}}>
                        Reservas
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "bookings-comms" ? "active" : ""}`} onClick={() => {setQuestionsType("bookings-comms")}}>
                        Comunicación Reservas
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "cancelled" ? "active" : ""}`} onClick={() => {setQuestionsType("cancelled")}}>
                        Cancelaciones
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "taxes" ? "active" : ""}`} onClick={() => {setQuestionsType("taxes")}}>
                        Impositivo
                    </p>
                    <p className={`FAQ__menu__question ${ questionsType === "accountabilities" ? "active" : ""}`} onClick={() => {setQuestionsType("accountabilities")}}>
                        Responsabilidades
                    </p>
                </div>
                <div className="FAQ__content">
                    {
                        questions.filter((r)=>{
                            if(questionsType === "covid") return r.questionsType === "covid"
                            if(questionsType === "register") return r.questionsType === "register"
                            if(questionsType === "login") return r.questionsType === "login"
                            if(questionsType === "password") return r.questionsType === "password"
                            if(questionsType === "profile-configuration") return r.questionsType === "profile-configuration"
                            if(questionsType === "housing-configuration") return r.questionsType === "housing-configuration"
                            if(questionsType === "bookings") return r.questionsType === "bookings"
                            if(questionsType === "bookings-comms") return r.questionsType === "bookings-comms"
                            if(questionsType === "cancelled") return r.questionsType === "cancelled"
                            if(questionsType === "taxes") return r.questionsType === "taxes"
                            if(questionsType === "accountabilities") return r.questionsType === "accountabilities"
                        }).map(e => (
                                <Accordion
                                    key={e.id}
                                    title={e.question}
                                    content={e.answer}
                                />
                        ))
                    }
                </div>
            </div>
        </div>
            <div className="FAQMobile">
            <h2 className="FAQMobile__title">
                    <p className={`FAQMobile__title__btn${(title !== "")? " active" : ""}`}
                    onClick={() => {
                        setTitle("")
                        setQuestionsType("")
                        window.scrollTo(0, 0)
                    }}>
                    </p>
                    {/* <p className="FAQMobile__title__title">
                        {(questionsType !== "") ? title : "Centro de Ayuda"}
                    </p> */}
                </h2>
                <div className={`FAQMobile__menu${(title !== "")? "" : " active"}`}>
                    <p className={`FAQMobile__menu__question ${ questionsType === "covid" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("COVID")
                        setQuestionsType("covid")
                    }}>
                        COVID
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "register" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Registro")
                        setQuestionsType("register")
                    }}>
                        Registro
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "login" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Inicio y Verificación")
                        setQuestionsType("login")
                    }}>
                        Inicio y Verificación
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "password" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Contraseña")
                        setQuestionsType("password")
                    }}>
                        Contraseña
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "profile-configuration" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Configuración del Perfil")
                        setQuestionsType("profile-configuration")
                    }}>
                        Configuración del Perfil
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "housing-configuration" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Configuración del Alohamiento")
                        setQuestionsType("housing-configuration")
                    }}>
                        Configuración del Alohamiento
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "bookings" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Reservas")
                        setQuestionsType("bookings")
                    }}>
                        Reservas
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "bookings-comms" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Comunicación Reservas")
                        setQuestionsType("bookings-comms")
                    }}>
                        Comunicación Reservas
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "cancelled" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Cancelaciones")
                        setQuestionsType("cancelled")
                    }}>
                        Cancelaciones
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "taxes" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Impositivo")
                        setQuestionsType("taxes")
                    }}>
                        Impositivo
                    </p>
                    <p className={`FAQMobile__menu__question ${ questionsType === "accountabilities" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Responsabilidades")
                        setQuestionsType("accountabilities")
                    }}>
                        Responsabilidades
                    </p>
                </div>
                <div className="FAQMobile__content">
                    {
                        questions.filter((r)=>{
                            if(questionsType === "covid") return r.questionsType === "covid"
                            if(questionsType === "register") return r.questionsType === "register"
                            if(questionsType === "login") return r.questionsType === "login"
                            if(questionsType === "password") return r.questionsType === "password"
                            if(questionsType === "profile-configuration") return r.questionsType === "profile-configuration"
                            if(questionsType === "housing-configuration") return r.questionsType === "housing-configuration"
                            if(questionsType === "bookings") return r.questionsType === "bookings"
                            if(questionsType === "bookings-comms") return r.questionsType === "bookings-comms"
                            if(questionsType === "cancelled") return r.questionsType === "cancelled"
                            if(questionsType === "taxes") return r.questionsType === "taxes"
                            if(questionsType === "accountabilities") return r.questionsType === "accountabilities"
                        }).map(e => (
                                <Accordion
                                    key={e.id}
                                    title={e.question}
                                    content={e.answer}
                                />
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default FAQComponentMobile;