import React from "react";
import ImageLazyLoad from "../../ui/image-lazyLoad";
import homeMobile1 from "../../../assets/static/landing-host/mobile4.webp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { loadUserData } from "../../../Helpers";

const BannerApp = ({ codeDiscount }) => {
  const userData = loadUserData();
  const router = useHistory();
  return (
    <>
      <section className="relative hidden sm:block">
        <ImageLazyLoad
          src={homeMobile1}
          alt="download app"
          className="relative object-cover w-full h-full"
        />
      </section>
      <div
        className={`z-0  bg-downloadAppMobile relative bg-center bg-cover  sm:py-[26%]  sm:hidden h-screen sm:h-full w-full bg-no-repeat   flex items-center justify-center`}
      >
        <button
          aria-label="Descargar app"
          //   onClick={
          //     userData
          //       ? () => {
          //           codeDiscount();
          //         }
          //       : () => {
          //           router.push("/login");
          //         }
          //   }
          className="absolute bottom-0 left-0 w-full h-2/3 "
        ></button>
      </div>
    </>
  );
};

export default BannerApp;
