// Librerías
import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AxiosAuth from '../../../services/requestApi';
import { BASE_URL } from '../../../Helpers'
// Componentes
import MainContainer from '../Utils/MainContainer';

const User = () => {
    const { id } = useParams();
    const [user, setUser] = useState([]);
    const [housing, setHousing] = useState([]);
    const [avatar, setAvatar] = useState('');
    const [dniFront, setDniFront] = useState('');
    const [dniBack, setDniBack] = useState('');
    const history = useHistory(); 

    useEffect(() => {
        const url = `${BASE_URL}/backoffice/users/${id}`;

        AxiosAuth.get(url).then((response)=>{
            const someone = response.data
            setUser(someone);
            setHousing(someone.housing);
            defaultAvatar(someone.avatar, someone.firstName, someone.lastName);
            setBothDNIPhotos(someone.dni_front, someone.dni_back);
          }).catch((e)=>{console.log(e)});
    }, [ id ])

    const defaultAvatar = (avatar, n, l) => {
      let _userAvatarDefault = `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${n}%20${l}`
      if(avatar !== undefined && avatar !== null){
        _userAvatarDefault = avatar.includes("https") ? avatar : BASE_URL + avatar.replace("public", ""); 
      }
      setAvatar(_userAvatarDefault);
    }

    const setBothDNIPhotos = (front, back) => {
      let _frontDefault = "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";
      let _backDefault = "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";
      const url = BASE_URL +  `/user/dni/`;

      if(front !== undefined && front !== null){
        _frontDefault = front.includes("https") ? front : url + front.replace("public", ""); 
        console.log(_frontDefault);
      }
      if(back !== undefined && back !== null){
        _backDefault = back.includes("https") ? back : url + back.replace("public", ""); 
        console.log(_backDefault);
      }
      setDniFront(_frontDefault);
      setDniBack(_backDefault);
    }

    return (
      <MainContainer title={`${user.firstName} ${user.lastName}`}>
        <section className="UserData">
          <div className="UserData__actions">
            <button className="UserData__actions__btn" onClick={ () => history.push("../users") }>
              <p className="UserData__actions__btn__text">Volver</p>
            </button>
            <Link to={`edit/${id}`} className="UserData__actions__btn">
              <p className="UserData__actions__btn__text">
                Editar
              </p>
            </Link>  
          </div>
          <div className="UserData__section">
            <h2 className="UserData__section__title">
              <img className="UserData__section__title__avatar" src={avatar} alt={`${user.firstName} ${user.lastName}`} />
              <p className="UserData__section__title__text">Datos de {`${user.firstName} ${user.lastName}`}:</p>
            </h2>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Proveedor: </p>
              <p className="UserData__dataGroup__content">{ user.provider }</p>
            </div>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">ID (Número único identificador): </p>
              <p className="UserData__dataGroup__content">{ user.id }</p>
            </div>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Email: </p>
              <p className="UserData__dataGroup__content">{(user.email) ? user.email : 'Cuenta sin email'}</p>
            </div>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Email confirmado: </p>
              <p className="UserData__dataGroup__content">{(user.emailConfirmed) ? 'Si' : 'No'}</p>
            </div>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Teléfono / Celular: </p>
              <p className="UserData__dataGroup__content">{(user.phoneNumber) ? user.phoneNumber : 'No ingresado aún'}</p>
            </div>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Teléfono / Celular confirmado: </p>
              <p className="UserData__dataGroup__content">{(user.phoneNumberConfirmed) ? 'Si' : 'No'}</p>
            </div>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">DNI: </p>
              <p className="UserData__dataGroup__content">{(user.dni) ? user.dni : 'DNI no ingresado'}</p>
            </div>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">DNI Verificado: </p>
              <p className="UserData__dataGroup__content">{(user.dniConfirmed) ? 'Si' : 'No'}</p>
            </div>
          </div>
          <div className="UserData__section">
            <h2 className="UserData__section__title">
              <p className="UserData__section__title__text" style={{margin: 0}}>Alohamientos de {`${user.firstName} ${user.lastName}`}:</p>
            </h2>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Cantidad de Alohamientos: </p>
              <p className="UserData__dataGroup__content">{ housing.length }</p>
            </div>
          {
            housing ?? housing.length > 0 ?
              housing.map(e => (
                <div className="UserData__dataGroup" key={e.id}>
                  <p className="UserData__dataGroup__title">Alohamiento #{ e.id }: </p>
                  <Link to={`/back-office/housing/${e.id}`} className="UserData__dataGroup__content">{ `${e.title} || ${e.adress}`}</Link>
                </div>
              ))
            : ''
          }
          </div>
          <div className="UserData__section">
            <h2 className="UserData__section__title">
              <p className="UserData__section__title__text" style={{margin: 0}}>Fotos del DNI de {`${user.firstName} ${user.lastName}`}:</p>
            </h2>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Frente: </p>
            </div>
            <img src={dniFront} alt=""/>
            <div className="UserData__dataGroup">
              <p className="UserData__dataGroup__title">Dorso: </p>
            </div>
            <img src={dniBack} alt=""/>
          </div>
        </section>
      </MainContainer>
    );
}

export default User;