import React from "react";

const ArrowSendIcon = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size ? size : "100%"}
      height={size ? size : "100%"}
    >
      <path
        fill={color ? color : "#000"}
        d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
      ></path>
    </svg>
  );
};

export default ArrowSendIcon;
