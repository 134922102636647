/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
// Librerías
import React, { useState }  from 'react';
import { connect, useDispatch } from 'react-redux';
import ErrorAlert from '../Alerts/ErrorAlert';
import {BASE_URL} from '../../Helpers';
import AuthService from '../../services/auth';
import { closeModal } from '../../store/modal/actions';
import axios from "axios";
// Componentes
import WelcomeModal from './WelcomeModal';
// Recursos
import '../../assets/styles/Modals/_register_modal.sass';
import facebook_login from '../../assets/static/Modals/fb-icon.svg';
import google_login from '../../assets/static/Modals/g-icon.svg';
import x from '../../assets/static/Modals/x.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {  useHistory } from 'react-router-dom';


const RegisterModal = () => {
    const eye = <FontAwesomeIcon icon={faEye} />;
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [birthday, setBirthday] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [validate, setValidate] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [errorInput, setErrorInput] = useState('');
    const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);
    const [showSendEmail, setShowSendEmail] = useState(true);
    const [showSendedEmail, setShowSendedEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const router = useHistory()

    const dispatch = useDispatch()
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    const handleGoogleSingUp = () => {
        window.location = `${BASE_URL}/auth/google/`
    }

    const handleFacebookSingUp = () => {
        window.location = `${BASE_URL}/auth/facebook/`
    }

    const isValidDate = (dateString) =>{
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!dateString.match(regEx)) return false;  // Invalid format
        var d = new Date(dateString);
        var dNum = d.getTime();
        if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
        d = new Date(dateString);
        return d.toISOString().slice(0,10) === dateString;
      }

    const ifValidate = (input) => {

        if(input === "name" || input=== undefined){
            if(name.length < 4){
                return {v:false, e:"El nombre debe ser más largo", i:'name'}
            }
        }

        if(input === "lastName" || input=== undefined){
            if(lastName.length < 2){
                return {v:false, e:"El apellido debe ser más largo", i:'lastName'}
            }
        }

        if(input === "email" || input=== undefined){
            if(email.length < 5){
                return {v:false, e:"El email debe ser válido",i:'email'}
            }
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(email.length > 5){
                if(!re.test(email)){
                    return {v:false, e:"El email es inválido", i:'email'}
                }
            }

        }

        if(input === "birthday" || input === undefined){
            if(!isValidDate(birthday)){
                return {v:false, e:"La fecha de nacimiento no es válida", i:'birthday'}
            }
        }
        
        if(input === "password" || input === undefined){
            /* 1 mayuscula, 1 minuscula, 1 digito, 8 digitos*/
            let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
            if(!re.test(password)){
                return {v:false, e:"La contraseña debe tener al menos 8 cáracteres, una mayuscula y un número", i:'password'}
            }
        }

        if(input === "password2" || input === undefined){
            if(password !== password2){
                return {v:false, e:"Las contraseña no coinciden", i:'password'}
            }
        }
        

        return {v:true, e:"", i:''}

    }


    const handleReSendEmail = () => {
        axios.post(`${BASE_URL}/auth/send_confirm_email`, {
            email: email
          })
          .then(function (response) {
            setShowSendedEmail(false)
            setShowSendEmail(false)
            
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const ifSuccess = (input) => {
        if(input === "password" || input === undefined){
            /* 1 mayuscula, 1 minuscula, 1 digito, 8 digitos*/
            let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
            if(!re.test(password)){
                return {v:true, e:"Su contraseña está correcta y es segura", i:'password'}
            }
        }
        return {v:false, e:"", i:''}

    }
    
    const handleValidate = (input) =>{
        setError('')
        const _ifValidate = ifValidate(input);
        const _ifSuccess = ifSuccess(input);
        setValidate(_ifValidate.v);
        if(!_ifValidate.v){
            setErrorInput(_ifValidate.i)
            setError(_ifValidate.e)
        }
        if(_ifSuccess.v){
            setSuccess(_ifSuccess.e) 
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const _ifValidate = ifValidate();
        setValidate(_ifValidate.v);
        if(_ifValidate.v){
            AuthService.register(name,lastName,email,password,birthday)
                .then(function (response) {
                    setShowRegisterSuccess(true)
                    // router.push("/gracias-registro-completo")
                })
                .catch(function (error) {
                    console.log(error.response)
                    if(error.response.status === 409){
                        setError("Ya existe una cuenta registrada con ese correo.")
                        setErrorInput("email")
                    }else{
                        setError("Error en la comunicación con el servidor.")
                    }
                });
        }else{
            setErrorInput(_ifValidate.i)
            setError(_ifValidate.e)
        }

    }

    const registerSuccess = <WelcomeModal handleReSendEmail={handleReSendEmail} showSendEmail={showSendEmail} name={name}/>    

    const formRegister = <form className="RegisterModal__form" onSubmit={handleSubmit}>
                <div className="RegisterModal__form__socialContainer">
                    <button className="RegisterModal__form__socialContainer__btn google" onClick={() => handleGoogleSingUp() }>
                        <img src={google_login} alt="Google" className="RegisterModal__form__socialContainer__btn__icon google" />
                        <p className="RegisterModal__form__socialContainer__btn__text">Ingresá con Google</p>
                    </button>
                    <button className="RegisterModal__form__socialContainer__btn facebook" onClick={() => handleFacebookSingUp() }>
                        <img className="RegisterModal__form__socialContainer__btn__icon" alt="Facebook" src={facebook_login} />
                        <p className="RegisterModal__form__socialContainer__btn__text">Ingresá con Facebook</p>
                    </button>
                </div>
                <div className="RegisterModal__form__separator">
                    <div className="RegisterModal__form__separator__text"> O regístrate con tu email</div>
                </div>
                {
                    (error)
                    ? 
                        <div className="RegisterModal__form__container">
                            <ErrorAlert message={error} />
                        </div>
                    : ''
                }
                {
                    (success)
                    ?
                        <div className="RegisterModal__form__container">
                            <ErrorAlert type={"success"} message={success} />
                        </div>
                    : ''
                }
                <div className="RegisterModal__form__inputContainer">
                    <div className={errorInput === 'name' ? "RegisterModal__form__inputContainer__inputWrapper error": "RegisterModal__form__inputContainer__inputWrapper"}>
                            <label className="RegisterModal__form__inputContainer__inputWrapper__label">Nombre</label>
                            <input
                                className="RegisterModal__form__inputContainer__inputWrapper__input"
                                type="text"
                                placeholder="Escribe tu nombre"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                onBlur={()=>handleValidate('name')}
                            />
                    </div>
                    <div className={errorInput === 'lastName' ? "RegisterModal__form__inputContainer__inputWrapper error": "RegisterModal__form__inputContainer__inputWrapper"}>
                            <label className="RegisterModal__form__inputContainer__inputWrapper__label">Apellido</label>
                            <input
                                className="RegisterModal__form__inputContainer__inputWrapper__input"
                                type="text"
                                placeholder="Escribe tu apellido"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                onBlur={()=>handleValidate('lastName')}
                            />
                    </div>
                </div>
                <div className="RegisterModal__form__inputContainer">
                    <div className={errorInput === 'email' ? "RegisterModal__form__inputContainer__inputWrapper error": "RegisterModal__form__inputContainer__inputWrapper"}>
                            <label className="RegisterModal__form__inputContainer__inputWrapper__label">E-mail</label>
                            <input
                                className="RegisterModal__form__inputContainer__inputWrapper__input"
                                type="email"
                                placeholder="Escribe tu e-mail"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onBlur={()=>handleValidate('email')}
                            />
                    </div>
                    <div className={errorInput === 'birthday' ? "RegisterModal__form__inputContainer__inputWrapper error": "RegisterModal__form__inputContainer__inputWrapper"}>
                            <label className="RegisterModal__form__inputContainer__inputWrapper__label">Fecha de Nacimiento</label>
                            <input
                                className="RegisterModal__form__inputContainer__inputWrapper__input"
                                type="date"
                                placeholder="Ingresa tu fecha de Nacimiento"
                                value={birthday}
                                onChange={e => setBirthday(e.target.value)}
                                onBlur={()=>handleValidate('birthday')}
                            />
                    </div>
                </div>
                <div className="RegisterModal__form__inputContainer">
                    <div className={errorInput === 'password' ? "RegisterModal__form__inputContainer__inputWrapper error": "RegisterModal__form__inputContainer__inputWrapper"}>
                            <label className="RegisterModal__form__inputContainer__inputWrapper__label">Contraseña</label>
                            <div className="RegisterModal__form__inputContainer__inputWrapper__passwordWraper">
                                <input
                                    className="RegisterModal__form__inputContainer__inputWrapper__input"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Escribe tu contraseña"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    onBlur={()=>handleValidate('password')}
                                />
                                <i style={showPassword ? {color:"#FF4E00"} : {}}  onClick={()=>{setShowPassword(!showPassword)}}>{eye}</i>
                            </div>
                    </div>
                    <div className={errorInput === 'password2' ? "RegisterModal__form__inputContainer__inputWrapper error": "RegisterModal__form__inputContainer__inputWrapper"}>
                        <label className="RegisterModal__form__inputContainer__inputWrapper__label">Repetir la contraseña</label>
                        <input
                            className="RegisterModal__form__inputContainer__inputWrapper__input"
                            name="password2"
                            type={showPassword ? "text" : "password"}
                            placeholder="Escribe tu contraseña"
                            value={password2}
                            onChange={e => setPassword2(e.target.value)}
                            onBlur={()=>handleValidate('password2')}
                        />
                    </div>
                </div>
                <p className="RegisterModal__form__container">
                    <div className="RegisterModal__form__container__text">
                        Al seleccionar registrarme a continuación, acepto terminos de servicio, los términos de pago del servicio y la política de privacidad y la política contra la descriminación de Alohar.
                    </div>
                </p>
                <div className="RegisterModal__form__buttons">
                    <button type="submit" value="Submit" className="RegisterModal__form__buttons__btn">Registrarme</button>
                </div>
            </form>
    
    return(
        <div className="RegisterModal">
            { showRegisterSuccess
                ? ''
                :
                    <header className="RegisterModal__header">
                        <p className="RegisterModal__header__title">Regístrate</p>
                        <img alt="x" src={x} className="RegisterModal__header__cross" onClick={() => dispatch(closeModal()) }/> 
                    </header>
            }
            <section className="RegisterModal__section">
                { showRegisterSuccess ? registerSuccess : formRegister }
            </section>
        </div>
    );
}

export default connect(null,{closeModal})(RegisterModal)
