// Librerías
import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AxiosAuth from '../../../services/requestApi';
import { BASE_URL } from '../../../Helpers';
// Componentes
import MainContainer from '../Utils/MainContainer';

const Previaje = ( props ) => {
    const { id } = useParams();

    const [previaje, setPreviaje] = useState(0);

    const history = useHistory();

    const getHousing = (id) => {
      const url = `${BASE_URL}/backoffice/housing/${id}`;
      AxiosAuth.get(url).then((response)=>{
        let housing = response.data;
        setPreviaje(housing.previaje);
      }).catch((e)=>{console.log(e)});
    }


    useEffect(() => {
      getHousing(id);
    },[]);

    const handleChange = (event)  => {
      const name = event.target.name;
      const value = event.target.value;

      if(name === "previaje") setPreviaje(value)
    }

    const sendData = () =>{
      const url = `/backoffice/housing/update/${id}`;
      const data = {
        previaje
      }

      AxiosAuth.patch(url,data)
        .then((response) => {
          history.push(`../${id}`);
        }).catch((error) => {
          console.log(error)
        });
    }

    const handleSubmit = () =>{
      sendData();
    }

    return (
        <MainContainer title="Previaje">
          <>
            <div className="MainContainer__form">
              <div className="MainContainer__form__group">
                <label htmlFor="previaje" className="MainContainer__form__label">Previaje</label>
                <input
                  type="number"
                  name="previaje"
                  id="previaje"
                  className="MainContainer__form__input"
                  placeholder="1 o 0"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={previaje}
                  max="1"
                  min="0"
                  step="1"
                  required
                />
              </div>
              <div>
                Previaje si = 1<br />Previaje no = 0
              </div>
              <div className="UserEditComponent__btns">
                <button className="MainContainer__form__btn__outline" onClick={ () => history.push(`../${id}`) }>
                  <p className="MainContainer__form__btn__outline__text">Volver</p>
                </button>
                <button className="MainContainer__form__btn" onClick={ handleSubmit }>Guardar</button>
              </div>
            </div>
          </>
        </MainContainer>
    )
}

export default Previaje;
