// Librerías
import React, { useState, useEffect } from "react";
import {
  faCheck,
  faStar,
  faMedal,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../../../Helpers";
// Components
import Confirmed from "./ConfirmedProfile";
import Separator from "../../Utils/Separator";
import Feature from "./Feature";
// Sass
import "../../../assets/styles/Profile/Utils/_usercard.sass";

const UserCard = (props) => {
  const user = props.user;
  const valuationsAsHost = props.valuations.guest_to_host;
  const valuationsAsGuest = props.valuations.host_to_guest;
  const valuationsAmount = valuationsAsHost?.length + valuationsAsGuest?.length;
  const [avatar, setAvatar] = useState(
    `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${user?.firstName}%20${user?.lastName}`
  );

  const setTheAvatar = (avatar) => {
    if (avatar?.includes("http")) return avatar;
    return `${BASE_URL}/user/avatar/${avatar?.replace("public", "")}`;
  };

  useEffect(() => {
    if (user.length > 0) {
      setAvatar(setTheAvatar(user.avatar));
    }
  }, [user, avatar]);

  return (
    <div className="UserCard">
      <div className="UserCard__avatar">
        <img
          className="UserCard__avatar__img"
          src={avatar}
          alt={`Perfil de ${user?.firstName} ${user?.lastName}`}
        />
      </div>
      <Feature
        title={`${
          valuationsAmount === 1
            ? `${valuationsAmount} Valuación`
            : `${valuationsAmount} Valuaciones`
        }`}
        icon={faStar}
      />
      {user.dniConfirmed ? (
        <Feature title="Identidad Confirmada" icon={faUserCheck} />
      ) : (
        ""
      )}
      {/* <Feature title="Super Alohador" icon={ faMedal } /> */}
      {user &&
      (user?.dniConfirmed ||
        user?.emailConfirmed ||
        user?.phoneNumberConfirmed) ? (
        <>
          <Separator />
          <Confirmed icon={faCheck} user={user} />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserCard;
