import React, { useEffect, useRef } from "react";
import "./button-gtm.sass";
const ButtonGTM = () => {
  const myRefname = useRef(null);

  useEffect(() => {
    buttonClick();
  }, []);

  const buttonClick = () => {
    myRefname.current.click();
  };

  return (
    <div className="buttongtm">
      <p className="buttongtm__button" ref={myRefname}>
        button-preview-payment
      </p>
    </div>
  );
};

export default ButtonGTM;
