// Librerías
import React, { useState } from 'react';
// Recursos
import '../../../assets/styles/SearchPage/Utils/_pricefilter.sass';
import caretdown from '../../../assets/static/caret-down.svg';

const PriceFilter = ({clickEventPrice}) => {
    const [state, setState] = useState('');

    const switchState = () => {
        switch (state) {
            case '':
                setState('down');
                clickEventPrice('min_price_to_max'); 
            break;
            case 'down':
                setState('up');   
                clickEventPrice('max_price_to_min'); 
            break;
            default:
                setState('down');
                clickEventPrice('min_price_to_max'); 
            break;
        }
    }

    return(
        <button
            className="PriceFilter"
            onClick={switchState}
        >
            Precio <img className={`PriceFilter__icon ${state}`} src={caretdown} alt="icon"/>
        </button>
    );
}

export default PriceFilter;