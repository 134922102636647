// Librerías
import React from 'react';
// Componentes
import Navbar from '../components/Navbar/Navbar';
import UploadDocument from '../components/Users/UploadDocument';
import Footer from '../components/Footer/Footer';

const UploadDocumentContainer = () => {
    return(
        <React.Fragment>
            <Navbar isLogged />
            <UploadDocument />
            <Footer/>
        </React.Fragment>
    );
}

export default UploadDocumentContainer;
