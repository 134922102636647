// Librerías
import React from 'react';
// Componentes
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const AppContainerNavbar = (props) => {  
  return (
    <>
      <Navbar />
        { props.children }
      <Footer />
    </>
  );
}

export default AppContainerNavbar;