import React from "react";
import ButtonPrimary from "../../ui/button-primary";
import PupOp from "../../Modals/pop-up";
import { isMobile } from "react-device-detect";
import HandleLikeIcon from "../../../assets/components-icons/HandleLike";

const PricingModalUpdate = ({
  onClick,
  title,
  secondButton,
  setCloseModal,
  closeModal,
  buttonTitle,
  success,
}) => {
  return (
    <PupOp
      close={closeModal !== false && true}
      onClose={setCloseModal}
      success={success}
    >
      <div className="flex flex-col justify-between w-11/12 h-full max-w-screen-sm gap-8 px-4 py-4 mx-auto ms:px-8 ms:py-8 h-aut sm:h-full">
        <div className="flex flex-col items-center gap-4 mt-8">
          {success && (
            <>
              <div className="mx-auto">
                <HandleLikeIcon size={50} color={"#fff"} />
              </div>
              <h2 className="text-6xl ms:text-9xl castaBold">¡Excelente!</h2>
            </>
          )}
          <h2 className="mt-4 text-lg text-center ">{title}</h2>
        </div>
        <div className="flex justify-between gap-4 mx-auto ms:gap-12 ms:mx-0 ms:justify-center">
          <ButtonPrimary
            width={isMobile && 100}
            title={buttonTitle}
            secondaryColor={true}
            onClick={() => setCloseModal(false)}
          />
          {secondButton && (
            <ButtonPrimary
              width={isMobile && 100}
              title={secondButton}
              onClick={() => {
                onClick();
              }}
            />
          )}
        </div>
      </div>
    </PupOp>
  );
};

export default PricingModalUpdate;
