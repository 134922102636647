// Librerías
import React from 'react';
// Recursos
import '../../assets/styles/Utils/_smallProgressBar.sass';

const SmallProgressBar = ({ progressBarWidth }) => {
    const progressBar = ( progressBarWidth ) => {
        let progress = progressBarWidth;
        return ({
            width: `${progress}%`,
        })
    }
    return(
        <div className="SmallProgressBar">
            <div className="SmallProgressBar__title">
                {progressBarWidth}%
            </div>
            <div className="SmallProgressBar__bar">
                <span className="SmallProgressBar__bar__progress" style={progressBar(progressBarWidth)}></span>
            </div>
        </div>
    );
}

export default SmallProgressBar;