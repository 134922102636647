import React from "react";
import CrossIcon from "../../../assets/components-icons/CrossIcon";

const ContainerModal = ({ children, setOpenModal, openModal }) => {
  return (
    <div
      className="flex flex-col 
    relative
    py-11
    items-center
    justify-between
    gap-8
   bg-white  max-w-[650px] max-h-[400px] w-11/12 rounded-14 "
    >
      <span
        className="absolute cursor-pointer top-4 right-4"
        onClick={() => {
          setOpenModal(!openModal);
        }}
      >
        <CrossIcon />
      </span>
      {children}
    </div>
  );
};

export default ContainerModal;
