const inistalState = {
  city: "",
  cityHome: "",
  cityName: "",
  checkin: "",
  checkout: "",
  adults: 1,
  childs: 0,
  url: "",
  neighborhood: false,
  triggerSearch: 0,
  result: [],
};

export default (state = inistalState, action) => {
  switch (action.type) {
    case "TRIGGER_SEARCH":
      return {
        ...state,
        triggerSearch: state.triggerSearch + 1,
      };
    case "SET_URL":
      return {
        ...state,
        url: action.url,
      };
    case "SET_RESULT":
      return {
        ...state,
        result: action.result,
      };
    case "SET_SEARCH":
      return {
        ...state,
        city: action.city,
        checkin: action.checkin,
        checkout: action.checkout,
        adults: action.adults,
        childs: action.childs,
      };
    case "SET_CITY":
      return {
        ...state,
        city: action.city,
        cityName: action.cityName,
        neighborhood: action.neighborhood,
      };
    case "SET_CITY_HOME":
      return {
        ...state,
        city: action.cityHome,
        cityName: action.cityName,
      };

    case "SET_CHECKIN":
      return {
        ...state,
        checkin: action.checkin,
      };

    case "SET_CHECKOUT":
      return {
        ...state,
        checkout: action.checkout,
      };

    case "SET_ADULT":
      return {
        ...state,
        adults: action.adults,
      };

    case "SET_CHILD":
      return {
        ...state,
        childs: action.childs,
      };

    default:
      return state;
  }
};
