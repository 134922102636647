// Librerías
import React, {useState, useEffect} from 'react';
// Componentes
import HostValuation from './HostValuation';
import GuestValuation from './GuestValuation';
import axios from 'axios';
import moment from 'moment';
// Recursos
import '../../assets/styles/Valuation/__valuation.sass';
import { useParams} from "react-router-dom";
import {BASE_URL} from '../../Helpers'


const ValuationComponent = () => {
  const { type, token } = useParams();
  const url_template = `${BASE_URL}/valuations/${type}/template`
  const url_detail = `${BASE_URL}/valuations/${token}`
  const [template, setTemplate] = useState(false)
  const [detail, setDetail] = useState(false)
  
  const configAvatar = (img, firstName) =>{
    const userAvatarDefault = `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${firstName}`;
    return img && img.includes("http") 
    ? img
    : img === null ? userAvatarDefault :`${BASE_URL}/user/avatar/${img}`
  }
  

  useEffect(()=>{
    if(!template){
      axios.get(url_template).then((response)=>{
        setTemplate(response.data)
      }).catch((e)=>{
        console.log(e)
      })
    }

    if(!detail){
        axios.get(url_detail).then((response)=>{
          const data = response.data
          const housingUrl = `${BASE_URL}/property/${data.housing.id}`
          let evaluationDetail
          const checkin = moment(data.checkin).format('DD/MM/YYYY')
          const checkout = moment(data.checkout).format('DD/MM/YYYY')
          if(type === "hosts"){
            evaluationDetail = {
              name: data.userGuest.firstName,
              img: configAvatar(data.userGuest.avatar,data.userGuest.firstName),
              startDate: checkin,
              endDate: checkout,
              url: housingUrl,
              title: data.housing.title,
            }}

          if(type === "guests"){
            evaluationDetail = {
              name: data.userHost.firstName,
              img: configAvatar(data.userHost.avatar,data.userHost.firstName),
              startDate: checkin,
              endDate: checkout,
              url: housingUrl,
              title: data.housing.title,
            }}

          setDetail(evaluationDetail)
        }).catch((e)=>{
          console.log(e)
        })
    }

  },[template,detail])

  return(
    <div className="Valuation">
      {
        type === "hosts" ?
        <GuestValuation
        name={detail.name}
        img={detail.img}
        startDate={detail.startDate}
        endDate={detail.endDate}
        template={template}
      />
        : ''
      }

      {
        type === "guests" ?
        <HostValuation
        name={detail.name}
        img={detail.img}
        startDate={detail.startDate}
        endDate={detail.endDate}
        url={detail.url}
        title={detail.title}
        template={template}
        />  : ''
      }

      <div className="Valuation__right">
        <p className="Valuation__tagline">
          Esperamos que<br />hayas disfrutado
        </p>
      </div>
    </div>
  );
}

export default ValuationComponent;