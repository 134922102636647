// Librerías
import React from 'react';
// Componentes
import Cancel from '../../../components/BackOffice/Reservations/Cancel';

const CancelContainer = () => {
  return(
    <Cancel />
  );
}

export default CancelContainer;