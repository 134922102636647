// Librerías
import React from 'react';
// Componentes
import FAQC from '../components/FAQ/FAQComponent';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

const FAQ = () => {
  return(
    <div>
        <Navbar addSearch={false} hideAlohadorButtons={true} hideSearchButtons={true}/>
        <FAQC />
        <Footer />
    </div>
  );
}

export default FAQ;