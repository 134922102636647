import React from "react";
import hand from "../../../assets/static/hand like.svg";
import close from "../../../assets/static/Icons/close.svg";

const ModalSuccessStyle = ({ closeModal, setCloseModal,children }) => {
  return (
    <div className={closeModal ? "modalActive" : "modalNone"}>
      <div className="modalActive__modal">
        <div className="modalActive__modal__container">
          <button
            className="modalActive__modal__container__close"
            onClick={setCloseModal}
          >
            <img
              alt="icon"
              className="modalActive__modal__container__close__img"
              src={close}
            />
          </button>
          <img
            alt="icon"
            className="modalActive__modal__container__close__hand"
            src={hand}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalSuccessStyle;
