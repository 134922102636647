import React from "react";
import ArrowPagination from "../../../assets/components-icons/ArrowPagination";

const PaginationBackOffice = ({ setNextPage, nextPage }) => {
  return (
    <div className="flex items-center justify-end w-full gap-2 py-2">
      <button
        className="flex border-stone-50"
        disabled={nextPage.page === 1}
        onClick={() =>
          setNextPage({
            page: 1,
            perPage: 10,
          })
        }
      >
        <ArrowPagination
          size={15}
          color={nextPage.page === 1 && "#ccc"}
          className={"rotate-180"}
        />
        <ArrowPagination
          size={15}
          color={nextPage.page === 1 && "#ccc"}
          className={"rotate-180"}
        />
      </button>
      <button
        className="border-stone-50"
        disabled={nextPage.page === 1}
        onClick={() =>
          setNextPage({
            page: nextPage.page - 1,
            perPage: 10,
          })
        }
      >
        <ArrowPagination
          color={nextPage.page === 1 && "#ccc"}
          className={"rotate-180"}
        />
      </button>
      pagina - {nextPage.page}
      <button
        onClick={() =>
          setNextPage({
            page: nextPage.page + 1,
            perPage: 10,
          })
        }
      >
        <ArrowPagination className={"rotate-0"} />
      </button>
    </div>
  );
};

export default PaginationBackOffice;
