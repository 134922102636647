import React from "react";
import "./card-detail-user-valuations.sass";
import Valuations from "../../../Utils/Valuations";
import UserAvatar from "../../../ui/userAvatar";
import { useEffect } from "react";
import AxiosAuth from "../../../../services/requestApi";
import { BASE_URL } from "../../../../Helpers";
import { useState } from "react";
import "./card-detail-user-valuations.sass";
import ValuationComments from "../valuation-comments";
import { Link } from "react-router-dom/cjs/react-router-dom";
const CardDetailUserValuations = ({ userData }) => {
  const [valuations, setValuations] = useState(false);
  useEffect(() => {
    const url = `${BASE_URL}/users/${userData.id}/valuations`;
    AxiosAuth.get(url).then((response) => {
      setValuations(response);
    });
  }, [userData]);
  return (
    <div className="cardDetailUserValuations">
      <Link to={`/users/show/${userData.id}`}>
        <UserAvatar size={50} userData={userData} />
      </Link>
      {valuations && (
        <Valuations
          response={valuations}
          userId={userData.id}
          size={"12px!important"}
        />
      )}
      {valuations && (
        <ValuationComments response={valuations} userId={userData.id} />
      )}
    </div>
  );
};

export default CardDetailUserValuations;
