import React from "react";

const EyeIcon = ({ size, color }) => {
  return (
    <svg
      width={size ? size : "100%"}
      height={size ? size : "100%"}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1660_3994)">
        <path
          d="M1.2041 12C1.2041 12 5.47017 4 12.9358 4C20.4014 4 24.6675 12 24.6675 12C24.6675 12 20.4014 20 12.9358 20C5.47017 20 1.2041 12 1.2041 12Z"
          stroke="#2B2B2B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9358 15C14.7028 15 16.1353 13.6569 16.1353 12C16.1353 10.3431 14.7028 9 12.9358 9C11.1687 9 9.73621 10.3431 9.73621 12C9.73621 13.6569 11.1687 15 12.9358 15Z"
          stroke="#2B2B2B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1660_3994">
          <rect
            width="25.5964"
            height="24"
            fill="white"
            transform="translate(0.137573)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
