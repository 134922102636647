import React, { useState } from "react";
import { useEffect } from "react";
import CrossIcon from "../../../assets/components-icons/CrossIcon";
import "./notification-housing.sass";
const NotificationHousing = ({ titleHousing, id, housingListPending }) => {
  const [close, setClose] = useState(true);
  const [housingFilter, setHousingFilter] = useState([]);
  const [key, setKey] = useState()
  useEffect(() => {
    const housingList = housingListPending.filter((e) => e.housing.id === id);
    setHousingFilter(housingList);
    setKey(id)
  }, []);
  return (
    <div
      key={key}
      className={close ? "notificationHousing" : "notificationHousingNone"}
    >
      <a
        className="notificationHousing__container"
        href={`housing/reservations/${id}`}
      >
        <p className="notificationHousing__container__text">
          {housingFilter.length > 1
            ? `Tienes ${housingFilter.length} reservas pendientes `
            : `Tienes ${housingFilter.length} reserva pendiente `}
        </p>
        <p className="notificationHousing__container__text">{titleHousing}</p>
        <p className="notificationHousing__container__text">Click aqui!</p>
      </a>
      <div
        className={"notificationHousing__close"}
        onClick={() => setClose(false)}
      >
        <CrossIcon color={"#9CA3AF"} />
      </div>
    </div>
  );
};

export default NotificationHousing;
