// Librerías
import React, {useState, useEffect} from 'react';
import { ScreenClassProvider,Container, Row, Col } from 'react-grid-system';
import {connect} from 'react-redux';
// Componentes
import Navbar from '../components/Navbar/Navbar';
import TravelList from '../components/UserTravelsPage/TravelList';
import AxiosAuth from '../services/requestApi'
import EmptyState from '../components/EmptyState/EmptyState';
// Assets
import '../assets/styles/UserTravelsPage/_user_travel_page.sass';
import loadingGif from '../assets/static/loading.gif';

const UserProfilePage = () => {
    const [reservations, setReservations] = useState([])
    const [init, setInit] = useState(false)
    const [loading, setLoading] = useState(true)

    const url = '/user/reservations/'

    useEffect(()=>{
        if(!init){
            AxiosAuth.get(url)
                .then((response)=>{
                    setReservations(response.data)
                    setLoading(false)
                }).catch((e)=>{
                    console.log(e)
                    setLoading(false)
                })
            setInit(true)
        }

    }, [init])
    return(
        <ScreenClassProvider>
            <div className="user_travel_page">
                <Navbar />
                <main>
                    <Container fluid >
                        <Row>
                            <Col md={12} sm={12} xs={12} className="principal_col">
                                <div className="my">Mis Viajes</div>
                                {
                                    loading ? <img alt="Cargando..." style={{margin:"auto"}} src={loadingGif}/>
                                                :
                                                reservations.length === 0 ?
                                                    <EmptyState message="Por el momento no tenes viajes programados ni hechos." />
                                                    :
                                                    <TravelList reservations={reservations} />
                                }
                            </Col>
                        </Row>
                    </Container>
                </main>
            </div>
        </ScreenClassProvider>
    );
}

export default connect()(UserProfilePage);
