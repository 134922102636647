const INITIAL_STATE = {
  // cambiaria step por current_step
  step: 1,
  housingId: null,
  validate: false,
  count_validate: 0,
  steps: {
    1: {
      endopoit: null,
      status: null,
      bodyParameters: null,
    },
    2: {
      //USER_STEP
      endopoit: "/user/phone",
      status: null,
      bodyParameters: {
        prefixPhoneNumber: null,
        phoneNumber: null,
      },
    },
    3: {
      //TYPE STEP
      endopoit: "/housing/public/2",
      status: null,
      bodyParameters: {
        typeHousingProperty: null,
        typeHousingSpace: null,
      },
    },
    4: {
      //STEP QUANTITIES
      endopoit: "/housing/public/3",
      status: null,
      bodyParameters: {
        guests: null,
        double_beds: null,
        simple_beds: null,
        rooms: null,
        bathrooms: null,
      },
    },
    5: {
      //STEP LOCATION
      endopoit: "/housing/public/4",
      status: null,
      bodyParameters: {
        adress: null,
        adressExtra: null,
        lat: null,
        lng: null,
        center: null,
        zoom: null,
        locationId: null,
        floor: null,
        department: null,
        TAD: null,
        neighborhood: null,
        province: null,
        countryId: null,
      },
    },
    6: {
      //STEP SERVICES
      endopoit: "/housing/public/5",
      status: null,
      bodyParameters: {
        typeHousingRoom: null,
        typeHousingService: null,
        extraHousingService: null,
        extraHousingRoom: null,
      },
    },
    7: {
      //STEP COVID
      endopoit: "/housing/public/5b",
      status: null,
      bodyParameters: {
        typeHousingSanitary: null,
        typeHousingSecurity: null,
      },
    },
    8: {
      //STEP IMAGES
      endopoit: null,
      status: null,
      bodyParameters: {
        files: [],
        displayDropzone: true,
        displayGallery: false,
      },
    },
    9: {
      //STEP RULES
      endopoit: "/housing/public/8",
      status: null,
      bodyParameters: {
        rule: null,
        ruleExtra: null,
        cancelation: null,
      },
    },
    10: {
      //STEP GUEST
      endopoit: "/housing/public/6",
      status: null,
      bodyParameters: {
        minNight: null,
        maxNight: null,
        timeAdvanceBook: null,
        startCheckInTime: null,
        endCheckInTime: null,
        checkOutTime: null,
        typeHousingReservation: null,
      },
    },
    11: {
      //TITLE STEP
      // Aca se crea el housing en el servidor
      // Por lo tanto tenes un ID
      endopoit: "/housing/public/1",
      status: null,
      bodyParameters: {
        title: null,
        description: null,
      },
    },
    12: {
      //STEP PRICE
      endopoit: "/housing/public/price",
      status: null,
      bodyParameters: {
        coin: null,
        basePrice: 0,
        cleaningPrice: 0,
        weeklyDiscount: 0,
        monthlyDiscount: 0,
      },
    },
    13: {
      //STEP PAYMENT
      endopoit: "/payment",
      status: null,
      bodyParameters: {
        cbu: null,
        alias: null,
        bank: null,
        account: null,
        headline: null,
        cuit: null,
        typeBankAccount: null,
      },
    },
    14: {
      //STEP LEGAL
      endopoit: "/housing/tos",
      status: null,
      bodyParameters: {
        tos: null,
      },
    },
    15: {
      //STEP CALENDAR
      endopoit: "/housing/public/calendar",
      status: null,
      bodyParameters: {
        calendar: [],
      },
    },
    16: {
      //STEP FINISH
      endopoit: "/housing/public",
      status: null,
      bodyParameters: {
        id: null,
      },
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  let step = state.step;

  if (
    state !== INITIAL_STATE &&
    action.type !== "RESTART" &&
    localStorage.getItem("userData") !== null
  )
    localStorage.setItem("housingPublicProcessData", JSON.stringify(state));
  if (action.type === "RESTART")
    localStorage.removeItem("housingPublicProcessData");

  switch (action.type) {
    case "RESTART": {
      INITIAL_STATE.steps[8].bodyParameters.files = [];
      INITIAL_STATE.steps[15].bodyParameters.calendar = [];

      return INITIAL_STATE;
    }
    case "COUNT_VALIDATE":
      return {
        ...state,
        count_validate: state.count_validate + 1,
      };
    case "NEXT_STEP":
      if (step <= 16) {
        step = step + 1;
      }
      /* Saltar Calendario*/
      // if(step === 15) step = 16
      return {
        ...state,
        step: step,
      };
    case "PREVIOUS_STEP":
      if (step >= 2) {
        step = step - 1;
      }
      return {
        ...state,
        step: step,
      };

    case "SET_STEP":
      return {
        ...state,
        step: action.step,
      };

    case "VALIDATE_STEP":
      return {
        ...state,
        validate: action.validate,
      };

    case "USER_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          2: {
            ...state.steps[2],
            bodyParameters: {
              prefixPhoneNumber: action.prefixPhoneNumber,
              phoneNumber: action.phoneNumber,
            },
          },
        },
      };

    case "TYPE_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          3: {
            ...state.steps[3],
            bodyParameters: {
              typeHousingProperty: action.typeHousingProperty,
              typeHousingSpace: action.typeHousingSpace,
            },
          },
        },
      };

    case "QUANTITIES_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          4: {
            ...state.steps[4],
            bodyParameters: {
              guests: action.guests,
              simple_beds: action.simple_beds,
              double_beds: action.double_beds,
              rooms: action.rooms,
              bathrooms: action.bathrooms,
            },
          },
        },
      };

    case "LOCATION_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          5: {
            ...state.steps[5],
            bodyParameters: {
              adress: action.adress,
              adressExtra: action.adressExtra,
              lat: action.lat,
              lng: action.lng,
              center: action.center,
              zoom: action.zoom,
              locationId: action.locationId,
              department: action.department,
              floor: action.floor,
              TAD: action.TAD,
              province: action.province,
              neighborhood: action.neighborhood,
              countryId: action.countryId ? action.countryId : 0,
            },
          },
        },
      };

    case "SERVICES_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          6: {
            ...state.steps[6],
            bodyParameters: {
              typeHousingRoom: action.typeHousingRoom,
              typeHousingService: action.typeHousingService,
              extraHousingService: action.extraHousingService,
              extraHousingRoom: action.extraHousingRoom,
            },
          },
        },
      };

    case "CODVID_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          7: {
            ...state.steps[7],
            bodyParameters: {
              typeHousingSanitary: action.typeHousingSanitary,
              typeHousingSecurity: action.typeHousingSecurity,
            },
          },
        },
      };

    case "PHOTOS_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          8: {
            ...state.steps[8],
            bodyParameters: {
              files: action.files,
              displayDropzone: action.displayDropzone,
              displayGallery: action.displayGallery,
            },
          },
        },
      };

    case "RULE_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          9: {
            ...state.steps[9],
            bodyParameters: {
              rule: action.rule,
              ruleExtra: action.ruleExtra,
              cancelation: action.cancelation,
            },
          },
        },
      };

    case "GUEST_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          10: {
            ...state.steps[10],
            bodyParameters: {
              minNight: action.minNight,
              maxNight: action.maxNight,
              timeAdvanceBook: action.timeAdvanceBook,
              startCheckInTime: action.startCheckInTime,
              endCheckInTime: action.endCheckInTime,
              checkOutTime: action.checkOutTime,
              typeHousingReservation: action.typeHousingReservation,
            },
          },
        },
      };

    case "TITLE_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          11: {
            ...state.steps[11],
            bodyParameters: {
              title: action.title,
              description: action.description,
            },
          },
        },
      };

    case "PRICE_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          12: {
            ...state.steps[12],
            bodyParameters: {
              coin: action.coin,
              basePrice: action.basePrice,
              cleaningPrice: action.cleaningPrice,
              weeklyDiscount: action.weeklyDiscount,
              monthlyDiscount: action.monthlyDiscount,
            },
          },
        },
      };

    case "PAYMENT_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          13: {
            ...state.steps[13],
            bodyParameters: {
              cbu: action.cbu,
              housingId: action.housingId,
              alias: action.alias,
              bank: action.bank,
              account: action.account,
              headline: action.headline,
              cuit: action.cuit,
              typeBankAccount: action.typeBankAccount,
            },
          },
        },
      };

    case "LEGAL_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          14: {
            ...state.steps[14],
            bodyParameters: {
              tos: action.tos,
            },
          },
        },
      };

    case "CALENDAR_STEP_UPDATE":
      return {
        ...state,
        steps: {
          ...state.steps,
          15: {
            ...state.steps[15],
            bodyParameters: {
              calendar: action.calendar,
            },
          },
        },
      };

    case "STEP_SUCCESS_SEND":
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.step]: {
            ...state.steps[action.step],
            status: action.status,
          },
        },
      };

    case "HOUSING_ID_UPDATE":
      return {
        ...state,
        housingId: action.housingId,
      };

    default:
      return state;
  }
};
