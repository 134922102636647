import React from "react";

const ArrowSmall = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      width={size ? size : "100%"}
      height={size ? size : "100%"}
      id="Capa_2"
      data-name="Capa 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92 58.33"
    >
      <g id="Layer_2" data-name="Layer 2">
        <path
          fill={color ? color : "#F84D10"}
          d="m0,29.16c0-2.6,1.29-4.89,3.27-6.29L23.89,2.25c3-3,7.88-3,10.88,0,3,3,3,7.88,0,10.88l-8.33,8.33h57.87c4.25,0,7.69,3.45,7.69,7.69s-3.44,7.69-7.69,7.69H26.44s8.33,8.33,8.33,8.33c3,3,3,7.88,0,10.88h0c-3,3-7.88,3-10.88,0L3.27,35.45C1.29,34.06,0,31.76,0,29.16Z"
        />
      </g>
    </svg>
  );
};

export default ArrowSmall;
