// Librerías
import React from 'react';
import { useParams } from "react-router-dom";
// Componentes
import AloharFormEdit from '../components/AloharForm/AloharFormEdit';
import Navbar from '../components/Navbar/Navbar';


const EditAloharPage = () => {
  const { editStep, id } = useParams();

  return(
    <div className="AloharForm">
      <Navbar />
      <AloharFormEdit editStep={editStep} id={id}/>
    </div>
  );
}

export default EditAloharPage;