// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
// Componentes
import Buttons from '../Utils/Buttons';
import ButtonEdit from '../Utils/ButtonEdit';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_legal.sass';
import Pdf from '../../../assets/static/legal.pdf';
import download from '../../../assets/static/Icons/download.svg';
import ProgressBar from '../Utils/ProgressBar';

class Legal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate
    }
  }

  

  render(){
    this.props.validateStep(true);

    return(
      <React.Fragment>
        <div className='legalResponsive'>
          <div className="Legal">
          {this.state.modeEdit ? '' : <ProgressBar title={"TÉRMINOS Y CONDICIONES"}/>}

            
            <h1 className="Legal__title">
              Términos y condiciones del servicio
            </h1>
            <h3 className="Legal__subtitle">
              Lea detenidamente estos Términos y Condiciones de Servicio, ya que contienen información importante acerca de sus derechos legales, recursos y obligaciones. Al acceder, navegar o utilizar la Plataforma Alohar, usted acepta cumplir y someterse a estos Términos y Condiciones.
            </h3>
            <p className="Legal__title">
            1. ALCANCE.
            </p>
            <p className="Legal__description">
            1.1 Generalidades. Los presentes Términos y Condiciones de Servicio constituyen un contrato legalmente vinculante entre los usuarios y BUCHU S.A., que rige su acceso y utilización del sitio web de BUCHU S.A., incluido cualquier subdominio del mismo, así como de cualquier otro sitio web a través del cual BUCHU S.A. ponga a disposición sus servicios.  La utilización de LA PLATAFORMA de BUCHU S.A. por parte de un usuario indica la aceptación absoluta de los presentes Términos y Condiciones y de la Política de Privacidad. El acceso a los servicios de BUCHU S.A. implica que el usuario declara ser mayor de 18 años, y que es persona capaz para entender y aceptar lo establecido en estos Términos y Condiciones, reservándose BUCHU S.A. la posibilidad de excluirlo de LA PLATAFORMA si así lo considerase oportuno y conveniente. Los usuarios pueden acceder a través del sitio web que BUCHU S.A. ponga a disposición, a sus servicios y aplicaciones por medio de celular, tabletas electrónicas y demás dispositivos inteligentes, así como interfaces de programa de aplicaciones. En adelante, el servicio prestado por BUCHU S.A. se denominará ALOHAR y será denominado “PLATAFORMA ALOHAR” o simplemente “LA PLATAFORMA”.
            </p>
            <br/>
            <p className="Legal__description">
            1.2 Administrador de LA PLATAFORMA. BUCHU S.A. es el administrador de LA PLATAFORMA y su titular y responsable legal. BUCHU es una Sociedad Anónima, identificada con la C.U.I.T. 30-71686126-7 con domicilio Legal en la calle, Córdoba 1957 piso 8º, departamento “D” de la ciudad de Mar del Plata, Provincia de Buenos Aires, República Argentina.
            </p>
            <br/>
            <a href={Pdf} target="_blank" rel="noopener noreferrer">
              <img alt='descargar pdf' src={download} />
               Descargar Términos y Condiciones de servicios</a>
            <div className='Legal__buttons'>
              { this.state.modeEdit ? <ButtonEdit /> : <Buttons /> }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer
};


export default connect(mapStateToProps ,aloharFormActions)(Legal);