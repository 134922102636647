// Librerías
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { restart } from '../store/aloharForm/actions';
// Componentes
import AloharForm from '../components/AloharForm/AloharForm';
import Navbar from '../components/Navbar/Navbar';
// Recursos
import '../assets/styles/AloharForm/__aloharform.sass'
import AloharFormOnlyApp from '../components/AloharForm/AloharFormOnlyApp';
import LocalStorageService from '../services/localStorageService';

// Función para obtener los parámetros de la URL
const getParamsFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    token: urlParams.get('token'),
    refreshToken: urlParams.get('refreshToken'),
    app: urlParams.get('app')
  };
};

const AloharCreateApp = () => {
  const { token, refreshToken, app } = getParamsFromUrl();
  useEffect(() => {
    if (token) {
      LocalStorageService.setToken(token);
    }
    if (refreshToken) {
      LocalStorageService.setRefreshToken(refreshToken);
    }
    if (app !== 'true') {
      // Redireccionar o mostrar un mensaje de error, por ejemplo:
      // window.location.href = '/error'; // Redireccionar a una página de error
      // alert('La URL no tiene el parámetro app=true'); // Mostrar mensaje de error
    }
  }, []); 

  if (app !== 'true') {
    return null; // No renderizar el componente si app no es 'true'
  }

  return (
    <div className="AloharForm">
      <AloharFormOnlyApp />
    </div>
  );
}

export default connect(null, { restart })(AloharCreateApp);
