// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
// Componentes
import Buttons from '../Utils/Buttons';
import ErrorMessage from '../../Alerts/ErrorAlert';
import Checkbox from '../Utils/Checkbox';
import ProgressBar from '../Utils/ProgressBar';
import ButtonEdit from '../Utils/ButtonEdit';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_services.sass';

const typeHousingService = [
  {
    value:"basic",
    title:"Elementos Básicos ( Toallas, Sábanas, Jabón, Papel higiénico, etc. )"
  },
  {
    value:"wifi",
    title:"Wifi"
  },
  {
    value:"tv",
    title:"TV"
  },
  {
    value:"heating",
    title:"Calefacción"
  },
  {
    value:"air_conditioning",
    title:"Aire Acondicionado"
  },
  {
    value:"griddle",
    title:"Plancha"
  },
  {
    value:"hair_dryer",
    title:"Secador de Pelo"
  },
  {
    value:"electric_oven",
    title:"Horno Eléctrico"
  },
  {
    value:"washing_machine",
    title:"Lavarropas"
  }

];

const typeHousingRoom = [
  {
    value:"quincho",
    title:"Quincho"
  },
  {
    value:"laundry",
    title:"Lavadora"
  },
  {
    value:"dryer",
    title:"Secadora"
  },
  {
    value:"cochera",
    title:"Cochera"
  },
  {
    value:"gym",
    title:"Gimnasio"
  },
  {
    value:"pool",
    title:"Pileta"
  },
  {
    value:"jacuzzi",
    title:"Jacuzzi"
  },
];

const typeHousingSanitary = [
  {
    value: "thermometer",
    title: "Termómetro"
  },
  {
    value: "alcohol",
    title: "Alcohol en Gel"
  },
  {
    value: "soap",
    title: "Jabon antibacterial"
  },
  {
    value: "deep_cleaning",
    title: "Unidad entregada con limpieza profunda"
  },
  {
    value: "mosquito_repellent",
    title: "Repelentes de mosquitos"
  },
  {
    value: "diffuser_antimosquito",
    title: "Difusor y pastillas anti mosquitos"
  },
];

const typeHousingSecurity = [
  {
    value: "smoke_detector",
    title: "Detector de humo"
  },
  {
    value: "co_detector",
    title: "Detector de mónoxido de carbono"
  },
  {
    value: "first_aid_kit",
    title: "Botiquín de primeros auxilios"
  },
  {
    value: "fire_extinguisher",
    title: "Extintor de fuego"
  },
  {
    value: "lock_bedroom",
    title: "Cerradura con llave en la puerta del dormitorio"
  }
];

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeHousingRoom: this.props.bodyParameters.typeHousingRoom || [],
      typeHousingService: this.props.bodyParameters.typeHousingService || [],
      extraHousingService: this.props.bodyParameters.extraHousingService || '',
      extraHousingRoom: this.props.bodyParameters.extraHousingRoom || '',
      typeHousingSanitary: this.props.bodyParameters.typeHousingSanitary || [],
      typeHousingSecurity: this.props.bodyParameters.typeHousingSecurity || [],
      errors : {},
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate
    };

    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.handleValidate({showError:false});
    this.props.servicesStep({
      typeHousingRoom: this.state.typeHousingRoom,
      typeHousingService: this.state.typeHousingService,
      extraHousingService: this.state.extraHousingService,
      extraHousingRoom: this.state.extraHousingRoom,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.count_validate !== prevProps.count_validate && !isNaN(this.props.count_validate)){
      this.handleValidate({showError:true});
    }
  }

  handleValidate = ({inputName,showError}) =>{
    let errors = {};
    const messengers = {
      typeHousingRoom: "Debe elegir al menos un tipo de habitación",
      typeHousingService: "Debe elegir al menos un tipo de servicio",
    };
    const validate = (
        this.state.typeHousingService.length > 0
      )

      if(inputName){
        // if(inputName === "typeHousingRoom" && this.state.typeHousingRoom.length === 0)
        //   errors['typeHousingRoom'] = messengers["typeHousingRoom"]
        if(inputName === "typeHousingService" && this.state.typeHousingService.length === 0)
          errors['typeHousingService'] = messengers["typeHousingService"]

      }else{
        // if(this.state.typeHousingRoom.length === 0)
        //   errors['typeHousingRoom'] = messengers["typeHousingRoom"]
        if(this.state.typeHousingService.length === 0)
          errors['typeHousingService'] = messengers["typeHousingService"]
      }

    if(showError){
      this.setState({errors:errors})
    }

    this.props.validateStep(validate);
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if(name === "typeHousingService" || name === "typeHousingRoom"){
      let checked = event.target.checked;
      const options = this.state[name]
      let index
      if(checked){
        options.push(value)
      }else{
        index = options.indexOf(value)
        options.splice(index, 1)
      }

      this.setState({ [name]: options },()=>{
        this.props.servicesStep(this.state)
        this.handleValidate({inputName:name, showError:true});
      } )
    }else{
      this.setState({ [name]: value },()=>{
        this.props.servicesStep(this.state)
        this.handleValidate({inputName:name, showError:true});
      } )
    }

  }

  render(){
    return(
      <React.Fragment>
        <div className='ServicesResponsive'>
          <div className="Services">
          {this.state.modeEdit ? '' : <ProgressBar title={"SERVICIOS"} stepAt={'Paso 1: Lo esencial'} />}
            <div className="Services__container">
              <div className="Services__container-left">
                <h2 className="Services__container__title">
                  ¿Qué servicios ofreces?
                </h2>
                <p className="Services__container__descriptionLeft">
                  Marca los servicios que los huéspedes pueden disfrutar en tu espacio.
                </p>
               
                {typeHousingService.map((service,index) => (
                  <Checkbox
                    checked={this.state.typeHousingService.indexOf(service.value) !== -1}
                    id={"typeHousingService_"+index}
                    key={"typeHousingService_"+index}
                    value={service.value}
                    name="typeHousingService"
                    title={service.title}
                    customClickEvent={this.handleChange.bind(this)} />
                ),this)}
                <p className="Services__container__title second">
                  Servicio adicional
                </p>
                <div className="Services__container__inputContainer">
                  <textarea
                    className="Services__container__inputContainer__textarea"
                    name="extraHousingService"
                    id="extraHousingService"
                    placeholder="Completa los adicionales"
                    value={this.state.extraHousingService}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    >
                  </textarea>
                </div>
                {
                  this.state.errors["typeHousingRoom"]
                  ? <ErrorMessage message={this.state.errors["typeHousingRoom"]} />
                  : ''
                }
                 {
                  this.state.errors["typeHousingService"]
                  ? <ErrorMessage message={this.state.errors["typeHousingService"]} />
                  : ''
                }
              </div>
              <div className="Services__container-right">
                <h2 className="Services__container__title">
                  ¿Qué espacios pueden utilizar los huéspedes?
                </h2>
                <p className="Services__container__descriptionRigth">
                Incluye las zonas comunes, pero no agregues espacios que no se encuentren en tu propiedad.
                </p>
                
                {typeHousingRoom.map((service,index) => (
                  <Checkbox
                    checked={this.state.typeHousingRoom.indexOf(service.value) !== -1}
                    id={"typeHousingRoom_"+index}
                    key={"typeHousingRoom_"+index}
                    value={service.value}
                    name="typeHousingRoom"
                    title={service.title}
                    customClickEvent={this.handleChange.bind(this)} />
                  ),this)}
                <p className="Services__container__title second">
                  Espacio adicional
                </p>
                <div className="Services__container__inputContainer">
                  <textarea
                    className="Services__container__inputContainer__textarea"
                    name="extraHousingRoom"
                    id="extraHousingRoom"
                    placeholder="Completa los adicionales"
                    value={this.state.extraHousingRoom}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    >
                  </textarea>
                </div>
                
              </div>
            </div>
            {this.state.modeEdit ? <ButtonEdit /> : <Buttons />}
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer
};

export default connect(mapStateToProps ,aloharFormActions)(Services);
