// Librerías
import React, { useState, useEffect } from 'react'
import AxiosAuth from '../../services/requestApi';
import { BASE_URL } from '../../Helpers';
// Recursos
import '../../assets/styles/Favorites/_favorites.sass';
import ProfileHousingCard from '../Utils/Cards/ProfileHousingCard';
import SideMenu from '../Utils/SideMenu';

const Favorites = () => {

  const [housing, setHousing] = useState([]);

  const getUserFavorites = () => {
    const url = `${BASE_URL}/housing/favorites`;
     ;
     AxiosAuth.get(url).then((response)=>{
      let data = response.data;
      setHousing(data);
    }).catch((e)=>{console.log(e)});
  }

  useEffect(() => {
    getUserFavorites();
  },[])

  const mappedHousing = housing.map(h => <ProfileHousingCard housing={h} />)

  return (
    
    <div className="favoritesContainer">
      <div className='favoritesContainer__sideBar'>
        <SideMenu />
      </div>  
      <div className="FavoritesListing">

      <h2 className="FavoritesListing__title">
        Tus Alohamientos Favoritos
      </h2>
      { 
        housing && housing.length > 0
        ? 
        <div className="FavoritesListing__list">
          { mappedHousing } 
        </div>
        :
        <> 
        <img class="FavoritesListing__img" src="/static/media/emptystate.8f83bf37.svg" alt=""></img>
          {'No tenes alohamientos favoritos. Empezá hoy!'}
        </> 
      }
    </div>
  </div>
  );
}

export default Favorites
