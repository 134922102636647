// Librerías
import React, { useEffect, useState } from 'react';
import AxiosAuth from '../../services/requestApi'
// Componentes
import DniInput from './Utils/DniInput';
import ErrorMessage from '../Alerts/ErrorAlert';
import {BASE_URL} from '../../Helpers'
// Recursos
import '../../assets/styles/Users/_useredit.sass';
import { useHistory } from "react-router-dom";

const UploadDocument = () => {
    const history = useHistory();
    const [front, setFront] = useState()
    const [back, setBack] = useState()
    const [init, setInit] = useState(false)
    const url = `/user/dni`;
    

    const dowloadImage = () =>{
        setInit(true)
        AxiosAuth.get(url)
            .then((response) => {
                const {dni_back, dni_front} = response.data
                if(dni_back) setBack(BASE_URL+"/user/dni/"+dni_back)
                if(dni_front) setFront(BASE_URL+"/user/dni/"+dni_front)
                
            }).catch((error) => {
                console.log(error)
            })
    }

    useEffect(()=>{
        if(!init) dowloadImage()
    })

    return(
        <div className="UserEditComponent">
            <div className="UserEditComponent__left">
                <div className="UserEditComponent__floating">
                    <h1 className="UserEditComponent__title dni">
                        Carga tu DNI
                    </h1>
                    <div className="UserEditComponent__dniInputContainer">
                        <p className="UserEditComponent__dniInputContainer__title">
                            Foto del frente:
                        </p>
                        <DniInput side="front" image={front}/>
                    </div>
                    <div className="UserEditComponent__dniInputContainer">
                        <p className="UserEditComponent__dniInputContainer__title">
                            Foto del dorso:
                        </p>
                        <DniInput side="back" image={back}/>
                    </div>
                </div>
            </div>
            <div className="UserEditComponent__right">
                <p className="UserEditComponent__tagline">
                    Creá con Laura<br/>en Santa Clara del Mar.
                </p>
            </div>
        </div>
    );
}

export default UploadDocument;