import React from "react";
import MainContainer from "../../../components/BackOffice/Utils/MainContainer";
import ButtonPrimary from "../../../components/ui/button-primary";
import InputGlobal from "../../../components/ui/input-global";
import { useState } from "react";
import { useMemo } from "react";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";

const WhitenPassword = () => {
  const [userEdit, setUserEdit] = useState("");
  const [loading, setLoading] = useState(false);
  const whitenPass = () => {
    setLoading(true);
    const url = `${BASE_URL}/backoffice/whiten-password/${userEdit}`;
    AxiosAuth.post(url)
      .then((response) => {
        setLoading("success");
      })
      .catch((error) => {
        setLoading("error");
      });
  };
  return (
    <MainContainer title="Blanqueo de clave">
      <h2>
        Re-establecer contraseña a "Alohar2025" para el usuario: {userEdit}
      </h2>
      <div className="flex flex-col justify-center h-40 gap-6 w-96">
        <InputGlobal
          placeholder="ID del usuario "
          name={"userEdit"}
          value={userEdit}
          type={"text"}
          onChange={(e) => setUserEdit(e.target.value)}
        />
        <ButtonPrimary
          loading={loading === "success" || "error" || false ? false : true}
          title={"Blanquear clave"}
          onClick={() => whitenPass()}
        />
        {loading === "success" && <ErrorAlert type={"success"} message={"Blanqueo exitoso"} />}

        {loading === "error" && (
          <ErrorAlert message={"Error en el blanqueo "} />
        )}
      </div>
    </MainContainer>
  );
};

export default WhitenPassword;
