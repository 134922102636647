// Librerías
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
// Componentes
import Radio from '../Utils/Radio';
import { closeModal } from '../../store/modal/actions'
import ErrorMessage from '../Alerts/ErrorAlert';
// Recursos
import x from '../../assets/static/Modals/x.svg';
import '../../assets/styles/Modals/_report_modal.sass';
import AxiosAuth from '../../services/requestApi';
import loadingGif from '../../assets/static/loading.gif';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

const report_types = {
  option_1: {
    id: "report_type_1",
    value: "incorrect",
    name: "report",
    title: "Es incorrecto o poco preciso.",
  },
  option_2: {
    id: "report_type_2",
    value: 'unreal',
    name: "report",
    title: "No es un alojamiento real.",
  },
  option_3: {
    id: "report_type_3",
    value: "fraud",
    name: "report",
    title: "Es una estafa.",
  },
  option_4: {
    id: "report_type_4",
    value: 'ofensive',
    name: "report",
    title: "Es ofensivo.",
  },
  option_5: {
    id: "report_type_5",
    value: "other",
    name: "report",
    title: "Es otro motivo.",
  }
}

const ReportModal = ({housingId}) => {
  const [typeRport, setTypeReport] = useState("")
  const [cellphone, setCellphone] = useState("")
  const [description, setDescription] = useState("")
  const [otherType, setOtherType] = useState("")
  const [sending, setSending] = useState(false);
  const [sended, setSended] = useState(false);
  const [error, setError] = useState({})
  const dispatch = useDispatch()
  
  const url = "/housing/report";

  const checking = e => {
    setTypeReport(e.target.value);
  }

  const sendReport = async () =>{
    
        const bodyParameters = {
          type: typeRport,
          otherType: otherType,
          id: housingId,
          description: description,
          cellphone: cellphone
        }
        
        setSending(true)

        const send = await AxiosAuth.post(url,bodyParameters)
          .then((data)=>{
            setSended(true)
            setSending(false)

          })
          .catch((e)=>{
              console.log(e.response)
          });  
    
        return send;
  }

  const validate = () =>{
    let valid = true
    const e = {}
    if(description.length < 5){
      valid = false
      e['description'] = "Debe realizar una descripción más larga"
    }

    if(cellphone.length < 5){
      valid = false
      e['cellphone'] = "Necesitamos tu telefono"
    }

    if(typeRport === "other" && otherType.length < 3 ){
      valid = false
      e['typeRport'] = "Necesitamos que nos detalles que tipo de denuncia estas realizando"
      
    }

    valid ? sendReport() : setError(e);

  }

  const handleRadioSelect = (event) =>{
    const value = event.target.value;
    setTypeReport(value)
  }

  const handleInput = (event) =>{
    const value = event.target.value;
    const name = event.target.name;

    if(name === "description") setDescription(value)
    if(name === "cellphone") setCellphone(value)
    if(name === "other") setOtherType(value)
    
    
  }

  const form = () => {
    return <>
          <div className="ReportModal__section__container__radioButtons">
            {
              error["typeRport"]
              ? <ErrorMessage message={error["typeRport"]} />
              : ''
            }
            {
              Object.keys(report_types).map((key,index) => {
                return <Radio 

                id={ report_types[key].id } 
                key={ report_types[key].id } 
                value={ report_types[key].value } 
                checked = { typeRport }
                name={ report_types[key].name } 
                title={ report_types[key].title } 
                customClickEvent={ handleRadioSelect }
                />
              })
            }
          </div>
          <form className="ReportModal__section__container__form">
            { typeRport === "other" ? <>
              <label className="ReportModal__section__container__form__text" htmlFor="other">Otro tipo</label>
              <input 
                className="ReportModal__section__container__form__input"
                name="other" 
                id="other" 
                type="text"
                onChange={handleInput}
                onBlur={handleInput}
                placeholder="Por favor indique el tipo de denuncia"
              /></> : ""}
                {
                  error["other"]
                  ? <ErrorMessage message={error["other"]} />
                  : ''
                }
            
            
            <label className="ReportModal__section__container__form__text" htmlFor="cellphone">Teléfono de contacto</label>
            <input 
                className="ReportModal__section__container__form__input"
                name="cellphone" 
                id="cellphone" 
                type="number"
                placeholder="Dejanos un celular para contactarnos"
                onChange={handleInput}
                onBlur={handleInput}
            />
                {
                  error["cellphone"]
                  ? <ErrorMessage message={error["cellphone"]} />
                  : ''
                }

            <label htmlFor="cellphone" className="ReportModal__section__container__form__text">Descripción</label>
            <textarea 
                  className="ReportModal__section__container__form__textarea"
                  name="description" 
                  id="description"
                  rows="10"
                  onChange={handleInput}
                  onBlur={handleInput}
                  placeholder="Cuentanos porque queres reportar el alojamiento"
                >
                </textarea>
                {
                  error["description"]
                  ? <ErrorMessage message={error["description"]} />
                  : ''
                }
          </form>
    </>
  }

  return(
    <div className="ReportModal">
      <header className="ReportModal__header">
          <p className="ReportModal__header__title">
            ¿Por qué vas a denunciar este anuncio?
          </p>
          <img className="ReportModal__header__cross" onClick={() => dispatch(closeModal())} alt="x" src={x} />
      </header>
      <div className="ReportModal__section">
        <div className="ReportModal__section__container">
            {sending ?  <img className="Checkout__loading" alt="loading" src={loadingGif}/>  
              : sended ? <div className="ConfirmModal__section__iconContainer" style={{textAlign: "center"}}>
                        <i className="ConfirmModal__section__icon" ><FontAwesomeIcon icon={faThumbsUp} /></i>
                        <p>¡Reporte enviado!</p>
                    </div> : form()}
          {
            sended ? 
              <button className="ReportModal__section__btn" onClick={() => dispatch(closeModal())}>
                <p className="ReportModal__section__btn__text">
                  Cerrar
                </p>
              </button>
              :
              <button className="ReportModal__section__btn" onClick={validate}>
                <p className="ReportModal__section__btn__text">
                  Enviar
                </p>
              </button>
          }
        </div>
      </div>
    </div>
  );
}

export default connect(null,{closeModal})(ReportModal);
