// Librerías
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Recursos
import '../../../assets/styles/Profile/Utils/_feature.sass'

const Feature = (props) => {
  return (
    <div className="Feature">
      <FontAwesomeIcon className={`Feature__icon${(props.small ? ' small' : '')}`} icon={ props.icon } />
      <p className={`Feature__text${(props.small ? ' small' : '')}`}>{ props.title }</p>
    </div>
  )
}

export default Feature;