// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
import validator from "validator";
import AxiosAuth from "../../../services/requestApi";
// Componentes
import ErrorMessage from "../../Alerts/ErrorAlert";
import AvatarInput from "../Utils/AvatarInput";
import Buttons from "../Utils/Buttons";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_user_step.sass";
import { BASE_URL } from "../../../Helpers";

/**
 * Formulario
 *
 * Foto: user_avatar
 *
 * ----
 * Teléfono:
 *
 * Prefijo por país: country_code
 * Celular: cellphone
 *
 */

class UserStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: this.props.bodyParameters.phoneNumber || "",
      prefixPhoneNumber: this.props.bodyParameters.prefixPhoneNumber || "+54",
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    const url = BASE_URL + "/user/phone";
    AxiosAuth.get(url)
      .then((response) => {
        if (response.data.phoneNumber) {
          this.setState(
            {
              phoneNumber: response.data.phoneNumber,
            },
            () => {
              this.props.userStep(this.state);
              this.handleValidate();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        this.handleValidate();
      });
  }

  handleValidate = () => {
    let errors = {};
    const validate =
      validator.isNumeric(this.state.phoneNumber) &&
      this.state.prefixPhoneNumber === "+54" &&
      this.state.phoneNumber.length > 8;

    if (this.state.phoneNumber.length <= 8)
      errors["phoneNumber"] =
        "El número de celular debe tener al menos 9 dígitos";
    if (!validator.isNumeric(this.state.phoneNumber))
      errors["phoneNumber"] =
        "El número de celular deben ser solo dígitos númericos";

    this.setState({ errors: errors });
    this.props.validateStep(validate);
  };

  handleBlur = () => {
    this.props.userStep(this.state);
    this.handleValidate();
  };

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val }, () => {
      this.handleValidate();
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="responsiveUserStep">
          <div className="UserStep">
            <div className="UserStep__container">
              <div className="UserStep__container__photo">
                <h2 className="UserStep__container__photo__title">
                  Foto de Perfil
                </h2>
                <p className="UserStep__container__photo__description">
                  Asegurate que se vea tu cara en la foto
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <AvatarInput />
                </div>
              </div>

              <div className="UserStep__container__cellphone">
                <h2 className="UserStep__container__cellphone__title">
                  Agregar tu número de celular
                </h2>
                <p className="UserStep__container__cellphone__description">
                  Te vamos a enviar solicitudes de reserva, recordatorios y
                  otras notificaciones. Este número debe estar habilitado para
                  recibir SMS o llamadas.
                  <br />
                  Tu número de teléfono debe tener como mínimo 9 números.
                </p>
                <div className="container">
                  <div className="inputContainer">
                    <label className="label">
                      <span className="text-label">Prefijo</span>
                    </label>
                    <input
                      name="prefixPhoneNumber"
                      id="country_code"
                      disabled
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      autoComplete={false}
                      value={"Argentina (+54)"}
                    />
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div className="inputContainer" id="second">
                    <label className="label">
                      <span className="text-label" htmlFor="cellphone">
                        Celular
                      </span>
                    </label>
                    <input
                      className={
                        this.state.errors["phoneNumber"]
                          ? "input errorInput"
                          : "input"
                      }
                      name="phoneNumber"
                      id="cellphone"
                      type="number"
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      autoComplete={false}
                    />
                  </div>
                </div>
                <div className="UserStep__container__cellphone__error">
                  {this.state.errors["phoneNumber"] ? (
                    <ErrorMessage message={this.state.errors["phoneNumber"]} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <Buttons />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return { ...reducers.aloharFormReducer, ...reducers.userReducer };
};

export default connect(mapStateToProps, aloharFormActions)(UserStep);
