import React from 'react';
import { Link } from 'react-router-dom'
import '../../assets/styles/LandingPage/_section_pre_prefooter.sass';
import search from '../../assets/static/Landing/icon/search.svg';
import {isMobile} from 'react-device-detect';

const PreFooter = () => {
    return(
        <div className="LandingPrefooter">
          <div className="LandingPrefooter__overlay">
          {
            isMobile ?
            <Link to="/quiero-ser-alohador" className="LandingPrefooter__link">
                <p className="LandingPrefooter__title">¿Querés ser Alohador?</p>
            </Link>
            :
            <>
              <h3 className='LandingPrefooter__title'>¿Querés ser Alohador?</h3>
              <Link to="/quiero-ser-alohador" className="LandingPrefooter__link">
                <img className="LandingPrefooter__link__icon" src={search} alt="Buscar" />
              </Link>
            </>
          }
          </div>
        </div>
    );
}

export default PreFooter;
