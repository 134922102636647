import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./landing-container.sass";

const LandingContainer = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="landingContainer">{children}</div>
    </>
  );
};

export default LandingContainer;
