// Librerías
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
// Recursos
import search from "../../assets/static/Landing/icon/search.svg";
import "../../assets/styles/LandingPage/_bigcard.sass";

const BigCard = ({ titulo, place, text, price, url, img, last }) => {
  // const [cityName, setCityName] = useState()
  const cityName = text.replace(/\s+/g, "-");
  const imagen = {
    backgroundImage: `url(${img})`,
  };

  return (
    <div className="BigCard" style={imagen}>
      <div className="BigCard__overlay">
        <div className="BigCard__textContainer">
          <p className={`BigCard__live${last ? " last" : ""}`}>{titulo}</p>
          <p className={`BigCard__place${last ? " last" : ""}`}>{text}</p>
          <h2 className={`BigCard__price${last ? " last" : ""}`}>
            AR${" "}
            {price
              .toString()
              .split(/(?=(?:\d{3})+(?:,|$))/g)
              .join(".")}
            *
          </h2>
          <p className="BigCard__description">
            Costo diario por alohamiento desde
          </p>
          <Link
            className={`BigCard__link${last ? " last" : ""}`}
            to={`${cityName}/${url}`}
          >
            <img
              className={`BigCard__link__icon${last ? " last" : ""}`}
              src={search}
              alt={`Buscar un lugar en ${place}`}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BigCard;
