// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
// Componentes
import Buttons from '../Utils/Buttons';
import ErrorMessage from '../../Alerts/ErrorAlert';
import ProgressBar from '../Utils/ProgressBar';
import ButtonEdit from '../Utils/ButtonEdit';
import Radio from '../Utils/Radio';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_guests.sass';

/**
 * Formulario:
 * Anticipación: booking_anticipation
 * Mínimo de noches: min_nights
 * Máximo de noches: max_nights
 * Antelación a los huéspedes: guests_anticipation
 * Min Check-in: min_checkin
 * Max Check-in: max_checkin
 * Checkout: checkout
 */

const typeHousingReservationOptions = [
  {
    value:'to_confirm',
    description: ['Cuando un huésped desea reservar tu espacio te llegará un email de aviso para que confirmes o rechaces la estadía, tu hogar quedara bloqueado esas fechas temporalmente, por eso es importante que rápidamente le des una respuesta al viajero.'],
    title:'No Inmediata (recomendada)'
  },
  {
    value:'immediate',
    description: ['El huésped puede reservar tu espacio directamente (aceptando las normas de tu hogar) sin consultas previas. Esta opción es mas efectiva y atrae mas viajeros por la facilidad de confirmación.'],
    title:'Inmediata'
  }]

class Guests extends Component{

  constructor(props) {
    super(props);

    this.state = {
      typeHousingReservation : this.props.bodyParameters.typeHousingReservation || 'to_confirm',
      minNight: this.props.bodyParameters.minNight || 1,
      maxNight: this.props.bodyParameters.maxNight || 15,
      timeAdvanceBook: this.props.bodyParameters.timeAdvanceBook || 0,
      startCheckInTime: this.convertMilitaryTime(this.props.bodyParameters.startCheckInTime) || '14:00',
      endCheckInTime: this.convertMilitaryTime(this.props.bodyParameters.endCheckInTime) || '20:00',
      checkOutTime: this.convertMilitaryTime(this.props.bodyParameters.checkOutTime) || '14:00',
      errors : {},
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate

    };

    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.handleValidate({showError:false});
    this.props.guestStep(this.state);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.count_validate !== prevProps.count_validate && !isNaN(this.props.count_validate)){
      this.handleValidate({showError:true});
    }
  }

  handleValidate = ({inputName,showError}) =>{
    let errors = {};
    const messengers = {
      minNight:"Debe elegir al menos 1 noche de alojamiento",
      maxNight:"Debe definir un máximo noches de alojamiento",
      timeAdvanceBook:"Debe elgir con cuanto tiempo de anticipación pueden llegar los húespedes",
      startCheckInTime: "Debe elegir el horario de inicio checkin",
      endCheckInTime: "Debe elegir el horario de finalización de checkin",
      checkOutTime: "Debe elegir el horario de checkout",
      typeHousingReservation: "Debe elegir el tipo de reserva que ofrece",
    };

    const validate = (
        this.state.minNight >= 0
        && this.state.maxNight > 0
        // && this.state.timeAdvanceBook > 0
        && this.state.startCheckInTime !== ''
        && this.state.endCheckInTime !== ''
        && this.state.checkOutTime !== ''
        && this.state.typeHousingReservation !== ''
      )

    if(inputName){
      if(inputName === "minNight" && this.state.minNight === 0)
        errors['minNight'] = messengers['minNight']
      if(inputName === "maxNight" && this.state.maxNight === 0)
        errors['maxNight'] = messengers['maxNight']
      // if(inputName === "timeAdvanceBook" && this.state.timeAdvanceBook < 0)
      //   errors['timeAdvanceBook'] = messengers['timeAdvanceBook']
      if(inputName === "startCheckInTime" && this.state.startCheckInTime === '')
        errors['startCheckInTime'] = messengers['startCheckInTime']
      if(inputName === "endCheckInTime" && this.state.endCheckInTime === '')
        errors['endCheckInTime'] = messengers['endCheckInTime']
      if(inputName === "checkOutTime" && this.state.checkOutTime === '')
        errors['checkOutTime'] = messengers['checkOutTime']
      if(inputName === "typeHousingReservation" && this.state.typeHousingReservation === '')
        errors['typeHousingReservation'] = messengers['typeHousingReservation']

    }else{
      if(this.state.minNight === 0)
        errors['minNight'] = messengers['minNight']
      if(this.state.maxNight === 0)
        errors['maxNight'] = messengers['maxNight']
      // if(this.state.timeAdvanceBook === undefined)
      //   errors['timeAdvanceBook'] = messengers['timeAdvanceBook']
      if(this.state.startCheckInTime === '')
        errors['startCheckInTime'] = messengers['startCheckInTime']
      if(this.state.endCheckInTime === '')
        errors['endCheckInTime'] = messengers['endCheckInTime']
      if(this.state.checkOutTime === '')
        errors['checkOutTime'] = messengers['checkOutTime']
      if(this.state.typeHousingReservation === '')
        errors['typeHousingReservation'] = messengers['typeHousingReservation']

    }

    if(showError)
      this.setState({errors:errors})
    this.props.validateStep(validate);
  }

  convertMilitaryTime = (time) => {
    // Convert MM:HH:SS to MM:HH
    if(time){
      const temp = time.split(":")
      return temp[0]+":"+temp[1]
    }

    return time
  }

  handleChange = (event) => {

    const name = event.target.name;
    let value = event.target.value;

    if(name === "minNight" || name === "maxNight" || name === "timeAdvanceBook") value = parseInt(value)
    if(name === "startCheckInTime" || name === "endCheckInTime" || name === "checkOutTime"){
      value = this.convertMilitaryTime(value)
    }

    this.setState({[name]: value},()=>{
        this.props.guestStep(this.state)
        this.handleValidate({inputName:name, showError:true});
      } );

  }

  render(){
    const nights = Array.from(Array(60).keys())
    const days = Array.from(Array(9).keys())

    return(
      <React.Fragment>
        <div className='guestsResponsive'>
          <div className="Guests">
          {this.state.modeEdit ? '' : 
          <ProgressBar title={"CHECKIN Y CHECKOUT"} stepAt={'Paso 3: Toques finales'} />}
            <div className="Guests__container">
              <h2 className="Guests__container__title">
                ¿Qué tipo de reserva prefieres tener?
              </h2>
              <div className="Guests__container__inputContainer">
                {/* <label htmlFor="booking_anticipation" className="Guests__container__inputContainer__label">Tiempo de Reserva</label> */}
                {/* PASAR A RADIO */}
                {
                  typeHousingReservationOptions.map((option)=>{
                    return <Radio
                      id={option.value}
                      key={option.value}
                      value={option.value}
                      name="typeHousingReservation"
                      title={option.title}
                      descriptions={option.description}
                      customClickEvent={this.handleChange.bind(this)}
                      checked={option.value === this.state.typeHousingReservation}
                    />

                  })
                }
                {/* <select onChange={this.handleChange}  defaultValue={this.state.typeHousingReservation} className="Guests__container__inputContainer__select--100" id="" name="typeHousingReservation">
                  <option value="" key="">Seleccione una opción</option>
                  <option value="to_confirm" key="to_confirm">Con confirmación</option>
                  <option value="immediate" key="immediate">Inmediata</option>
                </select> */}
                {
                  this.state.errors["typeHousingReservation"]
                  ? <ErrorMessage message={this.state.errors["typeHousingReservation"]} />
                  : ''
                }
              </div>
              <h2 className="Guests__container__subtitle">
                ¿Cuántas noches pueden quedarse los huéspedes?
              </h2>
              <p className="Guests__container__description">
                Cuanto más cortas sean las estadías, más reservas vas a tener, pero vas a tener que limpiar y preparar el alojamiento más seguido.
              </p>
              <div className="Guests__container__selects">
                <div className="Guests__container__inputContainer">
                  <label htmlFor="property_type" className="Guests__container__inputContainer__label">Mínimo de Noches</label>
                  <select onChange={this.handleChange}  defaultValue={this.state.minNight} className="Guests__container__inputContainer__select--40" name="minNight" id="min_nights">
                    {nights.map((n)=><option key={"min_"+n} value={n+1}>{n+1}</option>)}
                  </select>
                  {
                    this.state.errors["minNight"]
                    ? <ErrorMessage message={this.state.errors["minNight"]} />
                    : ''
                  }
                </div>
                <div className="Guests__container__inputContainer">
                  <label htmlFor="property_type" className="Guests__container__inputContainer__label">Máximo de Noches</label>
                  <select onChange={this.handleChange}  defaultValue={this.state.maxNight} className="Guests__container__inputContainer__select--40 no-border-left" name="maxNight" id="max_nights">
                    {nights.map((n)=><option key={"max_"+n} value={n+1}>{n+1}</option>)}
                  </select>
                </div> 
              </div>
              <h2 className="Guests__container__subtitle">
                ¿Cuánto tiempo de antelación necesitas para tener todo listo antes de que lleguen tus huéspedes?
              </h2>
              <p className="Guests__container__description">
                Si elegís la opción sin restricción tus huéspedes pueden aloharse el mismo día de la búsqueda, esto aumentará tus alquileres e ingresos un 35%.
              </p>

              <div className="Guests__container__selects">
                <div className='Guests__container__inputContainer'>
                <label htmlFor="guests_anticipation" className="Guests__container__inputContainer__label">Tiempo</label>
                <select onChange={this.handleChange} defaultValue={this.state.timeAdvanceBook} className="Guests__container__inputContainer__select--100" name="timeAdvanceBook" id="guests_anticipation">
                  {
                    days.map((n)=>
                      <option
                        key={"day_"+n}
                        value={n}
                      >
                          {n === 0 ? "Sin restricción" : n === 1? n+' día': n+' días'}
                      </option>)
                  }
                </select>
                {
                  this.state.errors["timeAdvanceBook"]
                  ? <ErrorMessage message={this.state.errors["timeAdvanceBook"]} />
                  : ''
                }
                </div>
              </div>

              <h2 className="Guests__container__subtitle">
                ¿Cuál es el horario del check in para tus huéspedes?
              </h2>

              <div className="Guests__container__selects">
                <div className="Guests__container__inputContainer">
                  <label defaultValue={this.state.startCheckInTime} htmlFor="property_type" className="Guests__container__inputContainer__label">Desde</label>
                  <input
                    type="time"
                    className="Guests__container__inputContainer__input"
                    name="startCheckInTime"
                    value={this.state.startCheckInTime}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                  />
                  {
                    this.state.errors["startCheckInTime"]
                    ? <ErrorMessage message={this.state.errors["startCheckInTime"]} />
                    : ''
                  }
                  {/* <select className="Guests__container__inputContainer__select--40" name="startCheckInTime" id="min_checkin">
                    <option value="0">1</option>
                  </select> */}
                </div>
                <div className="Guests__container__inputContainer">
                  <label htmlFor="property_type" className="Guests__container__inputContainer__label">Hasta</label>
                  <input
                    type="time"
                    className="Guests__container__inputContainer__input no-border-left"
                    name="endCheckInTime"
                    value={this.state.endCheckInTime}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                  />
                  {
                    this.state.errors["endCheckInTime"]
                    ? <ErrorMessage message={this.state.errors["endCheckInTime"]} />
                    : ''
                  }
                </div>
              </div>
              <h2 className="Guests__container__subtitle">
                ¿Hasta qué hora pueden hacer check-out los huéspedes?
              </h2>
              <div className="Guests__container__selects">
                <div className="Guests__container__inputContainer">
                  <label htmlFor="property_type" className="Guests__container__inputContainer__label">Hasta</label>
                  <input
                    type="time"
                    className="Guests__container__inputContainer__input__time"
                    name="checkOutTime"
                    value={this.state.checkOutTime}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                  />
                  {
                    this.state.errors["checkOutTime"]
                    ? <ErrorMessage message={this.state.errors["checkOutTime"]} />
                    : ''
                  }
                </div>
              </div>
            { this.state.modeEdit ? <ButtonEdit /> : <Buttons /> }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer
};

export default connect(mapStateToProps ,aloharFormActions)(Guests);