import React from "react";

const StarIcon = ({ size, color }) => {
  return (
    <svg
      width={size ? size : "100%"}
      height={size ? size : "100%"}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0L10.858 5.25446L16.584 5.87336L12.3154 9.73969L13.4962 15.3766L8.5 12.5117L3.50383 15.3766L4.68464 9.73969L0.416019 5.87336L6.14198 5.25446L8.5 0Z"
        fill={color ? color : "#FF7D52"}
      />
    </svg>
  );
};

export default StarIcon;
