// Libraries
import React from 'react';
// Assets
import "../../assets/styles/Modals/modalFilter.sass";
import close from '../../assets/static/Icons/close.png'

const Modal = ({children, isOpen, closeModal}) => {
    const handleModalContainerClick = e => e.stopPropagation();
    return (
        <>
            <article className={`modalFilter ${isOpen && "isOpen"}`} onClick={closeModal}> 
                <div className="modal-container" onClick={handleModalContainerClick}>
                    <div className='modalHeader'>
                        <span className='modalHeader__title'>Mis filtros</span>
                        <button className="modalHeader__btn" onClick={closeModal}>
                            <img alt="Close" className='modalHeader__btn__img' src={close} />
                        </button>
                    </div>
                        {children}
                </div>
            </article>
        </>
    )
}

export default Modal;
