import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const ReservationSuccess = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex items-center justify-center w-full min-h-full">
          Pantalla de reserva completa
        </div>
      </div>
      <Footer />
      <div />
    </>
  );
};

export default ReservationSuccess;
