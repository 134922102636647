/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MainContainer from "../../../components/BackOffice/Utils/MainContainer";
import { useForm } from "../../../hooks/useForm";
import ButtonPrimary from "../../../components/ui/button-primary";
import AxiosAuth from "../../../services/requestApi";
import { BASE_URL } from "../../../Helpers";
import { useState } from "react";
import InformationHousingCard from "./components/informationHousingCard";
import SelectDate from "../../../components/MainSearch/SelectDate";
import SelectPersons from "../../../components/MainSearch/SelectPersons";
import FormAddReservation from "./components/formAddReservation";
import ModalGlobal from "../../../components/ui/modal-global";
import axios from "axios";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import * as moment from "moment";
import * as serachActions from "../../../store/search/actions";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import FormEditReservation from "./components/formEditReservation";
import Pill from "../../../components/BackOffice/Utils/Pill";
import "./transferReservations.sass";
import PaginationBackOffice from "../../../components/BackOffice/pagination";
import LoadingGift from "../../../assets/components-icons/LoadingGift";
const TransferReservations = (props) => {
  const apiKey = process.env.REACT_APP_ALOHAR_KEY;
  const columns = [
    {
      name: "ID",
      selector: (row) => row["id"],
    },
    {
      name: "ID reserva",
      selector: (row) => row["reservationId"],
    },

    {
      name: "Estado admin",

      cell: (row) => (
        <>
          <Pill
            status={
              (row.cancellationMotive !== null && "cancelled") ||
              (row.stateAdmin !== 2 &&
                row.cancellationMotive === null &&
                "pending") ||
              (row.stateAdmin &&
                row.stateAdmin.toString() === "2" &&
                "confirmed")
            }
          />
        </>
      ),
    },
    {
      name: "Estado host",
      cell: (row) => (
        <>
          <Pill
            status={row.transferConfirmation === 2 ? "confirmed" : "pending"}
          />
        </>
      ),
    },
    {
      name: "Editar",
      selector: (row) => (
        <button
          className="manualTransfer__buttonEdit"
          onClick={() => {
            modalEditReservation(row);
          }}
        >
          editar
        </button>
      ),
    },
    {
      name: "Cancelar",
      cell: (row) => (
        <Link
          className={"manualTransfer__cancel"}
          to={`/back-office/reservations/cancel-transfer/${row.reservationId}`}
        >
          Cancelar
        </Link>
      ),
    },

    {
      name: "Fecha de Reserva",
      selector: (row) => row["issued"],
    },
    {
      name: "Check In",
      selector: (row) => row["checkin"],
    },
    {
      name: "Check Out",
      selector: (row) => row["checkout"],
    },
    {
      name: "Noches",
      selector: (row) => row["nights"],
    },

    {
      name: "Precio Base",
      selector: (row) => row["basePrice"],
    },
    {
      name: "Limpieza",
      selector: (row) => row["cleanPrice"],
    },
    {
      name: "FEE Huésped",
      selector: (row) => (row.guestFee === null ? 0 : row["guestFee"]),
    },

    {
      name: "Desc semanal",
      selector: (row) =>
        row.weeklyDiscount === null ? 0 : row["weeklyDiscount"],
    },
    {
      name: "Desc mensual",
      selector: (row) =>
        row.monthlyDiscount === null ? 0 : row["monthlyDiscount"],
    },
    {
      name: "Desc cupones",
      selector: (row) =>
        row.cuponDiscount === null ? 0 : row["cuponDiscount"],
    },
    {
      name: "Desc transfer",
      selector: (row) => row["transferDiscount"],
    },
    {
      name: "Precio Total",
      selector: (row) => row["totalPrice"],
    },
    {
      name: "Pago al alohador",
      selector: (row) => row["payAmount"],
    },

    {
      name: "Housing ID",
      selector: (row) => row["housingId"],
    },

    {
      name: "Huésped id",
      selector: (row) => row["userId"],
    },

    {
      name: "payerName",
      selector: (row) => row["payerName"],
    },

    {
      name: "CUIT payer",
      selector: (row) => row["payerCuit"],
    },

    {
      name: "Tipo de Reserva",
      selector: (row) => row["paymentType"],
    },
  ];

  let initialForm = {
    userId: "",
    housingId: "",
    reservationType: "transfer",
    transferDiscount: 2.5,
  };

  const [nextPage, setNextPage] = useState({
    page: 1,
    perPage: 10,
  });
  const [loading, setLoading] = useState({
    loading: false,
    csv: false,
  });
  const { form, handleChange, resetForm } = useForm(initialForm);
  const [listReservations, setListReservations] = useState([]);
  const [disableDates, setDisableDates] = useState();
  const [hotSaleDates, setHotSaleDates] = useState();

  const [bodyEditReservation, setBodyEditReservation] = useState({});
  const [limitGuests, setLimitGuests] = useState(false);
  const [closeModal, setCloseModal] = useState({
    modalCreateReservation: false,
    modalEditReservation: false,
    cancelReservation: false,
  });
  const [couponId, setCouponId] = useState(0);
  const [filter, setFilter] = useState();
  const [q, setQ] = useState("");
  const [searchColumns] = useState(["id", "payerName", "reservationId"]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [errors, setErrors] = useState({
    housingNotFound: false,
    reservationSuccess: false,
    userNotExist: false,
  });
  const [success, setSuccess] = useState({ reservationSuccess: false });
  const [housingPrices, setHousingPrices] = useState({
    minNight: 0,
    maxNight: 0,
    guests: 0,
    checkin: "",
    checkout: "",
    SERVICE_FEE: 0,
    BASE_PRICE: 0,
    CLEANING_PRICE: 0,
    WEEKLY_DISCOUNT: 0,
    MONTHLY_DISCOUNT: 0,
    totalPrice: 0,
    paymentType: form.reservationType,
    coupon: 0,
  });
  useEffect(() => {
    if (form.reservationType) {
      setHousingPrices({ ...housingPrices, paymentType: form.reservationType });
    }
  }, [form.reservationType]);

  const modalEditReservation = (data) => {
    setBodyEditReservation(data);
    setCloseModal({ ...closeModal, modalEditReservation: true });
  };
  const editTransfer = () => {
    const url = `${BASE_URL}/edit-transferData/${bodyEditReservation.id}`;
    const body = {
      id: bodyEditReservation.id,
      checkin: bodyEditReservation.checkin,
      checkout: bodyEditReservation.checkout,
      nights: bodyEditReservation.nights,
      reservationId: bodyEditReservation.reservationId,
      guests: bodyEditReservation.guests,
      basePrice: bodyEditReservation.basePrice,
      cleanPrice: bodyEditReservation.cleanPrice,
      guestFee: bodyEditReservation.guestFee,
      weeklyDiscount: bodyEditReservation.weeklyDiscount,
      monthlyDiscount: bodyEditReservation.monthlyDiscount,
      cuponId: bodyEditReservation.cuponId,
      cuponDiscount: bodyEditReservation.cuponDiscount,
      totalPrice: bodyEditReservation.totalPrice,
      transferDiscount: bodyEditReservation.transferDiscount,
      issued: bodyEditReservation.issued,
      housingId: bodyEditReservation.housingId,
      userId: {
        id: bodyEditReservation.userId,
      },
      paymentType: form.paymentType
        ? form.paymentType
        : bodyEditReservation.paymentType,
      payerName: form.playerName
        ? form.playerName
        : bodyEditReservation.playerName,
      payerCuit: form.payerCuit
        ? form.payerCuit
        : bodyEditReservation.payerCuit,
      payAmount: form.payAmount
        ? form.payAmount
        : bodyEditReservation.payAmount,
      stateAdmin: form.stateAdmin
        ? form.stateAdmin
        : bodyEditReservation.stateAdmin,
      changeStateDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      cancellationMotive: form.cancellationMotive
        ? form.cancellationMotive
        : bodyEditReservation.cancellationMotive,
    };
    AxiosAuth.put(url, body)
      .then((response) => {
        getAllTransfer();
        setErrors({ ...errors, reservationSuccess: true });
      })
      .catch((error) => {
        // setErrors({ ...errors, reservationSuccess: true });
      });
  };
  const createTransferReservation = async () => {
    const url = `${BASE_URL}/createReservationTest/${form.housingId}?userId=${form.userId}`;

    const body = {
      checkin: housingPrices.checkin,
      checkout: housingPrices.checkout,
      guests: housingPrices.guests,
      total: housingPrices.totalPrice.toString(),
      transferDiscount:
        form.reservationType !== "link" && form.transferDiscount,
      installments: 3,
      paymentType: form.reservationType,
      transferConfirmation: 1,
      token: "abc123",
      paymentProductId: "xyz456",
      merchantId: "789",
      planID: 17,
      plan: "Standard",
      payments: 1,
      coupon: couponId,
    };
    await AxiosAuth.post(url, body)
      .then((response) => {
        setSuccess({ ...success, reservationSuccess: true });
        setCloseModal({ ...closeModal, modalCreateReservation: false });
        getAllTransfer();
      })
      .catch((error) => {
        setErrors({ ...errors, userNotExist: true });
      });
  };
  const getAllTransfer = (page) => {
    setLoading(true);
    const url = `${BASE_URL}/allTransferData?page=${nextPage.page}&pageSize=${nextPage.perPage}`;
    AxiosAuth.get(url)
      .then((response) => {
        setListReservations(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTransfer();
  }, [nextPage]);

  const getDisableDates = (id) => {
    const url = `${BASE_URL}/housing/publicCalendar/${id}`;
    axios
      .get(url)
      .then((response) => {
        const diablesDates = response.data.calendar.calendar
          .filter(
            (c) =>
              c.typeHousingCalendar === "block" ||
              c.typeHousingCalendar === "hold" ||
              c.typeHousingCalendar === "calendarImport"
          )
          .map((c) => {
            c.end = moment(c.end)
              .utc(true)
              .add(response.data.timeAdvanceBook, "days")
              .format("YYYY-MM-DD");
            return c;
          });
        const datesHotSaleDisabled = response.data.calendar.calendar.filter(
          (c) => c.typeHousingCalendar === "hot_sale"
        );
        setDisableDates(diablesDates);
        setHotSaleDates(datesHotSaleDisabled);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (form.housingId.length > 1) {
      const url = `${BASE_URL}/housing/${form.housingId}?apiKey=${apiKey}`;
      axios
        .get(url)
        .then((response) => {
          setErrors({ ...errors, reservationSuccess: true });
          getDisableDates(form.housingId);
          setHousingPrices({
            ...housingPrices,
            maxNight: response.data.maxNight,
            minNight: response.data.minNight,
          });
          setLimitGuests(response.data.guests);
          setErrors({ ...errors, housingNotFound: false });
        })
        .catch((error) => {
          setErrors({ ...errors, housingNotFound: true });

          console.log(error);
        });
    }
  }, [form.housingId, form.userId]);

  const truncar = (num, digitos) => {
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);
    return _num ? _num[1] : num;
  };

  const costs = () => {
    const url = BASE_URL + `/housing/${form.housingId}/reservations/cost`;
    const bodyParameters = {
      id: form.housingId,
      checkin: housingPrices.checkin,
      checkout: housingPrices.checkout,
      coupon: couponId,
      guests: housingPrices.guests,
      transferDiscount:
        form.reservationType !== "link" && form.transferDiscount,
    };

    axios
      .post(url, bodyParameters)
      .then((response) => {
        const prices = response.data.prices;
        let price = {};
        prices.forEach((p) => {
          if (p.priceType === "SERVICE_FEE")
            price = { ...price, SERVICE_FEE: truncar(p.total, 2) };
          if (p.priceType === "BASE_PRICE") {
            price = { ...price, BASE_PRICE: truncar(p.total, 2) };
          }
          if (p.priceType === "CLEANING_PRICE")
            price = { ...price, CLEANING_PRICE: truncar(p.total, 2) };

          if (p.priceType === "WEEKLY_DISCOUNT")
            price = { ...price, WEEKLY_DISCOUNT: truncar(p.total, 2) };

          if (p.priceType === "MONTHLY_DISCOUNT")
            price = { ...price, MONTHLY_DISCOUNT: truncar(p.total, 2) };
          setHousingPrices({
            ...housingPrices,
            SERVICE_FEE: price.SERVICE_FEE,
            BASE_PRICE: price.BASE_PRICE,
            CLEANING_PRICE: price.CLEANING_PRICE,
            WEEKLY_DISCOUNT: price.WEEKLY_DISCOUNT,
            MONTHLY_DISCOUNT: price.MONTHLY_DISCOUNT,
            totalPrice: truncar(response.data.totalPrice, 2),
            couponDiscount: response.data.couponDiscount,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (housingPrices.checkin && housingPrices.checkout) {
      costs();
    }
  }, [
    housingPrices.checkout,
    form.reservationType,
    form.transferDiscount,
    // form.coupon,
    couponId,
  ]);
  console.log("form");
  console.log(form);
  useEffect(() => {
    setHousingPrices({
      ...housingPrices,
      guests: props.searchReducer.adults + props.searchReducer.childs,
    });
  }, [props]);

  useEffect(() => {
    if (
      formatDateMonth(housingPrices.checkin) !==
      formatDateMonth(housingPrices.checkout)
    ) {
      let firstDate = moment(housingPrices.checkin, "D/M/YY");
      let secondDate = moment(housingPrices.checkout, "D/M/YY");
      let milliseconds = 24 * 60 * 60 * 1000;
      let millisecondsDays = Math.abs(firstDate - secondDate);
      let elapsedMilliseconds = Math.round(millisecondsDays / milliseconds);
    }
  }, [housingPrices.checkout, housingPrices.checkin, housingPrices.guests]);

  const formatDateMonth = (format) => {
    const arrayDate = format === undefined ? false : format.split("/");
    return arrayDate.length === 3 ? arrayDate[1] : false;
  };

  useEffect(() => {
    if (startDate && endDate) {
      setHousingPrices({
        ...housingPrices,
        checkin: moment(startDate).utc().format("YYYY-MM-DD"),
        checkout: moment(endDate).utc().format("YYYY-MM-DD"),
      });
    }
  }, [startDate, endDate]);
  const convertArrayOfObjectsToCSV = (data) => {
    if (data == null || !data.length) {
      return null;
    }

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    const keys = Object.keys(data[0]);

    let result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
      keys.forEach((key, index) => {
        if (index > 0) {
          result += columnDelimiter;
        }

        let value = item[key];
        if (value === undefined || value === null) {
          value = "null"; // Reemplazar con 'null' si es necesario
        }

        result += value;
      });
      result += lineDelimiter;
    });

    return result;
  };
  const downloadCSV = () => {
    setLoading({
      ...loading,
      csv: true,
    });
    const url = `${BASE_URL}/backoffice/download-reservations?status=transfer`;
    AxiosAuth.get(url)
      .then((response) => {
        const link = document.createElement("a");
        let csv = convertArrayOfObjectsToCSV(response.data);
        if (csv == null || undefined) return;

        const filename = "export.csv";

        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
        setLoading({
          ...loading,
          csv: false,
        });
      })
      .catch((e) => {
        setLoading({
          ...loading,
          csv: false,
        });
      });
    setLoading({
      ...loading,
      csv: false,
    });
  };

  const search = (rows) => {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] !== null &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  const paginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsText: "Todos",
  };

  useEffect(() => {
    if (
      !closeModal.modalCreateReservation ||
      !closeModal.modalEditReservation
    ) {
      setErrors({
        ...errors,
        reservationSuccess: false,
        housingNotFound: false,
      });
    }
  }, [closeModal]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // La tecla "Enter" fue presionada, realizar búsqueda
      setLoading(true);
      if (filter && filter.length > 0) {
        const url = `${BASE_URL}/search-reservation-transfer?search=${filter}&page=${nextPage.page}&perPage=${nextPage.perPage}`;
        AxiosAuth.get(url)
          .then((response) => {
            setListReservations(response.data);
            setLoading(false);
          })
          .catch((e) => {
            getAllTransfer();
            setLoading(false);
            console.log(e);
          });
      }
      if (!filter) {
        getAllTransfer();
      }
    }
  };
  return (
    <MainContainer title="Reservacion manual ">
      <div className="modalEdit">
        <ModalGlobal
          closeModal={closeModal.modalCreateReservation}
          setCloseModal={() => {
            resetForm();
            setCloseModal({
              ...closeModal,
              modalCreateReservation: false,
            });
          }}
        >
          <div className="modalCreateReservation">
            <div className="modalCreateReservation__container">
              <FormAddReservation
                form={form}
                handleChange={handleChange}
                setCouponId={setCouponId}
              />
              <div>
                <h2>ARS {`$${housingPrices.totalPrice}`}/ noche</h2>
                <div className="Booking__container">
                  <div className="Booking__dates">
                    <SelectDate
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      disableDates={disableDates}
                      hotSaleDates={hotSaleDates}
                    />
                  </div>
                  <div className="Booking__people">
                    <SelectPersons />
                  </div>
                </div>
                <InformationHousingCard
                  housingInfo={housingPrices}
                  form={form}
                />
              </div>
            </div>
            {errors.housingNotFound && (
              <ErrorAlert message={"Alojamiento no encontrado"} />
            )}
            {errors.userNotExist && (
              <ErrorAlert message={"El usuario no existe"} />
            )}
            {limitGuests && limitGuests < housingPrices.guests && (
              <ErrorAlert message={"maximo de huespedes admitidos"} />
            )}
            {success.reservationSuccess && (
              <ErrorAlert message={"Reserva creada"} type={"success"} />
            )}
            <ButtonPrimary
              disabled={limitGuests < housingPrices.guests ? true : false}
              title={"Reservar"}
              onClick={() => {
                createTransferReservation();
              }}
            />
          </div>
        </ModalGlobal>
        <ModalGlobal
          closeModal={closeModal.modalEditReservation}
          setCloseModal={() => {
            setCloseModal({
              ...closeModal,
              modalEditReservation: false,
            });
          }}
        >
          <div className="modalCreateReservation">
            <div className="modalCreateReservation__container">
              <FormEditReservation form={form} handleChange={handleChange} />
            </div>
            {errors.housingNotFound && (
              <ErrorAlert message={"Error al editar, verifique los campos"} />
            )}
            {errors.reservationSuccess && (
              <ErrorAlert message={"Reserva editada"} type={"success"} />
            )}
            <ButtonPrimary
              title={"Editar reserva"}
              onClick={() => {
                editTransfer();
              }}
            />
          </div>
        </ModalGlobal>
        <ModalGlobal
          closeModal={closeModal.cancelReservation}
          setCloseModal={() => {
            setCloseModal({
              ...closeModal,
              cancelReservation: false,
            });
          }}
        >
          <div className="modalCreateReservation">
            <div className="modalCreateReservation__container">
              <FormEditReservation form={form} handleChange={handleChange} />
            </div>
            {errors.housingNotFound && (
              <ErrorAlert message={"Error al editar, verifique los campos"} />
            )}
            {errors.reservationSuccess && (
              <ErrorAlert message={"Reserva editada"} type={"success"} />
            )}
            <ButtonPrimary
              title={"Editar reserva"}
              onClick={() => {
                editTransfer();
              }}
            />
          </div>
        </ModalGlobal>
      </div>

      <div>
        <div className="ActionBar">
          <input
            className="ActionBar__input"
            type="text"
            placeholder="Búsqueda"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <div className="ActionBar__btns">
            <button
              className="w-52 ActionBar__btns__btn"
              onClick={() => downloadCSV()}
            >
              {loading.csv ? <LoadingGift size={25} /> : "Descargar CSV"}
            </button>
            <button
              className="ActionBar__btns__btn"
              onClick={() =>
                setCloseModal({
                  ...closeModal,
                  modalCreateReservation: !closeModal.modalCreateReservation,
                })
              }
            >
              Generar reserva
            </button>
          </div>
        </div>
        {loading.loading ? (
          <LoadingGift size={50} />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={search(listReservations)}
              paginationComponentOptions={10}
            />
            <PaginationBackOffice
              nextPage={nextPage}
              setNextPage={setNextPage}
            />
          </>
        )}
      </div>
    </MainContainer>
  );
};
const mapStateToProps = (reducers) => {
  return {
    searchReducer: reducers.searchReducer,
    userReducer: reducers.userReducer,
  };
};
export default connect(mapStateToProps, serachActions)(TransferReservations);
