import React from "react";
import { BASE_URL, loadUserData } from "../../Helpers";

const UserAvatar = () => {
  const userData = loadUserData()
  const defaultAvatar = `https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF7D52&color=fff`;

  if (!userData) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          fill="none"
          stroke="currentColor"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </g>
      </svg>
    );
  }

  const { avatar, firstName, lastName } = userData;
  const avatarUrl =
    avatar && firstName
      ? avatar.includes("http")
        ? avatar
        : `${BASE_URL}/${avatar}`
      : `${defaultAvatar}&name=${firstName}`;

  return (
    <img
      className="usermenubutton__imgcontainer__img"
      alt={`${firstName} ${lastName}`}
      src={avatarUrl}
    />
  );
};

export default UserAvatar;
