/* eslint-disable react-hooks/exhaustive-deps */
// Librerías
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  openEditModal,
  openConfirmModal,
  openNotificationModal,
} from "../../store/modal/actions";
// Componentes
// recursos
import pencil from "../../assets/static/Icons/pencil.svg";
import bag from "../../assets/static/Icons/bag.svg";
import trash from "../../assets/static/Icons/trash.svg";
// Modales
import ModalWrapper from "../Modals/ModalWrapper";
import NotificationModal from "../Modals/NotificationModal";
import EditModal from "../Modals/EditModal";
import ConfirmModal from "../Modals/ConfirmModal";
import { BASE_URL } from "../../Helpers";
import AxiosAuth from "../../services/requestApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faInbox } from "@fortawesome/free-solid-svg-icons";
import SmallProgressBar from "../Utils/SmallProgressBar";
import CustomToggle from "../Utils/CustomToggle";
import SideMenu from "../Utils/SideMenu";
import EmptyState from "../EmptyState/EmptyState";
import loadingGif from "../../assets/static/loadingBlack.gif";
import moment from "moment";
import axios from "axios";
import { restart } from "../../store/aloharForm/actions";
import "../../assets/styles/MyHousing/_housinglist.sass";
import EditModalApp from "../Modals/EditModalApp";

moment.locale("es");

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const HousingListAPP = ({ housingList, loading }) => {
  // La idea sería usar housingList en vez de placeholder data
  const router = useHistory();
  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [errorAction, setErrorAction] = useState(false);
  const [housingListSelected, setHousingListSelected] = useState(false);
  const [housingDelete, setHoysingDelete] = useState(false);
  const [userHousing, setUserHousing] = useState(false);
  const [userHousingMobile, setUserHousingMobile] = useState(false);
  const [typeModal, setTypeModal] = useState("confirmDelete");
  const [locationId, setlocationId] = useState("");
  const [_housingList, set_HousingList] = useState(housingList);
  const [_housingListMobile, set_HousingListMobile] = useState(housingList);
  const [loadingState, setLoadingState] = useState({});
  const [cityName, setCityName] = useState("");
  const [init, setInit] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();
  const history = useHistory();

  const handlerOnClick = (housing) => {
    setHousingListSelected(housing);
    dispatch(openEditModal());
  };

  useEffect(() => {
    if (_housingList.length === 0) set_HousingList(housingList);
    set_HousingListMobile(housingList);

    buildList();
    buildMobileList();
  }, [housingList, loading, _housingList, _housingListMobile]);

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (
          !ref.current ||
          ref.current.className === "button_profile" ||
          ref.current.contains(event.target)
        ) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  };

  const handleOpenConfirmModal = (item) => {
    setTypeModal("confirmDelete");
    setHoysingDelete(item);
    dispatch(openConfirmModal());
  };

  const handlerDeleteClick = async () => {
    const url = BASE_URL + "/housing";
    const housing = housingDelete;
  
    try {
       const response = await AxiosAuth.delete(url, { data: { id: housing.id } });
       set_HousingList(_housingList.filter((h) => h.id !== housing.id));
       buildList();
  
      history.push("/alohar_create_app"); // Navegar a una ruta diferente
      setTimeout(() => {        
        history.goBack(); // Volver a la página anterior
      }, 1000);
    } catch (e) {
      setTimeout(() => {
        dispatch(openNotificationModal());
      }, 10);
    }
  };
  

  const actionChange = async (event, item) => {
    setErrorAction(false);
    const value = event.target.checked;
    const housing = event.target.id.split("-")[0];

    let newState = {};
    const id = parseInt(housing);
    newState[id] = true;
    setLoadingState(newState);

    const bodyParameters = {
      id: parseInt(housing),
      action: value,
    };
    const url = BASE_URL + "/housing/public/action";

    const response = await AxiosAuth.post(url, bodyParameters)
      .then((response) => {
        const result = response.data;
        if (!result.status) {
          if (result.error === "incomplete")
            setErrorAction(
              "No se puede publicar el alojamiento porque hay datos incompletos.</br> Revise la publicación"
            );
          return false;
        }
        return true;
      })
      .catch((e) => {
        return false;
      });

    return response;
  };
  const changeTypeHousingReservation = async (event, item) => {
    setErrorAction(false);
    const value = event.target.checked;
    const housing = event.target.id.split("-")[0];
    const bodyParameters = {
      id: parseInt(housing),
      typeHousingReservation: value ? "immediate" : "to_confirm",
    };
    const url = BASE_URL + "/housing/public/TypeHousingReservation";

    const response = await AxiosAuth.post(url, bodyParameters)
      .then((response) => {
        const result = response.data;
        if (!result.status) {
          if (result.error === "incomplete") return false;
        }
        return true;
      })
      .catch((e) => {
        return false;
      });
    return response;
  };
  useEffect(() => {
    dispatch(restart());
  }, []);

  useEffect(() => {
    window.localStorage.removeItem("housingPublicProcessData");
    const url = `${BASE_URL}/location/search`;
    axios.get(url).then((response) => {
      const city = response.data;
      setCityName(city);
    });
  }, []);

  const buildMobileList = () => {
    const listPostedHouses = _housingListMobile.map((item, index) => {
      let media = "#";
      const totalStep = Object.keys(item.stepStatus).length;
      const stepSuccess = Object.values(item.stepStatus).filter(Boolean).length;
      const progress = Math.round((stepSuccess / totalStep) * 100);
      const lastUpdate = moment(item.updateAt).utc(true);
      const pendingConfirmations = item.hasPendingConfirmationReservations[1];
      if (item.housingMedia.length === 0) {
        media =
          "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";
      } else {
        const cover = item.housingMedia.filter((m) => m.cover);
        cover.length === 1
          ? (media = BASE_URL + cover[0]["media"].replace("public", ""))
          : (media =
              BASE_URL + item.housingMedia[0]["media"].replace("public", ""));
      }

      return (
        <div className="MobileHousingList__container">
          <div className="MobileHousingList__card">
            <div className="MobileHousingList__card__imgcontainer">
              <img
                src={media}
                alt={item.title}
                className="MobileHousingList__card__imgcontainer__img"
              />
            </div>
            <div className="MobileHousingList__buttonsContainer">
              <div className="MobileHousingList__buttons">
                <button
                  className="MobileHousingList__buttons__btn"
                  onClick={() => {
                    handlerOnClick(item);
                  }}
                >
                  Editar
                </button>
                {/* <Link
                  className="MobileHousingList__buttons__btn"
                  to={`/housing/reservations/${item.id}`}
                >
                  Reservas
                </Link> */}
                {/* <Link
                  className="MobileHousingList__buttons__btn"
                  to={`/housing/reservations/${item.id}`}
                >
                  Reservas a Confirmar
                  <div className="MobileHousingList__buttons__PendingConfirm">
                    {pendingConfirmations}
                  </div>
                </Link> */}
                <button
                  className="MobileHousingList__buttons__btn"
                  onClick={() => {
                    handleOpenConfirmModal(item);
                  }}
                >
                  <i>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </i>
                </button>
              </div>
            </div>
            <div className="MobileHousingList__card__datacontainer">
              <h3 className="MobileHousingList__card__datacontainer__title">
                {item.title}
              </h3>
              <div className="MobileHousingList__card__datacontainer__group reservations">
                {/* <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__leftMobileHousingList__card__datacontainer__group__text__left ">
                  Reservas pendientes de confirmación
                </p> */}
                <Link
                  className="HousingList__table__tr__td__btn"
                  to={`/housing/reservations/${item.id}`}
                >
                  {pendingConfirmations}
                </Link>
              </div>
              <div className="MobileHousingList__card__datacontainer__group">
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__left">
                  Estado
                </p>
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__right">
                  <CustomToggle
                    id={item.id}
                    key={"status" + item.id}
                    name="status"
                    title={["Activo", "Pausado"]}
                    checked={item.public}
                    onChange={actionChange}
                    source={"mobile"}
                  />
                </p>
              </div>
              <div className="MobileHousingList__card__datacontainer__group">
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__left">
                  Progreso
                </p>
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__right">
                  <SmallProgressBar progressBarWidth={progress} />
                </p>
              </div>
              <div className="MobileHousingList__card__datacontainer__group">
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__left">
                  Reserva Inmediata
                </p>
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__right">
                  <CustomToggle
                    id={item.id}
                    key={"instantBooking" + item.id}
                    name="instantBooking"
                    source={"mobile"}
                    title={["Si", "No"]}
                    checked={item.typeHousingReservation === "immediate"}
                    onChange={changeTypeHousingReservation}
                  />
                </p>
              </div>
              <div className="MobileHousingList__card__datacontainer__group">
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__left">
                  Ubicación
                </p>
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__right">
                  {item.location ? item.location.location : " Sin definir"}
                </p>
              </div>
              <div className="MobileHousingList__card__datacontainer__group">
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__leftMobileHousingList__card__datacontainer__group__text__left">
                  Precio
                </p>
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__right">
                  {item.basePrice ? `$${item.basePrice} ARS` : "Sin definir"}
                </p>
              </div>
              <div className="MobileHousingList__card__datacontainer__group">
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__left">
                  Última Modificación
                </p>
                <p className="MobileHousingList__card__datacontainer__group__text MobileHousingList__card__datacontainer__group__text__right">
                  {lastUpdate.format("D/MM/YYYY, H:m")}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });

    setUserHousingMobile(listPostedHouses);
  };

  const buildList = () => {
    const listPostedHouses = _housingList.map((item, index) => {
      let media = "#";
      const totalStep = Object.keys(item.stepStatus).length;
      const stepSuccess = Object.values(item.stepStatus).filter(Boolean).length;
      const progress = Math.round((stepSuccess / totalStep) * 100);
      const lastUpdate = new Date(item.updateAt);
      const pendingConfirmations = item.hasPendingConfirmationReservations[1];

      if (item.housingMedia.length === 0) {
        media =
          "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";
      } else {
        const cover = item.housingMedia.filter((m) => m.cover);
        cover.length === 1
          ? (media = BASE_URL + cover[0]["media"].replace("public", ""))
          : (media =
              BASE_URL + item.housingMedia[0]["media"].replace("public", ""));
      }

      return (
        <tr className="HousingList__table__tr hover" key={item.id}>
          {/* <td className="HousingList__table__tr__td widetd">&nbsp;</td> */}
          <td className="HousingList__table__tr__td">
            <ConditionalWrapper
              condition={item.public}
              // aca
              wrapper={(children) => (
                <div
                  className="cursor-pointer HousingList__table__tr__td__container"
                  to={`/property/${item.id}`}
                  onClick={() =>
                    router.push(`/${item.location.location}/property/${item.id}`)
                  }
                >
                  {children}
                </div>
              )}
            >
              <div className="HousingList__table__tr__td__image">
                <img src={media} alt={item.title} />
              </div>
            </ConditionalWrapper>
          </td>
          <td className="HousingList__table__tr__td">
            <p className="HousingList__table__tr__td__title">
              {item.title ? item.title : "Sin definir"}
            </p>
          </td>
          <td className="HousingList__table__tr__td">
            {item.location ? item.location.location : " Sin definir"}
          </td>
          <td className="HousingList__table__tr__td">
            {item.basePrice ? `$${item.basePrice} ARS` : "Sin definir"}
          </td>
          <td className="HousingList__table__tr__td">
            <CustomToggle
              id={item.id}
              key={"status" + item.id}
              name="status"
              title={["Activo", "Pausado"]}
              checked={item.public}
              onChange={actionChange}
              source={"desktop"}
            />
            {loadingState[item.id] ? (
              <div
                style={{ fontSize: "10px" }}
                className="HousingList__loading"
              >
                {" "}
                <img alt="Cargando..." src={loadingGif} />{" "}
              </div>
            ) : (
              ""
            )}
          </td>
          <td className="HousingList__table__tr__td">
            <CustomToggle
              id={item.id}
              key={"instantBooking" + item.id}
              name="instantBooking"
              title={["Si", "No"]}
              checked={item.typeHousingReservation === "immediate"}
              onChange={changeTypeHousingReservation}
              source={"desktop"}
            />
          </td>

          <td className="HousingList__table__tr__td">
            {lastUpdate.getDate() +
              "/" +
              (lastUpdate.getMonth() + 1) +
              "/" +
              lastUpdate.getFullYear()}{" "}
            <br /> {lastUpdate.getHours() + ":" + lastUpdate.getMinutes()}
          </td>
          <td className="HousingList__table__tr__td">
            <SmallProgressBar progressBarWidth={progress} />
          </td>
          <td className="HousingList__table__tr__td widetd">
            <div className="HousingList__table__tr__td__buttons">
              <button
                className="HousingList__table__tr__td__btn bottomHoverEdit"
                onClick={() => {
                  handlerOnClick(item);
                }}
              >
                <img alt="Editar" src={pencil} />
              </button>
              <Link
                className="HousingList__table__tr__td__btn bottomHoverBag"
                to={`/housing/reservations/${item.id}`}
              >
                <img alt="Reservas" src={bag} />
              </Link>
              <button
                className="HousingList__table__tr__td__btn bottomHoverDelete"
                onClick={() => {
                  handleOpenConfirmModal(item);
                }}
              >
                <img alt="Eliminar" src={trash} />
              </button>
            </div>
          </td>
          {/* <td className="HousingList__table__tr__td widetd">&nbsp;</td> */}
        </tr>
      );
    });

    setUserHousing(listPostedHouses);
  };

  useOnClickOutside(ref, () => setIsMenuOpen(false));
  const newHousing = () => {
    localStorage.removeItem("housingPublicProcessData");
    router.push("/alohar");
  };
  return (
    <>
      {loading ? (
        <div className="HousingList__loading">
          {" "}
          <img src={loadingGif} />{" "}
        </div>
      ) : _housingList.length === 0 ? (
        <div>
          <EmptyState message="Todavía no creaste ningun alohamiento" />

          <div
            onClick={() => newHousing()}
            className="HousingList__emptyTitle"
            to="/alohar"
          >
            Nuevo Alohamiento
            <div className="HousingList__headline__title__sum">+</div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="HousingList">
            <div className="HousingList__sideBar">
              <SideMenu />
            </div>
            <div className="HousingList__body">
              <div className="HousingList__container">
                <div className="HousingList__headline">
                  <h1 style={{}} className="HousingList__headline__title">
                    MIS ALOHAMIENTOS
                  </h1>
                  <div
                    onClick={() => newHousing()}
                    className="HousingList__emptyTitle"
                    to="/alohar"
                  >
                    Nuevo Alohamiento
                    <div className="HousingList__headline__title__sum">+</div>
                  </div>
                </div>
                <table className="HousingList__table">
                  <tr className="HousingList__table__tr">
                    <th className="HousingList__table__tr__th th_primary">
                      FOTO
                    </th>
                    <th className="HousingList__table__tr__th ">ALOHAMIENTO</th>
                    {/* <th className="HousingList__table__tr__th ">Tareas pendientes</th> */}
                    <th className="HousingList__table__tr__th ">UBICACIÓN</th>
                    <th className="HousingList__table__tr__th ">PRECIO</th>
                    <th className="HousingList__table__tr__th ">ESTADO</th>
                    <th className="HousingList__table__tr__th ">
                      RES. INMEDIATA
                    </th>
                    {/* <th className="HousingList__table__tr__th ">Reserva pendientes</th> */}
                    <th className="HousingList__table__tr__th ">
                      ÚLT. MODIFICACiÓN
                    </th>
                    <th className="HousingList__table__tr__th ">PUBLICACIÓN</th>
                    <th className="HousingList__table__tr__th "></th>
                  </tr>
                  {userHousing}
                </table>
              </div>
            </div>
            {modalState.isOpen && modalState.type === "EDIT" ? (
              <ModalWrapper>
                <EditModalApp housing={housingListSelected} />
              </ModalWrapper>
            ) : (
              ""
            )}
            {modalState.isOpen && modalState.type === "CONFIRM" ? (
              <ModalWrapper>
                <ConfirmModal
                  confirmAction={handlerDeleteClick}
                  title={"Borrar alojamiento"}
                  text={
                    "Una vez borrado el alojamiento no podrá volver a recuperarlo."
                  }
                  confirmButtonText={"Confirmar"}
                />
              </ModalWrapper>
            ) : (
              ""
            )}
            {modalState.isOpen && modalState.type === "NOTIFICATION" ? (
              <ModalWrapper>
                <NotificationModal
                  title={"No se puede borrar el alojamiento"}
                  text={
                    "Hay reservas confirmadas o pendientes a confirmar y no se puede borrar el registro. Puede desactivar el alojamiento para no recibir mas reservas y cancelar las que se encuentren en espera."
                  }
                  buttonText={"Aceptar"}
                />
              </ModalWrapper>
            ) : (
              ""
            )}
          </div>
          <div className="pt-24 MobileHousingList">
            <h1 style={{ paddingTop:20, textAlign:'center'}} className="MobileHousingList__title">Mis Alohamientos</h1>
            {userHousingMobile}
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default connect(null, {
  openEditModal,
  openConfirmModal,
  openNotificationModal,
  restart,
})(HousingListAPP);
