// Librerías
import React, { useState, useEffect } from 'react';
import { faCheck, faStar, faMedal, faUserCheck, faComment, faHome } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from '../../../Helpers';
// Componentes
import ConfirmedProfile from './ConfirmedProfile';
import Feature from './Feature';
import ProfileUserHousing from './ProfileUserHousing';
import Separator from '../../Utils/Separator';
// Sass
import '../../../assets/styles/Profile/Utils/_userdata.sass';
import ProfileValuationContainer from './ProfileValuationContainer';

const UserData = (props) => {
  const user = props.user

  const [avatar, setAvatar] = useState(`https://ui-avatars.com/api/?size=256&font-size=0.33&background=FF4E00&color=fff&name=${user?.firstName}%20${user?.lastName}`);
  const [joined, setJoined] = useState('');
  const average = props.average;
  const valuationsAsHost = props.valuations.guest_to_host;
  const valuationsAsGuest = props.valuations.host_to_guest;
  const valuationsAmount = valuationsAsHost?.length + valuationsAsGuest?.length;

  const setTheAvatar = (avatar) => {
    if(avatar?.includes("https")) return avatar;
    return `${BASE_URL}/user/avatar/${avatar?.replace("public", "")}`;
  }

  useEffect(()=>{
    setAvatar(setTheAvatar(user.avatar, user.firstName, user.lastName));
    let date = new Date(user.issued)
    setJoined(date.getFullYear())
  },[ user, avatar ])

  return (
    <div className="UserData">
      <div className="UserData__aloha">
        <h2 className="UserData__aloha__title">
          {`¡Aloha! Soy ${user?.firstName}.`}
        </h2>
        <p className="UserData__aloha__created">
          {`Se registró en ${joined}`}
        </p>
        <div className="UserData__aloha__avatar">
          <img src={ avatar } alt={`${user?.firstName}`} className="UserData__aloha__avatar__img"/>
        </div>
      </div>
      <div className="UserData__hide-on-desktop">
        <Feature title={`${(valuationsAmount === 1) ? `${ valuationsAmount } Valuación` :  `${ valuationsAmount } Valuaciones`}`} icon={ faStar } />
        {
          user?.dniConfirmed
          ?
          <Feature title="Identidad Confirmada" icon={ faUserCheck } />
          : ''
        }
        <Separator />
      </div>
      <div className="UserData__info">
        <h5 className="UserData__info__title">
          Información
        </h5>
        <div className="UserData__info__about">
          { (user.about) ? user.about : `${user.firstName} aún no completó su perfil.` }
        </div>
        {
          user.locationStr
          ?
          <Feature title={`Vive en ${user?.locationStr}`} icon={faHome} small={true}/>
          : ''
        }
        {
          user.languageStr
          ?
          <Feature title={`Habla ${user?.languageStr}`} icon={faComment} small={true}/>
          : ''
        }
      </div>
      <Separator />
      <div className="UserData__hide-on-desktop">
        <ConfirmedProfile icon={faCheck} user={ user }/>
        <Separator />
      </div>
      {
        props.housing?.length > 0
        ?
          <ProfileUserHousing housing={ props.housing } firstName={ user.firstName } />
        : ''
      }
      {
        valuationsAmount > 0
        ? <ProfileValuationContainer valuationsAsGuest={ valuationsAsGuest } valuationsAsHost={ valuationsAsHost } average={average} />
        : ''
      }
    </div>
  )
}

export default UserData;