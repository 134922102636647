import React from 'react'

const Offline = () => {
  return (
    <div class="Offline">
    </div>
  )
}

export default Offline
