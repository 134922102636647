// Librerías
import React from "react";
import { useHistory } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import { openRegisterModal } from "../../../store/modal/actions";
// Modales
// Recursos
import "../../../assets/styles/RentLanding/Content/_prefooter.sass";
import ButtonPrimary from "../../ui/button-primary";

const Prefooter = () => {
  const router = useHistory();
  const user = useSelector((state) => state.userReducer, shallowEqual);

  return (
    <React.Fragment>
      <div className="Prefooter">
        <div className="Prefooter__container">
          <h2 className="Prefooter__title">
            Dale nomás,
            <br />
            ¡empezá hoy mismo!
          </h2>
          <ButtonPrimary
            title={"Alohá"}
            onClick={
              user.login
                ? router.push("/alohar")
                : () => router.push("/register")
            }
          />
        </div>
        <p className="Prefooter__description">¿Qué estás esperando?</p>
      </div>
      {/* { modalState.isOpen ? selectModal(modalState.type) : '' } */}
    </React.Fragment>
  );
};

export default connect(null, { openRegisterModal })(Prefooter);
