// Librerías
import React from "react";
import close from "../../../assets/static/Icons/close.svg";
import "../../../assets/styles/SearchPage/Utils/_activefilters.sass";

const Pills = (props) => {
  return (
    <div className="ActiveFilters__Pills">
      {props.text}{" "}
      <img
        className="ActiveFilters__Pills__close"
        src={close}
        alt="Eliminar filtro"
        name={props.name}
        id={props.id}
        onClick={props.handling}
      />
    </div>
  );
};

const ListFilter = (props) => {
  // eslint-disable-next-line array-callback-return
  const list = props.list.map((listItem) => {
    if (listItem.checked === true) {
      return (
        <Pills
          key={listItem.title}
          text={listItem.title}
          handling={() => props.handling(listItem.name, false)}
        />
      );
    }
  });

  return <>{list}</>;
};

const ListNeighborhoods = (props) => {
  // eslint-disable-next-line array-callback-return
  const list = props.list.map((listItem) => {
    if (listItem.checked === true) {
      return (
        <Pills
          key={listItem.id}
          id={listItem.id}
          name={listItem.name}
          text={listItem.name}
          handling={(e) => props.handling(e)}
        />
      );
    }
  });

  return <>{list}</>;
};

const ActiveFilters = (props) => {
  const isAFilterActive =
    props.inmediateBooking ||
    props.flexibility ||
    props.housingType.some((t) => t.checked === true) ||
    props.spaceType.some((t) => t.checked === true) ||
    props.neighborhoods?.some((t) => t.checked === true) ||
    props.pets ||
    props.allGlampings;

  const displayNone = "none";

  return (
    <div className={`ActiveFilters${!isAFilterActive ? "none" : ""}`}>
      <ListFilter list={props.housingType} handling={props.housingTypeHandle} />
      <ListFilter list={props.spaceType} handling={props.spaceTypeHandle} />
      {props.neighborhoods && (
        <ListNeighborhoods
          list={props.neighborhoods}
          handling={props.neighborhoodsHandle}
        />
      )}
      {props.flexibility ? (
        <Pills
          text="Flexibilidad para cancelar"
          handling={props.flexibilityHandle}
        />
      ) : (
        ""
      )}
      {props.inmediateBooking ? (
        <Pills
          text="Reserva Inmediata"
          handling={props.inmediateBookingHandle}
        />
      ) : (
        ""
      )}
      {props.pets ? (
        <Pills text="Mascotas" handling={props.handlePetFilter} />
      ) : (
        ""
      )}
      {props.allGlampings ? (
        <Pills
          text="Glampings de todo el pais"
          handling={props.handleAllGlampings}
        />
      ) : (
        ""
      )}
      {isAFilterActive ? (
        <p className="ActiveFilters__reset" onClick={props.reset}>
          Borrar Todo
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default ActiveFilters;
