export const closeModal = () => {
    return {
        type: "CLOSE_MODAL"
    }
}

export const openLoginModal = () =>{
    return {
        type: "OPEN_LOGIN_MODAL"
    }
}

export const openRegisterModal = () =>{

    return {
        type: "OPEN_REGISTER_MODAL"
    }
}

export const openShareModal = () =>{

    return {
        type: "OPEN_SHARE_MODAL"
    }
}

export const openContactAlohadorModal = () =>{
    return {
        type: "OPEN_CONTACT_ALOHADOR_MODAL"
    }
}


export const openMessengerModal = () =>{
    return {
        type: "OPEN_MESSENGER_MODAL"
    }
}

export const openRecoverModal = () =>{
    return {
        type: "OPEN_RECOVER_MODAL"
    }
}

export const openEditModal = () =>{
    return {
        type: "OPEN_EDIT_MODAL"
    }
}

export const openConfirmModal = () =>{
    return {
        type: "OPEN_CONFIRM_MODAL"
    }
}

export const openNotificationModal = () =>{
    return {
        type: "OPEN_NOTIFICATION_MODAL"
    }
}

export const openBankPromotionModal = () =>{
    return {
        type: "OPEN_BANK_PROMOTION_MODAL"
    }
}

export const openCancelReservationModal = () =>{
    return {
        type: "OPEN_CANCELATION_RESERVATION_MODAL"
    }
}

export const openReportModal = () =>{
    return {
        type: "OPEN_REPORT_MODAL"
    }
}


export const openPhoneModal = () =>{
    return {
        type: "OPEN_PHONE_MODAL"
    }
}
