// Librerías
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import { BASE_URL } from "../../../Helpers";
// Container
import Container from "../../Containers/AppContainerNavbar";
// Componentes
import UserCard from "../Utils/UserCard";
import UserData from "../Utils/UserData";
// Recursos
import "../../../assets/styles/Profile/Public/_public_profile.sass";

const PublicProfile = () => {
  const { id } = useParams();
  const [user, setUser] = useState([]);
  const [housing, setHousing] = useState([]);
  const [valuations, setValuations] = useState([]);
  const [average, setAverage] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(true);
  const getUser = (id) => {
    setLoading(true);
    const url = `${BASE_URL}/user/get-profile/${id}`;
    axios
      .get(url)
      .then((response) => {
        let userData = response.data;
        setUser(userData);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setLoaded(false);
      });
  };

  const getUserHousing = (id) => {
    const url = `${BASE_URL}/housing/profile/${id}`;
    axios
      .get(url)
      .then((response) => {
        let housingData = response.data;
        setHousing(housingData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUserValuations = (id) => {
    const url = `${BASE_URL}/users/${id}/valuations`;
    axios
      .get(url)
      .then((response) => {
        let valuationsData = response.data;
        setValuations(valuationsData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUserValuationsAverage = (id) => {
    const url = `${BASE_URL}/users/${id}/valuations/average`;
    axios
      .get(url)
      .then((response) => {
        // Step 1
        const averageData = response.data;
        const size = averageData.length;
        const total = averageData
          .map((m) => m.avg)
          .reduce((i, a) => parseInt(i) + parseInt(a));
        const realAverage = total / size;
        setAverage(realAverage);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUser(id);
    getUserHousing(id);
    getUserValuations(id);
    getUserValuationsAverage(id);
  }, [id]);

  return (
    <Container>
      <div className="PublicProfile">
        {loaded === false ? <Redirect to="/" /> : ""}
        {!loading && (
          <div className="PublicProfile__container">
            <UserCard user={user} valuations={valuations} />
            <UserData
              user={user}
              valuations={valuations}
              housing={housing}
              average={average}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default PublicProfile;
