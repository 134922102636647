// Librerías
import React, { Component } from 'react';
// Recursos
import '../../../assets/styles/AloharForm/Utils/_dropzone.sass';
import cam from  '../../../assets/static/camara.svg';

class Dropzone extends Component{
  constructor(props){
    super(props)
    this.state = {
      files: this.props.files,
      limit: 10,
    }
    this.fileInput = React.createRef();
    this.openDialog = this.openDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let filesInput = event.target.files;
    let array = [...filesInput];
    // RULE: File ext
    array = array.filter( (obj) => {
      return obj.type === 'image/jpeg' || obj.type === 'image/jpg' || obj.type === 'image/png';
    });
    array.forEach((file, key) => {
      // We set the max size to 4MB
      let size = (file.size / 1024 / 1024).toFixed(2);
      if(size <= this.state.limit){
        file.id = key + 1;
        file.URL = URL.createObjectURL(file);
        this.handleAddItem(file);
      }
    });
    this.props.toggle();
  }

  handleAddItem(file){
    this.props.onFileUpload(file);
  }

  openDialog = () => {
    this.fileInput.current.click()
  }

  render(){
    return(
      <React.Fragment>
        <div className="Dropzone">
          <h3 className="Dropzone__title">
            Consejos
          </h3>
          <ul className="Dropzone__list">
            <li className="Dropzone__list__description">Prepara el ambiente: limpia ordena para que tu espacio se vea amplio y acogedor.</li>
            <li className="Dropzone__list__description">Realiza tus fotografías durante el día, enciende todas las luces, abre las cortinas y deja que la luz
natural cumpla su rol.</li>
            <li className="Dropzone__list__description">Agrega fotos variadas (sin repetir imágenes): para ayudar a los huéspedes a entender cómo será
alojarse en tu espacio, toma fotos del interior, el exterior y los alrededores de tu alojamiento.</li>
            <li className="Dropzone__list__description">Sacá fotos con la cámara horizontal y desde las esquinas para ofrecer un perspectiva real.</li>
            <li className="Dropzone__list__description">La resolución es importante: toma fotos que sean de al menos 1024 x 683 px. En caso de duda, es
mejor la foto más grande.</li>
            <li className="Dropzone__list__description">Incluí todos los espacios a los que van a poder acceder los huéspedes, como los dormitorios, los
baños, la cocina y el living.</li>
<li className="Dropzone__list__description">Las fotos deben cargarse en formato JPG, JPEG o PNG y cada una no deben superar los {this.state.limit} MB de tamaño.</li>
          </ul>
          <button className="Dropzone__btn" onClick={ this.openDialog }>
            <img src={ cam } alt="Cámara"/> <p className="Dropzone__btn__text">Agregar Fotos</p>
          </button>
          <input ref={ this.fileInput } type="file" accept=".png,.jpg,.jpeg" onChange={this.handleChange} multiple/>
        </div>
      </React.Fragment>
    );
  }
}

export default Dropzone;