// Librerías
import React from 'react';
// Componentes
import EasyHousing from './Content/EasyHousing';
import EasyHousingNumbers from './Content/EasyHousingNumbers';
import Guarantees from './Content/Guarantees';
import EasyCharge from './Content/EasyCharge';
import EasyChargeDescription from './Content/EasyChargeDescription';
import Prefooter from './Content/Prefooter';
// Recursos
import '../../assets/styles/RentLanding/_content.sass';

const Content = () => {
  return(
    <React.Fragment>
      <div className="Content">
        <EasyHousing />
        <EasyHousingNumbers />
        <Guarantees />
        <EasyCharge />
        <EasyChargeDescription />
        <Prefooter />
      </div>
    </React.Fragment>
  );
}

export default Content;