// Librerías
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
// Componentes
import Card from "../Utils/FinishedCard";
import ProgressBar from "../Utils/ProgressBar";
import { Helmet } from "react-helmet";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_finished.sass";
import { BASE_URL } from "../../../Helpers";
import AxiosAuth from "../../../services/requestApi";
import FinishedModal from "../../Modals/FinishedModal";
class Finished extends Component {
  constructor(props) {
    super(props);
    let cover = "../../../static/PlaceCards/place1.png";
    if (this.props.steps[8].bodyParameters.files.length > 0) {
      const fcover = this.props.steps[8].bodyParameters.files.filter((f) => {
        return f.cover;
      });
      cover =
        fcover.length === 0
          ? this.props.steps[8].bodyParameters.files[0].URL
          : fcover[0].URL;
    }

    this.state = {
      steps: this.props.steps,
      title: this.props.steps[11].bodyParameters.title || "Pendiente",
      basePrice: this.props.steps[12].bodyParameters.basePrice || "Pendiente",
      guests: this.props.steps[4].bodyParameters.guests || "Pendiente",
      rooms: this.props.steps[4].bodyParameters.rooms || "Pendiente",
      beds: this.props.steps[4].bodyParameters.beds || "Pendiente",
      bathrooms: this.props.steps[4].bodyParameters.bathrooms || "Pendiente",
      housingId: this.props.housingId,
      closeModal: true,
      img: cover,
      pendigItems: {},
    };

    Object.keys(this.state.pendigItems).forEach(function (stepNumer, index) {
      if (!this.state.pendigItems[stepNumer].status) {
        this.state.pendigItems[index] = this.state.pendigItems[stepNumer];
      }
    });

    if (Object.keys(this.state.pendigItems).length === 0) {
      const bodyParameters = {
        id: this.state.housingId,
        action: true,
      };
      const url = BASE_URL + "/housing/public/action";

      AxiosAuth.post(url, bodyParameters)
        .then((data) => {
          localStorage.removeItem("housingPublicProcessData");
        })
        .catch((e) => {
          console.log(e.response);
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <script>
            {`
            gtag('event', 'conversion', {'send_to': 'AW-453872768/nceaCOLgj_EBEICZttgB'});
            `}
          </script>
        </Helmet>
        <FinishedModal
          setCloseModal={() => this.setState({ closeModal: false })}
          closeModal={this.state.closeModal}
          link={`/profile/housing`}
          housingId={this.state.housingId}
          title={
            "Publicaste tu Alohamiento y estas listo para recibir tus próximos huéspedes."
          }
          buttonTitle={"Volver a Mis Alohamientos"}
        />
        <div className="Finished">
          <div className="Finished__progressBar">
            <ProgressBar
              progress={100}
              title={"PUBLICACION COMPLETADA"}
              stepAt={"Finalizado"}
            />
          </div>
          <div className="Finished__container">
            <div className="Finished__container-left">
              <h2 className="Finished__container__title">
                ¡Felicitaciones! Acabás de crear un Alohamiento
              </h2>
              <p className="Finished__container__description">
                Podes editar en cualquier momento la información en el menú
              </p>
              <Link className="Finished__container__btn" to="/profile/housing">
                Mis Alohamientos
              </Link>
              <br />
              <p className="Finished__container__description">
                Tu espacio ya está listo para ser visto por miles de personas de
                nuestra comunidad alohar, si queres ver como verán los demás tu
                espacio.
              </p>
              <br />
              <p className="Finished__container__description">
                <Link
                  className="Finished__container__btn-primary"
                  to={`property/${this.state.housingId}`}
                >
                  Ver Publicación
                </Link>
              </p>
              {/* <Share url="https://www.facebook.com" /> */}
              <br />
              <br />
              <p className="Finished__container__description">
                Ahora también podés ajustar las fechas y precios especiales por
                mes, semana o día. Tu espacio es flexible y tu calendario lo
                sabe, para hacerlo entrá acá
              </p>
              <br />
              <p className="Finished__container__description">
                <Link
                  className="Finished__container__btn-primary"
                  to={`edit/15/${this.state.housingId}`}
                >
                  Quiero ajustar mi calendario.
                </Link>
              </p>

              <ul>
                {Object.keys(this.state.pendigItems).map((key, index) => (
                  <li>{key}</li>
                ))}
              </ul>
            </div>
            <div className="Finished__container-right">
              <Card
                img={this.state.img}
                price={this.state.basePrice}
                title={this.state.title}
                guests={this.state.guests}
                rooms={this.state.rooms}
                beds={this.state.beds}
                bathrooms={this.state.bathrooms}
                blackText={true}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return { ...reducers.aloharFormReducer, ...reducers.userReducer };
};

export default connect(mapStateToProps, aloharFormActions)(Finished);
