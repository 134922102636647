// Liberías
import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import axios from "axios";
import * as moment from "moment";
import queryString from "query-string";
import { parseMoney } from "../../Helpers";
// Componentes
import SelectDate from "../MainSearch/SelectDate";
import SelectPersons from "../MainSearch/SelectPersons";
// Assets
import { Modal } from "antd";
import "../../assets/styles/PropertyPage/_intermediate_step.sass";
import { BASE_URL } from "../../Helpers";
import loadingGif from "../../assets/static/loading.gif";
import previajeLegal from "../../assets/static/previaje.pdf";

const cancelButtonStyle = {
  shape: "round",
};

const okButtonStyle = {
  shape: "round",
  className: "bg-primary",
};

const IntermediateStep = (props) => {
  const apiKey = process.env.REACT_APP_ALOHAR_KEY;
  const history = useHistory();
  const { id } = props;
  const [housing, setHousing] = useState({
    guests: 0,
    maxNight: 0,
    minNight: 0,
    basePrice: 0,
    title: "",
    user: {},
    init: false,
  });
  const [disableDates, setDisableDates] = useState();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [hotSaleDates, setHotSaleDates] = useState();
  const searchState = useSelector((state) => state.searchReducer, shallowEqual);
  const userState = useSelector((state) => state.userReducer, shallowEqual);
  const [price, setPrice] = useState(0);
  const [cleanPrice, setCleanPrice] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [realBasePrice, setRealBasePrice] = useState(0);
  const [days, setDays] = useState(0);
  const [error, setError] = useState("");
  const [redirctTo, setRedirctTo] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [weeklyDiscount, setWeeklyDiscount] = useState(0);
  const [realPrice, setRealPrice] = useState(0);
  const [previaje, setPreviaje] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const dispatch = useDispatch();
  const query = queryString.parse(window.location.search);
  const { idHHH, typeHousingReservation } = query;
  let { adults, checkin, checkout, childs } = searchState;
  // Modal Things
  const [visible, setVisible] = useState(false);
  const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
  const NOW = moment().format(DATE_FORMAT);
  const TO = moment("2022-01-03 10:00:00").format(DATE_FORMAT);

  const errors = {
    "Reservation Exceeds max number of guests":
      "Se superó el máximo de personas alojadas: " + housing.guests,
    "Checkin date is minor than checkout date":
      "La fecha de checkin es posterior a la de checkout",
    "Reservation Exceeds max nights for housing":
      "Se superó la cantidad máxima de noches que permite el alojamiento: " +
      housing.maxNight,
    "checkin or checkout are in the past": "La fecha de checkin debe ser mayor",
    "Reservation below min nights for housing":
      "No cumple con la cantidad mínima de noches: " + housing.minNight,
    "Housing is not available for the current dates":
      "El alojamiento no esta disponible para las fechas elegidas",
  };

  const quote = async () => {
    setLoadingPrice(true);
    const url = BASE_URL + `/housing/${id}/reservations/cost`;

    const bodyParameters = {
      id: id,
      checkin: moment(checkin, "D/M/YY").format("YYYY-MM-DD"),
      checkout: moment(checkout, "D/M/YY").format("YYYY-MM-DD"),
      guests: adults + childs,
    };
    clearPrice();
    await axios
      .post(url, bodyParameters)
      .then((data) => {
        const prices = data.data.prices;
        prices.forEach((p) => {
          if (p.priceType === "SERVICE_FEE")
            setServicePrice(truncar(p.total, 2));
          if (p.priceType === "BASE_PRICE") {
            setPrice(truncar(p.total, 2));
            calcRealBasePrice(truncar(p.total, 2));
          }
          if (p.priceType === "CLEANING_PRICE")
            setCleanPrice(truncar(p.total, 2));
          if (p.priceType === "WEEKLY_DISCOUNT")
            setWeeklyDiscount(truncar(p.total, 2));
          if (p.priceType === "MONTHLY_DISCOUNT")
            setMonthlyDiscount(truncar(p.total, 2));
        });
        setPreviaje(truncar(data.data.previajeAmount, 2));
        setRealPrice(data.data.totalPrice);
        setTaxes(data.data.taxes);
        setTotalPrice(truncar(data.data.totalPrice, 2));
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 400) {
          setError(errors[e.response.data.message]);
          clearPrice();
        }
      })
      .then(() => {
        setLoadingPrice(false);
      });
  };

  const clearPrice = () => {
    setServicePrice(0);
    setPrice(0);
    setCleanPrice(0);
    setWeeklyDiscount(0);
    setMonthlyDiscount(0);
    setRealPrice(0);
    setTotalPrice(0);
    setDays(0);
    setRealBasePrice(0);
    setTaxes(0);
    setPreviaje(0);
  };

  const calcRealBasePrice = (r) => {
    let start = moment(checkin, "D/M/YY");
    let end = moment(checkout, "D/M/YY");
    let duration = moment.duration(end.diff(start));
    let days = duration.asDays();
    if (days) {
      setRealBasePrice(Math.floor(truncar(r / days, 2)));
      setDays(days);
    }
  };

  const truncar = (num, digitos) => {
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);
    return _num ? _num[1] : num;
  };

  const toLogin = () => {
    history.push("/login");
  };

  useEffect(() => {
    const apiKey = process.env.REACT_APP_ALOHAR_KEY;
    if (!disableDates) {
      const urlCalendar = `${BASE_URL}/housing/calendar/${id}`;
      axios
        .get(urlCalendar)
        .then((response) => {
          setDisableDates(
            response.data.filter(
              (c) =>
                c.typeHousingCalendar === "block" ||
                c.typeHousingCalendar === "hold" ||
                c.typeHousingCalendar === "calendarImport"
            )
          );
          setHotSaleDates(
            response.data.filter((c) => c.typeHousingCalendar === "hot_sale")
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (!housing.init) {
      const url = `${BASE_URL}/housing/${id}?apiKey=${apiKey}`;
      axios
        .get(url)
        .then(function (response) {
          if (response.status === 200) {
            response.data.init = true;
            setHousing(response.data);

            setLoading(true);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.status === 404) {
            setRedirctTo(true);
          }
        });
    }

    if (checkout !== "" && checkin !== "") {
      setLoading(true);
      clearPrice();
      setError("");
      quote();
    }
  }, [checkout, checkin, adults, childs, housing]);
  const dateToday = moment();

  const validFrom =
    housing.housingPromotions?.length > 0 &&
    moment(housing.housingPromotions[0].valid_from);
  const validTo =
    housing.housingPromotions?.length > 0 &&
    moment(housing.housingPromotions[0].valid_to);

  return (
    <div className="IntermediateStep">
      {redirect ? <Redirect push to="login" /> : ""}
      {!housing.init ? (
        <img src={loadingGif} alt="Spinner" />
      ) : (
        <>
          <h2 className="IntermediateStep__title">{housing.title}</h2>
          <div className="IntermediateStep__container">
            <SelectDate
              clearPrice={clearPrice}
              disableDates={disableDates}
              hotSaleDates={
                housing.housingPromotions.length > 0 &&
                moment(housing.housingPromotions[0].valid_from) <= dateToday &&
                moment(housing.housingPromotions[0].valid_to) >= dateToday &&
                hotSaleDates
              }
            />
            <div className="IntermediateStep__people">
              <SelectPersons />
            </div>
          </div>
          <p className="IntermediateStep__desc">
            No vamos a cobrarte ningún cargo por el momento
          </p>
          <div className="w-90/100">
            {dateToday.isBetween(validFrom, validTo) ? (
              <strong className="">
                <del className="text-xs">${housing.basePrice}/noche</del>
                <br /> $
                {truncar(
                  housing.basePrice -
                    (housing.basePrice *
                      housing.housingPromotions[0].discount) /
                      100,
                  2
                )}
                /noche{" "}
                <span className="text-xs text-primary">
                  {housing.housingPromotions[0].discount} %OFF <br /> En fechas
                  seleccionadas
                </span>
              </strong>
            ) : (
              <strong>
                ARS $
                {realBasePrice !== 0 && days !== 0
                  ? realBasePrice
                      .toString()
                      .split(/(?=(?:\d{3})+(?:,|$))/g)
                      .join(".")
                  : housing.basePrice
                      .toString()
                      .split(/(?=(?:\d{3})+(?:,|$))/g)
                      .join(".")}
                / noche
              </strong>
            )}
          </div>
          <ul className="IntermediateStep__list">
            <li className="IntermediateStep__list__item">
              <p className="IntermediateStep__list__item__desc">
                Minimo de noches
              </p>
              <p className="IntermediateStep__list__item__amount">
                {housing.minNight}
              </p>
            </li>
            <li className="IntermediateStep__list__item">
              <p className="IntermediateStep__list__item__desc">
                Maximo de noches
              </p>
              <p className="IntermediateStep__list__item__amount">
                {housing.maxNight}
              </p>
            </li>
            <li className="IntermediateStep__list__item">
              <p className="IntermediateStep__list__item__desc">
                Precio por {days} noches
              </p>
              <p className="IntermediateStep__list__item__amount">
                ${parseMoney(price)}
              </p>
            </li>
            <li className="IntermediateStep__list__item">
              <p className="IntermediateStep__list__item__desc">
                Tarifa de limpieza
              </p>
              <p className="IntermediateStep__list__item__amount">
                ${parseMoney(cleanPrice)}
              </p>
            </li>
            <li className="IntermediateStep__list__item">
              <p className="IntermediateStep__list__item__desc">
                Tarifa por servicio
              </p>
              <p className="IntermediateStep__list__item__amount">
                ${parseMoney(servicePrice)}
              </p>
            </li>

            {taxes > 0 ? (
              <li className="IntermediateStep__list__item">
                <p className="IntermediateStep__list__item__desc">
                  Impuestos, tasas y cargos
                </p>
                <p className="IntermediateStep__list__item__amount">
                  ${parseMoney(taxes)}
                </p>
              </li>
            ) : (
              ""
            )}
            {weeklyDiscount > 0 ? (
              <li className="IntermediateStep__list__item">
                <p className="IntermediateStep__list__item__desc">
                  Descuento semanal
                </p>
                <p className="IntermediateStep__list__item__amount">
                  -${parseMoney(weeklyDiscount)}
                </p>
              </li>
            ) : (
              ""
            )}
            {monthlyDiscount > 0 ? (
              <li className="IntermediateStep__list__item">
                <p className="IntermediateStep__list__item__desc">
                  Descuento mensual
                </p>
                <p className="IntermediateStep__list__item__amount">
                  -${parseMoney(monthlyDiscount)}
                </p>
              </li>
            ) : (
              ""
            )}
            <li className="IntermediateStep__list__item">
              <p className="IntermediateStep__list__item__desc">Total</p>
              <p className="IntermediateStep__list__item__amount">
                ${parseMoney(totalPrice)}
              </p>
            </li>
            {error !== "" ? (
              <li className="IntermediateStep__list__item">
                <p className="IntermediateStep__list__item__amount">
                  Error: {error}
                </p>
              </li>
            ) : (
              ""
            )}
          </ul>
          {previaje > 0 ? (
            <div className="IntermediateStep__list">
              <div className="IntermediateStep__list__item">
                <p className="IntermediateStep__list__item__desc">
                  Crédito PREVIAJE:
                </p>
                <p className="IntermediateStep__list__item__amount">
                  ${parseMoney(previaje)}
                </p>
              </div>
              <div className="IntermediateStep__list__item">
                <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                  * Por favor leer los{" "}
                  <a href={previajeLegal} target="_blank">
                    términos, bases y condiciones
                  </a>
                  .
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <button
            disabled={totalPrice === 0 || error ? true : false}
            className={
              totalPrice === 0 || error
                ? "IntermediateStep__disabled"
                : "IntermediateStep__cta"
            }
            onClick={(e) => {
              if (loadingPrice) e.preventDefault();
              if (!checkin || !checkout) e.preventDefault();
              if (!userState.login) {
                toLogin();
              } else if (NOW <= TO) {
                setVisible(!visible);
              } else {
                history.push(
                  `/property/${id}/checkout?id=${id}&servicePrice=${servicePrice}&price=${price}&cleanPrice=${cleanPrice}&totalPrice=${totalPrice}&checkin=${checkin}&checkout=${checkout}&persons=${
                    adults + childs
                  }&housingTitle=${
                    housing.title
                  }&monthlyDiscount=${monthlyDiscount}&weeklyDiscount=${weeklyDiscount}&realPrice=${realPrice}&typeHousingReservation=${typeHousingReservation}&previaje=${previaje}&taxes=${taxes}`
                );
              }
            }}
          >
            {loadingPrice ? "Calculando..." : "Reservar"}
          </button>
          {/* Modal */}
          <Modal
            title={"Aviso importante sobre PREVIAJE"}
            visible={visible}
            onCancel={() => setVisible(!visible)}
            onOk={() =>
              history.push(
                `/property/${id}/checkout?id=${id}&servicePrice=${servicePrice}&price=${price}&cleanPrice=${cleanPrice}&totalPrice=${totalPrice}&checkin=${checkin}&checkout=${checkout}&persons=${
                  adults + childs
                }&housingTitle=${
                  housing.title
                }&realPrice=${realPrice}&previaje=${previaje}&taxes=${taxes}`
              )
            }
            cancelButtonProps={cancelButtonStyle}
            okButtonProps={okButtonStyle}
            cancelText={"Cancelar"}
            okText={"Entiendo y quiero reservar"}
          >
            <h2>¡Hola {userState.firstName}!</h2>
            <p>
              Queríamos avisarte que para enviarte la factura de tu compra para
              previaje es requisito que cargues nombre completo,{" "}
              <b>CUIT y dirección</b> sin eso no podremos emitir tu{" "}
              <b>factura</b>!
            </p>
            <br />
            <p>¡No te olvides, de esa info!</p>
            <br />
            <p>
              Acordate podes contactarnos a nuestro WhatsApp{" "}
              <a
                href="https://api.whatsapp.com/send?phone=5492233018749"
                target="_blank"
                rel="noopener noreferrer"
              >
                +54 9 223 301 8749
              </a>
              .
            </p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default IntermediateStep;
