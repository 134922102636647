// Librerías
import React from 'react';
import { connect } from 'react-redux';
// Componentes
import Navbar from '../Navbar/Navbar';
import MainSearch from '../MainSearch/MainInputSearch';
import Flag from './Flag';
// Recursos
import logoBlanco from '../../assets/static/logotipo.png';
import {isMobile} from 'react-device-detect';
import '../../assets/styles/LandingPage/_header.sass';

const Headers = (props) => {
    window.scrollTo(0, 0);
    const selectNavbar = (props) =>{
        if(isMobile && props.login){
            return  <Navbar isLanding={true} logo="white" showMobile={true} />           
        }else{
            return <Navbar isLanding={true} logo="white" showMobile={true} />
        }
    }
    
    return(
        <div className="header">
            <div className="header__overlay">
                {selectNavbar(props)}
                    {/* <Navbar isLanding={true} logo="white" showMobile={true} /> */}
                <div className="header_wrapper">
                    <img className="header_wrapper__logo" src={logoBlanco} alt="Alohar"/>
                    
                    <div className="header_wrapper_container">
                        <MainSearch />
                        <h2 className="tagline">Alohate en Pesos, Fácil y Seguro</h2>
                    </div>
                    {/* <Logged /> */}
                </div>
            </div>
            {/* <Flag /> */}
        </div>
    );
}

const mapStateToProps = (reducers) => {
    return reducers.userReducer
  };
  
export default connect(mapStateToProps, null)(Headers)

//export default Headers;