// Librerías
import React from 'react';
// Components
import ConfirmedComponent from '../components/Confirmed/ConfirmedComponent';
import CheckoutComponentMobile from '../components/Confirmed/ConfirmedComponentMobile';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

const ConfirmedContainerMobile = () => {
  return(
    <React.Fragment>
      <CheckoutComponentMobile />
    </React.Fragment>
  )
}

export default ConfirmedContainerMobile;