// Liberías
import React, { Component } from "react";
// Componentes
import Book from "./Utils/Book";
import Info from "./Utils/PropertyInfo";
import Gallery from "./Utils/PropertyGallery";
import Location from "./Utils/property-location/PropertyLocation";
import Properties from "./Utils/PropertyProperties";
import Reviews from "./Utils/PropertyReviews";
import Separator from "../Utils/Separator";
// Assets
import "../../assets/styles/PropertyPage/_property_page.sass";

class PropertyComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="PropertyComponent">
          <Gallery images={this.props.images} video={this.props.video} />
          <div className="PropertyComponent__info">
            <Info housing={this.props.housing} />
            <div className="PropertyComponent__info__sidebar">
              <Book housing={this.props.housing} />
              {/* <Calendar /> */}
            </div>
          </div>
          <div className="PropertyComponent__location">
            <Location housing={this.props.housing} />
          </div>

          <div className="PropertyComponent__reviews">
            <Separator />
            <Reviews housing={this.props.housing.id} />
            <Separator />
          </div>
          <div className="PropertyComponent__properties">
            <Properties housing={this.props.housing} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PropertyComponent;
