// Librerías
import React from 'react';
import { connect, useDispatch } from 'react-redux';
// Componentes
import { closeModal } from '../../store/modal/actions'
// Recursos
import x from '../../assets/static/Modals/x.png';
import '../../assets/styles/Modals/_confirm_modal.sass';

import {
    EmailShareButton,
    FacebookShareButton,
    PinterestShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    EmailIcon,
    WhatsappIcon,
    FacebookMessengerIcon,
    PinterestIcon,
    TelegramIcon
  } from "react-share";

const ShareModal = ({title, text}) => {
    const url = window.location.href;
    const hashtags = ["alohar", "AlojateEnPesos"]
    const dispatch = useDispatch()
    
    return(
        <div className="ConfirmModal">
            <header className="ConfirmModal__header">Compartir en tus redes sociales <img onClick={() => dispatch(closeModal())} alt="x" src={x} className="x"/></header>
            <section className="ConfirmModal__section">
                <h1 className="ConfirmModal__section__titleNaranja">¡Envíale a tus contactos esta oportunidad de alojarse en pesos, fácil y seguro!</h1>
                <br/>
                <div class="ConfirmModal__section__row">
                        <TwitterShareButton
                            title={title}
                            url={url}
                            hashtags={hashtags}
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>

                        <EmailShareButton
                            subject="¡Mirá este alojamiento!"
                            body="Alojamientos en pesos, fácil y seguro. Mirá este y no te pierdas la oportunidad!"
                            title={title}
                            url={url}
                        >
                            <EmailIcon size={32} round/>
                        </EmailShareButton>

                        <FacebookShareButton
                            title={title}
                            url={url}
                            hashtags={hashtags}
                        >
                            <FacebookIcon size={32} round/>
                        </FacebookShareButton>
                        

                        <WhatsappShareButton
                        title={title}
                        url={url}
                        hashtags={hashtags}
                        >
                            <WhatsappIcon size={32} round/>
                        </WhatsappShareButton>

                        <TelegramShareButton
                        title={title}
                        url={url}
                        hashtags={hashtags}
                        >
                            <TelegramIcon size={32} round/>
                        </TelegramShareButton>
                </div>
                
            </section>
            <section className="ConfirmModal__buttons">
                <button className="button_gray" onClick={() => dispatch(closeModal())}>Cancelar</button>
            </section>
        </div>
    );
}

export default connect(null,{closeModal})(ShareModal);
